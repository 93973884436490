import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/login/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public loginForm:FormGroup; 
  validmsg: any;

  displayStyle_DoyouwantRegister = "none";
  tokenableid: any;
  displayStyleThankyou = "none";

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService,
    private route: Router
    ) { }

  ngOnInit(): void {

    this.initialize();    

  }

  initialize(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    $('#submitfinal').prop('disabled', true);

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      $(".div-center").attr('style', 'top: -250px');
    }


    // ********* //
    // $("#sidebar-wrapper").attr('style', 'display: none !important');
    // $(".container-fluid").attr('style', 'padding: 0px !important');
    // // $("body").attr('style', 'overflow: hidden !important');
    // $(".row").attr('overflow-y', 'hidden !important');
    // $(".navbar").css("display","none");
    // $("#sticky-footer").css("display","none");
    // ********* //

    // Form element defined below
    this.loginForm = this.fb.group({
      username: new FormControl('', Validators.required),
      pwd: new FormControl(''),
      forgotpwdemail: new FormControl(''),
      temppwdDiv: new FormControl(''),
      newpwdDiv: new FormControl(''),
      confirmpwd: new FormControl('')
    });

  }

  login() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.pwd.value)
        .subscribe((result: any) => {
          if (result.status == true){          
          
            this.tokenableid = result.data.tokenable_id;

            if(result.data.tempFlag == "no"){              

              if(result.data.mobile_verify == "YES") {

                this.displayStyle_DoyouwantRegister = "none";
                // console.log(result);
                localStorage.setItem('tokenable_id', result.data.tokenable_id);
                localStorage.setItem('username', this.loginForm.controls.username.value);
                localStorage.setItem('Token', result.data.token);
                localStorage.setItem('resstatus', result.status);
                localStorage.setItem('surveyLoginFlag', 'No');              
  
                let postdataObj = {"tokenable_id":result.data.tokenable_id};
                this.authService.getuserdetails(postdataObj)
                .subscribe((userdata: any) => {           
                                                  
                  // console.log(userdata.data[0]['type_name']);
                  localStorage.setItem('type_name', userdata.data[0]['type_name']);
                  localStorage.setItem('client_id', userdata.data[0]['client_id']);
                  localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
                  localStorage.setItem('company_name', userdata.data[0]['company_name']);
                  localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
                  localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);

  
                  // let register = 1;
  
                  if(userdata.data[0]['type_name'] == "superadmin"){
                    this.route.navigate(['/SuperAdmin-dashboard']);
                  }else if(userdata.data[0]['type_name'] == "admin"){
                    this.route.navigate(['/Admin-dashboard']);
                  }else if(userdata.data[0]['type_name'] == "brokeradmin"){
                    this.route.navigate(['/broker-dashboard']);
                    localStorage.setItem('broker_id', userdata.data[0]['broker_id']);
                  }else if(userdata.data[0]['type_name'] == "employeradmin"){
                    this.route.navigate(['/employer-dashboard']);
                    localStorage.setItem('employer_id', userdata.data[0]['employer_id']);
                  }else{
                    this.route.navigate(['/Client-dashboard']);
                    // if(register == 1){
                    //   this.route.navigate(['/profile']);
                    // }else{
                    //   this.route.navigate(['/clientCreation']);
                    // }
                  }
                  
                });                                                                    
  
              } else {
                // console.log(result);
                this.validmsg = result.message;
                // $('#validmsg').css('display', 'block');
                
                this.route.navigate(['/verify-mobile']);
                
                localStorage.setItem('tokenable_id', result.data.tokenable_id);
                localStorage.setItem('resstatus', result.status);
                localStorage.setItem('type_name', result.data.type_name);
                localStorage.setItem('plan_name', result.data.plan_name);
                localStorage.setItem('pagehide', 'yes');
                
              }

            }else{

              $('#logindiv').css('display', 'none');
              $('#forgotpwd').css('display', 'block');
              $('#forgotpwdDiv').css('display', 'none');
              $('#paramsg').css('display', 'none');
              $('.loginh4').css('display', 'none');
              $('.forgoth4').css('display', 'none');
              $('#newpwdDiv').css('display', 'block');
              $('#confirmpwd').css('display', 'block');
              $('#temppwdDiv').css('display', 'none');
              $('#submitemail').css('display', 'none');
              $('#forgotpwdemail').css('display', 'none');
              $('#submitpwd').css('display', 'block');
              $('.reseth4').css('display', 'block');              
              
            }
            
          }

        }, (error) => {

          this.displayStyle_DoyouwantRegister = "block";
          // console.log(error);
          // console.log(error.error.status);
          // console.log(error.error.message);

          this.validmsg = error.error.message;
          // $('#validmsg').css('display', 'block');          
        });
    } else {

    }
  }  

  closePopup(val: any){
    if(val == 'yes'){
      this.route.navigate(['/register']); 
    }else{
      this.displayStyle_DoyouwantRegister = "none";
    }
  }

  openforgotpwd(){
    $('#logindiv').css('display', 'none');
    $('#forgotpwd').css('display', 'block');
    $('#forgotpwdDiv').css('display', 'block');
    $('#paramsg').css('display', 'block');
    $('.loginh4').css('display', 'none');
    $('.forgoth4').css('display', 'block');
    $('#newpwdDiv').css('display', 'none');
    $('#confirmpwd').css('display', 'none');
    $('#temppwdDiv').css('display', 'none');
    $('#submitpwd').css('display', 'none');
    $('.reseth4').css('display', 'none');
  }

  backtologin(){
    $('#logindiv').css('display', 'block');
    $('#forgotpwd').css('display', 'none');
    $('#forgotpwdDiv').css('display', 'none');
    $('.loginh4').css('display', 'block');
    $('.forgoth4').css('display', 'none');           
    $('.reseth4').css('display', 'none');
  }

  submitemail(){
    
    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    $('#forgotpwdDiv').css('display', 'none');    

    var postdata = {
      "email_address": this.loginForm.controls.forgotpwdemail.value
    };

    this.authService.emailSendTempPassword(postdata)
    .subscribe((result: any) => {
      if (result.status == true) {
      
        this.show = false;
      // console.log(result);                                  

      // $('#temppwdDiv').css('display', 'block');
      // $('#newpwdDiv').css('display', 'block');
      // $('#confirmpwd').css('display', 'block');
      // $('#submitpwd').css('display', 'block');
      // $('#submitemail').css('display', 'none');
      // $('#paramsg').css('display', 'none');

      // $('.loginh4img').css('display', 'block');
      // $('.forgoth4').css('display', 'none');
      // $('.reseth4').css('display', 'none');

      this.backtologin();

      // this.tokenableid = result.data.tokenable_id;

      } else {
        // console.log(result);            
      }
    }, (error) => {
      
      // console.log(error);
      // console.log(error.error.status);
      // console.log(error.error.message);

      // this.validmsg = error.error.message;
      // $('#validmsg').css('display', 'block');          
    });
          
  }

  submitpwd(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id": this.tokenableid,
      // "temp_password": this.loginForm.controls.temppwdDiv.value,
      "new_password": this.loginForm.controls.newpwdDiv.value
    };

    this.authService.sendnewPwd(postdata)
    .subscribe((result: any) => {
      if (result.status == true) {
      
        this.show = false;
        // console.log(result);           
        // this.displayStyleThankyou = "block";              

        localStorage.setItem('tokenable_id', result.data.tokenable_id);
        localStorage.setItem('username', this.loginForm.controls.username.value);
        localStorage.setItem('Token', result.data.token);
        localStorage.setItem('resstatus', result.status);
        localStorage.setItem('surveyLoginFlag', 'No');              

        let postdataObj = {"tokenable_id":result.data.tokenable_id};
        this.authService.getuserdetails(postdataObj)
        .subscribe((userdata: any) => {           
                                          
          // console.log(userdata.data[0]['type_name']);
          localStorage.setItem('type_name', userdata.data[0]['type_name']);
          localStorage.setItem('client_id', userdata.data[0]['client_id']);
          localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
          localStorage.setItem('company_name', userdata.data[0]['company_name']);
          localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
          localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);

          // let register = 1;

          if(userdata.data[0]['type_name'] == "superadmin"){
            this.route.navigate(['/SuperAdmin-dashboard']);
          }else if(userdata.data[0]['type_name'] == "admin"){
            this.route.navigate(['/Admin-dashboard']);
          }else{
            this.route.navigate(['/Client-dashboard']);
            // if(register == 1){
            //   this.route.navigate(['/profile']);
            // }else{
            //   this.route.navigate(['/clientCreation']);
            // }
          }
            
          });
      
      } else {
        // console.log(result);            
      }
    }, (error) => {
      
      // console.log(error);
      // console.log(error.error.status);
      // console.log(error.error.message);

      // this.validmsg = "Temporary Password do not match";
      // $('#validmsg').css('display', 'block');          
    });

  }

  checkpwd(){

    if(this.loginForm.controls.newpwdDiv.value == this.loginForm.controls.confirmpwd.value){
      $('#submitfinal').prop('disabled', false);      
      $('#validmsg').css('display', 'none');
    }else{
      $('#submitfinal').prop('disabled', true);
      this.validmsg = 'Password do no match';
      $('#validmsg').css('display', 'block');
    }

  }

  closePopup_forgotpwd() {    
    this.displayStyleThankyou = "none";
    $('#logindiv').css('display', 'block');
    $('#forgotpwd').css('display', 'none');
    $('.loginh4').css('display', 'block');
    $('.forgoth4').css('display', 'none');
    $('.reseth4').css('display', 'none');
    $('#validmsg').css('display', 'none');
  }

}
