import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  constructor() { }

  ngOnInit(): void {    
    localStorage.clear();

    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
    
  }  

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
