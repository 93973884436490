import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboard implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  resstatus = localStorage.getItem('resstatus');

  constructor(private route: Router) {
    if (this.resstatus == null) {
      this.route.navigate(['/home']);
    }    
    if (localStorage.getItem('type_name') != 'admin') {
      localStorage.clear();
      this.route.navigate(['/home']);
    }
   }

  ngOnInit(): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
  }

}
