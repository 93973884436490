<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title">Manage City
      <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right">Add New</button>
    </h3>
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <hr>

    <div class="table-responsive mt-4">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['country_name']}}</td>
                   <td>{{group['state_name']}}</td>                   
                   <td>{{group['districtName']}}</td>
                   <td style="width: 15%" align="center">
                    <!-- <img src="../../../assets/img/common icons/eye.png" id="viewuser" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="View" /> -->
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_add}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Add TPA</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="AddInfo">                    
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="country_name">Contact person *</label>
                            <input type="text" class="form-control" formControlName="country_name" id="country_name" placeholder="Contact person"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.country_name.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.country_name.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.country_name.errors.required">
                                    Contact person is required
                                </p>
                            </ng-container>
                        </div>
                    </div>            
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="name">Name *</label>
                            <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                    Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div>                                            
                </div>
            </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitAdd()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Edit City</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="EditInfo">                    
              <div class="row">
                  <div class="col-sm-6">
                      <div class="form-group">
                          <label class="bs-form-label" for="country_name_edit">Contact person *</label>
                          <input type="text" class="form-control" formControlName="country_name_edit" id="country_name_edit" placeholder="Contact person"
                          [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.country_name_edit.errors}"
                          required />
                          <ng-container
                              *ngIf="EditInfoSubmitted && getEditInformationFormControls.country_name_edit.errors">
                              <p class="error"
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.country_name_edit.errors.required">
                                  Contact person is required
                              </p>
                          </ng-container>
                      </div>
                  </div>            
                  <div class="col-sm-6">
                      <div class="form-group">
                          <label class="bs-form-label" for="name_edit">Name *</label>
                          <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                          [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                          required />
                          <ng-container
                              *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                              <p class="error"
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                  Name is required
                              </p>
                          </ng-container>
                      </div>
                  </div>
                  <div class="col-sm-6 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="edit_is_active">Status *</label>
                        <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                            <option [value]="1">Active</option>
                            <option [value]="0">Deactive</option>
                        </select>                    
                    </div>
                </div>
                  <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
              </div>
          </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Delete City</h4>
              </div>
              <form [formGroup]="DeleteInfo">
                  <div class="modal-body">                                
                      Are you sure you want to delete?
                  </div>
                  <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                      <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                  </div>
              </form>            
          </div>
      </div>
    </div>

</div>