<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title" *ngIf="addflag == 0">Manage Master Broker
      <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right">Add New</button>
    </h3>
    <h3 class="bs-title" *ngIf="addflag == 1">Add Broker
        <button type="button" class="btn btn-success" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
    </h3>
    <h3 class="bs-title" *ngIf="addflag == 2">Edit Broker
        <button type="button" class="btn btn-success" (click)="closePopupAdd()" role="button" style="float:right">Back</button>
    </h3>
    
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <hr>

    <div class="table-responsive mt-4" *ngIf="addflag == 0">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Name</th>
                <th>Email ID</th>
                <!-- <th>Added date</th> -->
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['name']}}</td>
                   <td>{{group['email_1']}}</td>                   
                   <!-- <td>{{group['added_date']}}</td> -->
                   <td style="width: 15%" align="center">
                    <!-- <img src="../../../assets/img/common icons/eye.png" id="viewuser" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="View" /> -->
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <!-- Add Form -->
    <div *ngIf="addflag == 1">                                        
        <form [formGroup]="AddInfo">                    
            <div class="row">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="name">Name *</label>
                        <input type="text" class="form-control" formControlName="name" id="name" placeholder="Name"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.name.errors}"
                        required />
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.name.errors.required">
                                Name is required
                            </p>
                        </ng-container>
                    </div>
                </div>                                            

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="has_saas">Has Saas *</label>
                        <select class="form-select" formControlName="has_saas" id="has_saas"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.has_saas.errors}"
                        required>
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.has_saas.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.has_saas.errors.required">
                                Has Saas is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="is_rfq">Is RFQ *</label>
                        <select class="form-select" formControlName="is_rfq" id="is_rfq"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors}"
                        required>
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                        <ng-container
                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.is_rfq.errors.required">
                                Is RFQ is required
                            </p>
                        </ng-container>
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pan">PAN *</label>
                        <input type="text" class="form-control" formControlName="pan" id="pan" placeholder="PAN" maxlength="10"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.pan.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.pan.errors.required">
                              PAN is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="gstin">GST *</label>
                        <input type="text" class="form-control" formControlName="gstin" id="gstin" placeholder="GST" maxlength="15"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.gstin.errors}"
                        required />
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.gstin.errors.required">
                              GST is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="ip_checking">Ip Checking</label>
                        <select class="form-select" formControlName="ip_checking" id="ip_checking">
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="country">Country</label>
                        <select class="form-select" formControlName="country" id="country" (change)="getState($event)">
                            <option value="" selected>Select Country</option>
                            <option *ngFor="let title of InfoArray_country" [value]="title.country_id">
                                {{title.country_name}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="state">State</label>
                        <select class="form-select" formControlName="state" id="state" (change)="getCity($event)">
                            <option value="" selected>Select State</option>
                            <option *ngFor="let title of InfoArray_state" [value]="title.state_id">
                                {{title.state_name}}
                            </option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="city">City</label>
                        <select class="form-select" formControlName="city" id="city">
                            <option value="" selected>Select City</option>
                            <option *ngFor="let title of InfoArray_city" [value]="title.pincode_id">
                                {{title.taluk}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pincode">Pincode</label>
                        <input type="text" class="form-control" formControlName="pincode" id="pincode" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" />                        
                    </div>
                </div>

                <div class="col-sm-6 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_1">Address Line 1 </label>
                        <input type="text" class="form-control" formControlName="address_line_1" id="address_line_1" placeholder="address line 1" />                        
                    </div>
                </div>

                <div class="col-sm-6 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_2">Address Line 2 </label>
                        <input type="text" class="form-control" formControlName="address_line_2" id="address_line_2" placeholder="address line 2" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_1">Email 1 *</label>
                        <input type="text" class="form-control" formControlName="email_1" id="email_1" placeholder="email 1"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.email_1.errors}"
                        required />                        
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.email_1.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.email_1.errors.required">
                                Email 1 is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_2">Email 2 </label>
                        <input type="text" class="form-control" formControlName="email_2" id="email_2" placeholder="email 2" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_1">Contact 1 *</label>
                        <input type="text" class="form-control" formControlName="contact_1" id="contact_1" placeholder="contact 1" (keypress)="isNumber($event)" maxlength="10"
                        [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.contact_1.errors}"
                        required /> 
                        <ng-container
                        *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1.errors">
                            <p class="error"
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.contact_1.errors.required">
                                Contact 1 is required
                            </p>
                        </ng-container>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_2">Contact 2 </label>
                        <input type="text" class="form-control" formControlName="contact_2" id="contact_2" placeholder="contact 2" (keypress)="isNumber($event)" maxlength="10" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="tfa_enabled">TFA Enabled</label>
                        <select class="form-select" formControlName="tfa_enabled" id="tfa_enabled">
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="tfa_type">TFA Type</label>
                        <select class="form-select" formControlName="tfa_type" id="tfa_type">
                            <option value="" selected>Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="status">Status</label>
                        <select class="form-select" formControlName="status" id="status">
                            <option value="" selected>Select</option>
                            <option value="1">Active</option>
                            <option value="0">Deactive</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="ip_address">Ip Address </label>
                        <input type="text" class="form-control" formControlName="ip_address" id="ip_address" placeholder="Ip address" (keyup)="isDecimal($event)" />                        
                        <small style="font-size: 12px;">for eg. 192.158.1.38 (copy you address)</small>
                    </div>
                </div>

                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-success mt-3" (click)="onSubmitAdd()" role="button">Save</button>                    
                </div>
            </div>            
        </form>                       
    </div>    

    <!-- Edit Form -->
    <div *ngIf="addflag == 2">                                        
        <form [formGroup]="EditInfo">                    
            <div class="row">

                <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
                
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="name_edit">Name *</label>
                        <input type="text" class="form-control" formControlName="name_edit" id="name_edit" placeholder="Name"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.name_edit.errors}"
                        required />
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.name_edit.errors.required">
                                Name is required
                            </p>
                        </ng-container>
                    </div>
                </div>                                            

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="has_saas_edit">Has Saas *</label>
                        <select class="form-select" formControlName="has_saas_edit" id="has_saas_edit"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors}"
                        required>                            
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.has_saas_edit.errors.required">
                                Has Saas is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="is_rfq_edit">Is RFQ *</label>
                        <select class="form-select" formControlName="is_rfq_edit" id="is_rfq_edit"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors}"
                        required>                            
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.is_rfq_edit.errors.required">
                                Is RFQ is required
                            </p>
                        </ng-container>
                    </div>
                </div>
                
                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pan_edit">PAN *</label>
                        <input type="text" class="form-control" formControlName="pan_edit" id="pan_edit" placeholder="PAN" maxlength="10"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors}"
                        required />
                        <ng-container
                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.pan_edit.errors.required">
                              PAN is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="gstin_edit">GST *</label>
                        <input type="text" class="form-control" formControlName="gstin_edit" id="gstin_edit" placeholder="GST" maxlength="15"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors}"
                        required />
                        <ng-container
                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.gstin_edit.errors.required">
                              GST is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                <div class="col-sm-4 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="ip_checking_edit">Ip Checking</label>
                        <select class="form-select" formControlName="ip_checking_edit" id="ip_checking_edit">                            
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>                       
                    </div>
                </div>                

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="country_edit">Country</label>
                        <select class="form-select" formControlName="country_edit" id="country_edit" (change)="getState($event)">
                            <!-- <option value="" selected>Select Country</option> -->
                            <option *ngFor="let title of InfoArray_country" [value]="title.country_id">
                                {{title.country_name}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="state_edit">State</label>
                        <select class="form-select" formControlName="state_edit" id="state_edit" (change)="getCity($event)">
                            <!-- <option value="" selected>Select State</option> -->
                            <option *ngFor="let title of InfoArray_state" [value]="title.state_id">
                                {{title.state_name}}
                            </option>
                        </select>                       
                    </div>
                </div>
                
                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="city_edit">City</label>
                        <select class="form-select" formControlName="city_edit" id="city_edit">
                            <!-- <option value="" selected>Select City</option> -->
                            <option *ngFor="let title of InfoArray_city" [value]="title.pincode_id">
                                {{title.taluk}}
                            </option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="pincode_edit">Pincode</label>
                        <input type="text" class="form-control" formControlName="pincode_edit" id="pincode_edit" placeholder="Pincode" maxlength="6" (keypress)="isNumber($event)" />                        
                    </div>
                </div>

                <div class="col-sm-6 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_1_edit">Address Line 1 </label>
                        <input type="text" class="form-control" formControlName="address_line_1_edit" id="address_line_1_edit" placeholder="address line 1" />                        
                    </div>
                </div>

                <div class="col-sm-6 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="address_line_2_edit">Address Line 2 </label>
                        <input type="text" class="form-control" formControlName="address_line_2_edit" id="address_line_2_edit" placeholder="address line 2" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_1_edit">Email 1 *</label>
                        <input type="text" class="form-control" formControlName="email_1_edit" id="email_1_edit" placeholder="email 1"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors}"
                        required />       
                        <ng-container
                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.email_1_edit.errors.required">
                              GST is required
                            </p>
                        </ng-container>                 
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="email_2_edit">Email 2 </label>
                        <input type="text" class="form-control" formControlName="email_2_edit" id="email_2_edit" placeholder="email 2" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_1_edit">Contact 1 *</label>
                        <input type="text" class="form-control" formControlName="contact_1_edit" id="contact_1_edit" placeholder="contact 1" (keypress)="isNumber($event)" maxlength="10"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.contact_1_edit.errors}"
                        required />    
                        <ng-container
                        *ngIf="EditInfoSubmitted && getEditInformationFormControls.contact_1_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.contact_1_edit.errors.required">
                              GST is required
                            </p>
                        </ng-container>                    
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="contact_2_edit">Contact 2 </label>
                        <input type="text" class="form-control" formControlName="contact_2_edit" id="contact_2_edit" placeholder="contact 2" (keypress)="isNumber($event)" maxlength="10" />                        
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="tfa_enabled_edit">TFA Enabled</label>
                        <select class="form-select" formControlName="tfa_enabled_edit" id="tfa_enabled_edit">                            
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="tfa_type_edit">TFA Type</label>
                        <select class="form-select" formControlName="tfa_type_edit" id="tfa_type_edit">                           
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="status_edit">Status</label>
                        <select class="form-select" formControlName="status_edit" id="status_edit">
                            <option [value]="1">Active</option>
                            <option [value]="0">Deactive</option>
                        </select>                       
                    </div>
                </div>

                <div class="col-sm-3 mt-3">
                    <div class="form-group">
                        <label class="bs-form-label" for="ip_address_edit">Ip Address </label>
                        <input type="text" class="form-control" formControlName="ip_address_edit" id="ip_address_edit" placeholder="Ip address" (keyup)="isDecimal_edit($event)" />                        
                        <small style="font-size: 12px;">for eg. 192.158.1.38 (copy you address)</small>
                    </div>
                </div>

                <div class="col-sm-12" align="center">
                    <button type="button" class="btn btn-success mt-3" (click)="onSubmitEdit()" role="button">Update</button>                    
                </div>
            </div>            
        </form>                       
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Delete Broker</h4>
              </div>
              <form [formGroup]="DeleteInfo">
                  <div class="modal-body">                                
                      Are you sure you want to delete?
                  </div>
                  <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                      <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                  </div>
              </form>            
          </div>
      </div>
    </div>

</div>