import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SuperAdminDashboard } from './superAdmin/dashboard/dashboard.component';
import { AdminDashboard } from './admin/dashboard/dashboard.component';
import { ClientDashboard } from './client/dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { ClientCreationComponent } from './client-creation/client-creation.component';
import { PricingComponent } from './client/pricing/pricing.component';
import { ProfileComponent } from './client/profile/profile.component';
import { UsersComponent } from './superAdmin/user mangement/users/users.component';
import { UsersClientComponent } from './superAdmin/user mangement/users-client/users-client.component';
import { EmployeeDependencyComponent } from './employee-dependency/employee-dependency.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { VerifymobileComponent } from './auth/verification/verifymobile/verifymobile.component';
import { VerifyemailComponent } from './auth/verification/verifyemail/verifyemail.component';
import { MarketDataComponent } from './superAdmin/market-data/market-data.component';
import { TpaDetailsComponent } from './superAdmin/tpa-details/tpa-details.component';
import { InsuranceCompanyInsurerComponent } from './superAdmin/insurance-company-insurer/insurance-company-insurer.component';
import { SelectProductComponent } from './client/select-product/select-product.component';
import { SelectProductMobileComponent } from './client/select-product-mobile/select-product-mobile.component';
import { ProductDetailsComponent } from './superAdmin/product-mgmt/product-details/product-details.component';
import { SubproductDetailsComponent } from './superAdmin/product-mgmt/subproduct-details/subproduct-details.component';
import { TopupPolicyDetailsComponent } from './topup-policy-details/topup-policy-details.component';
import { GpaPolicyDetailsComponent } from './gpa-policy-details/gpa-policy-details.component';
import { GtlPolicyDetailsComponent } from './gtl-policy-details/gtl-policy-details.component';
import { OpdPolicyDetailsComponent } from './opd-policy-details/opd-policy-details.component';
import { GoToPoliciesComponent } from './go-to-policies/go-to-policies.component';

import { PolicyConversionGmcComponent } from './superAdmin/policy-conversion-gmc/policy-conversion-gmc.component';
import { PolicyConversionGpaComponent } from './superAdmin/policy-conversion-gpa/policy-conversion-gpa.component';
import { PolicyConversionGtlComponent } from './superAdmin/policy-conversion-gtl/policy-conversion-gtl.component';
import { PolicyConversionOpdComponent } from './superAdmin/policy-conversion-opd/policy-conversion-opd.component';

import { PolicyFinalizeGmcComponent } from './superAdmin/policy-finalize-gmc/policy-finalize-gmc.component';
import { PolicyFinalizeGpaComponent } from './superAdmin/policy-finalize-gpa/policy-finalize-gpa.component';
import { PolicyFinalizeGtlComponent } from './superAdmin/policy-finalize-gtl/policy-finalize-gtl.component';
import { PolicyFinalizeOpdComponent } from './superAdmin/policy-finalize-opd/policy-finalize-opd.component';

import { SurveyLoginComponent } from './survey/auth/survey-login/survey-login.component';
import { SurveyRegisterComponent } from './survey/auth/survey-register/survey-register.component';
import { SurveyEmailVerificationComponent } from './survey/auth/survey-verification/survey-email-verification/survey-email-verification.component';

import { SurveySelectProductComponent } from './survey/survey-select-product/survey-select-product.component';

import { SurveyGmcPolicyDetailsComponent } from './survey/survey-gmc-policy-details/survey-gmc-policy-details.component';
import { SurveyGpaPolicyDetailsComponent } from './survey/survey-gpa-policy-details/survey-gpa-policy-details.component';
import { SurveyGtlPolicyDetailsComponent } from './survey/survey-gtl-policy-details/survey-gtl-policy-details.component';
import { SurveyOpdPolicyDetailsComponent } from './survey/survey-opd-policy-details/survey-opd-policy-details.component';

import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';

import { BenefitBenchmarkingComponent } from './survey/benefit-benchmarking/benefit-benchmarking.component';

import { SurveyManagerComponent } from './superAdmin/survey-manager/survey-manager.component';

import { ManageCountryComponent } from './superAdmin/manage-country/manage-country.component';
import { ManageStateComponent } from './superAdmin/manage-state/manage-state.component';
import { ManageCityComponent } from './superAdmin/manage-city/manage-city.component';
import { ManageBrokerComponent } from './superAdmin/manage-broker/manage-broker.component';
import { ManageIndustryComponent } from './superAdmin/manage-industry/manage-industry.component';
import { ManageUserTypesComponent } from './superAdmin/manage-user-types/manage-user-types.component';
import { ManagePlanComponent } from './superAdmin/manage-plan/manage-plan.component';
import { ManageAnnoucementsComponent } from './superAdmin/manage-annoucements/manage-annoucements.component';
import { ManageNewsComponent } from './superAdmin/manage-news/manage-news.component';
import { MasterBrokerComponent } from './superAdmin/master-broker/master-broker.component';


//broker
import { DashboardComponent } from './broker/dashboard/dashboard.component';
import { EmployerDashboardComponent } from './broker/employer-dashboard/employer-dashboard.component';
import { BrokerManageMasterEmployerComponent } from './broker/broker-manage-master-employer/broker-manage-master-employer.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'SuperAdmin-dashboard', component: SuperAdminDashboard },
  { path: 'Client-dashboard', component: ClientDashboard },
  { path: 'Admin-dashboard', component: AdminDashboard },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'home', component: HomeComponent },
  { path: 'clientCreation', component: ClientCreationComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'admin-users', component: UsersComponent },
  { path: 'admin-users-Client', component: UsersClientComponent },
  { path: 'employee-Dependency', component: EmployeeDependencyComponent },
  { path: 'policy-details', component: PolicyDetailsComponent },
  { path: 'verify-mobile', component: VerifymobileComponent },
  { path: 'verify-email', component: VerifyemailComponent },
  { path: 'admin-market-data', component: MarketDataComponent },
  { path: 'admin-tpa-details', component: TpaDetailsComponent },
  { path: 'admin-insurance-company-insurer', component: InsuranceCompanyInsurerComponent },
  { path: 'select-product', component: SelectProductComponent },
  { path: 'select-product-mobile', component: SelectProductMobileComponent },
  { path: 'admin-product-details', component: ProductDetailsComponent },
  { path: 'admin-subproduct-details', component: SubproductDetailsComponent },
  { path: 'topup-policy-details', component: TopupPolicyDetailsComponent },
  { path: 'gpa-policy-details', component: GpaPolicyDetailsComponent },
  { path: 'gtl-policy-details', component: GtlPolicyDetailsComponent },
  { path: 'opd-policy-details', component: OpdPolicyDetailsComponent },
  { path: 'go-to-policies', component: GoToPoliciesComponent },
  { path: 'admin-policy-conversion-gmc', component: PolicyConversionGmcComponent },
  { path: 'admin-policy-conversion-gpa', component: PolicyConversionGpaComponent },
  { path: 'admin-policy-conversion-gtl', component: PolicyConversionGtlComponent },
  { path: 'admin-policy-conversion-opd', component: PolicyConversionOpdComponent },
  { path: 'admin-policy-finalize-gmc', component: PolicyFinalizeGmcComponent},
  { path: 'admin-policy-finalize-gpa', component: PolicyFinalizeGpaComponent},
  { path: 'admin-policy-finalize-gtl', component: PolicyFinalizeGtlComponent},
  { path: 'admin-policy-finalize-opd', component: PolicyFinalizeOpdComponent},
  { path: 'survey-login', component: SurveyLoginComponent},
  { path: 'survey-register', component: SurveyRegisterComponent},
  { path: 'survey-verify-email', component: SurveyEmailVerificationComponent},
  { path: 'survey-select-product', component: SurveySelectProductComponent},
  { path: 'survey-gmc-policy-details', component: SurveyGmcPolicyDetailsComponent},
  { path: 'survey-gpa-policy-details', component: SurveyGpaPolicyDetailsComponent},
  { path: 'survey-gtl-policy-details', component: SurveyGtlPolicyDetailsComponent},
  { path: 'survey-opd-policy-details', component: SurveyOpdPolicyDetailsComponent},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'benefit-benchmarking', component: BenefitBenchmarkingComponent},
  { path: 'admin-survey-manager', component: SurveyManagerComponent},
  { path: 'admin-manage-country', component: ManageCountryComponent},
  { path: 'admin-manage-state', component: ManageStateComponent},
  { path: 'admin-manage-city', component: ManageCityComponent},
  { path: 'admin-manage-broker', component: ManageBrokerComponent},
  { path: 'admin-manage-industry', component: ManageIndustryComponent},
  { path: 'admin-manage-user-types', component: ManageUserTypesComponent},
  { path: 'admin-manage-plan', component: ManagePlanComponent},
  { path: 'admin-manage-annoucements', component: ManageAnnoucementsComponent},
  { path: 'admin-manage-news', component: ManageNewsComponent},
  { path: 'admin-manage-master-broker', component: MasterBrokerComponent},
  { path: 'broker-manage-master-employer', component: BrokerManageMasterEmployerComponent},
  { path: 'broker-dashboard', component: DashboardComponent},
  { path: 'employer-dashboard', component: EmployerDashboardComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
