<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title">Manage Plan
      <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right">Add New</button>
    </h3>
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <hr>

    <div class="table-responsive mt-4">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Plan</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['plan_name']}}</td>                
                   <td style="width: 15%" align="center">
                    <!-- <img src="../../../assets/img/common icons/eye.png" id="viewuser" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="View" /> -->
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_add}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Add Plan</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="AddInfo">                    
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="plan_name">Plan *</label>
                            <input type="text" class="form-control" formControlName="plan_name" id="plan_name" placeholder="Plan"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.plan_name.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.plan_name.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.plan_name.errors.required">
                                    Plan is required
                                </p>
                            </ng-container>
                        </div>
                    </div>  

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="plan_description">Description</label>
                            <input type="text" class="form-control" formControlName="plan_description" id="plan_description" placeholder="Description"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.plan_description.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.plan_description.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.plan_description.errors.required">
                                    Description is required
                                </p>
                            </ng-container>  
                        </div>
                    </div> 

                    <div class="col-sm-6 mt-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="monthly_charges">Monthly Charges</label>
                            <input type="text" class="form-control" formControlName="monthly_charges" id="monthly_charges" placeholder="Monthly Charges"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.monthly_charges.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.monthly_charges.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.monthly_charges.errors.required">
                                    Monthly Charges is required
                                </p>
                            </ng-container>
                        </div>
                    </div>         
                    
                    <div class="col-sm-6 mt-3">
                        <div class="form-group">
                            <label class="bs-form-label" for="yearly_charges">Yearly Charges</label>
                            <input type="text" class="form-control" formControlName="yearly_charges" id="yearly_charges" placeholder="Yearly Charges"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.monthly_charges.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.yearly_charges.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.yearly_charges.errors.required">
                                    Yearly Charges is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                                                                                                          
                </div>
            </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitAdd()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Edit Plan</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="EditInfo">                    
              <div class="row">
                  <div class="col-sm-6">
                      <div class="form-group">
                          <label class="bs-form-label" for="plan_name_edit">Plan *</label>
                          <input type="text" class="form-control" formControlName="plan_name_edit" id="plan_name_edit" placeholder="Plan"
                          [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.plan_name_edit.errors}"
                          required />
                          <ng-container
                              *ngIf="EditInfoSubmitted && getEditInformationFormControls.plan_name_edit.errors">
                              <p class="error"
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.plan_name_edit.errors.required">
                                  Plan is required
                              </p>
                          </ng-container>
                      </div>
                  </div>    
                  
                  <div class="col-sm-6">
                    <div class="form-group">
                        <label class="bs-form-label" for="plan_description_edit">Description</label>
                        <input type="text" class="form-control" formControlName="plan_description_edit" id="plan_description_edit" placeholder="Description" 
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.edit_plan_description.errors}"
                        required />
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.plan_description_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.plan_description_edit.errors.required">
                                Description is required
                            </p>
                        </ng-container>                        
                    </div>
                </div> 

                <div class="col-sm-6 mt-3"> 
                    <div class="form-group">
                        <label class="bs-form-label" for="monthly_charges_edit">Monthly Charges</label>
                        <input type="text" class="form-control" formControlName="monthly_charges_edit" id="monthly_charges_edit" placeholder="Monthly Charges"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.edit_monthly_charges.errors}"
                        required />
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.monthly_charges_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.monthly_charges_edit.errors.required">
                                Monthly Charges is required
                            </p>
                        </ng-container>
                    </div>
                </div> 

                <div class="col-sm-6 mt-3"> 
                    <div class="form-group">
                        <label class="bs-form-label" for="yearly_charges_edit">Yearly Charges</label>
                        <input type="text" class="form-control" formControlName="yearly_charges_edit" id="yearly_charges_edit" placeholder="Yearly Charges"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.yearly_charges_edit.errors}"
                        required />
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.yearly_charges_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.yearly_charges_edit.errors.required">
                                Yearly Charges is required
                            </p>
                        </ng-container>
                    </div>
                </div>

                  <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
              </div>
          </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Delete Plan</h4>
              </div>
              <form [formGroup]="DeleteInfo">
                  <div class="modal-body">                                
                      Are you sure you want to delete?
                  </div>
                  <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                      <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                  </div>
              </form>            
          </div>
      </div>
    </div>

</div>