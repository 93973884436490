import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { UsersCreationService } from '../survey-manager/users';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-survey-manager',
  templateUrl: './survey-manager.component.html',
  styleUrls: ['./survey-manager.component.css']
})
export class SurveyManagerComponent implements OnInit {

  public usersInfo:FormGroup;
  usersInfoSubmitted: boolean = false;
  
  public usersEdit:FormGroup;  
  usersEditSubmitted: boolean = false;

  public documentUpload:FormGroup;
  documentUploadSubmitted: boolean = false;

  public usersDelete:FormGroup;  

  member_data: any;

  user_role: any;
  validmsg: any;
  planArray: any = [];
  usersArray: any = [];    
  displayStyle = "none";
  displayStyleDelete = "none";
  displayStyleDelete2 = "none";
  displayStyle_add = "none";
  displayStyle_upload = "none";
  isuranceCompanyDetailsarr: any = [];
  
  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: [1,2,3], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  resstatus = localStorage.getItem('resstatus');

  masterSelected:boolean;
  checklist:any;
  checkedList:any;

  show = false;
  fullScreen = true;
  template = ``;

  constructor(private fb: FormBuilder, private usersCreationService: UsersCreationService, private route: Router) { 
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {        
        this.user_role = localStorage.getItem('type_name');        
      }  
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }  
    });
   }

  ngOnInit(): void {

    this.initGroup();  

  }

  initGroup(){    
    
    this.usersArray = [];
    this.getUsersInfo();
    this.getplanInfo();    

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.usersInfo = this.fb.group({
      email: new FormControl(''),
      company_name: new FormControl(''),    
      contact_person_name: new FormControl(''),
      mobile: new FormControl(''),
      address: new FormControl(''),
      insurance_company: new FormControl('')
    });
    
    // Form element defined below
    this.usersEdit = this.fb.group({
      edit_id: new FormControl(''),
      edit_email: new FormControl(''),
	    edit_company_name: new FormControl(''),    
      edit_contact_person_name: new FormControl(''),
      edit_mobile: new FormControl(''),
      edit_address: new FormControl(''),
      edit_insurance_company: new FormControl('')
    });

    // Form element defined below
    this.usersDelete = this.fb.group({
      delete_id: new FormControl('')      
    });    

    // Step 4 Form element defined below
    this.documentUpload = this.fb.group({
      
      member_data: new FormControl(''),
      
    }); 

    this.getIsuranceCompanyDetails();

  }

  getIsuranceCompanyDetails(){
    this.usersCreationService.getIsuranceCompanyDetails()
      .subscribe((result: any) => {

        // console.log(result[0]);
        this.isuranceCompanyDetailsarr = result;
        // this.isuranceCompanyDetailsarr.unshift({'insure_comp_id' : 0});
        // console.log(this.isuranceCompanyDetailsarr);

        // for(var i = 0; i < result.length; i++){

        //   let html = `              
        //     <ng-option [value]="`+result[i]['insure_comp_id']+`">`+result[i]['name']+`</ng-option>
        //   `;

        //   $('#insurance_company').append(html);
        //   $('#previous_insurer').append(html);

        // }

      }, (error) => {
          // console.log(result);
    });
  }

  getplanInfo(){
    this.usersCreationService.getplanInfo()
        .subscribe((result: any) => {
          
          // console.log(result);
          this.planArray = result;
          
        }, (error) => {          
            // console.log(result);                 
      });
  }

  getUsersInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``; 

    this.usersCreationService.getUsersInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.usersArray = result;
          this.checklist = result;                    
          this.dtTrigger.next();

        }, (error) => {          
          
          this.usersArray = [];
          
      });
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }  
    if(evt.target.value == "0"){
      var value = '#' + evt.target.id;
      $(value).val('');
    }    
    return true;
  }

  closealert(){
    $("#validmsg").css('display', 'none');    
    $("#errormsg").css('display', 'none');    
  }

  onSubmit() {

    this.usersInfoSubmitted = true;
    if (this.usersInfoSubmitted && this.usersInfo.valid) {
      
      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "email_address":this.usersInfo.controls['email'].value,
        "company_name":this.usersInfo.controls['company_name'].value,
        "contact_person_name":this.usersInfo.controls['contact_person_name'].value,
        "contact_number":this.usersInfo.controls['mobile'].value,
        "address":this.usersInfo.controls['address'].value,
        "industry":this.usersInfo.controls['insurance_company'].value,
      };
      // console.log(postdata);

      this.usersCreationService.usersCreate(postdata)
        .subscribe((result: any) => {
          
          this.show = false;
          // console.log(result);

          if (result.status == true) {            
            
            // this.usersInfo.reset();            
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            this.getUsersInfo();
            this.dtTrigger.unsubscribe();
            this.validmsg = result.message;
            this.displayStyle_add= "none";

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }  

  get getUsersInformationFormControls() {
    return this.usersInfo.controls;
  }
  
  get getUsersEditrmationFormControls() {
    return this.usersEdit.controls;
  }

  get getdocumentUploadFormControls() {
    return this.documentUpload.controls;
  }

  openPopup(res: any) {
    this.displayStyle = "block";
    this.onEditLoad(res);
  }

  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_id
    };

    this.usersCreationService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result);
        this.usersDelete.controls['delete_id'].setValue(result[0]['client_id']);
        
      }, (error) => {          
               
    });

  }

  onSubmitsurvey(){
    this.displayStyleDelete2 = "block";
  }

  sendsurvey() {            

    this.show = true;
    this.fullScreen = true;
    this.template = ``;    
    this.displayStyleDelete2 = "none";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "user_ids":this.checkedList
    };

    this.usersCreationService.sendsurvey(postdata)
      .subscribe((result: any) => {
                
        // console.log(result);

        if (result.status == true) {            

          this.show = false;

          this.getUsersInfo();
          this.dtTrigger.unsubscribe();          

          // this.usersInfo.reset();            
          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');      

        } else {
          // console.log(result);            
        }        
      }, (error) => {   
        
        this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block'); 
               
    });

  }

  onEditLoad(res: any){          

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_id
    };

    this.usersCreationService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        // console.log(result[0]['email']);
     
        this.usersEdit.controls['edit_id'].setValue(result[0]['client_id']);
        this.usersEdit.controls['edit_email'].setValue(result[0]['email_address']);
        this.usersEdit.controls['edit_company_name'].setValue(result[0]['company_name']);
        this.usersEdit.controls['edit_contact_person_name'].setValue(result[0]['contact_person']);
        this.usersEdit.controls['edit_mobile'].setValue(result[0]['contact_number']);
        this.usersEdit.controls['edit_address'].setValue(result[0]['registered_address']);
        this.usersEdit.controls['edit_insurance_company'].setValue(result[0]['industry']);

      }, (error) => {          
               
    });

  }

  closePopup() {
    this.displayStyle = "none";
    this.displayStyleDelete = "none";
    this.displayStyle_add = "none";
    this.displayStyle_upload = "none"; 
    this.displayStyleDelete2 = "none";  
  }

  onSubmitEdit(){

    let id = $('#edit_id').val();
   
    this.usersEditSubmitted = true;
    if (this.usersEditSubmitted && this.usersEdit.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,

        // "email":this.usersEdit.controls['edit_username'].value,
        "company_name":this.usersEdit.controls['edit_company_name'].value,
        "contact_person_name":this.usersEdit.controls['edit_contact_person_name'].value,
        "contact_number":this.usersEdit.controls['edit_mobile'].value,
        "registered_address":this.usersEdit.controls['edit_address'].value,
        "industry":this.usersEdit.controls['edit_insurance_company'].value,
        
        // "is_active":this.usersEdit.controls['edit_is_active'].value
      };

      this.usersCreationService.usersUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            this.closePopup();         
            this.validmsg = result.message;
            this.getUsersInfo();
            this.dtTrigger.unsubscribe();

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.usersCreationService.usersDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          

          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');          
          this.validmsg = result.message;   
          this.closePopup();       
          this.getUsersInfo();
          this.dtTrigger.unsubscribe();

        } else {
          // console.log(result);            
        }
      }, (error) => {    
        this.closePopup();
        this.validmsg = error.error.message;      
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  openPopupAdd(){    
    this.displayStyle_add = "block";
  }

  openPopupupload(){    
    this.displayStyle_upload = "block";
  }

  member_data_change(event: any) {
    if (event.target.files.length > 0) {

      this.member_data = event.target.files[0];
      // console.log(event.target.files[0])

      $("#custom-file-upload_member_data").empty();
      $('#custom-file-upload_member_data').html(event.target.files[0]['name']);
      $('#custom-file-upload_member_data').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#custom-file-upload_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      $('#small_member_data').empty();
      $('#small_member_data').html('');
      $('#small_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
    }

  }
  
  submit_upload(){

    this.documentUploadSubmitted = true;
    if (this.documentUploadSubmitted && this.documentUpload.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``; 
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "member_data": this.member_data,
      };

      this.usersCreationService.submit_upload(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none'); 
            
            this.closePopup();         
            

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();         
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // Check All Checkbox Checked
  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  // Get List of Checked Items
  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i]['user_id']);
    }
    //this.checkedList = JSON.stringify(this.checkedList);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
