import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class Service {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }
  
  /*********** GMC **********/

  getgmc_Section1(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingprimary', 
      data
    , httpOptions);
  }

  getgmc_Section1_chart(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingprimarygraph', 
      data
    , httpOptions);
  }

  getgmc_Section2(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingmaternity', 
      data
    , httpOptions);
  }

  getgmc_Section2_chart(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingmaternitygraph', 
      data
    , httpOptions);
  }

  getgmc_Section3(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingcostcontrol', 
      data
    , httpOptions);
  }

  getgmc_Section3_chart(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingcostcontrolgraph', 
      data
    , httpOptions);
  }

  getgmc_Section4(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingother', 
      data
    , httpOptions);
  }

  getfilters(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingsurvey', 
      data
    , httpOptions);
  }
  
  getFilers(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingsurvey', 
      data
    , httpOptions);
  }

  getIndustryType(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'industry-detailsfront', 
      data
    , httpOptions);
  }

  getgmc_Section4_chart(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingmedicalpeer', 
      data
    , httpOptions);
  }
  /************ END *********/

  
  /*********** GTL **********/
  getgtl(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkingsumassuredgtl', 
      data
    , httpOptions);
  }

  gtl_array_chart(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'report/benefitsbenchmarkinggtlridergraph', 
      data
    , httpOptions);
  } 

  /************ END *********/

}