<head>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css">
</head>
<!-- header -->
<header class="header-area">
    <div class="header-top second-header d-none d-md-block">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-3 d-none d-lg-block">
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <div class="header-cta">
                        <ul>
                            <li>
                                <img src="../../assets/website/icon/email.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">info@neodigit.in</span>
                            </li>
                            <!-- <li>
                                <img src="../../assets/website/icon/telephone.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">+8 12 3456897</span>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-3 d-none d-lg-block">
                    <div class="header-social text-right">
                        <span>
                            <a href="#" title="Facebook"><i class="fab fa-facebook"></i></a>
                            <a href="#" title="Twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>                            
                        </span>
                        <!--  /social media icon redux -->                        
                    </div>                    
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                </div>                

            </div>
        </div>
    </div>
    <div id="header-sticky" class="menu-area">
        <div class="container">
            <div class="second-menu">
                <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-2">
                        <div class="logo">
                            <a routerLink="/home" href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                    </div>
                    <div class="col-xl-10 col-lg-8">
                        <div class="main-menu text-right pr-15">
                            <nav id="mobile-menu">
                                <ul>
                                    <li class="has-sub">
                                        <a routerLink="/home" href="#">Home</a>
                                    </li>
                                    <li><a routerLink="/about-us" href="#">About Us</a></li>                                    
                                    <li><a routerLink="/contact-us" href="#">Contact Us</a></li>
                                    
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <!-- <div class="col-xl-2 col-lg-2 d-none d-lg-block">
                        <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                    </div> -->
                    <div class="col-12">
                        <div class="mobile-menu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header-end -->
<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <!-- breadcrumb-area -->
    <section class="breadcrumb-area d-flex align-items-center" style="background-image:url(../../assets/website/bg/header-bg.png)">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="breadcrumb-wrap text-center">
                        <div class="breadcrumb-title mb-30">
                            <h2>About Us</h2>                                    
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- breadcrumb-area-end -->
        <!-- about-area -->
    <section id="about" class="about-area about-p mt-100 pb-80 p-relative" style="background-image:url(../../assets/website/an-bg/an-bg03.png); background-size: contain; background-repeat: no-repeat;background-position: center center;">
        <div class="container">
            <div class="row align-items-center">					
              <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="s-about-img p-relative">
                        <img src="../../assets/website/bg/illlustration.png" alt="img">
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="about-content s-about-content pl-30">
                        <div class="section-title mb-20">
                            <span>About Us</span>
                            <h2>We Are Specialize in Medical Diagnositics</h2>                                  
                        </div>
                        <p>Nulla lacinia sapien a diam ullamcorper, sed congue leo vulputate. Phasellus et ante ultrices, sagittis purus vitae, sagittis quam. Quisque urna lectus, auctor quis tristique tincidunt, semper vel lectus. Mauris eget eleifend massa. Praesent ex felis, laoreet nec tellus in, laoreet commodo ipsum.</p>
                        
                        <ul>
                                <li>
                                    <div class="icon"><i class="fas fa-chevron-right"></i></div> 
                                    <div class="text">Pellentesque placerat, nisi congue vehicula efficitur.
                                    </div>
                                </li>
                                <li>
                                    <div class="icon"><i class="fas fa-chevron-right"></i></div> 
                                    <div class="text">Pellentesque placerat, nisi congue vehicula efficitur.
                                    </div>
                                </li>
                                <li>
                                    <div class="icon"><i class="fas fa-chevron-right"></i></div> 
                                    <div class="text">Phasellus mattis vitae magna in suscipit. Nam tristique posuere sem, mattis molestie est bibendum.
                                    </div>
                                </li>
                            <div></div>
                        </ul>
                       
                       <div class="slider-btn mt-30">                                          
                                    <a href="#" class="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">Read More <i class="fas fa-chevron-right"></i></a>					
                                </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <!-- about-area-end -->
    
    <!-- counter-area -->
    <div class="counter-area pt-100 mb-100" style="background-image:url(../../assets/website/an-bg/an-bg04.png); background-repeat: no-repeat; background-size: contain; ">
        <div class="container">
            <div class="row align-items-end">
                 <div class="col-lg-3 col-md-6 col-sm-12">
                   <div class="single-counter text-center" >
                     <img src="../../assets/website/icon/cunt-icon01.png" alt="img">
                        <div class="counter p-relative">
                            <span class="count">500</span><small>+</small>                                   
                        </div>
                        <p>Doctors At Work</p>                               
                    </div>
                </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="single-counter text-center" >
                        <img src="../../assets/website/icon/cunt-icon02.png" alt="img">
                        <div class="counter p-relative">
                            <span class="count">58796</span><small>+</small>                                   
                        </div>
                        <p>Happy Patients</p>                               
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="single-counter text-center" >
                         <img src="../../assets/website/icon/cunt-icon03.png" alt="img">
                        <div class="counter p-relative">
                           <span class="count">500</span><small>+</small>                       
                        </div>
                        <p>Medical Beds</p>                               
                    </div>
                </div>
                 <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="single-counter text-center" >
                         <img src="../../assets/website/icon/cunt-icon04.png" alt="img">
                        <div class="counter p-relative">
                            <span class="count">200</span><small>+</small>                      
                        </div>
                        <p>Winning Awards</p>                                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- counter-area-end -->	
      <!-- newslater-area -->
    <section class="newslater-area pb-50" style="background-image: url(../../assets/website/an-bg/an-bg06.png);background-position: center bottom; background-repeat: no-repeat;" >
        <div class="container">
            <div class="row align-items-end">
                <div class="col-xl-4 col-lg-4 col-lg-4">
                    <div class="section-title mb-100">
                        <span>NEWSLETTER</span>          
                        <h2>Subscribe To Our Newsletter</h2>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <form name="ajax-form" id="contact-form4" action="#" method="post" class="contact-form newslater pb-130">
                       <div class="form-group">
                          <input class="form-control" id="email2" name="email" type="email" placeholder="Email Address..." value="" required=""> 
                          <button type="submit" class="btn btn-custom" id="send2">Subscribe <i class="fas fa-chevron-right"></i></button>
                       </div>
                       <!-- /Form-email -->	
                    </form>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <img src="../../assets/website/bg/news-illustration.png">
                </div>
            </div>
           
        </div>
    </section>
    <!-- newslater-aread-end -->
    <!-- testimonial-area -->
    <section id="testimonios" class="testimonial-area testimonial-p pt-50 pb-85 fix" style="background-image: url(../../assets/website/an-bg/an-bg07.png);background-position: center; background-repeat: no-repeat;background-size: contain;" >
        <div class="container">
              <div class="row justify-content-center">
                
                <div class="col-lg-8"> 
             <div class="section-title center-align mb-60 text-center">
                        <span>TESTIMONIAL</span>
                        <h2>What Our Client’s Say’s</h2>
                       <p>Fusce pharetra odio in urna laoreet laoreet. Aliquam erat volutpat. Phasellus nec ligula arcu. Aliquam eu urna pulvinar, iaculis ipsum in, porta massa.</p>
                    </div>
                    </div>
                    </div>
            
           <div class="row justify-content-center">
                
                <div class="col-lg-10">                           
                    <div class="testimonial-active">
                      
                 
                        <div class="single-testimonial">
                             <div class="testi-img">
                                <img src="../../assets/website/testimonial/testimonial-img.png" alt="img">                                        
                            </div>
                            <div class="single-testimonial-bg">
                            <div class="com-icon"><img src="../../assets/website/testimonial/qutation.png" alt="img"></div>
                                <div class="testi-author">
                                             <div class="ta-info">                                          
                                    <h6>Adam McWilliams</h6>
                                    <span>CEO & Founder</span>
                                    
                                </div>
                            </div>
                            <p>Nullam metus mi, sollicitudin eu elit non, laoreet consectetur urna. Nullam quis aliquet elit. Cras augue tortor, lacinia et fermentum eget, suscipit id ligula. Donec id mollis sem, nec tincidunt neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                                </div>
                           
                        </div>
                        <div class="single-testimonial">
                             <div class="testi-img">
                                <img src="../../assets/website/testimonial/testimonial-img.png" alt="img">                                        
                            </div>
                            <div class="single-testimonial-bg">
                            <div class="com-icon"><img src="../../assets/website/testimonial/qutation.png" alt="img"></div>
                                <div class="testi-author">
                                             <div class="ta-info">                                          
                                    <h6>Rose Dose</h6>
                                    <span>Sale Executive</span>
                                    
                                </div>
                            </div>
                            <p>Nullam metus mi, sollicitudin eu elit non, laoreet consectetur urna. Nullam quis aliquet elit. Cras augue tortor, lacinia et fermentum eget, suscipit id ligula. Donec id mollis sem, nec tincidunt neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                                </div>
                           
                        </div>
                            <div class="single-testimonial">
                             <div class="testi-img">
                                <img src="../../assets/website/testimonial/testimonial-img.png" alt="img">                                        
                            </div>
                            <div class="single-testimonial-bg">
                            <div class="com-icon"><img src="../../assets/website/testimonial/qutation.png" alt="img"></div>
                                <div class="testi-author">
                                             <div class="ta-info">                                          
                                    <h6>Margie R. Robinson</h6>
                                    <span>Web Developer</span>
                                    
                                </div>
                            </div>
                            <p>Nullam metus mi, sollicitudin eu elit non, laoreet consectetur urna. Nullam quis aliquet elit. Cras augue tortor, lacinia et fermentum eget, suscipit id ligula. Donec id mollis sem, nec tincidunt neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                                </div>
                           
                        </div>
                             <div class="single-testimonial">
                             <div class="testi-img">
                                <img src="../../assets/website/testimonial/testimonial-img.png" alt="img">                                        
                            </div>
                            <div class="single-testimonial-bg">
                            <div class="com-icon"><img src="../../assets/website/testimonial/qutation.png" alt="img"></div>
                                <div class="testi-author">
                                             <div class="ta-info">                                          
                                    <h6>Jone Dose</h6>
                                    <span>MD & Founder</span>
                                    
                                </div>
                            </div>
                            <p>Nullam metus mi, sollicitudin eu elit non, laoreet consectetur urna. Nullam quis aliquet elit. Cras augue tortor, lacinia et fermentum eget, suscipit id ligula. Donec id mollis sem, nec tincidunt neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                                </div>
                           
                        </div>
                    
                        
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <!-- testimonial-area-end -->
     <!-- brand-area -->
    <div class="brand-area" style="background-image:url(../../assets/website/an-bg/an-bg12.png); background-size: cover;background-repeat: no-repeat;">
        <div class="container">
            <div class="row brand-active">
                <div class="col-xl-2">
                    <div class="single-brand">
                        <img src="../../assets/website/brand/c-logo.png" alt="img">
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="single-brand active">
                          <img src="../../assets/website/brand/c-logo02.png" alt="img">
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="single-brand">
                          <img src="../../assets/website/brand/c-logo03.png" alt="img">
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="single-brand">
                          <img src="../../assets/website/brand/c-logo04.png" alt="img">
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="single-brand">
                          <img src="../../assets/website/brand/c-logo.png" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- brand-area-end -->
    
</main>
<!-- main-area-end -->
<!-- footer -->
<footer class="footer-bg footer-p" style="background-color: #fff;">
    <div class="overly"><img src="../../assets/website/an-bg/footer-bg.png" alt="rest"></div>
    <div class="footer-top pb-30" style="background-color: #ECF1FA;">
        <div class="container">
            <div class="row justify-content-between">

                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="flog mb-35">
                            <a href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                        <div class="footer-text mb-20">
                            <p>Sed ut perspiciatis unde om is nerror sit voluptatem accustium dolorem tium totam rem
                                aperam eaque ipsa quae ab illose
                                inntore veritatis</p>
                        </div>
                        <div class="footer-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-google-plus-g"></i></a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Our Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Partners</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> About Us</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Career</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Reviews</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Terms & Conditions</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Help</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Other Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> About Us</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Services</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Project</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Our Team</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Latest Blog</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="footer-link">
                            <div class="f-contact">
                                <ul>
                                    <li>
                                        <img src="../../assets/img/common icons/to-do-list.png" style="width: 40px; height: 40px;" />
                                        <span>1800-121-3637<br>+91 555 234-8765</span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/clientuser.png" style="width: 40px; height: 40px;" />
                                        <span><a href="mailto:info@example.com">info@example.com</a><br><a
                                                href="mailto:sale@example.com">sale@example.com</a></span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/product.png" style="width: 40px; height: 40px;" />
                                        <span>380 St Kilda Road, Melbourne<br>VIC 3004, Australia</span>
                                    </li>
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="copyright-text text-center">
                        <div class="container text-center">
                            <small class="text-black">Copyright &copy; 2023 NeoDigit | All rights reserved</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer-end -->