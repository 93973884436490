<div id="dashboard" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <!-- <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>         -->
  
    <h3 class="bs-title alignc">Employer Dashboard</h3>
    <hr>    
  
    <div class="row">


            <div class="col-sm-12 col-lg-3 col-md-6">
                <div class="cardcustom">
                    1
                </div>
            </div>

            <div class="col-sm-12 col-lg-3 col-md-6">
                <div class="cardcustom">
                    2
                </div>
            </div>
            
            <div class="col-sm-12 col-lg-3 col-md-6">
                <div class="cardcustom">
                    3
                </div>
            </div>

            <div class="col-sm-12 col-lg-3 col-md-6">
                <div class="cardcustom">
                    4
                </div>
            </div>


    </div>
</div>