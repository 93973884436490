import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DataTablesModule } from 'angular-datatables';

import { NgSelectModule } from '@ng-select/ng-select';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NavbarComponent } from './commonComponents/navbar/navbar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { SuperAdminDashboard } from './superAdmin/dashboard/dashboard.component';
import { AdminDashboard } from './admin/dashboard/dashboard.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';

// import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'angular-highcharts';
import { HomeComponent } from './home/home.component';
import { ClientCreationComponent } from './client-creation/client-creation.component';
import { PricingComponent } from './client/pricing/pricing.component';
import { ProfileComponent } from './client/profile/profile.component';
import { UsersComponent } from './superAdmin/user mangement/users/users.component';
import { UsersClientComponent } from './superAdmin/user mangement/users-client/users-client.component';
import { EmployeeDependencyComponent } from './employee-dependency/employee-dependency.component';
import { ClientDashboard } from './client/dashboard/dashboard.component';
import { PolicyDetailsComponent } from './policy-details/policy-details.component';
import { VerifymobileComponent } from './auth/verification/verifymobile/verifymobile.component';
import { VerifyemailComponent } from './auth/verification/verifyemail/verifyemail.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MarketDataComponent } from './superAdmin/market-data/market-data.component';
import { TpaDetailsComponent } from './superAdmin/tpa-details/tpa-details.component';
import { InsuranceCompanyInsurerComponent } from './superAdmin/insurance-company-insurer/insurance-company-insurer.component';
import { SelectProductComponent } from './client/select-product/select-product.component';
import { ProductDetailsComponent } from './superAdmin/product-mgmt/product-details/product-details.component';
import { SubproductDetailsComponent } from './superAdmin/product-mgmt/subproduct-details/subproduct-details.component';
import { TopupPolicyDetailsComponent } from './topup-policy-details/topup-policy-details.component';
import { GpaPolicyDetailsComponent } from './gpa-policy-details/gpa-policy-details.component';
import { GtlPolicyDetailsComponent } from './gtl-policy-details/gtl-policy-details.component';
import { OpdPolicyDetailsComponent } from './opd-policy-details/opd-policy-details.component';
import { GoToPoliciesComponent } from './go-to-policies/go-to-policies.component';
import { SelectProductMobileComponent } from './client/select-product-mobile/select-product-mobile.component';
import { PolicyConversionGmcComponent } from './superAdmin/policy-conversion-gmc/policy-conversion-gmc.component';
import { PolicyConversionGpaComponent } from './superAdmin/policy-conversion-gpa/policy-conversion-gpa.component';
import { PolicyConversionGtlComponent } from './superAdmin/policy-conversion-gtl/policy-conversion-gtl.component';
import { PolicyConversionOpdComponent } from './superAdmin/policy-conversion-opd/policy-conversion-opd.component';
import { PolicyFinalizeGmcComponent } from './superAdmin/policy-finalize-gmc/policy-finalize-gmc.component';
import { PolicyFinalizeGpaComponent } from './superAdmin/policy-finalize-gpa/policy-finalize-gpa.component';
import { PolicyFinalizeGtlComponent } from './superAdmin/policy-finalize-gtl/policy-finalize-gtl.component';
import { PolicyFinalizeOpdComponent } from './superAdmin/policy-finalize-opd/policy-finalize-opd.component';
import { SurveyLoginComponent } from './survey/auth/survey-login/survey-login.component';
import { SurveyRegisterComponent } from './survey/auth/survey-register/survey-register.component';
import { SurveyEmailVerificationComponent } from './survey/auth/survey-verification/survey-email-verification/survey-email-verification.component';
import { SurveySelectProductComponent } from './survey/survey-select-product/survey-select-product.component';
import { SurveyGmcPolicyDetailsComponent } from './survey/survey-gmc-policy-details/survey-gmc-policy-details.component';
import { SurveyGpaPolicyDetailsComponent } from './survey/survey-gpa-policy-details/survey-gpa-policy-details.component';
import { SurveyGtlPolicyDetailsComponent } from './survey/survey-gtl-policy-details/survey-gtl-policy-details.component';
import { SurveyOpdPolicyDetailsComponent } from './survey/survey-opd-policy-details/survey-opd-policy-details.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { BenefitBenchmarkingComponent } from './survey/benefit-benchmarking/benefit-benchmarking.component';
import { SurveyManagerComponent } from './superAdmin/survey-manager/survey-manager.component';
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
import { ManageCountryComponent } from './superAdmin/manage-country/manage-country.component';
import { ManageStateComponent } from './superAdmin/manage-state/manage-state.component';
import { ManageCityComponent } from './superAdmin/manage-city/manage-city.component';
import { ManageBrokerComponent } from './superAdmin/manage-broker/manage-broker.component';
import { ManageIndustryComponent } from './superAdmin/manage-industry/manage-industry.component';
import { ManageUserTypesComponent } from './superAdmin/manage-user-types/manage-user-types.component';
import { ManagePlanComponent } from './superAdmin/manage-plan/manage-plan.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ManageAnnoucementsComponent } from './superAdmin/manage-annoucements/manage-annoucements.component';
import { ManageNewsComponent } from './superAdmin/manage-news/manage-news.component';
import { MasterBrokerComponent } from './superAdmin/master-broker/master-broker.component';
import { BrokerManageMasterEmployerComponent } from './broker/broker-manage-master-employer/broker-manage-master-employer.component';
import { DashboardComponent } from './broker/dashboard/dashboard.component';
import { EmployerDashboardComponent } from './broker/employer-dashboard/employer-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    RegisterComponent,
    SuperAdminDashboard,
    AdminDashboard,
    HomeComponent,
    ClientCreationComponent,
    PricingComponent,
    ProfileComponent,
    UsersComponent,
    UsersClientComponent,
    EmployeeDependencyComponent,
    ClientDashboard,
    PolicyDetailsComponent,
    VerifymobileComponent,
    VerifyemailComponent,
    MarketDataComponent,
    TpaDetailsComponent,
    InsuranceCompanyInsurerComponent,
    SelectProductComponent,
    ProductDetailsComponent,
    SubproductDetailsComponent,
    TopupPolicyDetailsComponent,
    GpaPolicyDetailsComponent,
    GtlPolicyDetailsComponent,
    OpdPolicyDetailsComponent,
    GoToPoliciesComponent,
    SelectProductMobileComponent,
    PolicyConversionGmcComponent,
    PolicyConversionGpaComponent,
    PolicyConversionGtlComponent,
    PolicyConversionOpdComponent,
    PolicyFinalizeGmcComponent,
    PolicyFinalizeGpaComponent,
    PolicyFinalizeGtlComponent,
    PolicyFinalizeOpdComponent,
    SurveyLoginComponent,
    SurveyRegisterComponent,
    SurveyEmailVerificationComponent,
    SurveySelectProductComponent,
    SurveyGmcPolicyDetailsComponent,
    SurveyGpaPolicyDetailsComponent,
    SurveyGtlPolicyDetailsComponent,
    SurveyOpdPolicyDetailsComponent,
    AboutUsComponent,
    ContactUsComponent,
    BenefitBenchmarkingComponent,
    SurveyManagerComponent,
    ManageCountryComponent,
    ManageStateComponent,
    ManageCityComponent,
    ManageBrokerComponent,
    ManageIndustryComponent,
    ManageUserTypesComponent,
    ManagePlanComponent,
    ManageAnnoucementsComponent,
    ManageNewsComponent,
    MasterBrokerComponent,
    BrokerManageMasterEmployerComponent,
    DashboardComponent,
    EmployerDashboardComponent,
  ],
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    NgSelectModule,
    FormsModule,
    NgxLoaderModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,    
    NgxSliderModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
