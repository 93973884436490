<div class="d-flex" id="wrapper">
    <!-- Sidebar-->
    <div class="d-flex flex-column flex-shrink-0 p-3 sidebarcss" id="sidebar-wrapper" *ngIf="restatus != null && pagehide_status != 'yes'">
        <div class="mobdiv" style="position: fixed; top:8px; z-index: 1000; width: 210px;">
            <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none" style="margin-left: 15px;">
                <!-- <img class="img-fluid sidebarlogo" src="../../../assets/mainlogo.png"
                    style="width: 150px; height: 35px; border-radius: 4px; margin-left: 0px;" />                 -->
                <img class="img-fluid sidebarlogo" src="../../../assets/mainlogo.png"
                    style="width: 30px; height: 30px; border-radius: 4px; margin-left: 0px;" />
                <span class="fs-5 text-dark"> &nbsp; 
                    <img class="img-fluid" src="../../../assets/textlogo.png"
                    style="width: 100px; height: 40px; border-radius: 4px; margin-left: 0px;" />
                </span>
            </a>
            <!-- <hr class="sidebarhr" style="margin-top: 11px; margin-bottom: 14px"> -->
            <!-- <hr class="sidebarhrshort" style="margin-top: 11px; margin-bottom: 14px; width:250px; display: none;"> -->
            <div class="uldiv mt-3">
                <ul class="nav nav-pills flex-column mb-auto" *ngIf="surveyLoginFlag == 'No'">
                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a routerLink="/SuperAdmin-dashboard" id="SuperAdmin-dashboard" *ngIf="user_role == 'superadmin'" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('SuperAdmin-dashboard', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/dashboard.png" style="width: 32px; " />
                            <label class="b-title"> Dashboard </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'admin'">
                        <a routerLink="/Admin-dashboard" id="Admin-dashboard" *ngIf="user_role == 'admin'" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('Admin-dashboard', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/dashboard.png" style="width: 32px; " />
                            <label class="b-title"> Dashboard </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'client'">
                        <a routerLink="/Client-dashboard" id="Client-dashboard" *ngIf="user_role == 'client'" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('Client-dashboard', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/dashboard.png" style="width: 32px; " />
                            <label class="b-title"> Dashboard </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'superadmin' and user_role == 'admin'">
                        <a routerLink="/clientCreation" id="clientCreation" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('clientCreation', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/clientuser.png" style="width: 32px; " />
                            <label class="b-title"> Client Creation </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'client'">
                        <a routerLink="/select-product" id="select-product" class="nav-link list-group-item text-white" aria-current="page" *ngIf="isMobile == false" (click)="activeNav('select-product', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/policy.png" style="width: 32px; " />
                            <label class="b-title"> Portfolio Details </label>
                        </a>
                        <a routerLink="/select-product-mobile" class="nav-link list-group-item text-white" aria-current="page" *ngIf="isMobile == true">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/policy.png" style="width: 32px; " />
                            <label class="b-title"> Portfolio Details </label>
                        </a>
                    </li>                    
                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" role="button" id="groupnavPC" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/user_mgmt.png" style="width: 32px; " />
                            <label class="b-title"> Policy Convert </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%;">
                            <a routerLink="/admin-policy-conversion-gmc" id="admin-policy-conversion-gmc" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-conversion-gmc', 'groupnavPC')">GMC</a>
                            <a routerLink="/admin-policy-conversion-gpa" id="admin-policy-conversion-gpa" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-conversion-gpa', 'groupnavPC')">GPA</a>
                            <a routerLink="/admin-policy-conversion-gtl" id="admin-policy-conversion-gtl" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-conversion-gtl', 'groupnavPC')">GTL</a>
                            <a routerLink="/admin-policy-conversion-opd" id="admin-policy-conversion-opd" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-conversion-opd', 'groupnavPC')">OPD</a>
                        </div>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" id="groupnavPF" role="button" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/user_mgmt.png" style="width: 32px; " />
                            <label class="b-title"> Policy Finalize </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%;">
                            <a routerLink="/admin-policy-finalize-gmc" id="admin-policy-finalize-gmc" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-finalize-gmc', 'groupnavPF')">GMC</a>
                            <a routerLink="/admin-policy-finalize-gpa" id="admin-policy-finalize-gpa" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-finalize-gpa', 'groupnavPF')">GPA</a>
                            <a routerLink="/admin-policy-finalize-gtl" id="admin-policy-finalize-gtl" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-finalize-gtl', 'groupnavPF')">GTL</a>
                            <a routerLink="/admin-policy-finalize-opd" id="admin-policy-finalize-opd" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-policy-finalize-opd', 'groupnavPF')">OPD</a>
                        </div>
                    </li>
                    <li class="nav-item mb-2" *ngIf="user_role == 'client' || user_role == 'superadmin'">
                        <a routerLink="/employee-Dependency" id="employee-Dependency" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('employee-Dependency', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/employee.png" style="width: 32px; " />
                            <label class="b-title"> EMP Dependency </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'client'">
                        <a routerLink="/pricing" id="pricing" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('pricing', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/pricing.png" style="width: 32px; " />
                            <label class="b-title"> Pricing-Plan </label>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" id="groupuser" role="button" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/user_mgmt.png" style="width: 32px; " />
                            <label class="b-title"> User Mgmt </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%;">
                            <a routerLink="/admin-users" id="admin-users" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-users', 'groupuser')">Users</a>
                            <a routerLink="/admin-users-Client" id="admin-users-Client" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-users-Client', 'groupuser')">User Client</a>
                        </div>
                    </li>                
                    <li class="nav-item" *ngIf="user_role == 'client'">
                      <a routerLink="/profile" *ngIf="user_role == 'client'" id="profile" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('profile', '')">
                          <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/profile-user.png" style="width: 32px; " />
                          <label class="b-title"> Profile </label>
                      </a>
                    </li>                
                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" id="groupuserproduct" role="button" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/product.png" style="width: 32px; " />
                            <label class="b-title"> Product Mgmt </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%;">
                            <a routerLink="/admin-product-details" id="admin-product-details" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-product-details', 'groupuserproduct')">Product</a>
                            <a routerLink="/admin-subproduct-details" id="admin-subproduct-details" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-subproduct-details', 'groupuserproduct')">Sub Product</a>
                        </div>
                    </li>                 

                    <li class="nav-item" *ngIf="user_role == 'client'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" id="groupuserbenefit-benchmarking" role="button" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/product.png" style="width: 32px; " />
                            <label class="b-title"> Reports </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%;">
                            <a routerLink="/benefit-benchmarking" id="product-details" class="dropdown-item" style="text-align: center;" (click)="activeNav('benefit-benchmarking', 'groupuserbenefit-benchmarking')">Benefit Benchmarking</a>
                        </div>
                    </li>

                    <li class="nav-item" *ngIf="user_role == 'superadmin'">
                        <a class="nav-link list-group-item text-white dropdown-toggle" id="managelist" role="button" data-bs-toggle="dropdown" aria-current="page">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/product.png" style="width: 32px; " />
                            <label class="b-title"> Management </label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="background: #e9ecef; width: 100%; height: 200px; overflow-y: auto;">
                            <a routerLink="/admin-manage-master-broker" id="admin-manage-master-broker" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-master-broker', 'managelist')">Manage Master Broker</a>
                            <a routerLink="/admin-manage-news" id="admin-manage-news" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-news', 'managelist')">Manage News</a>
                            <a routerLink="/admin-manage-annoucements" id="admin-manage-annoucements" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-annoucements', 'managelist')">Manage Annoucements</a>
                            <a routerLink="/admin-manage-country" id="admin-manage-country" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-country', 'managelist')">Manage Country</a>
                            <a routerLink="/admin-manage-state" id="admin-manage-state" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-state', 'managelist')">Manage State</a>
                            <a routerLink="/admin-manage-city" id="admin-manage-city" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-city', 'managelist')">Manage City</a>
                            <a routerLink="/admin-manage-broker" id="admin-manage-broker" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-broker', 'managelist')">Manage Broker</a>
                            <a routerLink="/admin-manage-industry" id="admin-manage-industry" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-industry', 'managelist')">Manage Industry</a>
                            <a routerLink="/admin-manage-user-types" id="admin-manage-user-types" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-user-types', 'managelist')">Manage User Types</a>
                            <a routerLink="/admin-manage-plan" id="admin-manage-plan" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-manage-plan', 'managelist')">Manage Plan</a>
                            <a routerLink="/admin-market-data" id="admin-market-data" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-market-data', 'managelist')">Market Data</a>
                            <a routerLink="/admin-tpa-details" id="admin-tpa-details" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-tpa-details', 'managelist')">TPA Details</a>
                            <a routerLink="/admin-insurance-company-insurer" id="admin-insurance-company-insurer" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-insurance-company-insurer', 'managelist')">Insurance Company</a>
                            <a routerLink="/admin-survey-manager" id="admin-survey-manager" class="dropdown-item" style="text-align: center;" (click)="activeNav('admin-survey-manager', 'managelist')">Survey Manager</a>                            
                        </div>
                    </li>                    
                    
                    <li class="nav-item" *ngIf="user_role == 'brokeradmin'">
                        <a routerLink="/broker-dashboard" id="broker-dashboard" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('broker-dashboard', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/dashboard.png" style="width: 32px; " />
                            <label class="b-title"> Dashboard </label>
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="user_role == 'brokeradmin'">
                        <a routerLink="/broker-manage-master-employer" id="broker-manage-master-employer" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('broker-manage-master-employer', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/product.png" style="width: 32px; " />
                            <label class="b-title"> Manage Employer </label>
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="user_role == 'employeradmin'">
                        <a routerLink="/employer-dashboard" id="employer-dashboard" class="nav-link list-group-item text-white" aria-current="page" (click)="activeNav('employer-dashboard', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/product.png" style="width: 32px; " />
                            <label class="b-title"> Dashboard </label>
                        </a>
                    </li>
                   
                </ul>
                <ul class="nav nav-pills flex-column mb-auto" *ngIf="surveyLoginFlag == 'Yes'">
                    <li class="nav-item">
                        <a routerLink="/survey-select-product" id="survey-gmc-policy-details" class="nav-link list-group-item text-white activenav" aria-current="page" (click)="activeNav('survey-gmc-policy-details', '')">
                            <img class="img-fluid ficons sidebaricon" src="../../../assets/img/common icons/policy.png" style="width: 32px; " />
                            <label class="b-title"> Survey </label>
                        </a>
                    </li>                    
                </ul>
            </div>           
            <!-- <hr class="sidebarhr" style="position: relative; top: -10px;"> -->
            <!-- <hr class="sidebarhrshort" style="margin-top: 6px; margin-bottom: 14px; width:250px; display: none;">             -->
        </div>
    </div>
    <!-- Page content wrapper-->
    <div id="page-content-wrapper">
        <!-- Top navigation-->
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom"
            style="background: #fff !important; color: #ffffff; position: fixed; width: 100%; z-index: 2000;" *ngIf="restatus != null && pagehide_status != 'yes'">
            <div class="container-fluid">
                <span class="toggleiconleft" style="z-index: 20000; margin-left: 20px;" id="toggleiconleft">
                    <img class="img-fluid ficons" src="../../../assets/img/common icons/arrow-left.png" style="width: 23px; cursor:pointer;z-index: 20000;" />
                    <!-- <i class="bi bi-arrow-left-circle ficons" style="font-size: 26px; cursor:pointer;z-index: 20000;"></i></span> -->
                </span>
                <span class="toggleiconright" style="z-index: 20000; display: none; margin-left: 20px;" id="toggleiconright">
                    <!-- <i class="bi bi-arrow-right-circle ficons" style="font-size: 26px; cursor:pointer;z-index: 20000;"></i> -->
                    <img class="img-fluid ficons" src="../../../assets/img/common icons/arrow-right.png" style="width: 23px; cursor:pointer;z-index: 20000;" />
                </span>


                <span style="z-index: 20000; position: fixed; right: 80px; cursor: pointer;" (click)="colorpallet()">
                    <img src="../../../assets/img/common icons/color-wheel.png" style="width: 30px;">
                </span>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation"><span
                        class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mt-2 mt-lg-0">

                        <!-- <li class="nav-item" style="display: flex;">
                            <span style="margin-top: 12px; margin-right: 10px;color:#ffffff; font-size: 12px;"><a routerLink="/employee-Dependency" style="color:#ffffff; z-index: 3000000;"><b>Click Here</b></a> to complete your profile</span>
                            <div class="progress" style="width: 650px; margin-top: 12px; margin-right: 10px;height: 1.5rem; border: solid 2px #ffffff;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width: 40%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                    <span style="color: #ffffff; font-weight: bold;"> 40% Completed </span>
                                </div>
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                    <span style="color: #000000; font-weight: bold;"> 60% Incomplete - Fill Member Data </span>
                                </div>
                            </div>
                        </li> -->

                        <!-- <li class="nav-item active" style="margin-bottom: 0px;" *ngIf="user_role == 'client'"><a class="nav-link">{{user_company}}</a></li> -->

                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#!">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </a>
                        </li>  -->                       

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle displaynot" id="navbarDropdown" href="#" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <!-- <i class="bi bi-person-circle"></i> -->
                                <img class="img-fluid" src="../../../assets/img/common icons/user.png" alt="Img" style="width: 25px;" />
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" style="margin: 0px;">
                                <a class="dropdown-item" style="pointer-events: none;">
                                    <strong>{{username}}</strong>
                                </a>
                                <a class="dropdown-item" *ngIf="user_role == 'client'" style="pointer-events: none;">
                                    Company - <strong>{{user_company}}</strong>
                                </a>
                                <a class="dropdown-item" *ngIf="user_role == 'client'" style="pointer-events: none;">
                                    Plan - <strong>{{plan_name}}</strong>
                                </a>
                                <div class="dropdown-divider" *ngIf="user_role == 'client'" style="margin: 0px;"></div>
                                <a class="dropdown-item" href="#" (click)="signout()">
                                    <!-- <img class="img-fluid ficons" src="../../../assets/img/common icons/exit.png" style="width: 15px; cursor:pointer;z-index: 20000;" alt="Sign Out" /> -->
                                    Sign out
                                </a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
        <!-- Page content-->
        <div class="container-fluid" style="overflow-y: auto; z-index: 1;">
            <router-outlet>

                <!---------- Progress Bar ----------->
                <!-- <div *ngIf="this.flow1 == 'yes' || this.flow2 == 'yes' || this.flow3 == 'yes'" id="pbar">
                    <div id="progressdiv" class="bs-stepper bg-white rounded p-3 containerclass" style="margin-top: 75px !important; margin-bottom: -60px !important;" *ngIf="restatus != null && pagehide_status != 'yes'">

                        <div *ngIf="this.flow1 == 'yes' && this.flow2 == null && this.flow3 == null">
                            <span style="margin-top: 12px; margin-right: 10px;color:#000000;" class="bs-form-label">
                                Your Profile is Incomplete! To complete 20% more of your profile -
                                <a routerLink="/employee-Dependency"><b>Fill Member Data</b></a>
                            </span>

                            <div class="progress" style="width: 100%; margin-top: 12px; margin-right: 10px;height: 2rem; border: solid 2px #ffffff;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 40%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                    <span class="bs-form-label" style="color: #ffffff; font-weight: bold;"> 40% Completed </span>
                                </div>
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                    <span class="bs-form-label" style="color: #000000; font-weight: bold;"> 60% Incomplete</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="this.flow1 == 'yes' && this.flow2 == 'yes' && this.flow3 == null">
                            <span style="margin-top: 12px; margin-right: 10px;color:#000000;" class="bs-form-label">
                                Your Profile is Incomplete! To complete 20% more of your profile -
                                <a routerLink="/policy-details"><b>Fill Policy Details</b></a>
                            </span>

                            <div class="progress" style="width: 100%; margin-top: 12px; margin-right: 10px;height: 2rem; border: solid 2px #ffffff;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 70%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                    <span class="bs-form-label" style="color: #ffffff; font-weight: bold;"> 70% Completed </span>
                                </div>
                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 30%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                    <span class="bs-form-label" style="color: #000000; font-weight: bold;"> 30% Incomplete</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="this.flow1 == 'yes' && this.flow2 == 'yes' && this.flow3 == 'yes'">
                            <span style="margin-top: 12px; margin-right: 10px;color:#000000;" class="bs-form-label">
                                Your Profile is Completed!
                            </span>

                            <div class="progress" style="width: 100%; margin-top: 12px; margin-right: 10px;height: 2rem; border: solid 2px #ffffff;">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%;" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                                    <span class="bs-form-label" style="color: #ffffff; font-weight: bold;"> 100% Completed </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->
                <!------------------------------------>

            </router-outlet>
        </div>
    </div>
</div>

<footer id="sticky-footer" class="flex-shrink-0 py-1 bg-white" *ngIf="restatus != null && pagehide_status != 'yes'">
    <div class="container text-center">
        <small class="text-black">Copyright &copy; 2023 NeoDigit | All rights reserved</small>
    </div>
</footer>


<!---------- Modal Pallet ------------->
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':modelpallet}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Theme Color Pallet</h4>
            </div>            
            <div class="modal-body">  
                                
                <form [formGroup]="pallet">                    
                    <h6>Sidebar Color</h6>
                    <hr>

                    <div class="row">

                        <div class="col-sm-2">                        
                            <input type="color" id="sidebarinput" formControlName="sidebarinput" name="favcolor" value="#000" style="width: 60px; height: 60px; cursor: pointer; " />
                        </div>
                        
                        <!-- <div class="col-sm-2">                        
                            <label>
                                <input type="radio" name="test" value="pink" formControlName="sidebarinput">
                                <img src="../../../assets/img/common icons/pink.png" class="palletimg" alt="Option 1">
                            </label>
                        </div>
                        
                        <div class="col-sm-2">    
                            <label>
                                <input type="radio" name="test" value="blue" formControlName="sidebarinput">
                                <img src="../../../assets/img/common icons/blue.png" class="palletimg" alt="Option 1">
                            </label>                                               
                        </div>

                        <div class="col-sm-2">    
                            <label>
                                <input type="radio" name="test" value="orange" formControlName="sidebarinput">
                                <img src="../../../assets/img/common icons/orange.png" class="palletimg" alt="Option 1">
                            </label>                                               
                        </div> -->

                    </div>
                </form>                                  

            </div>
            <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSave()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>                     
        </div>
    </div>
  </div>