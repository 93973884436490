<div id="users" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <form [formGroup]="usersClientInfo">
        <h3 class="bs-title">User Client</h3>
        <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
            <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
            <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
        </div>
        <hr style="margin-top: -5px;">
        <div class="row">
            <div class="col-lg-5">
                <div class="form-group">
                    <label class="bs-form-label" for="user">User *</label>
                    <select class="form-select" formControlName="user" id="user"
                    [ngClass]="{'control-red': usersClientSubmitted && getusersClientInfoFormControls.user.errors}"
                    required>
                        <option value="" selected>Select</option>
                        <option *ngFor="let user of usersArray" [value]="user['user_id']">{{user['username']}}</option>                                            
                    </select>
                    <ng-container
                        *ngIf="usersClientSubmitted && getusersClientInfoFormControls.user.errors">
                        <p class="error">
                            User is required
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="form-group">
                    <label class="bs-form-label" for="client">Client *</label>
                    <select class="form-select" formControlName="client" id="client"
                    [ngClass]="{'control-red': usersClientSubmitted && getusersClientInfoFormControls.client.errors}"
                    required>
                        <option value="" selected>Select</option>                    
                        <option *ngFor="let client of clientArray" [value]="client['client_id']">{{client['company_name']}}</option>                                            
                    </select>
                    <ng-container
                        *ngIf="usersClientSubmitted && getusersClientInfoFormControls.client.errors">
                        <p class="error">
                            Client is required
                        </p>
                    </ng-container> 
                </div>
            </div>            
            <div class="col-lg-2">
                <div class="form-group">
                    <label class="bs-form-label" for="savebtn"></label>
                    <input type="submit" class="form-control" (click)="onSubmit()" id="savebtn" value="Save" />                    
                </div>
            </div>            
        </div>
    </form>

    <div class="table-responsive mt-4">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>User</th>
                <th>Client</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
             <tr *ngFor="let group of listArray; let i = index">
                <td style="width:5%">{{i+1}}</td>
                   <td>{{group['username']}}</td>
                   <td>{{group['company_name']}}</td>
                   <td style="width: 15%" align="center">
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopup(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit User Client</h4>
            </div>
            <div class="modal-body">
                
                <form [formGroup]="usersClientEdit">                    
                    <div class="row">                               
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_user_id_fk">User *</label>
                                <select *ngIf="user_role == 'superadmin'" class="form-select" formControlName="edit_user_id_fk" id="edit_user_id_fk"
                                [ngClass]="{'control-red': usersClientEditSubmitted && getusersClientEditInfoFormControls.edit_user_id_fk.errors}"
                                required>
                                    <option value="" selected>Select</option>                    
                                    <option *ngFor="let user of usersArray" [value]="user['user_id']">{{user['username']}}</option>                                            
                                </select>             
                                <ng-container
                                    *ngIf="usersClientEditSubmitted && getusersClientEditInfoFormControls.edit_user_id_fk.errors">
                                    <p class="error">
                                        User is required
                                    </p>
                                </ng-container>                  
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_client_id_fk">Client *</label>
                                <select class="form-select" formControlName="edit_client_id_fk" id="edit_client_id_fk"
                                [ngClass]="{'control-red': usersClientEditSubmitted && getusersClientEditInfoFormControls.edit_client_id_fk.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let client of clientArray" [value]="client['client_id']">{{client['company_name']}}</option>                                            
                                </select>     
                                <ng-container
                                    *ngIf="usersClientEditSubmitted && getusersClientEditInfoFormControls.edit_client_id_fk.errors">
                                    <p class="error">
                                        Client is required
                                    </p>
                                </ng-container>               
                            </div>
                        </div>       
                        <!-- <div class="col-sm-6 mt-3">
                            <div class="form-group">
                                <label class="bs-form-label" for="edit_is_active">Status *</label>
                                <select class="form-select" formControlName="edit_is_active" id="edit_is_active" required>
                                    <option [value]="1">Active</option>
                                    <option [value]="0">Deactive</option>
                                </select>                    
                            </div>
                        </div>                -->
                        <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />                                                                      
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
                <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
            </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Delete User Client</h4>
                </div>
                <form [formGroup]="usersDelete">
                <div class="modal-body">
                    Are you sure you want to delete?
                </div>
                <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>
                    <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                </div>
                </form>
            </div>
        </div>
    </div>

</div>