import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { UsersClientService } from '../users-client/client';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-users-client',
  templateUrl: './users-client.component.html',
  styleUrls: ['./users-client.component.css']
})
export class UsersClientComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public usersClientInfo:FormGroup;
  usersClientSubmitted: boolean = false;

  public usersClientEdit:FormGroup;
  usersClientEditSubmitted: boolean = false;

  public usersDelete:FormGroup;

  user_role: any;
  validmsg: any;

  usersArray: any = [];
  clientArray: any = [];
  listArray: any = [];

  displayStyle = "none";
  displayStyleDelete = "none";
  dtOptions: DataTables.Settings = {
    destroy: true,
    columnDefs: [ {
      targets: [1,2], // column index (start from 0)
      orderable: false, // set orderable false for selected columns
    }]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  resstatus = localStorage.getItem('resstatus');

  constructor(private fb: FormBuilder, private usersClientService: UsersClientService, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {        
        this.user_role = localStorage.getItem('type_name');        
      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }    
    });
   }

  ngOnInit(): void {

    this.initGroup();  

  }

  initGroup(){

    this.usersArray = [];
    this.clientArray = [];
    this.getusersInfo();
    this.getClientInfo();
    this.getusersClientInfo();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    // Form element defined below
    this.usersClientInfo = this.fb.group({
      user: new FormControl(''),
      client: new FormControl('')      
    });

    // Form element defined below
    this.usersClientEdit = this.fb.group({
      edit_user_id_fk: new FormControl(''),
      edit_client_id_fk: new FormControl(''),
      // edit_is_active: new FormControl('0'),
      edit_id: new FormControl('')
    });

    // Form element defined below
    this.usersDelete = this.fb.group({
      delete_id: new FormControl('')      
    });

  }

  get getusersClientInfoFormControls() {
    return this.usersClientInfo.controls;
  }
  
  get getusersClientEditInfoFormControls() {
    return this.usersClientEdit.controls;
  }

  getusersInfo(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    this.usersClientService.getusersInfo()
        .subscribe((result: any) => {          
          
          this.show = false;
          // console.log(result);
          this.usersArray = result.data;                    

        }, (error) => {          
          
          this.usersArray = [];
          
      });
  }

  getClientInfo(){
    this.usersClientService.getClientInfo()
        .subscribe((result: any) => {          
          
          // console.log(result);
          this.clientArray = result.data;                    

        }, (error) => {          
          
          this.clientArray = [];
          
      });
  }

  getusersClientInfo(){
    this.usersClientService.getusersClientInfo()
        .subscribe((result: any) => {          
          
          // console.log(result);
          this.listArray = result.data;                    
          this.dtTrigger.next();

        }, (error) => {          
          
          this.listArray = [];
          
      });
  }

  closealert(){
    $("#validmsg").css('display', 'none');    
    $("#errormsg").css('display', 'none');    
  }

  onSubmit() {

    this.usersClientSubmitted = true;
    if (this.usersClientSubmitted && this.usersClientInfo.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;
      
      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "user_id_fk":this.usersClientInfo.controls['user'].value,
        "client_id_fk":this.usersClientInfo.controls['client'].value              
      };
      // console.log(postdata);

      this.usersClientService.usersAssociate(postdata)
        .subscribe((result: any) => {
          
          // console.log(result);

          if (result.status == true) {            
            
            this.show = false;
            // this.usersClientInfo.reset();
            this.usersClientInfo.controls['user'].setValue('');
            this.usersClientInfo.controls['client'].setValue('');

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');

            this.validmsg = result.message;
            this.getusersClientInfo();
            this.dtTrigger.unsubscribe();

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });
      
    } else {
      // validate all form fields
    }
  }   

  openPopup(res: any) {
    this.displayStyle = "block";
    this.onEditLoad(res);
  }

  openPopupDelete(res: any) {
    this.displayStyleDelete = "block";  
    
    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_client_id
    };

    this.usersClientService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        console.log(result);
        this.usersDelete.controls['delete_id'].setValue(result.data[0]['user_client_id']);

      }, (error) => {          
              
    });

  }

  onEditLoad(res: any){          

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id":res.user_client_id
    };

    this.usersClientService.usersEdit(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          console.log(result.data);
          this.usersClientEdit.controls['edit_user_id_fk'].setValue(result.data[0]['user_id_fk']);
          this.usersClientEdit.controls['edit_client_id_fk'].setValue(result.data[0]['client_id_fk']);
          // this.usersClientEdit.controls['edit_is_active'].setValue(result.data[0]['is_active']); 
          this.usersClientEdit.controls['edit_id'].setValue(result.data[0]['user_client_id']);                  

        } else {
          // console.log(result);            
        }
      }, (error) => {          
              
    });

  }

  onSubmitEdit(){

    let id = $('#edit_id').val();   

    this.usersClientEditSubmitted = true;
    if (this.usersClientEditSubmitted && this.usersClientEdit.valid) {

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "id": id,
        "user_id_fk":this.usersClientEdit.controls['edit_user_id_fk'].value,
        "client_id_fk":this.usersClientEdit.controls['edit_client_id_fk'].value,      
        // "is_active":this.usersEdit.controls['edit_is_active'].value,
        "user_client_id":this.usersClientEdit.controls['edit_id'].value
      };

      this.usersClientService.usersUpdate(postdata)
        .subscribe((result: any) => {
          
          if (result.status == true) {            
            
            this.show = false;
            // console.log(result.data);          
            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            this.validmsg = result.message;   
            this.closePopup();       
            this.getusersClientInfo();
            this.dtTrigger.unsubscribe();          

          } else {
            // console.log(result);            
          }
        }, (error) => {          
          this.closePopup();
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');  
          $('#errormsg').css('display', 'block');        
      });

    }

  }

  onSubmitDelete(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;  
    
    let id = $('#delete_id').val();

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "id": id
    };

    this.usersClientService.usersDelete(postdata)
      .subscribe((result: any) => {
        
        if (result.status == true) {            
          
          this.show = false;
          // console.log(result.data);          
          $('#validmsg').css('display', 'block');
          $('#errormsg').css('display', 'none');
          this.validmsg = result.message;   
          this.closePopup();       
          this.getusersClientInfo();
          this.dtTrigger.unsubscribe();          

        } else {
          // console.log(result);            
        }
      }, (error) => {     
        this.closePopup();             
        this.validmsg = error.error.message;
        $('#validmsg').css('display', 'none');  
        $('#errormsg').css('display', 'block');        
    });

  }

  closePopup() {
    this.displayStyle = "none";
    this.displayStyleDelete = "none";  
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
