import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { SurveyPolicyDetailsService } from '../survey-gmc-policy-details/survey-gmc-policy-details';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-survey-gmc-policy-details',
  templateUrl: './survey-gmc-policy-details.component.html',
  styleUrls: ['./survey-gmc-policy-details.component.css']
})
export class SurveyGmcPolicyDetailsComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public policyDetails:FormGroup;
  public verifypolicyDetails:FormGroup;
  public policyFeatures:FormGroup;
  public documentUpload:FormGroup;
  public step5form:FormGroup;
  public step6form:FormGroup;

  private stepper: Stepper;

  client_id: any = localStorage.getItem('surveyClinet_id');
  survey_policy_id: any = localStorage.getItem('survey_policy_id');

  policyDetailsSubmitted: boolean = false;
  verifypolicyDetailsSubmitted: boolean = false;
  policyFeaturesSubmitted: boolean = false;
  documentUploadSubmitted: boolean = false;
  step5FormSubmitted: boolean = false;
  step6FormSubmitted: boolean = false;

  isuranceCompanyDetailsarr: any = [];
  tpadetailsArr: any = [];
  brokerdetailsArr: any = [];
  productsArr:  any = [];

  fileData: any;
  fileName: any = '';

  cnt_graded = 1;
  cnt_graded_topup = 1;

  cnt_graded_gtl = 1;
  cnt_graded_topup_gtl = 1;

  cnt_graded_gpa = 1;
  cnt_graded_topup_gpa = 1;

  resstatus = localStorage.getItem('resstatus');  
  plan_page_flag = localStorage.getItem('plan_page_flag');

  validmsg: any;

  upload_policydocument: any;
  upload_your_active_member_data: any;
  upload_claim_MIS: any;
  upload_claim_pdf_summary: any;
  policy_document: any;
  member_data: any;
  claims_in_current_year: any;
  previous_year_one: any;
  previous_year_two: any;


  cnt = 1;
  cnt_others = 1;
  uploadcnt = 1;
  amt = 1;
  checkopt_val = 1;

  benefitArr: any = {};
  othersArr: any = {};

  uploadExtraArr: any = {};
  gradedArr: any = {};
  metroArr: any = {};

  hospitalizationArr: any = {};
  hospitalization_tableArr: any = [];
  ICU_tableArr: any = [];

  maternityArr: any = {};
  normal_delivery_limit_Arr: any = [];
  c_section_limit_Arr: any = [];

  mygradedarray: any = [];
  mygradedarray_text: any = [];

  client_product_id: any = 0;
  benefit_id: any = 0;

  displayStyleThankyou = "none";

  dropdownList: any = [];
  selectedItems: any =[];
  dropdownSettings = {};
  familyArrData: any = [];
  familyArr: any = {};

  greatest_graded_value: any;

  no_flow_flag: boolean = false;

  isDisplay:any;

  stepper_flag: boolean = false;

  flag_firstcheck_FC:any = 0;
  flag_firstcheck_SI:any = 0;
  flag_firstcheck_hosp:any = 0;
  flag_firstcheck_additionalbenefits:any = 0;
  flag_firstcheck_ambulance:any = 0;
  flag_firstcheck_OPD:any = 0;
  flag_firstcheck_WPC:any = 0;
  flag_firstcheck_maternity:any = 0;
  flag_firstcheck_CCM:any = 0;
  flag_firstcheck_PC:any = 0;

  flagFC:any = 0;
  flagSI:any = 0;
  flaghosp:any = 0;
  flagadditionalbenefits:any = 0;
  flagambulance:any = 0;
  flagOPD:any = 0;
  flagWPC:any = 0;
  flagmaternity:any = 0;
  flagCCM:any = 0;
  flagPC:any = 0;

  emp_flag:any = 0;
  spo_flag:any = 0;
  child_flag:any = 0;
  par_flag:any = 0;
  sb_flag:any = 0;

  metrocnt = 1;

  survey_gmc_policy_check:any;

  addtext:any;

  proposal_number_show: any;
  BasePlanArr: any = [];

  stepcomplete: any = '';

  MOSArr: any;
  MOSArr_gtl: any;

  gradedArrSI: any = {};
  gradedArrSI_topup: any = {}; 

  gradedArrSI_gtl: any = {};
  gradedArrSI_topup_gtl: any = {};
  
  gradedArrSI_gpa: any = {};
  gradedArrSI_topup_gpa: any = {};

  img_document: any;

  clientInfo: any = [];
  surveyInfo: any = [];

    constructor(private fb: FormBuilder, private policyDetailsService: SurveyPolicyDetailsService, private route: Router) {

      this.route.events.subscribe((e) => {
        if (this.resstatus == null) {
            this.route.navigate(['/home']);
        }
      });

    }

    ngOnInit(): void {

      this.initGroup();
      
      this.Prog(0,'FC');
      this.Prog(0,'SI');
      this.Prog(0,'waiting');
      this.Prog(0,'hosp');
      this.Prog(0,'maternity');
      this.Prog(0,'CCM');
      this.Prog(0,'OPD');
      this.Prog(0,'Others');
      this.Prog(0,'Ambulance');
      this.Prog(0,'PC'); 

    }

    initGroup(){

      this.survey_gmc_policy_check = localStorage.getItem('survey_gmc_policy_check');

      // this.getIsuranceCompanyDetails();
      // this.getTPADetails();
      // this.getBrokerDetails();
      // this.getSubCategory();
      // this.getBasePlan();
      
      if(this.plan_page_flag == ''){
        this.plan_page_flag = '';
      }else{
        this.plan_page_flag = localStorage.getItem('plan_page_flag');
      }

      this.validmsg = '';
      $('#validmsg').css('display', 'none');
      $('#basics_accord').css('display','none');
      $('#sumisured_accord').css('display','none');
      $('#waitingPeriodCondition_accord').css('display','none');
      $('#hospitalisationaccord_accord').css('display','none');
      $('#maternity_accord').css('display','none');
      $('#CCM_accord').css('display','none');
      $('#OPD_accord').css('display','none');
      $('#Others_accord').css('display','none');
      $('#AmbulanceCover_accord').css('display','none');
      $('#PC_accord').css('display','none');
      $('#Mobileaccord').css('display','none');

      $('#EPD').prop('disabled',true);

      // Define Stepper
      const stepperElement = document.querySelector('#stepper2');
      if(stepperElement !== null){
      this.stepper = new Stepper(stepperElement , {
            linear: false,
            animation: true
          })
      }

      // Step 1 Form element defined below
      this.policyDetails = this.fb.group({
        company_name: new FormControl(''),
        industry: new FormControl(''),
        employee_headcount: new FormControl(''),
        MNC: new FormControl('yes'),
        turnover: new FormControl(''),
      });

      $('.existing_policy_check_yesno').css('display','none');
      
      // Step 2 Form element defined below                    
      this.verifypolicyDetails = this.fb.group({      
        emp_atstart: new FormControl(''),

        do_you_have_gmc: new FormControl('No'),
        family_contruct: new FormControl(''),
        LGBTQ: new FormControl('No'),

        //SI
        sum_insured_type: new FormControl('Not Floater'),                        
        sum_insured_approach: new FormControl(''),
        sum_insured_uniform: new FormControl(''),

        //Waiting
        thirty_days_waiting_period: new FormControl('Not Applicable'),
        one_and_half_days_waiting_period: new FormControl('Not Applicable'),
        pre_existing_condition: new FormControl('Not Applicable'),

        //hospi
        room_rent_condition_one: new FormControl(''),
        ICU_room_rent: new FormControl(''),
        pre_post_hospitalisation: new FormControl(''),

        day_care_procedures: new FormControl('Covered'),
        min_max_day_care_procedures: new FormControl('na_day_care_procedures'),
        day_care_procedures_limit_perc: new FormControl('100'),
        day_care_procedures_limit_value: new FormControl(''),

        domiciliary_hospitalisation: new FormControl('Covered'),
        min_max_domiciliary_hospitalisation: new FormControl('na_domiciliary_hospitalisation'),
        domiciliary_hospitalisation_limit_perc: new FormControl('100'),
        domiciliary_hospitalisation_limit_value: new FormControl(''),

        // maternity
        do_you_want_maternity: new FormControl('No'),

        room_rent_natal_cover: new FormControl('Not Covered'),
        room_rent_natal_cover_isCover: new FormControl('within maternity limit'),        
        room_rent_natal_cover_limit: new FormControl(''),

        bay_day_one: new FormControl('Not Covered'),
        bay_day_one_isCover: new FormControl('within maternity limit'),
        baby_specific_covered_limit: new FormControl(''),   

        infertility_cover: new FormControl('Not Covered'),
        infertility_cover_isCover: new FormControl('within maternity limit'),
        infertility_covered_limit: new FormControl(''),

        surrogacy_cover: new FormControl('Not Covered'),
        surrogacy_cover_isCover: new FormControl('within maternity limit'),
        surrogacy_covered_limit: new FormControl(''),        
        
        maternity_related_complications_yes: new FormControl('Not Covered'),
        maternity_related_complications_yes_isCover: new FormControl('within maternity limit'),        

        maternity_related_complications_no: new FormControl('Not Covered'),
        maternity_related_complications_no_isCover: new FormControl('within maternity limit'),

        normal_delivery_limit_maternity: new FormControl(''),
        c_section_limit_maternity: new FormControl(''),

        // Claim        
        copay: new FormControl('Not Applicable'),
        deductable: new FormControl('Not Applicable'),
        sum_incured_sub_limit: new FormControl('Not Applicable'),
        claim_diseasewisecapping_condition: new FormControl('Not Applicable'),        

        //others
        out_patient_benefits: new FormControl('Not Covered'),
        out_patient_benefits_limit: new FormControl(''),
        opd_dental: new FormControl('Not Covered'),
        opd_dental_limit: new FormControl(''),
        opd_vision: new FormControl('Not Covered'),
        opd_vision_limit: new FormControl(''),
        preventive_health_checkup: new FormControl('Not Covered'),
        preventive_health_checkup_limit: new FormControl(''),

        organ_donor_cover: new FormControl('Not Covered'),
        min_max_organ_donor_cover: new FormControl('na_organ_donor_cover'),
        organ_donor_cover_limit_perc: new FormControl('100'),
        organ_donor_cover_limit_value: new FormControl(''),
		
		    oral_chemo_therapy: new FormControl('Not Covered'),
        min_max_oral_chemo_therapy: new FormControl('na_oral_chemo_therapy'),
        oral_chemo_therapy_limit_perc: new FormControl('100'),
        oral_chemo_therapy_limit_value: new FormControl(''),
		
		    ayush_treatment: new FormControl('Not Covered'),
        min_max_ayush_treatment: new FormControl('na_ayush_treatment'),
        ayush_treatment_limit_perc: new FormControl('100'),
        ayush_treatment_limit_value: new FormControl(''),

        lasik_treatment: new FormControl('Not Covered'),
        min_max_lasik_treatment: new FormControl('na_lasik_treatment'),
        lasik_treatment_limit_perc: new FormControl('100'),
        lasik_treatment_limit_value: new FormControl(''),
		
		    corporate_buffer: new FormControl('Not Covered'),
        corporate_buffer_isCover: new FormControl('Applicable for Critical illness'),
        corporate_buffer_limit: new FormControl(''),
        corporate_buffer_family_limit: new FormControl(''),

        // Ambulance
        road_ambulance: new FormControl('Not Covered'),
        min_max_road_ambulance: new FormControl('na_road_ambulance'),
        road_ambulance_limit_perc: new FormControl(''),
        road_ambulance_limit_value: new FormControl(''),

        cardiac_ambulance: new FormControl('Not Covered'),
        min_max_cardiac_ambulance: new FormControl('na_cardiac_ambulance'),
        cardiac_ambulance_limit_perc: new FormControl(''),
        cardiac_ambulance_limit_value: new FormControl(''),

        air_ambulance: new FormControl('Not Covered'),
        min_max_air_ambulance: new FormControl('na_air_ambulance'),
        air_ambulance_limit_perc: new FormControl(''),
        air_ambulance_limit_value: new FormControl(''),

        Premium_Contribution: new FormControl(''),

        do_you_offer_topup: new FormControl('No'),

      });

      // Step 3 Form element defined below
      this.policyFeatures = this.fb.group({

        do_you_have_gpa: new FormControl('No'),
        no_employess_gpa: new FormControl(''),

        //SI
        SI_type: new FormControl(''),
        multiple_of_salary: new FormControl(''),
        Define_flat_SI: new FormControl(''),
        SI_Junior_management: new FormControl(''),
        SI_Middle_management: new FormControl(''),
        SI_Senior_management: new FormControl(''),

        //Waiting        
        Top_up_plan_under_accident_cover: new FormControl('No'),
        Top_up_plan_under_accident_cover_limit: new FormControl(''),
        SI_type_topup: new FormControl(''),
        multiple_of_salary_topup: new FormControl(''),
        Define_flat_SI_topup: new FormControl(''),
        SI_Junior_management_topup: new FormControl(''),
        SI_Middle_management_topup: new FormControl(''),
        SI_Senior_management_topup: new FormControl(''),
        
        Can_spouse_Accidental_cover_be_bought: new FormControl('No'),
        Can_spouse_Accidental_cover_be_bought_limit: new FormControl(''),
        AD: new FormControl('No'),
        PPD: new FormControl('No'),
        TTD: new FormControl('No'),

        Accidental_Death: new FormControl('No'),
        Accidental_Death_limit: new FormControl(''),
        Permanent_Total_Disability: new FormControl('No'),
        Permanent_Total_Disability_limit: new FormControl(''),
        Permanent_Partial_Disability: new FormControl('No'),
        Permanent_Partial_Disability_limit: new FormControl(''),
        Total_disability_loss_of_wages: new FormControl('No'),
        Total_disability_loss_of_wages_limit: new FormControl(''),
        Total_disability_loss_of_wages_INR_per_week: new FormControl(''),
        Total_disability_loss_of_wages_actual_weekly_salary: new FormControl(''),      
        
        Double_dismemberment: new FormControl('No'),
        Double_dismemberment_limit: new FormControl(''),

        Medical_benefits_Medex: new FormControl('No'),
        Medical_benefits_Medex_limit: new FormControl(''),

        Transportation_of_mortal_remains: new FormControl('No'),
        Transportation_of_mortal_remains_limit: new FormControl(''),

        Ambulance_charges: new FormControl('No'),
        Ambulance_charges_limit: new FormControl(''),

        Modification_benefit: new FormControl('No'),
        Modification_benefit_limit: new FormControl(''),

        Education_benefit: new FormControl('No'),
        Education_benefit_limit: new FormControl(''),

        Funeral_and_Repatriation_expenses: new FormControl('No'),
        Funeral_and_Repatriation_expenses_limit: new FormControl(''),

        Terrorism_clause: new FormControl('No'),
        Terrorism_clause_limit: new FormControl(''),

        do_you_have_topup: new FormControl('No'),
        offer_spouse_PA: new FormControl('No'),

      });

      // Step 4 Form element defined below
      this.documentUpload = this.fb.group({
     
      do_you_have_gtl: new FormControl('No'),
      no_employess_gtl: new FormControl(''),

      //SI
      SI_type_gtl: new FormControl(''),
      multiple_of_salary_gtl: new FormControl(''),
      Define_flat_SI_gtl: new FormControl(''),
      SI_Junior_management_gtl: new FormControl(''),
      SI_Middle_management_gtl: new FormControl(''),
      SI_Senior_management_gtl: new FormControl(''),

      actively_at_work: new FormControl('No'),
      Waived_For_all_Employees: new FormControl(''),
      Only_For_Existing_Employees: new FormControl(''),   

      // Others       
      Terminal_illness: new FormControl('No'),
      Terminal_illness_limit: new FormControl(''),
       
      Accidental_death_benefit: new FormControl('No'),
      Accidental_death_benefit_limit: new FormControl(''),      

      Accidental_permananent_Partial_Disability: new FormControl('No'),
      Accidental_permananent_Partial_Disability_limit: new FormControl(''),

      Accelerated_critical_illness: new FormControl('No'),
      Accelerated_critical_illness_limit: new FormControl(''),

      Additional_critical_illness: new FormControl('No'),
      Additional_critical_illness_limit: new FormControl(''),

      do_you_have_topupplan: new FormControl('No'),
      do_you_have_spouse_cover: new FormControl('No'),    

      });
      
      // Step 5 Form element defined below
      this.step5form = this.fb.group({
     
        do_you_have_opd: new FormControl('No'),
        OPD_cashless: new FormControl('No'),          
        Preventive_Health_Check_up: new FormControl('No'),
        Annual_Health_Check_up: new FormControl('No'),
        medical_facilities: new FormControl('No'),                   
  
      });

      // Step 6 Form element defined below
      this.step6form = this.fb.group({
             
        do_you_have_ROB: new FormControl('No'),

        offer_gratuity: new FormControl('No'),
        offer_gratuity_per_law: new FormControl('No'),

        offer_pension_plans: new FormControl('No'),          
        offer_car_leases: new FormControl('No'),
        offer_leave_encashment: new FormControl('No'),
        offer_canteen_facility: new FormControl('No'),                   
        offer_creche_facilities: new FormControl('No'),          
        offer_employee_emergency: new FormControl('No'),
        offer_courses: new FormControl('No'),
        offer_pick_drop: new FormControl('No'),      
        offer_Employee_stock: new FormControl('No'),          
        offer_Gadget_allowance: new FormControl('No'),
        offer_flexi_work: new FormControl('No'),
        offer_flexi_pay: new FormControl('No'),
        offer_sabbatical_benefits: new FormControl('No'),

        offer_menstural_leave: new FormControl('No'),
  
      });            
      
      if(this.client_id != 0 && this.survey_policy_id != 0){
        this.LoadData();
      }else{

      }      

    }    

    LoadData(){

      // this.show = true;
      // this.fullScreen = true;
      // this.template = ``; 
      
      var postdata;

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,
        "survey_policy_id": this.survey_policy_id,
      }    

      this.policyDetailsService.getclientsurveyinfo(postdata)
          .subscribe((result: any) => {

            if (result.status == true) {                    

              // this.show = false;

              // console.log(result.data.clientInfo['company_name']);

              // step 1
              this.policyDetails.controls['company_name'].setValue(result.data.clientInfo['company_name']);
              this.policyDetails.controls['industry'].setValue(result.data.clientInfo['industry']);
              this.policyDetails.controls['employee_headcount'].setValue(result.data.clientInfo['employee_headcount']);
              this.policyDetails.controls['MNC'].setValue(result.data.clientInfo['MNC']);
              this.policyDetails.controls['turnover'].setValue(result.data.clientInfo['turnover']);                       

              // // step 2
              this.verifypolicyDetails.controls['emp_atstart'].setValue(result.data.surveyPolicyInfo['no_of_employees_at_start_of_the_policy']);
              this.verifypolicyDetails.controls['do_you_have_gmc'].setValue(result.data.surveyPolicyInfo['previous_existing_policy']);
              if(result.data.surveyPolicyInfo['previous_existing_policy'] == 'Yes'){
                $(".do_you_have_gmc_yesdiv").css('display', 'block');
                $('#radio-two_do_you_have_gmc_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_gmc_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_do_you_have_gmc_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_gmc_no').css('background', '#ff4052');
                $(".do_you_have_gmc_yesdiv").css('display', 'none');
              }

              this.verifypolicyDetails.controls['family_contruct'].setValue(result.data.surveyPolicyInfo['family_construct']);
              this.verifypolicyDetails.controls['LGBTQ'].setValue(result.data.surveyPolicyInfo['LGBTQ']);
              if(result.data.surveyPolicyInfo['LGBTQ'] == 'Yes'){
                $('#radio-two_LGBTQ_yes').css('background', '#217e2b');
                $('#radio-one_LGBTQ_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_LGBTQ_yes').css('background', '#f1f1f1');
                $('#radio-one_LGBTQ_no').css('background', '#ff4052');
              }

              // SI
              this.verifypolicyDetails.controls['sum_insured_type'].setValue(result.data.surveyPolicyInfo['sic_sum_insured_type']);
              this.verifypolicyDetails.controls['sum_insured_approach'].setValue(result.data.surveyPolicyInfo['sic_sum_insured_approach']);
              // this.verifypolicyDetails.controls['sum_insured_uniform'].setValue(result.data.surveyPolicyInfo['sic_sum_insured_uniform_amount']);             

              if(result.data.surveyPolicyInfo['sic_sum_insured_approach'] == "Uniform"){
                this.sumapproach('Uniform');
                $('#sum_insured_uniform').val(result.data.surveyPolicyInfo['sic_sum_insured_uniform_amount']);
              }
              

              // Waiting
              this.verifypolicyDetails.controls['thirty_days_waiting_period'].setValue(result.data.surveyPolicyInfo['wpc_thirty_days_waiting_period']);
              if(result.data.surveyPolicyInfo['wpc_thirty_days_waiting_period'] == 'Not Applicable'){
                $('#divthirty_days_waiting_period_Covered').css('background', '#217e2b');
                $('#divthirty_days_waiting_period_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divthirty_days_waiting_period_Covered').css('background', '#f1f1f1');
                $('#divthirty_days_waiting_period_NotCovered').css('background', '#ff4052');
              }

              this.verifypolicyDetails.controls['one_and_half_days_waiting_period'].setValue(result.data.surveyPolicyInfo['wpc_1year_or_2years_waiting_period']);
              if(result.data.surveyPolicyInfo['wpc_1year_or_2years_waiting_period'] == 'Not Applicable'){
                $('#divone_and_half_days_waiting_period_Covered').css('background', '#217e2b');
                $('#divone_and_half_days_waiting_period_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divone_and_half_days_waiting_period_Covered').css('background', '#f1f1f1');
                $('#divone_and_half_days_waiting_period_NotCovered').css('background', '#ff4052');
              }

              this.verifypolicyDetails.controls['pre_existing_condition'].setValue(result.data.surveyPolicyInfo['wpc_pre_existing_condtion']);
              if(result.data.surveyPolicyInfo['wpc_pre_existing_condtion'] == 'Not Applicable'){
                $('#divpre_existing_condition_Covered').css('background', '#217e2b');
                $('#divpre_existing_condition_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divpre_existing_condition_Covered').css('background', '#f1f1f1');
                $('#divpre_existing_condition_NotCovered').css('background', '#ff4052');
              }

              // hosp
              this.verifypolicyDetails.controls['room_rent_condition_one'].setValue(result.data.surveyPolicyInfo['hd_normal_room_rent']);
              this.verifypolicyDetails.controls['ICU_room_rent'].setValue(result.data.surveyPolicyInfo['hd_ICU_room_rent']);
              this.verifypolicyDetails.controls['pre_post_hospitalisation'].setValue(result.data.surveyPolicyInfo['hd_pre_post_hospitalisation_cover']);

              this.verifypolicyDetails.controls['day_care_procedures'].setValue(result.data.surveyPolicyInfo['hd_day_care_procedures']);
              this.verifypolicyDetails.controls['min_max_day_care_procedures'].setValue(result.data.surveyPolicyInfo['hd_min_max_day_care_procedures']);
              this.verifypolicyDetails.controls['day_care_procedures_limit_perc'].setValue(result.data.surveyPolicyInfo['hd_day_care_procedures_limit_perc']);
              this.verifypolicyDetails.controls['day_care_procedures_limit_value'].setValue(result.data.surveyPolicyInfo['hd_day_care_procedures_limit_value']);

              if(result.data.surveyPolicyInfo['hd_day_care_procedures'] == 'Not Covered'){
                $("#divday_care_procedures_limit").css('display', 'none');                    
                $('#divday_care_procedures_limit_Covered').css('background', '#f1f1f1');
                $('#divday_care_procedures_limit_NotCovered').css('background', '#ff4052');            
              }else{
                $("#divday_care_procedures_limit").css('display', 'block');
                $('#divday_care_procedures_limit_Covered').css('background', '#217e2b');
                $('#divday_care_procedures_limit_NotCovered').css('background', '#f1f1f1');                                                
              }

              if(result.data.surveyPolicyInfo['hd_min_max_day_care_procedures'] == 'max_day_care_procedures' || result.data.surveyPolicyInfo['hd_min_max_day_care_procedures'] == 'min_day_care_procedures'){
                $(".day_care_procedures_limit_value").css('display', 'flex');
              }else{
                $(".day_care_procedures_limit_value").css('display', 'none');
              }                            

              this.verifypolicyDetails.controls['domiciliary_hospitalisation'].setValue(result.data.surveyPolicyInfo['hd_domiciliary_hospitalisation']);
              this.verifypolicyDetails.controls['min_max_domiciliary_hospitalisation'].setValue(result.data.surveyPolicyInfo['hd_min_max_domiciliary_hospitalisation']);
              this.verifypolicyDetails.controls['domiciliary_hospitalisation_limit_perc'].setValue(result.data.surveyPolicyInfo['hd_domiciliary_hospitalisation_limit_perc']);
              this.verifypolicyDetails.controls['domiciliary_hospitalisation_limit_value'].setValue(result.data.surveyPolicyInfo['hd_domiciliary_hospitalisation_limit_value']);

              if(result.data.surveyPolicyInfo['hd_domiciliary_hospitalisation'] == 'Not Covered'){
                $("#divdomiciliary_hospitalisation_limit").css('display', 'none');
                $('#divdomiciliary_hospitalisation_limit_Covered').css('background', '#f1f1f1');
                $('#divdomiciliary_hospitalisation_limit_NotCovered').css('background', '#ff4052');  
              }else{
                $("#divdomiciliary_hospitalisation_limit").css('display', 'block');
                $('#divdomiciliary_hospitalisation_limit_Covered').css('background', '#217e2b');
                $('#divdomiciliary_hospitalisation_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['hd_min_max_domiciliary_hospitalisation'] == 'max_domiciliary_hospitalisation' || result.data.surveyPolicyInfo['hd_min_max_domiciliary_hospitalisation'] == 'min_domiciliary_hospitalisation'){
                console.log(1)
                $(".domiciliary_hospitalisation_limit_value").css('display', 'flex');
              }else{
                console.log(2)
                $(".domiciliary_hospitalisation_limit_value").css('display', 'none');
              }

              // Maternity
              this.verifypolicyDetails.controls['do_you_want_maternity'].setValue(result.data.surveyPolicyInfo['mc_do_you_want_maternity']);

              if(result.data.surveyPolicyInfo['mc_do_you_want_maternity'] == 'Not Covered'){
                $(".do_you_want_maternity_yesdiv").css('display', 'none');
                $(".do_you_want_maternity_nodiv").css('display', 'block');
                $('#radio-two_do_you_want_maternity_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_want_maternity_no').css('background', '#ff4052');  
              }else{
                $(".do_you_want_maternity_yesdiv").css('display', 'block');
                $(".do_you_want_maternity_nodiv").css('display', 'none');
                $('#radio-two_do_you_want_maternity_yes').css('background', '#217e2b');
                $('#radio-one_do_you_want_maternity_no').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['room_rent_natal_cover'].setValue(result.data.surveyPolicyInfo['mc_prepost_natal_cover']);
              this.verifypolicyDetails.controls['room_rent_natal_cover_isCover'].setValue(result.data.surveyPolicyInfo['mc_prepost_natal_iscover']);
              this.verifypolicyDetails.controls['room_rent_natal_cover_limit'].setValue(result.data.surveyPolicyInfo['mc_prepost_natal_cover_limit']);

              if(result.data.surveyPolicyInfo['mc_prepost_natal_cover'] == 'Not Covered'){
                $(".divroom_rent_natal_cover").css('display', 'none');                
                $('#divroom_rent_natal_cover_Covered').css('background', '#f1f1f1');
                $('#divroom_rent_natal_cover_NotCovered').css('background', '#ff4052');  
              }else{
                $(".divroom_rent_natal_cover").css('display', 'block');
                $('#divroom_rent_natal_cover_Covered').css('background', '#217e2b');
                $('#divroom_rent_natal_cover_NotCovered').css('background', '#f1f1f1');  
              }

              this.verifypolicyDetails.controls['bay_day_one'].setValue(result.data.surveyPolicyInfo['mc_bay_day_one']);
              this.verifypolicyDetails.controls['bay_day_one_isCover'].setValue(result.data.surveyPolicyInfo['mc_bay_day_one_iscover']);
              this.verifypolicyDetails.controls['baby_specific_covered_limit'].setValue(result.data.surveyPolicyInfo['mc_bay_day_one_limit']);

              if(result.data.surveyPolicyInfo['mc_bay_day_one'] == 'Not Covered'){
                $(".divbay_day_one").css('display', 'none');
                $('#divbay_day_one_Covered').css('background', '#f1f1f1');
                $('#divbay_day_one_NotCovered').css('background', '#ff4052');
              }else{
                $(".divbay_day_one").css('display', 'block');
                $('#divbay_day_one_Covered').css('background', '#217e2b');
                $('#divbay_day_one_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['infertility_cover'].setValue(result.data.surveyPolicyInfo['mc_infertility_cover']);
              this.verifypolicyDetails.controls['infertility_cover_isCover'].setValue(result.data.surveyPolicyInfo['mc_infertility_cover_iscover']);
              this.verifypolicyDetails.controls['infertility_covered_limit'].setValue(result.data.surveyPolicyInfo['mc_infertility_cover_limit']);

              if(result.data.surveyPolicyInfo['mc_infertility_cover'] == 'Not Covered'){
                $(".divinfertility_cover").css('display', 'none');
                $('#divinfertility_cover_Covered').css('background', '#f1f1f1');
                $('#divinfertility_cover_NotCovered').css('background', '#ff4052');
              }else{
                $(".divinfertility_cover").css('display', 'block');
                $('#divinfertility_cover_Covered').css('background', '#217e2b');
                $('#divinfertility_cover_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['surrogacy_cover'].setValue(result.data.surveyPolicyInfo['mc_surrogacy_cover']);
              this.verifypolicyDetails.controls['surrogacy_cover_isCover'].setValue(result.data.surveyPolicyInfo['mc_surrogacy_cover_iscover']);
              this.verifypolicyDetails.controls['surrogacy_covered_limit'].setValue(result.data.surveyPolicyInfo['mc_surrogacy_cover_limit']);

              if(result.data.surveyPolicyInfo['mc_surrogacy_cover'] == 'Not Covered'){
                $(".divsurrogacy_cover").css('display', 'none');
                $('#divsurrogacy_cover_Covered').css('background', '#f1f1f1');
                $('#divsurrogacy_cover_NotCovered').css('background', '#ff4052');
              }else{
                $(".divsurrogacy_cover").css('display', 'block');
                $('#divsurrogacy_cover_Covered').css('background', '#217e2b');
                $('#divsurrogacy_cover_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['maternity_related_complications_yes'].setValue(result.data.surveyPolicyInfo['mc_maternity_related_complications']);
              if(result.data.surveyPolicyInfo['mc_maternity_related_complications'] == 'Not Covered'){
                $(".divmaternity_related_complications_yes").css('display', 'none');
                $('#divmaternity_related_complications_yes_Covered').css('background', '#f1f1f1');
                $('#divmaternity_related_complications_yes_NotCovered').css('background', '#ff4052');
              }else{
                $(".divmaternity_related_complications_yes").css('display', 'block');
                $('#divmaternity_related_complications_yes_Covered').css('background', '#217e2b');
                $('#divmaternity_related_complications_yes_NotCovered').css('background', '#f1f1f1');
              }
              this.verifypolicyDetails.controls['maternity_related_complications_yes_isCover'].setValue(result.data.surveyPolicyInfo['mc_maternity_related_complications_iscover']);
     
              this.verifypolicyDetails.controls['maternity_related_complications_no'].setValue(result.data.surveyPolicyInfo['mc_maternity_related_complications']);
              if(result.data.surveyPolicyInfo['mc_maternity_related_complications'] == 'Not Covered'){
                $(".divmaternity_related_complications_no").css('display', 'none');
                $('#divmaternity_related_complications_no_Covered').css('background', '#f1f1f1');
                $('#divmaternity_related_complications_no_NotCovered').css('background', '#ff4052');
              }else{
                $(".divmaternity_related_complications_no").css('display', 'block');
                $('#divmaternity_related_complications_no_Covered').css('background', '#217e2b');
                $('#divmaternity_related_complications_no_NotCovered').css('background', '#f1f1f1');
              }
              this.verifypolicyDetails.controls['maternity_related_complications_no_isCover'].setValue(result.data.surveyPolicyInfo['mc_maternity_related_complications_iscover']);
        
              this.verifypolicyDetails.controls['normal_delivery_limit_maternity'].setValue(result.data.surveyPolicyInfo['mc_normal_delivery_limit_maternity']);
              this.verifypolicyDetails.controls['c_section_limit_maternity'].setValue(result.data.surveyPolicyInfo['mc_c_section_limit_maternity']);
        
              // Claim 
              this.verifypolicyDetails.controls['copay'].setValue(result.data.surveyPolicyInfo['ccm_copay']);
              if(result.data.surveyPolicyInfo['ccm_copay'] == 'Not Applicable'){
                $('#divcopay_Covered').css('background', '#217e2b');
                $('#divcopay_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divcopay_Covered').css('background', '#f1f1f1');
                $('#divcopay_NotCovered').css('background', '#ff4052');
              }

              this.verifypolicyDetails.controls['deductable'].setValue(result.data.surveyPolicyInfo['ccm_deductable']);        
              if(result.data.surveyPolicyInfo['ccm_deductable'] == 'Not Applicable'){
                $('#divdeductable_Covered').css('background', '#217e2b');
                $('#divdeductable_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divdeductable_Covered').css('background', '#f1f1f1');
                $('#divdeductable_NotCovered').css('background', '#ff4052');
              }

              this.verifypolicyDetails.controls['sum_incured_sub_limit'].setValue(result.data.surveyPolicyInfo['ccm_sum_incured_sub_limit']);
              if(result.data.surveyPolicyInfo['ccm_sum_incured_sub_limit'] == 'Not Applicable'){
                $('#divsum_incured_sub_limit_Covered').css('background', '#217e2b');
                $('#divsum_incured_sub_limit_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divsum_incured_sub_limit_Covered').css('background', '#f1f1f1');
                $('#divsum_incured_sub_limit_NotCovered').css('background', '#ff4052');
              }

              this.verifypolicyDetails.controls['claim_diseasewisecapping_condition'].setValue(result.data.surveyPolicyInfo['ccm_claim_diseasewisecapping_condition']);
              if(result.data.surveyPolicyInfo['ccm_claim_diseasewisecapping_condition'] == 'Not Applicable'){
                $('#divclaim_diseasewisecapping_condition_Covered').css('background', '#217e2b');
                $('#divclaim_diseasewisecapping_condition_NotCovered').css('background', '#f1f1f1');
              }else{
                $('#divclaim_diseasewisecapping_condition_Covered').css('background', '#f1f1f1');
                $('#divclaim_diseasewisecapping_condition_NotCovered').css('background', '#ff4052');
              }
      

              // others 
              this.verifypolicyDetails.controls['out_patient_benefits'].setValue(result.data.surveyPolicyInfo['opd_out_patient_benefits']);
              this.verifypolicyDetails.controls['out_patient_benefits_limit'].setValue(result.data.surveyPolicyInfo['opd_out_patient_limit']);        

              if(result.data.surveyPolicyInfo['opd_out_patient_benefits'] == 'Not Covered'){
                $("#divout_patient_benefits_limit").css('display', 'none');
                $('#divout_patient_benefits_limit_Covered').css('background', '#f1f1f1');
                $('#divout_patient_benefits_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divout_patient_benefits_limit").css('display', 'block');
                $('#divout_patient_benefits_limit_Covered').css('background', '#217e2b');
                $('#divout_patient_benefits_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['opd_dental'].setValue(result.data.surveyPolicyInfo['opd_dental']);
              this.verifypolicyDetails.controls['opd_dental_limit'].setValue(result.data.surveyPolicyInfo['opd_dental_limit']);

              if(result.data.surveyPolicyInfo['opd_dental'] == 'Not Covered'){
                $("#divopd_dental_limit").css('display', 'none');
                $('#divopd_dental_limit_Covered').css('background', '#f1f1f1');
                $('#divopd_dental_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divopd_dental_limit").css('display', 'block');
                $('#divopd_dental_limit_Covered').css('background', '#217e2b');
                $('#divopd_dental_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['opd_vision'].setValue(result.data.surveyPolicyInfo['opd_vision']);
              this.verifypolicyDetails.controls['opd_vision_limit'].setValue(result.data.surveyPolicyInfo['opd_vision_limit']);

              if(result.data.surveyPolicyInfo['opd_vision'] == 'Not Covered'){
                $("#divopd_vision_limit").css('display', 'none');
                $('#divopd_vision_limit_Covered').css('background', '#f1f1f1');
                $('#divopd_vision_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divopd_vision_limit").css('display', 'block');
                $('#divopd_vision_limit_Covered').css('background', '#217e2b');
                $('#divopd_vision_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['preventive_health_checkup'].setValue(result.data.surveyPolicyInfo['opd_preventive_health_checkup']);
              this.verifypolicyDetails.controls['preventive_health_checkup_limit'].setValue(result.data.surveyPolicyInfo['opd_preventive_health_checkup_limit']);

              if(result.data.surveyPolicyInfo['opd_preventive_health_checkup'] == 'Not Covered'){
                $("#divpreventive_health_checkup_limit").css('display', 'none');
                $('#divpreventive_health_checkup_limit_Covered').css('background', '#f1f1f1');
                $('#divpreventive_health_checkup_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divpreventive_health_checkup_limit").css('display', 'block');
                $('#divpreventive_health_checkup_limit_Covered').css('background', '#217e2b');
                $('#divpreventive_health_checkup_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['organ_donor_cover'].setValue(result.data.surveyPolicyInfo['ad_organ_donor_cover']);
              this.verifypolicyDetails.controls['min_max_organ_donor_cover'].setValue(result.data.surveyPolicyInfo['ad_minmax_organ_donor_cover']);
              this.verifypolicyDetails.controls['organ_donor_cover_limit_perc'].setValue(result.data.surveyPolicyInfo['ad_organ_donor_perc']);
              this.verifypolicyDetails.controls['organ_donor_cover_limit_value'].setValue(result.data.surveyPolicyInfo['ad_organ_donor_limit']);

              if(result.data.surveyPolicyInfo['ad_organ_donor_cover'] == 'Not Covered'){
                $("#divorgan_donor_cover_limit").css('display', 'none');
                $('#divorgan_donor_cover_limit_Covered').css('background', '#f1f1f1');
                $('#divorgan_donor_cover_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divorgan_donor_cover_limit").css('display', 'block');
                $('#divorgan_donor_cover_limit_Covered').css('background', '#217e2b');
                $('#divorgan_donor_cover_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ad_minmax_organ_donor_cover'] == 'max_organ_donor_cover' || result.data.surveyPolicyInfo['ad_minmax_organ_donor_cover'] == 'min_organ_donor_cover'){
                $(".organ_donor_cover_limit_value").css('display', 'flex');
              }else{
                $(".organ_donor_cover_limit_value").css('display', 'none');
              }

              this.verifypolicyDetails.controls['oral_chemo_therapy'].setValue(result.data.surveyPolicyInfo['ad_oral_chemo_therapy_cover']);
              this.verifypolicyDetails.controls['min_max_oral_chemo_therapy'].setValue(result.data.surveyPolicyInfo['ad_minmax_oral_chemo_therapy_cover']);
              this.verifypolicyDetails.controls['oral_chemo_therapy_limit_perc'].setValue(result.data.surveyPolicyInfo['ad_oral_chemo_therapy_perc']);
              this.verifypolicyDetails.controls['oral_chemo_therapy_limit_value'].setValue(result.data.surveyPolicyInfo['ad_oral_chemo_therapy_limit']);
            
              if(result.data.surveyPolicyInfo['ad_oral_chemo_therapy_cover'] == 'Not Covered'){
                $("#divoral_chemo_therapy_limit").css('display', 'none');
                $('#divoral_chemo_therapy_limit_Covered').css('background', '#f1f1f1');
                $('#divoral_chemo_therapy_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divoral_chemo_therapy_limit").css('display', 'block');
                $('#divoral_chemo_therapy_limit_Covered').css('background', '#217e2b');
                $('#divoral_chemo_therapy_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ad_minmax_oral_chemo_therapy_cover'] == 'max_oral_chemo_therapy' || result.data.surveyPolicyInfo['ad_minmax_oral_chemo_therapy_cover'] == 'min_oral_chemo_therapy'){
                $(".oral_chemo_therapy_limit_value").css('display', 'flex');
              }else{
                $(".oral_chemo_therapy_limit_value").css('display', 'none');
              }

              this.verifypolicyDetails.controls['ayush_treatment'].setValue(result.data.surveyPolicyInfo['ad_ayush_treatment_cover']);
              this.verifypolicyDetails.controls['min_max_ayush_treatment'].setValue(result.data.surveyPolicyInfo['ad_minmax_ayush_treatment_cover']);
              this.verifypolicyDetails.controls['ayush_treatment_limit_perc'].setValue(result.data.surveyPolicyInfo['ad_ayush_treatment_perc']);
              this.verifypolicyDetails.controls['ayush_treatment_limit_value'].setValue(result.data.surveyPolicyInfo['ad_ayush_treatment_limit']);
            
              if(result.data.surveyPolicyInfo['ad_ayush_treatment_cover'] == 'Not Covered'){
                $("#divayush_treatment_limit").css('display', 'none');
                $('#divayush_treatment_limit_Covered').css('background', '#f1f1f1');
                $('#divayush_treatment_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divayush_treatment_limit").css('display', 'block');
                $('#divayush_treatment_limit_Covered').css('background', '#217e2b');
                $('#divayush_treatment_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ad_minmax_ayush_treatment_cover'] == 'max_ayush_treatment' || result.data.surveyPolicyInfo['ad_minmax_ayush_treatment_cover'] == 'min_ayush_treatment'){
                console.log(1)
                $(".ayush_treatment_limit_value").css('display', 'flex');
              }else{
                console.log(2)
                $(".ayush_treatment_limit_value").css('display', 'none');
              }

              this.verifypolicyDetails.controls['lasik_treatment'].setValue(result.data.surveyPolicyInfo['ad_lasik_treatment']);
              // this.verifypolicyDetails.controls['min_max_lasik_treatment'].setValue(result.data.surveyPolicyInfo['min_max_lasik_treatment']);
              // this.verifypolicyDetails.controls['lasik_treatment_limit_perc'].setValue(result.data.surveyPolicyInfo['lasik_treatment_limit_perc']);
              this.verifypolicyDetails.controls['lasik_treatment_limit_value'].setValue(result.data.surveyPolicyInfo['ad_lasik_treatment_value']);
        
              if(result.data.surveyPolicyInfo['ad_lasik_treatment'] == 'Not Covered'){
                $("#divlasik_treatment_limit").css('display', 'none');
                $('#divlasik_treatment_limit_Covered').css('background', '#f1f1f1');
                $('#divlasik_treatment_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divlasik_treatment_limit").css('display', 'block');
                $('#divlasik_treatment_limit_Covered').css('background', '#217e2b');
                $('#divlasik_treatment_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.verifypolicyDetails.controls['corporate_buffer'].setValue(result.data.surveyPolicyInfo['ad_corporate_buffer']);
              if(result.data.surveyPolicyInfo['ad_corporate_buffer'] == 'Not Covered'){
                $(".divcorporate_buffer").css('display', 'none');
              }else{
                $(".divcorporate_buffer").css('display', 'block');
              }
              this.verifypolicyDetails.controls['corporate_buffer_isCover'].setValue(result.data.surveyPolicyInfo['ad_corporate_buffer_cover']);
              this.verifypolicyDetails.controls['corporate_buffer_limit'].setValue(result.data.surveyPolicyInfo['ad_corporate_buffer_policy_limit']);
              this.verifypolicyDetails.controls['corporate_buffer_family_limit'].setValue(result.data.surveyPolicyInfo['ad_corporate_buffer_family_limit']);		  

              if(result.data.surveyPolicyInfo['ad_corporate_buffer'] == 'Not Covered'){
                $("#divcorporate_buffer_limit").css('display', 'none');
              }else{
                $("#divcorporate_buffer_limit").css('display', 'block');
              }

              // Ambulance
              this.verifypolicyDetails.controls['road_ambulance'].setValue(result.data.surveyPolicyInfo['ac_road_ambulance_cover']);
              this.verifypolicyDetails.controls['min_max_road_ambulance'].setValue(result.data.surveyPolicyInfo['ac_minmax_road_ambulance_cover']);
              this.verifypolicyDetails.controls['road_ambulance_limit_perc'].setValue(result.data.surveyPolicyInfo['ac_road_ambulance_limit_perc']);
              this.verifypolicyDetails.controls['road_ambulance_limit_value'].setValue(result.data.surveyPolicyInfo['ac_road_ambulance_limit']);
        
              if(result.data.surveyPolicyInfo['ac_road_ambulance_cover'] == 'Not Covered'){
                $("#divroad_ambulance_limit").css('display', 'none');
                $('#divroad_ambulance_limit_Covered').css('background', '#f1f1f1');
                $('#divroad_ambulance_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divroad_ambulance_limit").css('display', 'block');
                $('#divroad_ambulance_limit_Covered').css('background', '#217e2b');
                $('#divroad_ambulance_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ac_minmax_road_ambulance_cover'] == 'max_road_ambulance' || result.data.surveyPolicyInfo['ac_minmax_road_ambulance_cover'] == 'min_road_ambulance'){
                $(".road_ambulance_limit_value").css('display', 'flex');                
              }else{
                $(".road_ambulance_limit_value").css('display', 'none');                
              }

              this.verifypolicyDetails.controls['cardiac_ambulance'].setValue(result.data.surveyPolicyInfo['ac_cardiac_ambulance_cover']);
              this.verifypolicyDetails.controls['min_max_cardiac_ambulance'].setValue(result.data.surveyPolicyInfo['ac_minmax_cardiac_ambulance_cover']);
              this.verifypolicyDetails.controls['cardiac_ambulance_limit_perc'].setValue(result.data.surveyPolicyInfo['ac_cardiac_ambulance_limit_perc']);
              this.verifypolicyDetails.controls['cardiac_ambulance_limit_value'].setValue(result.data.surveyPolicyInfo['ac_cardiac_ambulance_limit']);		  

              if(result.data.surveyPolicyInfo['ac_cardiac_ambulance_cover'] == 'Not Covered'){
                $("#divcardiac_ambulance_limit").css('display', 'none');
                $('#divcardiac_ambulance_limit_Covered').css('background', '#f1f1f1');
                $('#divcardiac_ambulance_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divcardiac_ambulance_limit").css('display', 'block');
                $('#divcardiac_ambulance_limit_Covered').css('background', '#217e2b');
                $('#divcardiac_ambulance_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ac_minmax_cardiac_ambulance_cover'] == 'max_cardiac_ambulance' || result.data.surveyPolicyInfo['ac_minmax_cardiac_ambulance_cover'] == 'min_cardiac_ambulance'){
                $(".cardiac_ambulance_limit_value").css('display', 'flex');
              }else{
                $(".cardiac_ambulance_limit_value").css('display', 'none');
              }

              this.verifypolicyDetails.controls['air_ambulance'].setValue(result.data.surveyPolicyInfo['ac_air_ambulance_cover']);
              this.verifypolicyDetails.controls['min_max_air_ambulance'].setValue(result.data.surveyPolicyInfo['ac_minmax_air_ambulance_cover']);
              this.verifypolicyDetails.controls['air_ambulance_limit_perc'].setValue(result.data.surveyPolicyInfo['ac_air_ambulance_limit_perc']);
              this.verifypolicyDetails.controls['air_ambulance_limit_value'].setValue(result.data.surveyPolicyInfo['ac_air_ambulance_limit']);		  
      
              if(result.data.surveyPolicyInfo['ac_air_ambulance_cover'] == 'Not Covered'){
                $("#divair_ambulance_limit").css('display', 'none');
                $('#divair_ambulance_limit_Covered').css('background', '#f1f1f1');
                $('#divair_ambulance_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divair_ambulance_limit").css('display', 'block');
                $('#divair_ambulance_limit_Covered').css('background', '#217e2b');
                $('#divair_ambulance_limit_NotCovered').css('background', '#f1f1f1');
              }

              if(result.data.surveyPolicyInfo['ac_minmax_air_ambulance_cover'] == 'max_air_ambulance' || result.data.surveyPolicyInfo['ac_minmax_air_ambulance_cover'] == 'min_air_ambulance'){
                $(".air_ambulance_limit_value").css('display', 'flex');
              }else{
                $(".air_ambulance_limit_value").css('display', 'none');
              }

              this.verifypolicyDetails.controls['Premium_Contribution'].setValue(result.data.surveyPolicyInfo['premium_contribution']);
              this.verifypolicyDetails.controls['do_you_offer_topup'].setValue(result.data.surveyPolicyInfo['do_you_offer_topup']);		
              if(result.data.surveyPolicyInfo['ad_lasik_treatment'] == 'No'){
                $('#radio-two_do_you_offer_topup_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_offer_topup_no').css('background', '#ff4052');
              }else{
                $('#radio-two_do_you_offer_topup_yes').css('background', '#217e2b');
                $('#radio-one_do_you_offer_topup_no').css('background', '#f1f1f1');
              }                

              // step 3
              this.policyFeatures.controls['do_you_have_gpa'].setValue(result.data.surveyPolicyInfo['gpa_status']);
              if(result.data.surveyPolicyInfo['gpa_status'] == 'Yes'){
                $(".do_you_have_gpa_yesdiv").css('display', 'block');
                $('#radio-two_do_you_have_gpa_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_gpa_no').css('background', '#f1f1f1');
              }else{
                $(".do_you_have_gpa_yesdiv").css('display', 'none');
                $('#radio-two_do_you_have_gpa_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_gpa_no').css('background', '#ff4052');
              }
              this.policyFeatures.controls['no_employess_gpa'].setValue(result.data.surveyPolicyInfo['gpa_employee_count']);

              //SI

              this.policyFeatures.controls['SI_type'].setValue(result.data.surveyPolicyInfo['sic_accidental_sum_assured_type']);
              if(result.data.surveyPolicyInfo['sic_accidental_sum_assured_type'] == 'Multiple of Salary'){
                $("#div_Multiple_of_Salary").css('display', 'block');
              }else{
                $("#div_Multiple_of_Salary").css('display', 'none');
              }

              if(result.data.surveyPolicyInfo['sic_accidental_sum_assured_type'] == 'Flat'){
                $("#div_Flat").css('display', 'block');
              }else{
                $("#div_Flat").css('display', 'none');
              }

              if(result.data.surveyPolicyInfo['sic_accidental_sum_assured_type'] == 'Graded'){
                $("#div_Graded").css('display', 'block');
              }else{
                $("#div_Graded").css('display', 'none');
              }

              this.policyFeatures.controls['multiple_of_salary'].setValue(result.data.surveyPolicyInfo['sic_multiple_of_salary']);
              this.policyFeatures.controls['Define_flat_SI'].setValue(result.data.surveyPolicyInfo['sic_define_flat_accidental_si']);
              this.policyFeatures.controls['SI_Junior_management'].setValue(result.data.surveyPolicyInfo['sic_graded_si_junior_management']);
              this.policyFeatures.controls['SI_Middle_management'].setValue(result.data.surveyPolicyInfo['sic_graded_si_middle_management']);
              this.policyFeatures.controls['SI_Senior_management'].setValue(result.data.surveyPolicyInfo['sic_graded_si_senior_management']);

              //Waiting

              this.policyFeatures.controls['Accidental_Death'].setValue(result.data.surveyPolicyInfo['ddb_accidental_death']);
              if(result.data.surveyPolicyInfo['ddb_accidental_death'] == 'No'){
                $('#radio-two_Accidental_Death_yes').css('background', '#f1f1f1');
                $('#radio-one_Accidental_Death_no').css('background', '#ff4052');
              }else{
                $('#radio-two_Accidental_Death_yes').css('background', '#217e2b');
                $('#radio-one_Accidental_Death_no').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Permanent_Total_Disability'].setValue(result.data.surveyPolicyInfo['ddb_permanent_total_disability']);
              if(result.data.surveyPolicyInfo['ddb_permanent_total_disability'] == 'No'){
                $("#divPermanent_Total_Disability_limit").css('display', 'none');
                $('#divPermanent_Total_Disability_limit_Covered').css('background', '#f1f1f1');
                $('#divPermanent_Total_Disability_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divPermanent_Total_Disability_limit").css('display', 'block');
                $('#divPermanent_Total_Disability_limit_Covered').css('background', '#217e2b');
                $('#divPermanent_Total_Disability_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.policyFeatures.controls['Permanent_Total_Disability_limit'].setValue(result.data.surveyPolicyInfo['ddb_permanent_total_disability_limit']);


              this.policyFeatures.controls['Permanent_Partial_Disability'].setValue(result.data.surveyPolicyInfo['ddb_permanent_partial_disability']);
              // this.policyFeatures.controls['Permanent_Partial_Disability_limit'].setValue(result.data.surveyPolicyInfo['ddb_permanent_partial_disability']);
              if(result.data.surveyPolicyInfo['ddb_permanent_partial_disability'] == 'No'){
                $("#divPermanent_Partial_Disability_limit").css('display', 'none');
                $('#divPermanent_Partial_Disability_limit_Covered').css('background', '#f1f1f1');
                $('#divPermanent_Partial_Disability_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divPermanent_Partial_Disability_limit").css('display', 'block');
                $('#divPermanent_Partial_Disability_limit_Covered').css('background', '#217e2b');
                $('#divPermanent_Partial_Disability_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.policyFeatures.controls['Total_disability_loss_of_wages'].setValue(result.data.surveyPolicyInfo['ddb_total_disability_loss_of_wages']);
              this.policyFeatures.controls['Total_disability_loss_of_wages_limit'].setValue(result.data.surveyPolicyInfo['ddb_total_disability_loss_of_wages_limit']);
              if(result.data.surveyPolicyInfo['ddb_total_disability_loss_of_wages'] == 'No'){
                $("#divTotal_disability_loss_of_wages_limit").css('display', 'none');
                $('#divTotal_disability_loss_of_wages_limit_Covered').css('background', '#f1f1f1');
                $('#divTotal_disability_loss_of_wages_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divTotal_disability_loss_of_wages_limit").css('display', 'block');
                $('#divTotal_disability_loss_of_wages_limit_Covered').css('background', '#217e2b');
                $('#divTotal_disability_loss_of_wages_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.policyFeatures.controls['Total_disability_loss_of_wages_INR_per_week'].setValue(result.data.surveyPolicyInfo['ddb_total_disability_loss_of_wages_INR_per_week']);
              this.policyFeatures.controls['Total_disability_loss_of_wages_actual_weekly_salary'].setValue(result.data.surveyPolicyInfo['ddb_total_disability_loss_of_wages_INR_weekly_salary']);

              this.policyFeatures.controls['Double_dismemberment'].setValue(result.data.surveyPolicyInfo['ab_double_dismemberment']);
              if(result.data.surveyPolicyInfo['ab_double_dismemberment'] == 'No'){
                $("#divMedical_benefits_Medex_limit").css('display', 'none');
                $('#radio-two_Double_dismemberment_yes').css('background', '#f1f1f1');
                $('#radio-one_Double_dismemberment_no').css('background', '#ff4052');
              }else{
                $("#divMedical_benefits_Medex_limit").css('display', 'block');
                $('#radio-two_Double_dismemberment_yes').css('background', '#217e2b');
                $('#radio-one_Double_dismemberment_no').css('background', '#f1f1f1');
              }
              // this.policyFeatures.controls['Double_dismemberment_limit'].setValue(result.data.surveyPolicyInfo['ddb_accidental_death']);

              this.policyFeatures.controls['Medical_benefits_Medex'].setValue(result.data.surveyPolicyInfo['ab_medical_benefits_medex']);
              this.policyFeatures.controls['Medical_benefits_Medex_limit'].setValue(result.data.surveyPolicyInfo['ab_medical_benefits_medex_limit']);

              if(result.data.surveyPolicyInfo['ab_medical_benefits_medex'] == 'No'){
                $("#divMedical_benefits_Medex_limit").css('display', 'none');
                $('#divMedical_benefits_Medex_limit_Covered').css('background', '#f1f1f1');
                $('#divMedical_benefits_Medex_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divMedical_benefits_Medex_limit").css('display', 'block');
                $('#divMedical_benefits_Medex_limit_Covered').css('background', '#217e2b');
                $('#divMedical_benefits_Medex_limit_NotCovered').css('background', '#f1f1f1');
              }

              this.policyFeatures.controls['Transportation_of_mortal_remains'].setValue(result.data.surveyPolicyInfo['ab_transportation_of_mortal_remains']);
              this.policyFeatures.controls['Transportation_of_mortal_remains_limit'].setValue(result.data.surveyPolicyInfo['ab_transportation_of_mortal_remains_limit']);
              if(result.data.surveyPolicyInfo['ab_transportation_of_mortal_remains'] == 'No'){
                $("#divTransportation_of_mortal_remains_limit").css('display', 'none');
                $('#divTransportation_of_mortal_remains_limit_Covered').css('background', '#f1f1f1');
                $('#divTransportation_of_mortal_remains_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divTransportation_of_mortal_remains_limit").css('display', 'block');
                $('#divTransportation_of_mortal_remains_limit_Covered').css('background', '#217e2b');
                $('#divTransportation_of_mortal_remains_limit_NotCovered').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Ambulance_charges'].setValue(result.data.surveyPolicyInfo['ab_ambulance_charges']);
              this.policyFeatures.controls['Ambulance_charges_limit'].setValue(result.data.surveyPolicyInfo['ab_ambulance_charges_limit']);
              if(result.data.surveyPolicyInfo['ab_ambulance_charges'] == 'No'){
                $("#divAmbulance_charges_limit").css('display', 'none');
                $('#divAmbulance_charges_limit_Covered').css('background', '#f1f1f1');
                $('#divAmbulance_charges_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divAmbulance_charges_limit").css('display', 'block');
                $('#divAmbulance_charges_limit_Covered').css('background', '#217e2b');
                $('#divAmbulance_charges_limit_NotCovered').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Modification_benefit'].setValue(result.data.surveyPolicyInfo['ab_modification_benefit']);
              this.policyFeatures.controls['Modification_benefit_limit'].setValue(result.data.surveyPolicyInfo['ab_modification_benefit_limit']);
              if(result.data.surveyPolicyInfo['ab_modification_benefit'] == 'No'){
                $("#divModification_benefit_limit").css('display', 'none');
                $('#divModification_benefit_limit_Covered').css('background', '#f1f1f1');
                $('#divModification_benefit_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divModification_benefit_limit").css('display', 'block');
                $('#divModification_benefit_limit_Covered').css('background', '#217e2b');
                $('#divModification_benefit_limit_NotCovered').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Education_benefit'].setValue(result.data.surveyPolicyInfo['ab_education_benefit']);
              this.policyFeatures.controls['Education_benefit_limit'].setValue(result.data.surveyPolicyInfo['ab_education_benefit_limit']);
              if(result.data.surveyPolicyInfo['ab_education_benefit'] == 'No'){
                $("#divEducation_benefit_limit").css('display', 'none');
                $('#divEducation_benefit_limit_Covered').css('background', '#f1f1f1');
                $('#divEducation_benefit_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divEducation_benefit_limit").css('display', 'block');
                $('#divEducation_benefit_limit_Covered').css('background', '#217e2b');
                $('#divEducation_benefit_limit_NotCovered').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Funeral_and_Repatriation_expenses'].setValue(result.data.surveyPolicyInfo['ab_funeral_and_repatriation_expenses']);
              this.policyFeatures.controls['Funeral_and_Repatriation_expenses_limit'].setValue(result.data.surveyPolicyInfo['ab_funeral_and_repatriation_expenses_limit']);
              if(result.data.surveyPolicyInfo['ab_funeral_and_repatriation_expenses'] == 'No'){
                $("#divFuneral_and_Repatriation_expenses_limit").css('display', 'none');
                $('#divFuneral_and_Repatriation_expenses_limit_Covered').css('background', '#f1f1f1');
                $('#divFuneral_and_Repatriation_expenses_limit_NotCovered').css('background', '#ff4052');
              }else{
                $("#divFuneral_and_Repatriation_expenses_limit").css('display', 'block');
                $('#divFuneral_and_Repatriation_expenses_limit_Covered').css('background', '#217e2b');
                $('#divFuneral_and_Repatriation_expenses_limit_NotCovered').css('background', '#f1f1f1');
              }


              this.policyFeatures.controls['Terrorism_clause'].setValue(result.data.surveyPolicyInfo['ddb_accidental_death']);
              if(result.data.surveyPolicyInfo['ddb_accidental_death'] == 'No'){
                $('#divTerrorism_clause_limit_Covered').css('background', '#f1f1f1');
                $('#divTerrorism_clause_limit_NotCovered').css('background', '#ff4052');
              }else{
                $('#divTerrorism_clause_limit_Covered').css('background', '#217e2b');
                $('#divTerrorism_clause_limit_NotCovered').css('background', '#f1f1f1');
              }
              this.policyFeatures.controls['Terrorism_clause_limit'].setValue(result.data.surveyPolicyInfo['ddb_accidental_death']);

              this.policyFeatures.controls['do_you_have_topup'].setValue(result.data.surveyPolicyInfo['do_you_have_topup_accident_cover']);
              if(result.data.surveyPolicyInfo['do_you_have_topup_accident_cover'] == 'No'){
                $('#radio-two_do_you_have_topup_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_topup_no').css('background', '#ff4052');
              }else{
                $('#radio-two_do_you_have_topup_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_topup_no').css('background', '#f1f1f1');
              }

              this.policyFeatures.controls['offer_spouse_PA'].setValue(result.data.surveyPolicyInfo['offer_spouse_accident_cover']);
              if(result.data.surveyPolicyInfo['offer_spouse_accident_cover'] == 'No'){
                $('#radio-two_offer_spouse_PA_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_spouse_PA_no').css('background', '#ff4052');
              }else{
                $('#radio-two_offer_spouse_PA_yes').css('background', '#217e2b');
                $('#radio-one_offer_spouse_PA_no').css('background', '#f1f1f1');
              }

              //Step 4
              this.documentUpload.controls['do_you_have_gtl'].setValue(result.data.surveyPolicyInfo['gpa_status']);
              if(result.data.surveyPolicyInfo['gpa_status'] == 'Yes'){
                $(".do_you_have_gtl_yesdiv").css('display', 'block');
                $('#radio-two_do_you_have_gtl_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_gtl_no').css('background', '#f1f1f1');                
              }else{
                $(".do_you_have_gtl_yesdiv").css('display', 'none');
                $('#radio-two_do_you_have_gtl_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_gtl_no').css('background', '#ff4052');
              }

              this.documentUpload.controls['no_employess_gtl'].setValue(result.data.surveyPolicyInfo['gtl_employee_count']);

              this.documentUpload.controls['SI_type_gtl'].setValue(result.data.surveyPolicyInfo['gtl_sum_assured_type']);
              if(result.data.surveyPolicyInfo['gtl_sum_assured_type'] == 'Multiple of Salary'){
                $("#div_Multiple_of_Salary_gtl").css('display', 'block');
              }else{
                $("#div_Multiple_of_Salary_gtl").css('display', 'none');
              }

              if(result.data.surveyPolicyInfo['gtl_sum_assured_type'] == 'Flat'){
                $("#div_Flat_gtl").css('display', 'block');
              }else{
                $("#div_Flat_gtl").css('display', 'none');
              }

              if(result.data.surveyPolicyInfo['gtl_sum_assured_type'] == 'Graded'){
                $("#div_Graded_gtl").css('display', 'block');
              }else{
                $("#div_Graded_gtl").css('display', 'none');
              }
              
              this.documentUpload.controls['multiple_of_salary_gtl'].setValue(result.data.surveyPolicyInfo['gtl_multiple_of_salary']);
              this.documentUpload.controls['Define_flat_SI_gtl'].setValue(result.data.surveyPolicyInfo['gtl_define_flat_accidental_si']);
              this.documentUpload.controls['SI_Junior_management_gtl'].setValue(result.data.surveyPolicyInfo['gtl_graded_si_junior_management']);
              this.documentUpload.controls['SI_Middle_management_gtl'].setValue(result.data.surveyPolicyInfo['gtl_graded_si_middle_management']);
              this.documentUpload.controls['SI_Senior_management_gtl'].setValue(result.data.surveyPolicyInfo['gtl_graded_si_senior_management']);

              this.documentUpload.controls['actively_at_work'].setValue(result.data.surveyPolicyInfo['gtl_actively_at_work']);
              if(result.data.surveyPolicyInfo['gtl_actively_at_work'] == 'Yes'){
                $("#divactively_at_work_limit").css('display', 'block');
                $('#divactively_at_work_limit_Covered').css('background', '#217e2b');
                $('#divactively_at_work_limit_NotCovered').css('background', '#f1f1f1');                
              }else{
                $("#divactively_at_work_limit").css('display', 'none');
                $('#divactively_at_work_limit_Covered').css('background', '#f1f1f1');
                $('#divactively_at_work_limit_NotCovered').css('background', '#ff4052');
              }

              this.documentUpload.controls['Waived_For_all_Employees'].setValue(result.data.surveyPolicyInfo['gtl_waived_for_all_employees']);
              this.documentUpload.controls['Only_For_Existing_Employees'].setValue(result.data.surveyPolicyInfo['gtl_only_for_existing_employees']);

              this.documentUpload.controls['Terminal_illness'].setValue(result.data.surveyPolicyInfo['gtl_terminal_illness']);
              this.documentUpload.controls['Terminal_illness_limit'].setValue(result.data.surveyPolicyInfo['gtl_terminal_illness_limit']);
              if(result.data.surveyPolicyInfo['gtl_terminal_illness'] == 'Yes'){
                $("#divTerminal_illness_limit").css('display', 'block');
                $('#divTerminal_illness_limit_Covered').css('background', '#217e2b');
                $('#divTerminal_illness_limit_NotCovered').css('background', '#f1f1f1');                
              }else{
                $("#divTerminal_illness_limit").css('display', 'none');
                $('#divTerminal_illness_limit_Covered').css('background', '#f1f1f1');
                $('#divTerminal_illness_limit_NotCovered').css('background', '#ff4052');
              }

              this.documentUpload.controls['Accidental_death_benefit'].setValue(result.data.surveyPolicyInfo['gtl_accidental_death_benefit']);
              this.documentUpload.controls['Accidental_death_benefit_limit'].setValue(result.data.surveyPolicyInfo['gtl_accidental_death_benefit_limit']);
              if(result.data.surveyPolicyInfo['gtl_accidental_death_benefit'] == 'Yes'){
                $("#divAccidental_death_benefit_limit").css('display', 'block');
                $('#divAccidental_death_benefit_limit_Covered').css('background', '#217e2b');
                $('#divAccidental_death_benefit_limit_NotCovered').css('background', '#f1f1f1');                
              }else{
                $("#divAccidental_death_benefit_limit").css('display', 'none');
                $('#divAccidental_death_benefit_limit_Covered').css('background', '#f1f1f1');
                $('#divAccidental_death_benefit_limit_NotCovered').css('background', '#ff4052');
              }

              // this.documentUpload.controls['Accidental_permananent_Partial_Disability'].setValue(result.data.surveyPolicyInfo['do_you_have_gtl']);
              // this.documentUpload.controls['Accidental_permananent_Partial_Disability_limit'].setValue(result.data.surveyPolicyInfo['do_you_have_gtl']);

              this.documentUpload.controls['Accelerated_critical_illness'].setValue(result.data.surveyPolicyInfo['gtl_accelerated_critical_illness']);
              this.documentUpload.controls['Accelerated_critical_illness_limit'].setValue(result.data.surveyPolicyInfo['gtl_accelerated_critical_illness_limit']);

              if(result.data.surveyPolicyInfo['gtl_accelerated_critical_illness'] == 'Yes'){
                $("#divAccelerated_critical_illness_limit").css('display', 'block');
                $('#divAccelerated_critical_illness_limit_Covered').css('background', '#217e2b');
                $('#divAccelerated_critical_illness_limit_NotCovered').css('background', '#f1f1f1');
              }else{
                $("#divAccelerated_critical_illness_limit").css('display', 'none');
                $('#divAccelerated_critical_illness_limit_Covered').css('background', '#f1f1f1');
                $('#divAccelerated_critical_illness_limit_NotCovered').css('background', '#ff4052');
              }
              
              this.documentUpload.controls['Additional_critical_illness'].setValue(result.data.surveyPolicyInfo['gtl_additional_critical_illness']);
              this.documentUpload.controls['Additional_critical_illness_limit'].setValue(result.data.surveyPolicyInfo['gtl_additional_critical_illness_limit']);
              if(result.data.surveyPolicyInfo['gtl_additional_critical_illness'] == 'Yes'){
                $("#divAdditional_critical_illness_limit").css('display', 'block');
                $('#divAdditional_critical_illness_limit_Covered').css('background', '#217e2b');
                $('#divAdditional_critical_illness_limit_NotCovered').css('background', '#f1f1f1');
              }else{
                $("#divAdditional_critical_illness_limit").css('display', 'none');
                $('#divAdditional_critical_illness_limit_Covered').css('background', '#f1f1f1');
                $('#divAdditional_critical_illness_limit_NotCovered').css('background', '#ff4052');
              }

              this.documentUpload.controls['do_you_have_topupplan'].setValue(result.data.surveyPolicyInfo['gtl_do_you_have_topup_cover']);
              this.documentUpload.controls['do_you_have_spouse_cover'].setValue(result.data.surveyPolicyInfo['gtl_do_you_have_spouse_cover']);
              if(result.data.surveyPolicyInfo['gtl_do_you_have_topup_cover'] == 'Yes'){
                $("#divdo_you_have_topupplan_limit").css('display', 'block');
                $('#radio-two_do_you_have_topupplan_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_topupplan_no').css('background', '#f1f1f1');
              }else{
                $("#divdo_you_have_topupplan_limit").css('display', 'none');
                $('#radio-two_do_you_have_topupplan_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_topupplan_no').css('background', '#ff4052');
              }


              //Step 5
              this.step5form.controls['do_you_have_opd'].setValue(result.data.surveyPolicyInfo['hw_offer_health_wellbeing']);
              if(result.data.surveyPolicyInfo['hw_offer_health_wellbeing'] == 'Yes'){
                $(".do_you_have_opd_yesdiv").css('display', 'block');
                $('#radio-two_do_you_have_spouse_cover_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_spouse_cover_no').css('background', '#f1f1f1');
              }else{
                $(".do_you_have_opd_yesdiv").css('display', 'none');
                $('#radio-two_do_you_have_spouse_cover_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_spouse_cover_no').css('background', '#ff4052');
              }
              this.step5form.controls['OPD_cashless'].setValue(result.data.surveyPolicyInfo['hw_OPD_cashless']);
              if(result.data.surveyPolicyInfo['hw_OPD_cashless'] == 'Yes'){
                $('#radio-two_OPD_cashless_yes').css('background', '#217e2b');
                $('#radio-one_OPD_cashless_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_OPD_cashless_yes').css('background', '#f1f1f1');
                $('#radio-one_OPD_cashless_no').css('background', '#ff4052');
              }

              this.step5form.controls['Preventive_Health_Check_up'].setValue(result.data.surveyPolicyInfo['hw_preventive_health_check_up']);
              if(result.data.surveyPolicyInfo['hw_preventive_health_check_up'] == 'Yes'){
                $('#radio-two_Preventive_Health_Check_up_yes').css('background', '#217e2b');
                $('#radio-one_Preventive_Health_Check_up_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_Preventive_Health_Check_up_yes').css('background', '#f1f1f1');
                $('#radio-one_Preventive_Health_Check_up_no').css('background', '#ff4052');
              }

              this.step5form.controls['Annual_Health_Check_up'].setValue(result.data.surveyPolicyInfo['hw_annual_health_check_up']);
              if(result.data.surveyPolicyInfo['hw_annual_health_check_up'] == 'Yes'){
                $('#radio-two_Annual_Health_Check_up_yes').css('background', '#217e2b');
                $('#radio-one_Annual_Health_Check_up_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_Annual_Health_Check_up_yes').css('background', '#f1f1f1');
                $('#radio-one_Annual_Health_Check_up_no').css('background', '#ff4052');
              }

              this.step5form.controls['medical_facilities'].setValue(result.data.surveyPolicyInfo['hw_medical_facilities']);
              if(result.data.surveyPolicyInfo['hw_medical_facilities'] == 'Yes'){
                $('#radio-two_medical_facilities_yes').css('background', '#217e2b');
                $('#radio-one_medical_facilities_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_medical_facilities_yes').css('background', '#f1f1f1');
                $('#radio-one_medical_facilities_no').css('background', '#ff4052');
              }


              //Step 6
              this.step6form.controls['do_you_have_ROB'].setValue(result.data.surveyPolicyInfo['rb_do_you_offer']);
              if(result.data.surveyPolicyInfo['rb_do_you_offer'] == 'Yes'){
                $(".do_you_have_ROB_yesdiv").css('display', 'block');
                $('#radio-two_do_you_have_ROB_yes').css('background', '#217e2b');
                $('#radio-one_do_you_have_ROB_no').css('background', '#f1f1f1');
              }else{
                $(".do_you_have_ROB_yesdiv").css('display', 'none');
                $('#radio-two_do_you_have_ROB_yes').css('background', '#f1f1f1');
                $('#radio-one_do_you_have_ROB_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_gratuity'].setValue(result.data.surveyPolicyInfo['rb_offer_gratuity']);
              if(result.data.surveyPolicyInfo['rb_offer_gratuity'] == 'Yes'){
                $('#radio-two_offer_gratuity_yes').css('background', '#217e2b');
                $('#radio-one_offer_gratuity_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_gratuity_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_gratuity_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_gratuity_per_law'].setValue(result.data.surveyPolicyInfo['rb_offer_gratuity_per_law']);
              if(result.data.surveyPolicyInfo['rb_offer_gratuity_per_law'] == 'Yes'){
                $('#radio-two_offer_gratuity_per_law_yes').css('background', '#217e2b');
                $('#radio-one_offer_gratuity_per_law_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_gratuity_per_law_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_gratuity_per_law_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_pension_plans'].setValue(result.data.surveyPolicyInfo['rb_offer_pension_plans']);
              if(result.data.surveyPolicyInfo['rb_offer_pension_plans'] == 'Yes'){
                $('#radio-two_offer_pension_plans_yes').css('background', '#217e2b');
                $('#radio-one_offer_pension_plans_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_pension_plans_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_pension_plans_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_car_leases'].setValue(result.data.surveyPolicyInfo['rb_offer_car_lease']);
              if(result.data.surveyPolicyInfo['rb_offer_car_lease'] == 'Yes'){
                $('#radio-two_offer_car_leases_yes').css('background', '#217e2b');
                $('#radio-one_offer_car_leases_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_car_leases_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_car_leases_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_leave_encashment'].setValue(result.data.surveyPolicyInfo['rb_offer_leave_encashment']);
              if(result.data.surveyPolicyInfo['rb_offer_leave_encashment'] == 'Yes'){
                $('#radio-two_offer_leave_encashment_yes').css('background', '#217e2b');
                $('#radio-one_offer_leave_encashment_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_leave_encashment_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_leave_encashment_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_canteen_facility'].setValue(result.data.surveyPolicyInfo['rb_offer_canteen_facility']);
              if(result.data.surveyPolicyInfo['rb_offer_canteen_facility'] == 'Yes'){
                $('#radio-two_offer_canteen_facility_yes').css('background', '#217e2b');
                $('#radio-one_offer_canteen_facility_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_canteen_facility_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_canteen_facility_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_creche_facilities'].setValue(result.data.surveyPolicyInfo['rb_offer_creche_facilities']);
              if(result.data.surveyPolicyInfo['rb_offer_creche_facilities'] == 'Yes'){
                $('#radio-two_offer_creche_facilities_yes').css('background', '#217e2b');
                $('#radio-one_offer_creche_facilities_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_creche_facilities_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_creche_facilities_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_employee_emergency'].setValue(result.data.surveyPolicyInfo['rb_offer_employee_emergency']);
              if(result.data.surveyPolicyInfo['rb_offer_employee_emergency'] == 'Yes'){
                $('#radio-two_offer_employee_emergency_yes').css('background', '#217e2b');
                $('#radio-one_offer_employee_emergency_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_employee_emergency_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_employee_emergency_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_courses'].setValue(result.data.surveyPolicyInfo['rb_offer_courses']);
              if(result.data.surveyPolicyInfo['rb_offer_courses'] == 'Yes'){
                $('#radio-two_offer_courses_yes').css('background', '#217e2b');
                $('#radio-one_offer_courses_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_courses_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_courses_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_pick_drop'].setValue(result.data.surveyPolicyInfo['rb_offer_pick_drop']);
              if(result.data.surveyPolicyInfo['rb_offer_pick_drop'] == 'Yes'){
                $('#radio-two_offer_pick_drop_yes').css('background', '#217e2b');
                $('#radio-one_offer_pick_drop_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_pick_drop_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_pick_drop_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_Employee_stock'].setValue(result.data.surveyPolicyInfo['rb_offer_employee_esop']);
              if(result.data.surveyPolicyInfo['rb_offer_employee_esop'] == 'Yes'){
                $('#radio-two_offer_Employee_stock_yes').css('background', '#217e2b');
                $('#radio-one_offer_Employee_stock_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_Employee_stock_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_Employee_stock_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_Gadget_allowance'].setValue(result.data.surveyPolicyInfo['rb_offer_gadget_allowance']);
              if(result.data.surveyPolicyInfo['rb_offer_gadget_allowance'] == 'Yes'){
                $('#radio-two_offer_Gadget_allowance_yes').css('background', '#217e2b');
                $('#radio-one_offer_Gadget_allowance_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_Gadget_allowance_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_Gadget_allowance_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_flexi_work'].setValue(result.data.surveyPolicyInfo['rb_offer_flexi_work']);
              if(result.data.surveyPolicyInfo['rb_offer_flexi_work'] == 'Yes'){
                $('#radio-two_offer_flexi_work_yes').css('background', '#217e2b');
                $('#radio-one_offer_flexi_work_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_flexi_work_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_flexi_work_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_flexi_pay'].setValue(result.data.surveyPolicyInfo['rb_offer_flexi_pay']);
              if(result.data.surveyPolicyInfo['rb_offer_flexi_pay'] == 'Yes'){
                $('#radio-two_offer_flexi_pay_yes').css('background', '#217e2b');
                $('#radio-one_offer_flexi_pay_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_flexi_pay_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_flexi_pay_no').css('background', '#ff4052');
              }

              this.step6form.controls['offer_sabbatical_benefits'].setValue(result.data.surveyPolicyInfo['rb_offer_sabbatical_benefits']);
              if(result.data.surveyPolicyInfo['rb_offer_sabbatical_benefits'] == 'Yes'){
                $('#radio-two_offer_sabbatical_benefits_yes').css('background', '#217e2b');
                $('#radio-one_offer_sabbatical_benefits_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_sabbatical_benefits_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_sabbatical_benefits_no').css('background', '#ff4052');
              }              
              
              this.step6form.controls['offer_menstural_leave'].setValue(result.data.surveyPolicyInfo['rb_offer_menstural_leave']);
              if(result.data.surveyPolicyInfo['rb_offer_menstural_leave'] == 'Yes'){
                $('#radio-two_offer_menstural_leave_yes').css('background', '#217e2b');
                $('#radio-one_offer_menstural_leave_no').css('background', '#f1f1f1');
              }else{
                $('#radio-two_offer_menstural_leave_yes').css('background', '#f1f1f1');
                $('#radio-one_offer_menstural_leave_no').css('background', '#ff4052');
              }

            } else {
              // console.log(result);
            }
          }, (error) => {           
            // this.validmsg = error.error.message;
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
        });

    }    

    mouseEnter(evt : any, val:any){      
      $("#" + val).css('display', 'block');    
    }

    mouseLeave(evt : any, val:any){
      $("#" + val).css('display', 'none');
    }

    existing_policy_check(){
      
    }

    policy_number_text(e:any){      

      this.addtext = $("#" + e.target.id + " option:selected").text();

    }

    entermanually(){
      if(this.policyDetails.controls['upload_check'].value == 'manually'){
        $('#div_upload_policydocument').css('display','none');
        $('#sample_template_excel').css('display','none');
        $('#excel_flow_save').css('display','none');
        $('#manual_flow_save').css('display','block');        
      }else{
        $('#div_upload_policydocument').css('display','block');
        $('#sample_template_excel').css('display','block');
        $('#excel_flow_save').css('display','block');
        $('#manual_flow_save').css('display','none');
      }
      if(this.policyDetails.controls['upload_check'].value == 'clone_plan'){
        $('#div_clone_plan').css('display','block');
        $('#div_upload_policydocument').css('display','none');
        $('#excel_flow_save').css('display','none');
        $('#manual_flow_save').css('display','block');        
      }else{
        $('#div_clone_plan').css('display','none');
      }
    }

    how_soon_buy_check(evt:any){
      var val = evt.target.value;
      if(val == "Specific month"){
        $('.choose_month').css('display','block');
        $('.emp_atstart').addClass('mt-3');
      }else{
        $('.choose_month').css('display','none');
        $('.emp_atstart').removeClass('mt-3');
      }

    }

    lives_covered(evt: any){
      
      var id = evt.target.id;
      var value: any = evt.target.value.replaceAll(',', '');

      if(id == "lives_covered"){
        var temp:any = $('#emp_atstart').val();      
      }else{
        var temp:any = $('#emp_atreport').val();      
      }
      
      var emp_atstart:any = temp.replaceAll(',', '');
      console.log(value+" <",emp_atstart);

      if(value < emp_atstart){   
        console.log('yes');     
        $('#' + id).val('');        
      }else{
        console.log('no');
      }      
      
    }

    /**** Hospitalization ******/
    sumapproach(val: any){

      $('#sum_insured_approach_error').css('display', 'none');

      if(val == 'Graded'){        

        $('.div_sum_insured_approach').css('display', 'block');
        $('.append_sum_insured_approach_uniform').empty();
        $('.div_sum_insured_approach_uniform').css('display', 'none');
        $('.is_standard_room_rent').css('display', 'block');
        $('#is_standard_active').css('display', 'none');
        $('.is_standard_room_rent_maternity').css('display', 'block');
        $('#maternity_is_standard_active').css('display', 'none');

        let html = `
        <div id="this_graded_row_`+this.amt+`" class="col-sm-12 row">
            <div class="col-sm-4 form-group mt-3">
              <label class="bs-form-label" for="grade_text_`+this.amt+`">Grade </label>
              <input type="text" class="form-control" name="grade_text_`+this.amt+`" id="grade_text_`+this.amt+`" />
            </div>
            <div class="col-sm-4 form-group mt-3">
                <label class="bs-form-label" for="graded_amount_`+this.amt+`">Amount *</label>
                <input type="text" class="form-control graded_amount" name="graded_amount_`+this.amt+`" id="graded_amount_`+this.amt+`" />
                <p class="error" id="graded_amount_`+this.amt+`_error" style="display:none; color: #df0000; font-size: 13px;">
                    This field is required
                </p>
            </div>
            <div class="col-sm-2 form-group mt-3 pt-2">                        
                <img src="../../../assets/img/common icons/trash.png" id="graded_row_`+this.amt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
            </div>
        </div>
        `;

        $('.append_sum_insured_approach').append(html);
        let flag = this;
        let id = this.amt;
        $("#graded_amount_"+this.amt).on('change',function(e) {
          flag.navigate(e, id);
        });

        $("#grade_text_"+this.amt).on('change',function(e) {
          flag.navigate_text(e, id);
        });

        $("#graded_row_"+this.amt).on('click',function(e) {
          flag.removeGradedRow(e);
        });

        $("#graded_amount_"+this.amt).on('keyup',function(e) {
          flag.isnumberGraded(e);
        });

        this.amt++;        

      }else{
        
        this.mygradedarray = [];
        // console.log(this.mygradedarray);

        $('.append_sum_insured_approach').empty();
        $('.div_sum_insured_approach').css('display', 'none');
        $('.div_sum_insured_approach_uniform').css('display', 'block');
        $('.is_standard_room_rent').css('display', 'none');
        $('#is_standard_active').css('display', 'block');
        $('.is_standard_room_rent_maternity').css('display', 'none'); 
        $('#maternity_is_standard_active').css('display', 'block');

        let html = `
        <div class="col-sm-6 form-group mt-3">
            <label class="bs-form-label" for="sum_insured_uniform">Amount </label>
            <input type="text" class="form-control" name="sum_insured_uniform" id="sum_insured_uniform" />
        </div>
        `;

        $('.append_sum_insured_approach_uniform').append(html);                              

        let flag = this;
        $("#sum_insured_uniform").on('keyup',function(e) {
          flag.isnumberGraded(e);
        });

      }

      // console.log(this.mygradedarray);

      /**************************************************/

    }

    navigate_text(val:any, id: any){
      
      this.mygradedarray_text.push(val.target.value);
      $('#grade_text_' + id).css("pointer-events", "none");
      // console.log(this.mygradedarray_text);

    }

    navigate(val:any, id: any){      

      this.mygradedarray.push(val.target.value);
      // console.log(this.mygradedarray);
      this.greatest_graded_value = Math.max.apply(Math, this.mygradedarray);

      $('#graded_amount_' + id).css("pointer-events", "none");
      
      /********** Number Separator Starts ********/
      var gradedArr = this.mygradedarray;
      setTimeout(function() {

        for(var i = 0; i < gradedArr.length; i++){
          
          var value: any = gradedArr[i];

          if(value.length == 4){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 5){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 6){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 7){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 8){
            var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }
          if(value.length == 9){
            var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
            $('#number_separator_' + i).val(finalval);
            $('#number_separator_maternity_' + i).val(finalval);
          }

        }                    
        
      }, 100);
      /********** Number Separator Ends ********/

    }

    removeGradedRow(e: any){
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_graded_row_' + id;
      $(idstr).remove();

      this.mygradedarray.pop(e.target.value);
      this.mygradedarray_text.pop(e.target.value);
      console.log(this.mygradedarray);
      console.log(this.mygradedarray_text);
      this.greatest_graded_value = Math.max.apply(Math, this.mygradedarray);

    }

    isnumberGraded(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var value = '#' + evt.target.id;
          $(value).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
    }
    /***************************/

    onItemSelect(item: any) {
      // console.log(item);
      this.familyArrData.push(item);
    }

    onSelectAll(items: any) {
      // console.log(items);
      this.familyArrData = items;
    }

    isformat(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    isNumber(evt: any) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }  
      if(evt.target.value == "0"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }    
      return true;
    }

    closealert(){
      $("#validmsg").css('display', 'none');
      $("#errormsg").css('display', 'none');
    }

    get getpolicyDetailsFormControls() {
      return this.policyDetails.controls;
    }

    get getverifypolicyDetailsFormControls() {
      return this.verifypolicyDetails.controls;
    }

    get getpolicyFeaturesFormControls() {
      return this.policyFeatures.controls;
    }

    get getdocumentUploadFormControls() {
      return this.documentUpload.controls;
    }

    get getstep5formControls() {
      return this.step5form.controls;
    }

    get getstep6formControls() {
      return this.step6form.controls;
    }

    // Stepper Next Event
    next(step: number) {
      if (step == 1) {        
        
        this.policyDetailsSubmitted = true;
        if (this.policyDetailsSubmitted && this.policyDetails.valid) {          
            this.onsubmit_first('No');
            this.stepper.next();                                                              
            console.log(this.policyDetails.value);
            this.stepcomplete = 'step1';
        }
      }
      if (step == 2) {

        this.verifypolicyDetailsSubmitted = true;       
        
        if(this.verifypolicyDetails.controls['sum_insured_approach'].value == ''){
          $('#sum_insured_approach_error').css('display', 'block');
        }else{
          $('#sum_insured_approach_error').css('display', 'none');
        }

        /****** For dynamic added amount fields *********/
        $(".graded_amount").each(function() {
          
          var value = $(this).val();
          var field_id = $(this).attr('id');
          var id = $(this).attr('id') + '_error';       

          if(value == ''){
            $('#' + id).css('display', 'block');
            $('#' + field_id).css('border', 'solid 1px #df0000');
          }else{
            $('#' + id).css('display', 'none');
            $('#' + field_id).css('border', '1px solid #ced4da');
          }

        });
        /*********************** END ********************/

        var errorArr = ['day_care_procedures_limit_perc','day_care_procedures_limit_value',
                        'domiciliary_hospitalisation_limit_perc', 'domiciliary_hospitalisation_limit_value',
                        'out_patient_benefits_limit' , 'opd_dental_limit',
                        'opd_vision_limit', 'preventive_health_checkup_limit',
                        'organ_donor_cover_limit_perc', 'organ_donor_cover_limit_value',
                        'oral_chemo_therapy_limit_perc', 'oral_chemo_therapy_limit_value',
                        'ayush_treatment_limit_perc', 'ayush_treatment_limit_value',
                        'lasik_treatment_limit_perc', 'lasik_treatment_limit_value',
                        'corporate_buffer_limit', 'corporate_buffer_family_limit',
                        'road_ambulance_limit_perc', 'road_ambulance_limit_value',
                        'cardiac_ambulance_limit_perc', 'cardiac_ambulance_limit_value',
                        'air_ambulance_limit_perc', 'air_ambulance_limit_value',
                        'baby_specific_covered_limit', 'infertility_covered_limit', 'surrogacy_covered_limit',
                        'normal_delivery_limit_maternity', 'c_section_limit_maternity'];
        
        for(var i = 0; i < errorArr.length; i++){
          
          var val = errorArr[i];
          var id = errorArr[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.verifypolicyDetails.controls[val].value == '' || this.verifypolicyDetails.controls[val].value == 'select' || this.verifypolicyDetails.controls[val].value == 0){              
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }        
        

        /**************** SEND DATA **************/
        var gmc_form = '#gmc_form';        
        var cond = $(gmc_form + ' .error').is(':visible');
          
        if(cond){            

        }else{

          /************* SI Approach Array ****************/                  
          var SIval = this.verifypolicyDetails.controls['sum_insured_approach'].value;
          console.log(SIval);
          if(SIval == "Graded"){

            this.gradedArr = {graded_text: this.mygradedarray_text, graded_sum_insured_amount: this.mygradedarray};
            
          }else{
            
            this.gradedArr = $('#sum_insured_uniform').val();

          }
          console.log(this.gradedArr);
          /****************** END *************************/

          this.onsubmit_second();
          this.stepper.next();
          this.stepcomplete = 'step2';
          console.log(this.verifypolicyDetails.value);
        }        
        
      }
      if (step == 3) {                                

        this.policyFeaturesSubmitted = true;

        var errorArr = ['SI_type', 'multiple_of_salary','Define_flat_SI',
                        'SI_Junior_management', 'SI_Middle_management', 'SI_Senior_management',
                        'Permanent_Total_Disability_limit', 'Total_disability_loss_of_wages_limit',
                        'Total_disability_loss_of_wages_INR_per_week', 'Total_disability_loss_of_wages_actual_weekly_salary',
                        'Medical_benefits_Medex_limit', 'Transportation_of_mortal_remains_limit',
                        'Ambulance_charges_limit', 'Modification_benefit_limit',
                        'Education_benefit_limit', 'Funeral_and_Repatriation_expenses_limit',
                        'Terrorism_clause_limit'];
        
        for(var i = 0; i < errorArr.length; i++){
          
          var val = errorArr[i];
          var id = errorArr[i] + '_error';
         
          if($('#' + val + ':visible').length == 0){                                    

          }else{
            if(this.policyFeatures.controls[val].value == '' || this.policyFeatures.controls[val].value == 'select' || this.policyFeatures.controls[val].value == 0){              

              if(val == 'Total_disability_loss_of_wages_limit' || val == 'Total_disability_loss_of_wages_INR_per_week' || val == 'Total_disability_loss_of_wages_actual_weekly_salary'){
                $('#' + id).css('display', 'block');
                $('#' + id).css('position', 'absolute');
                $('#' + id).css('margin-top', '30px');
              }else{
                $('#' + id).css('display', 'block');
              }

              // $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            }else{
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }          

        }

        /**************** SEND DATA **************/
        var gpa_form = '#gpa_form';        
        var cond = $(gpa_form + ' .error').is(':visible');
          
        if(cond){            

        }else{

          /********* SI Array ********/
          for (var i = 1; i < this.cnt_graded_gpa; i++) {
            this.gradedArrSI_gpa[i] = { bname: $('#graded_name_gpa_' + i).val(), blimit: $('#graded_limit_gpa_' + i).val() };
          }

          let objectoSI_gpa = this.gradedArrSI_gpa;
          for (var key in objectoSI_gpa) {
            if (objectoSI_gpa.hasOwnProperty(key)) {
              if (objectoSI_gpa[key]['bname'] === undefined || objectoSI_gpa[key]['bname'] === null) {
                delete objectoSI_gpa[key];
              }
            }
          }
          /*** END ***/

          /********* SI TOPUP Array ********/
          for (var i = 1; i < this.cnt_graded_topup_gpa; i++) {
            this.gradedArrSI_topup_gpa[i] = { bname: $('#graded_topup_name_gpa_' + i).val(), blimit: $('#graded_topup_limit_gpa_' + i).val() };
          }

          let objectoSI_topup_gpa = this.gradedArrSI_topup_gpa;
          for (var key in objectoSI_topup_gpa) {
            if (objectoSI_topup_gpa.hasOwnProperty(key)) {
              if (objectoSI_topup_gpa[key]['bname'] === undefined || objectoSI_topup_gpa[key]['bname'] === null) {
                delete objectoSI_topup_gpa[key];
              }
            }
          }
          /*** END ***/

          console.log(this.policyFeatures.value);
          this.onsubmit_third();
          this.stepper.next();  
          this.stepcomplete = 'step3';

        }

                                                        

      }
      if (step == 4) {
        this.documentUploadSubmitted = true;

        var errorArr = ['SI_type_gtl', 'multiple_of_salary_gtl', 'Define_flat_SI_gtl',
                        'SI_Junior_management_gtl', 'SI_Middle_management_gtl', 'SI_Senior_management_gtl',
                        'Terminal_illness_limit', 'Accidental_death_benefit_limit',
                        'Accidental_permananent_Partial_Disability_limit', 'Accelerated_critical_illness_limit',
                        'Additional_critical_illness_limit'];

        for (var i = 0; i < errorArr.length; i++) {

          var val = errorArr[i];
          var id = errorArr[i] + '_error';

          if ($('#' + val + ':visible').length == 0) {

          } else {
            if (this.documentUpload.controls[val].value == '' || this.documentUpload.controls[val].value == 'select' || this.documentUpload.controls[val].value == 0) {
              $('#' + id).css('display', 'block');
              $('#' + val).addClass('control-red');
            } else {
              $('#' + id).css('display', 'none');
              $('#' + val).removeClass('control-red');
            }
          }

        }

        /**************** SEND DATA **************/
        var gtl_form = '#gtl_form';
        var cond = $(gtl_form + ' .error').is(':visible');

        if (cond) {

        } else {

          /********* SI Array ********/
          for (var i = 1; i < this.cnt_graded_gtl; i++) {
            this.gradedArrSI_gtl[i] = { bname: $('#graded_name_gtl_' + i).val(), blimit: $('#graded_limit_gtl_' + i).val() };
          }

          let objectoSI_gtl = this.gradedArrSI_gtl;
          for (var key in objectoSI_gtl) {
            if (objectoSI_gtl.hasOwnProperty(key)) {
              if (objectoSI_gtl[key]['bname'] === undefined || objectoSI_gtl[key]['bname'] === null) {
                delete objectoSI_gtl[key];
              }
            }
          }
          /*** END ***/

          /********* SI TOPUP Array ********/
          for (var i = 1; i < this.cnt_graded_topup_gtl; i++) {
            this.gradedArrSI_topup_gtl[i] = { bname: $('#graded_topup_name_gtl_' + i).val(), blimit: $('#graded_topup_limit_gtl_' + i).val() };
          }

          let objectoSI_topup_gtl = this.gradedArrSI_topup_gtl;
          for (var key in objectoSI_topup_gtl) {
            if (objectoSI_topup_gtl.hasOwnProperty(key)) {
              if (objectoSI_topup_gtl[key]['bname'] === undefined || objectoSI_topup_gtl[key]['bname'] === null) {
                delete objectoSI_topup_gtl[key];
              }
            }
          }
          /*** END ***/

          this.stepper.next();
          this.onsubmit_fourth();
          this.stepcomplete = 'step4';

        }
        
      }
      if (step == 5) {

        this.step5FormSubmitted = true;
        this.stepper.next();
        this.stepcomplete = 'step5';
        this.onsubmit_fivth();

      }
      if (step == 6) {
        this.step6FormSubmitted = true;
        this.stepper.next();
        this.stepcomplete = 'step6';
        this.onsubmit_sixth();
      }
    }

    // Load onclick expire date
    loadecpirydate(){

      var policy_date = this.verifypolicyDetails.controls['policy_date'].value;

      var parts = policy_date.match(/(\d+)/g);
      var d = new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based

      var newdate = new Date(d.getTime() + 365*24*60*60*1000);
      var dateString = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];

      this.verifypolicyDetails.controls['policy_expirydate'].setValue(dateString);

    }

    // Stepper Previous Event
    previous() {
      this.stepper.previous();
    }

    getIsuranceCompanyDetails(){
      this.policyDetailsService.getIsuranceCompanyDetails()
        .subscribe((result: any) => {

          // console.log(result[0]);
          this.isuranceCompanyDetailsarr = result;
          // this.isuranceCompanyDetailsarr.unshift({'insure_comp_id' : 0});
          // console.log(this.isuranceCompanyDetailsarr);

          // for(var i = 0; i < result.length; i++){

          //   let html = `              
          //     <ng-option [value]="`+result[i]['insure_comp_id']+`">`+result[i]['name']+`</ng-option>
          //   `;

          //   $('#insurance_company').append(html);
          //   $('#previous_insurer').append(html);

          // }

        }, (error) => {
            // console.log(result);
      });
    }

    getTPADetails(){
      this.policyDetailsService.getTPADetails()
        .subscribe((result: any) => {

          // console.log(result);
          this.tpadetailsArr = result;

          for(var i = 0; i < result.length; i++){

            let html = `
              <option value="`+result[i]['tpa_id']+`">`+result[i]['name']+`</option>
            `;

            $('#tpa').append(html);

          }

        }, (error) => {
            // console.log(result);
      });
    }

    getBrokerDetails(){
      this.policyDetailsService.getBrokerDetails()
        .subscribe((result: any) => {

          // console.log(result);
          this.brokerdetailsArr = result;

          for(var i = 0; i < result.length; i++){

            let html = `
              <option value="`+result[i]['broker_id']+`">`+result[i]['name']+`</option>
            `;

            $('#broker_name').append(html);

          }


        }, (error) => {
            // console.log(result);
      });

    }

    getSubCategory(){
      var postdata = {
        'id': 1
      };

      this.policyDetailsService.getSubCategory(postdata)
        .subscribe((result: any) => {

          // console.log(result.data);
          this.productsArr = result.data;

          var val;

          for(var i = 0; i < result.data.length; i++){

            if(result.data[i]['sub_product_id'] == 1){
              val = 'selected';
            }else{
              val = '';
            }
  
            let html = `
              <option value="`+result.data[i]['sub_product_id']+`" `+ val +`>`+result.data[i]['sub_product_name']+`</option>
            `;

            $('#policy_sub_category').append(html);

          }


        }, (error) => {
            // console.log(result);
      });

    }

    getBasePlan(){

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,
        'product_id': 1,
        'sub_product_id': 1
      };
  
      this.policyDetailsService.getBasePlan(postdata)
        .subscribe((result: any) => {
  
          // console.log(result);
          this.BasePlanArr = result.data.clientPolicyList;
  
          for(var i = 0; i < result.data.clientPolicyList.length; i++){
  
            let html = `
              <option value="`+result.data.clientPolicyList[i]['cpp_id']+`">`+result.data.clientPolicyList[i]['proposal_name']+`</option>
            `;
  
            $('#base_plan').append(html);
  
          }
  
        }, (error) => {
            // console.log(result);
      });
    }

    /********* GET Upload Files Data ************/

    // step 1 field
    upload_policydocument_change(event: any){
      if (event.target.files.length > 0) {

        this.upload_policydocument = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_policydocument").empty();
        $('#custom-file-upload_upload_policydocument').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_policydocument').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_policydocument').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_policydocument').empty();
        $('#small_upload_policydocument').html('');
        $('#small_upload_policydocument').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
    }

    // step 4 fields
    upload_your_active_member_data_change(event: any){
      if (event.target.files.length > 0) {
        // this.upload_your_active_member_data = event.target.files[0];
        this.upload_your_active_member_data = event.target.files[0];
        // console.log(event.target.files[0]);

        $("#custom-file-upload_upload_your_active_member_data").empty();
        $('#custom-file-upload_upload_your_active_member_data').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_your_active_member_data').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_your_active_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_your_active_member_data').empty();
        $('#small_upload_your_active_member_data').html('');
        $('#small_upload_your_active_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
    }

    upload_claim_MIS_change(event: any){
      if (event.target.files.length > 0) {

        this.upload_claim_MIS = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_claim_MIS").empty();
        $('#custom-file-upload_upload_claim_MIS').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_claim_MIS').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_claim_MIS').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_claim_MIS').empty();
        $('#small_upload_claim_MIS').html('');
        $('#small_upload_claim_MIS').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    upload_claim_pdf_summary_change(event: any) {
      if (event.target.files.length > 0) {

        this.upload_claim_pdf_summary = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_upload_claim_pdf_summary").empty();
        $('#custom-file-upload_upload_claim_pdf_summary').html(event.target.files[0]['name']);
        $('#custom-file-upload_upload_claim_pdf_summary').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_upload_claim_pdf_summary').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_upload_claim_pdf_summary').empty();
        $('#small_upload_claim_pdf_summary').html('');
        $('#small_upload_claim_pdf_summary').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    policy_document_change(event: any) {
      if (event.target.files.length > 0) {

        this.policy_document = event.target.files[0];
        // console.log(event.target.files[0])

        $("#custom-file-upload_policy_document").empty();
        $('#custom-file-upload_policy_document').html(event.target.files[0]['name']);
        $('#custom-file-upload_policy_document').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_policy_document').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_policy_document').empty();
        $('#small_policy_document').html('');
        $('#small_policy_document').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }

    }

    member_data_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.member_data = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-upload_member_data").empty();
        $('#custom-file-upload_member_data').html(event.target.files[0]['name']);
        $('#custom-file-upload_member_data').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-upload_member_data').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_member_data').empty();
        $('#small_member_data').html('');
        $('#small_member_data').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    claims_in_current_year_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.claims_in_current_year = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-claims_in_current_year").empty();
        $('#custom-file-claims_in_current_year').html(event.target.files[0]['name']);
        $('#custom-file-claims_in_current_year').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-claims_in_current_year').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_claims_in_current_year').empty();
        $('#small_claims_in_current_year').html('');
        $('#small_claims_in_current_year').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    previous_year_one_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.previous_year_one = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-previous_year_one").empty();
        $('#custom-file-previous_year_one').html(event.target.files[0]['name']);
        $('#custom-file-previous_year_one').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-previous_year_one').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_previous_year_one').empty();
        $('#small_previous_year_one').html('');
        $('#small_previous_year_one').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }

    previous_year_two_change(event: any) {
      if (event.target.files.length > 0) {
  
        this.previous_year_two = event.target.files[0];
        // console.log(event.target.files[0])
  
        $("#custom-file-previous_year_two").empty();
        $('#custom-file-previous_year_two').html(event.target.files[0]['name']);
        $('#custom-file-previous_year_two').css('text-align', 'left');
  
        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);
  
        $('#custom-file-previous_year_two').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');
  
        $('#small_previous_year_two').empty();
        $('#small_previous_year_two').html('');
        $('#small_previous_year_two').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
      }
  
    }
    /********************************************/


    /******************************/
    basicaccord(){
      this.flag_firstcheck_FC = 1;
      if($('#basics_accord').css('display') == 'none'){
        $('#basics_accord').slideToggle(1000);
        // $('#basics_accord').css('display','block');
        $('#basic_down_arrow').css('display','none');
        $('#basic_up_arrow').css('display','block');
      }else{
        $('#basics_accord').slideToggle(1000);
        // $('#basics_accord').css('display','none');
        $('#basic_down_arrow').css('display','block');
        $('#basic_up_arrow').css('display','none');
      }
    }

    sumisuredaccord(){
      this.flag_firstcheck_SI = 1;
      if($('#sumisured_accord').css('display') == 'none'){
        $('#sumisured_accord').slideToggle(1000);
        // $('#basics_accord').css('display','block');
        $('#sumisured_down_arrow').css('display','none');
        $('#sumisured_up_arrow').css('display','block');
      }else{
        $('#sumisured_accord').slideToggle(1000);
        // $('#basics_accord').css('display','none');
        $('#sumisured_down_arrow').css('display','block');
        $('#sumisured_up_arrow').css('display','none');
      }
    }

    waitingPeriodCondition(){
      this.flag_firstcheck_WPC = 1;
      if($('#waitingPeriodCondition_accord').css('display') == 'none'){
        $('#waitingPeriodCondition_accord').slideToggle(1000);
        // $('#waitingPeriodCondition_accord').css('display','block');
        $('#WPC_down_arrow').css('display','none');
        $('#WPC_up_arrow').css('display','block');
      }else{
        $('#waitingPeriodCondition_accord').slideToggle(1000);
        // $('#waitingPeriodCondition_accord').css('display','none');
        $('#WPC_down_arrow').css('display','block');
        $('#WPC_up_arrow').css('display','none');
      }
    }

    hospitalisationaccord(){
      this.flag_firstcheck_hosp = 1;
      if($('#hospitalisationaccord_accord').css('display') == 'none'){
        $('#hospitalisationaccord_accord').slideToggle(1000);
        // $('#hospitalisationaccord_accord').css('display','block');
        $('#hospitalisationaccord_down_arrow').css('display','none');
        $('#hospitalisationaccord_up_arrow').css('display','block');
      }else{
        $('#hospitalisationaccord_accord').slideToggle(1000);
        // $('#hospitalisationaccord_accord').css('display','none');
        $('#hospitalisationaccord_down_arrow').css('display','block');
        $('#hospitalisationaccord_up_arrow').css('display','none');
      }
    }

    maternityccord(){
      this.flag_firstcheck_maternity = 1;
      if($('#maternity_accord').css('display') == 'none'){
        $('#maternity_accord').slideToggle(1000);
        // $('#maternity_accord').css('display','block');
        $('#maternityaccord_down_arrow').css('display','none');
        $('#maternityaccord_up_arrow').css('display','block');
      }else{
        $('#maternity_accord').slideToggle(1000);
        // $('#maternity_accord').css('display','none');
        $('#maternityaccord_down_arrow').css('display','block');
        $('#maternityaccord_up_arrow').css('display','none');
      }
    }

    ccmaccord(){
      this.flag_firstcheck_CCM = 1;
      if($('#CCM_accord').css('display') == 'none'){
        $('#CCM_accord').slideToggle(1000);
        // $('#CCM_accord').css('display','block');
        $('#CCM_down_arrow').css('display','none');
        $('#CCM_up_arrow').css('display','block');
      }else{
        $('#CCM_accord').slideToggle(1000);
        // $('#CCM_accord').css('display','none');
        $('#CCM_down_arrow').css('display','block');
        $('#CCM_up_arrow').css('display','none');
      }
    }

    OPDaccord(){
      this.flag_firstcheck_OPD = 1;
      if($('#OPD_accord').css('display') == 'none'){
        $('#OPD_accord').slideToggle(1000);
        // $('#OPD_accord').css('display','block');
        $('#OPD_down_arrow').css('display','none');
        $('#OPD_up_arrow').css('display','block');
      }else{
        $('#OPD_accord').slideToggle(1000);
        // $('#OPD_accord').css('display','none');
        $('#OPD_down_arrow').css('display','block');
        $('#OPD_up_arrow').css('display','none');
      }
    }

    Othersaccord(){
      this.flag_firstcheck_additionalbenefits = 1;
      if($('#Others_accord').css('display') == 'none'){
        $('#Others_accord').slideToggle(1000);
        // $('#Others_accord').css('display','block');
        $('#Others_down_arrow').css('display','none');
        $('#Others_up_arrow').css('display','block');
      }else{
        $('#Others_accord').slideToggle(1000);
        // $('#Others_accord').css('display','none');
        $('#Others_down_arrow').css('display','block');
        $('#Others_up_arrow').css('display','none');
      }
    }

    Ambulanceaccord(){
      this.flag_firstcheck_ambulance = 1;
      if($('#AmbulanceCover_accord').css('display') == 'none'){
        $('#AmbulanceCover_accord').slideToggle(1000);
        // $('#AmbulanceCover_accord').css('display','block');
        $('#AmbulanceCover_down_arrow').css('display','none');
        $('#AmbulanceCover_up_arrow').css('display','block');
      }else{
        $('#AmbulanceCover_accord').slideToggle(1000);
        // $('#AmbulanceCover_accord').css('display','none');
        $('#AmbulanceCover_down_arrow').css('display','block');
        $('#AmbulanceCover_up_arrow').css('display','none');
      }
    }

    PCaccord(){
      this.flag_firstcheck_PC = 1;
      if($('#PC_accord').css('display') == 'none'){
        $('#PC_accord').slideToggle(1000);
        // $('#PC_accord').css('display','block');
        $('#PC_down_arrow').css('display','none');
        $('#PC_up_arrow').css('display','block');
      }else{
        $('#PC_accord').slideToggle(1000);
        // $('#PC_accord').css('display','none');
        $('#PC_down_arrow').css('display','block');
        $('#PC_up_arrow').css('display','none');
      }
    }

    Mobileaccord(i:any){
      var id = '#MobileCover_accord_' + i;
      var id_up = '#MobileCover_up_arrow_' + i;
      var id_down = '#MobileCover_down_arrow_' + i;

      if($(id).css('display') == 'none'){
        $(id).css('display','block');
        $(id_down).css('display','none');
        $(id_up).css('display','block');
      }else{
        $(id).css('display','none');
        $(id_down).css('display','block');
        $(id_up).css('display','none');
      }
    }
    /*****************************/


    /***** Add Benefit Field *****/
    addBenefitField(){

      let html = `
      <div id="this_benefit_row_`+this.cnt+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="benefit_name_`+this.cnt+`">Benefit Name </label>
            <input type="text" class="form-control" formControlName="benefit_name_`+this.cnt+`" id="benefit_name_`+this.cnt+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="benefit_limit_`+this.cnt+`">Benefit limit </label>
            <input type="text" class="form-control" formControlName="benefit_limit_`+this.cnt+`" id="benefit_limit_`+this.cnt+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="benefit_row_`+this.cnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newbenifitField').append(html);

      this.policyFeatures.addControl("benefit_name_"+this.cnt, new FormControl(''));
      this.policyFeatures.addControl("benefit_limit_"+this.cnt, new FormControl(''));

      var bname = "benefit_name_"+this.cnt;
      var blimit = "benefit_limit_"+this.cnt;

      this.benefitArr[this.cnt] = {bname: "", blimit: ""};

      let flag = this;
      $("#benefit_row_"+this.cnt).on('click',function(e) {
        flag.removeBenefitRow(e);
      });

      $("#benefit_limit_"+this.cnt).on('keyup',function(e) {
        flag.isnumberBenefit(e);
      });
      
      $("#benefit_limit_"+this.cnt).on('input',function(e) {
        flag.isformatbenefit(e);
      });

      this.cnt++;

      // console.log(this.cnt);

    }

    removeBenefitRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_benefit_row_' + id;
      $(idstr).remove();

    }


    isnumberBenefit(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatbenefit(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }


    /************* END ****************/


    /***** Add Benefit Field *****/
    addOthersField(){

      let html = `
      <div id="this_others_row_`+this.cnt_others+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="others_name_`+this.cnt_others+`">Benefit Name </label>
            <input type="text" class="form-control" formControlName="others_name_`+this.cnt_others+`" id="others_name_`+this.cnt_others+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="others_limit_`+this.cnt_others+`">Benefit limit </label>
            <input type="text" class="form-control" formControlName="others_limit_`+this.cnt_others+`" id="others_limit_`+this.cnt_others+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="others_row_`+this.cnt_others+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newothersField').append(html);

      this.policyFeatures.addControl("others_name_"+this.cnt_others, new FormControl(''));
      this.policyFeatures.addControl("others_limit_"+this.cnt_others, new FormControl(''));

      var bname = "others_name_"+this.cnt_others;
      var blimit = "others_limit_"+this.cnt_others;

      this.othersArr[this.cnt_others] = {bname: "", blimit: ""};

      let flag = this;
      $("#others_row_"+this.cnt_others).on('click',function(e) {
        flag.removeOthersRow(e);
      });

      $("#others_limit_"+this.cnt_others).on('keyup',function(e) {
        flag.isnumberOthers(e);
      });
      
      $("#others_limit_"+this.cnt_others).on('input',function(e) {
        flag.isformatothers(e);
      });

      this.cnt_others++;

      // console.log(this.cnt_others);

    }

    removeOthersRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_others_row_' + id;
      $(idstr).remove();

    }

    isnumberOthers(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatothers(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    /************* END ****************/
    

    /***** Add Upload Field *****/
    addUploadField(){

      let html = `
      <div class="row" id="this_upload_row_`+this.uploadcnt+`"> 
        <div class="col-sm-11">
            <div class="form-group mt-3">
                <label class="bs-form-label" for="upload_field_`+this.uploadcnt+`">Document</label>

                <label for="file_field_extra`+this.uploadcnt+`" id="custom-file-upload_`+this.uploadcnt+`" class="custom-file-upload form-control extrafile" style="text-align:center; cursor:pointer">
                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                </label>
                <input id="file_field_extra`+this.uploadcnt+`" type="file" accept="application/pdf" class="extrafile" enctype="multipart/form-data" style="display: none" />
                <input id="`+this.uploadcnt+`" type="hidden" class="filehidden" />

            </div>
            <small style="font-size: 12px; color:#605D62" id="small_extra`+this.uploadcnt+`">Upload only PDF files (Max size 4MB)</small>
        </div>
        <div class="col-sm-1 form-group mt-3 pt-2">                        
              <img src="../../../assets/img/common icons/trash.png" id="upload_row_`+this.uploadcnt+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      // $('.newuploadField').append(html);

      $(html).insertBefore('.newuploadField');

      var uploadcnt = this.uploadcnt;
      const extraUploadArr: any = {};

      $('.extrafile').on("change", function(event: any, extrafileArr: []){

        // console.log(uploadcnt);

        if (event.target.files.length > 0) {

          extrafileArr = event.target.files[0];

          $("#custom-file-upload_" + uploadcnt).empty();
          $('#custom-file-upload_' + uploadcnt).html(event.target.files[0]['name']);
          $('#custom-file-upload_' + uploadcnt).css('text-align', 'left');

          var iSize = Math.round(event.target.files[0]['size'] / 1024);
          // console.log(iSize);
          iSize = (Math.round(iSize * 100) / 100);

          $('#custom-file-upload_' + uploadcnt).append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

          $('#small_extra'+ uploadcnt).empty();
          $('#small_extra'+ uploadcnt).html('');
          $('#small_extra'+ uploadcnt).append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');
        }

        // console.log(extrafileArr);

        extraUploadArr[uploadcnt] = extrafileArr;
        // console.log(extraUploadArr);

      });

      // console.log(extraUploadArr);
      this.uploadExtraArr[this.uploadcnt] = extraUploadArr;
      console.log(this.uploadExtraArr);      

      let flag = this;
      $("#upload_row_"+this.uploadcnt).on('click',function(e) {
        flag.removeUploadRow(e);
      });

      this.uploadcnt++;

    }

    removeUploadRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_upload_row_' + id;
      $(idstr).remove();
      delete this.uploadExtraArr[id];      
      console.log(this.uploadExtraArr);      
    }
    /************** Step 3 download links download files ********/
    downloadcsv(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
      link.setAttribute('download', `Sample Template.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadclaimMIS(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/New Microsoft Excel Worksheet.xlsx');
      link.setAttribute('download', `Sample Template.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    downloadPolicyDetails(){
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', '../../assets/policy_details.xlsx');
      link.setAttribute('download', `Policy Details.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    /*************** Add Metro Field *****************/
    addMetroField(){

      let html = `
      <hr class="my-2 mt-3 MobileAccord">
      <div class="extraclaimfields row" id="this_metro_row_`+this.metrocnt+`">      
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Diesease</label>            
          <input type="text" class="form-control" formControlName="metro_name_`+this.metrocnt+`" id="metro_name_`+this.metrocnt+`" />            
        </div>        
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Metro</label>
          <input type="text" class="form-control" formControlName="metro_`+this.metrocnt+`" id="metro_`+this.metrocnt+`" />            
        </div>
        <div class="col-sm-4" style="padding: 0px 20px;">
          <label class="bs-form-label my-2 MobileAccord">Non metro</label>
          <div style="display:flex">
            <input type="text" class="form-control" formControlName="nonmetro_`+this.metrocnt+`" id="nonmetro_`+this.metrocnt+`" />
            <img src="../../../assets/img/common icons/trash.png" id="metro_row_`+this.metrocnt+`" role="button" class="img-fluid" style="width: 20px; height: 20px;margin-left: 10px; margin-top: 7px;" title="Remove" />
          </div>
        </div>        
      </div>
      <hr class="my-2 mt-3 MobileAccord">
      `;

      $('.newmetroField').append(html);

      this.policyFeatures.addControl("metro_name_"+this.metrocnt, new FormControl(''));
      this.policyFeatures.addControl("metro_"+this.metrocnt, new FormControl(''));
      this.policyFeatures.addControl("nonmetro_"+this.metrocnt, new FormControl(''));

      var metro_name = "metro_name_"+this.metrocnt;
      var metro = "metro_"+this.metrocnt;
      var nonmetro = "nonmetro_"+this.metrocnt;

      this.metroArr[this.metrocnt] = {metro_name: "", metro: "", nonmetro: ""};

      let flag = this;
      $("#metro_row_"+this.metrocnt).on('click',function(e) {
        flag.removeMetroRow(e);
      });

      $("#metro_"+this.metrocnt).on('keyup',function(e) {
        flag.isnumberMetro(e);
      });
      
      $("#nonmetro_"+this.metrocnt).on('keyup',function(e) {
        flag.isnumberMetro(e);
      });

      $("#metro_"+this.metrocnt).on('input',function(e) {
        flag.isformatmetro(e);
      });

      $("#nonmetro_"+this.metrocnt).on('input',function(e) {
        flag.isformatmetro(e);
      });

      this.metrocnt++;

      // console.log(this.metrocnt);

    }

    removeMetroRow(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_metro_row_' + id;      
      $(idstr).remove();

    }

    isnumberMetro(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatmetro(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    /************ Check If Select Box has Other option and add textfield ******/
    checkopt(t: any){

      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        // console.log($('#' + id).parent());

        $('#' + id).parent().append(html);

      }else{
        $('#' + appendid).remove();
      }

    }

    checkopt_table(t: any, value:any){
      // alert($("#"+t).val());
      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        $('#checkopt_table_' + id).append(html);

      }else{
        $('#' + appendid).remove();
      }

      /*********/
      let flag = this;
      if(value == 'hosp'){
        this.navigate_hostpitalization($("#"+t).val());
      }
      if(value == 'ICU'){
        this.navigate_ICU($("#"+t).val());
      }

      if(value == 'normal_delivery_limit'){
        this.navigate_normal_delivery_limit($("#"+t).val());
      }

      if(value == 'c_section_limit'){
        this.navigate_c_section_limit($("#"+t).val());
      }

      console.log(this.mygradedarray);

    }
    
    navigate_hostpitalization(val:any){
      // console.log(val);
      if(this.hospitalization_tableArr.indexOf(val) >= 0){

      }else{
        this.hospitalization_tableArr.push(val);
      }
      // console.log(this.hospitalization_tableArr);      
    }

    navigate_ICU(val:any){

      if(this.ICU_tableArr.indexOf(val) >= 0){

      }else{
        this.ICU_tableArr.push(val);
      }
      // console.log(this.ICU_tableArr);

    }


    navigate_normal_delivery_limit(val:any){

      if(this.normal_delivery_limit_Arr.indexOf(val) >= 0){

      }else{
        this.normal_delivery_limit_Arr.push(val);
      }
      // console.log(this.normal_delivery_limit_Arr);

    }

    navigate_c_section_limit(val:any){

      if(this.c_section_limit_Arr.indexOf(val) >= 0){

      }else{
        this.c_section_limit_Arr.push(val);
      }
      // console.log(this.c_section_limit_Arr);

    }

    checkopt_table_normal(t: any, value:any){
      var id = t;
      var val = $('#' + t).val();
      var appendid = t + '_conditionbox';

      if(val == "Others Please specify"){

        var html = `
        <div class="col-sm-12 form-group mt-3" id="`+appendid+`">
          <label class="bs-form-label" for="`+appendid+`">Specify condition</label>
          <input type="text" class="form-control" formControlName="`+appendid+`" id="`+appendid+`" />
        </div>
        `;

        $('#' + id).parent().append(html);

      }else{
        $('#' + appendid).remove();
      }
      
    }

    /************* Show and hide limit input fields ************/
    checkradio(id: any, val: any){
      // debugger;
      if(val == "Covered"){
        $('#'+id+'_'+val).css('background', '#217e2b');
        $('#'+id+'_'+'NotCovered').css('background', '#f1f1f1');
        $('#'+id).css("display", "block");
      }else{
        $('#'+id+'_'+val).css('background', '#ff4052');
        $('#'+id+'_'+'Covered').css('background', '#f1f1f1');
        $('#'+id).css("display", "none");
      }
    }

    checkradio_class(id: any, val: any){      
      if(val == "Covered"){
        $('#'+id+'_'+val).css('background', '#217e2b');
        $('#'+id+'_'+'NotCovered').css('background', '#f1f1f1');
        $('.'+id).css("display", "block");
      }else{
        $('#'+id+'_'+val).css('background', '#ff4052');
        $('#'+id+'_'+'Covered').css('background', '#f1f1f1');
        $('.'+id).css("display", "none");
      }
    }

    checkradio_basic(id: any, val: any){
      // debugger;
      if(val == "Covered"){        
        $('#'+id).css("display", "block");
      }else{       
        $('#'+id).css("display", "none");
      }
    }

    checkradio_c(id: any, val: any){
      // debugger;
      if(val == "Covered"){
        $('#'+id+'_'+val).css('background', '#217e2b');
        $('#'+id+'_'+'NotCovered').css('background', '#f1f1f1');
        $('#'+id).css("display", "block");
      }else{
        $('#'+id+'_'+val).css('background', '#ff4052');
        $('#'+id+'_'+'Covered').css('background', '#f1f1f1');
        $('#'+id).css("display", "none");
      }
    }

    checkempty(e:any){
      
      var id = e.target.id;
      var value = $('#' + id).val();
      var appendid = '#' + id + '_error';      

      if(value == ''){                

      }else{

        $(appendid).remove();

      }      

    }

    /************* show_family_otp ************/
    show_family_otp(val:any){    
      if($('#show_family_otp_' + val + ':visible').length == 0){
        $('#show_family_otp_' + val).css('display', 'flex');
        $('#thead_' + val).css('display', 'table-cell');
        $('#tbody_' + val).css('display', 'table-cell');
        $('#deductible_tbody_' + val).css('display', 'table-cell');
        $('#sum_insured_tbody_' + val).css('display', 'table-cell');
      }else{
        $('#show_family_otp_' + val).css('display', 'none');
        $('#thead_' + val).css('display', 'none');
        $('#tbody_' + val).css('display', 'none');
        $('#deductible_tbody_' + val).css('display', 'none');
        $('#sum_insured_tbody_' + val).css('display', 'none');
      }
    }

    show_family_otp1(val:any, text: any){     

      // console.log(text,val);
      if(text == 'yes'){
        $('#show_family_otp_' + val).css('display', 'flex');
        $('#thead_' + val).css('display', 'table-cell');
        $('#tbody_' + val).css('display', 'table-cell');
        $('#deductible_tbody_' + val).css('display', 'table-cell');
        $('#sum_insured_tbody_' + val).css('display', 'table-cell');
        $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'table-cell');

        //metro table
        // $('.metro_table').css('display', 'block');
        $('#tbody_PC_' + val).css('display', 'contents'); 
        
        if(val == 'Employees'){
          this.emp_flag = 1;
        }
        if(val == 'Spouse'){
          this.spo_flag = 1;
        }
        if(val == 'Childrens'){
          this.child_flag = 1;
        }
        if(val == 'Parents'){
          this.par_flag = 1;
        }
        if(val == 'Siblings_or_disabled_siblings'){
          this.sb_flag = 1;
        }
                                        
      }else{
        $('#show_family_otp_' + val).css('display', 'none');
        $('#thead_' + val).css('display', 'none');
        $('#tbody_' + val).css('display', 'none');
        $('#deductible_tbody_' + val).css('display', 'none');
        $('#sum_insured_tbody_' + val).css('display', 'none');
        $('#claim_diseasewisecapping_condition_tbody_' + val).css('display', 'none');

        //metro
        $('#tbody_PC_' + val).css('display', 'none');

        if(val == 'Employees'){
          this.emp_flag = 0;
        }
        if(val == 'Spouse'){
          this.spo_flag = 0;
        }
        if(val == 'Childrens'){
          this.child_flag = 0;
        }
        if(val == 'Parents'){
          this.par_flag = 0;
        }
        if(val == 'Siblings_or_disabled_siblings'){
          this.sb_flag = 0;
        }

      }

      /********* Check if all no then hide metrol table **********/                  

      // console.log(this.emp_flag,this.spo_flag,this.child_flag,this.par_flag,this.sb_flag);

      if(this.emp_flag == 0 && this.spo_flag == 0 && this.child_flag == 0 && this.par_flag == 0 && this.sb_flag == 0){
        $('.PC_table').css('display', 'none');
      }else{
        $('.PC_table').css('display', 'block');
      }
      /************************** END ****************************/

      /************* SHOW/HIDE each section **********************/

      if(val == 'Employees'){
        $('.div_Spouse').css('display', 'flex');
      }
      if(val == 'Spouse'){
        $('.div_Childrens').css('display', 'flex');
      }
      if(val == 'Childrens'){
        $('.div_Parents').css('display', 'flex');
      }
      if(val == 'Parents'){
        $('.div_Siblings_or_disabled_siblings').css('display', 'flex');
      }
      if(val == 'Siblings_or_disabled_siblings'){
        $('.div_Overall_Age_limit').css('display', 'flex');
      }
      /************************** END ****************************/   
      
      /********************** SHOW/HIDE maternity section ********/     

      if(this.policyFeatures.controls['Employees'].value == 'No' && 
      this.policyFeatures.controls['Spouse'].value == 'No' &&
      this.policyFeatures.controls['Parents'].value == 'Yes' &&
      this.policyFeatures.controls['Childrens'].value == 'No' &&
      this.policyFeatures.controls['Siblings_or_disabled_siblings'].value == 'No'){
        $("#root_maternity").css('display', 'none');       
      }else{
        $("#root_maternity").css('display', 'block');        
      }
      
      /********************** END ********************************/

    }

    show_family_otp_tr(val:any, text: any){     

      // console.log(text,val);
      if(text == 'yes'){
        $('.claim_table').css('display', 'block');
        $('#tr_' + val).css('display', 'contents');
      }else{        
        $('#tr_' + val).css('display', 'none');
      }

      /****** Check if all not application then *******/      
      var copay = this.policyFeatures.controls['copay'].value;
      var deductable = this.policyFeatures.controls['deductable'].value;
      var sum_incured_sub_limit = this.policyFeatures.controls['sum_incured_sub_limit'].value;
      var claim_diseasewisecapping_condition = this.policyFeatures.controls['claim_diseasewisecapping_condition'].value;      
      
      if(copay == 'Not Applicable' && deductable == 'Not Applicable' && 
          sum_incured_sub_limit == 'Not Applicable' && claim_diseasewisecapping_condition == 'Not Applicable')
      {
        $('.claim_table').css('display', 'none');
      }
      
      if(claim_diseasewisecapping_condition == 'Not Applicable'){
        $('.metro_table').css('display', 'none');
      }
      /************************** END *************************** */

    }

    age_limit(evt: any, val:any){

      let id = evt.target.id;                    
      var value: any = evt.target.value;

      if(val == 'children'){
        if(value > 35 || value == 0){
          $('#' + id).val('');
        }
      }
      if(val == 'sibling'){
        if(value > 60 || value == 0){
          $('#' + id).val('');
        }
      }                     
      if(val == 'agerange'){
        
        if(value > 100){
          $('#' + id).val('');
        }
                
        if(value == '00'){
          $('#' + id).val('');
        }

      }                     

    }
    
    /******  Hospitalization first question show/hide *********/
    is_standard(val:any){                
      
      $('#is_standard_room_rent_error').css('display', 'none');

      if(val == 'yes'){
        $('#is_standard_active').css('display', 'block');
        $('#hospitalizationTablediv').css('display', 'none');        
      }else{
        $('#is_standard_active').css('display', 'none');
        $('#hospitalizationTablediv').css('display', 'block');        
      }
    }

    is_standard_maternity(val:any){   

      $('#is_standard_room_rent_maternity_error').css('display', 'none');            

      if(val == 'yes'){
        $('#maternity_is_standard_active').css('display', 'block');
        $('#maternitytable_div').css('display', 'none');
      }else{
        $('#maternity_is_standard_active').css('display', 'none');
        $('#maternitytable_div').css('display', 'block');
      }
    }

    yes_no_color_func(e:any, val:any){
      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_LGBTQ_no').css('background', '#f1f1f1');        
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_LGBTQ_yes').css('background', '#f1f1f1');        
      }
    }

    do_you_offer_topup(e:any, val:any){

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_offer_topup_no').css('background', '#f1f1f1');
        $('.do_you_offer_topup_yesdiv').css('display', 'block');
        $('.do_you_offer_topup_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_offer_topup_yes').css('background', '#f1f1f1');
        $('.do_you_offer_topup_yesdiv').css('display', 'none');
        $('.do_you_offer_topup_nodiv').css('display', 'block');
      }
    }
    
    do_you_have_gmc(e:any, val:any){
      $('#do_you_have_gmc_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_gmc_no').css('background', '#f1f1f1');
        $('.do_you_have_gmc_yesdiv').css('display', 'block');
        $('.do_you_have_gmc_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_gmc_yes').css('background', '#f1f1f1');
        $('.do_you_have_gmc_yesdiv').css('display', 'none');
        $('.do_you_have_gmc_nodiv').css('display', 'block');
      }
    }

    Accidental_Death(e:any, val:any){
      $('#Accidental_Death_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_Accidental_Death_no').css('background', '#f1f1f1');
        $('.Accidental_Death_yesdiv').css('display', 'block');
        $('.Accidental_Death_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_Accidental_Death_yes').css('background', '#f1f1f1');
        $('.Accidental_Death_yesdiv').css('display', 'none');
        $('.Accidental_Death_nodiv').css('display', 'block');
      }
    }

    yes_no_change(e:any, val:any){

      // console.log(e.target.id)
      // console.log(val)

      // $('#do_you_have_gmc_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#' + e.target.id +'_no').css('background', '#f1f1f1');        
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_gpa_yes').css('background', '#f1f1f1');        
      }
    }

    do_you_have_gpa(e:any, val:any){
      $('#do_you_have_gpa_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_gpa_no').css('background', '#f1f1f1');
        $('.do_you_have_gpa_yesdiv').css('display', 'block');
        $('.do_you_have_gpa_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_gpa_yes').css('background', '#f1f1f1');
        $('.do_you_have_gpa_yesdiv').css('display', 'none');
        $('.do_you_have_gpa_nodiv').css('display', 'block');
      }
    }

    do_you_have_gtl(e:any, val:any){
      $('#do_you_have_gtl_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_gtl_no').css('background', '#f1f1f1');
        $('.do_you_have_gtl_yesdiv').css('display', 'block');
        $('.do_you_have_gtl_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_gtl_yes').css('background', '#f1f1f1');
        $('.do_you_have_gtl_yesdiv').css('display', 'none');
        $('.do_you_have_gtl_nodiv').css('display', 'block');
      }
    }

    do_you_have_opd(e:any, val:any){
      $('#do_you_have_opd_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_opd_no').css('background', '#f1f1f1');
        $('.do_you_have_opd_yesdiv').css('display', 'block');
        $('.do_you_have_opd_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_opd_yes').css('background', '#f1f1f1');
        $('.do_you_have_opd_yesdiv').css('display', 'none');
        $('.do_you_have_opd_nodiv').css('display', 'block');
      }
    }

    do_you_have_ROB(e:any, val:any){
      $('#do_you_have_ROB_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_ROB_no').css('background', '#f1f1f1');
        $('.do_you_have_ROB_yesdiv').css('display', 'block');
        $('.do_you_have_ROB_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_ROB_yes').css('background', '#f1f1f1');
        $('.do_you_have_ROB_yesdiv').css('display', 'none');
        $('.do_you_have_ROB_nodiv').css('display', 'block');
      }
    }

    /**************/
    check_SI_for_parents(event: any){
      let val: any;
      val = event.target.value;
      var str:any = event.target.id;
      var id = str.charAt(str.length -1 );
      // console.log(id);
      // console.log(this.greatest_graded_value);
      if(val > this.greatest_graded_value){
        $('#SI_for_parents_error_' + id).css('display', 'block');
        var valid = '#SI_for_parents_' + id;
        $(valid).val('');
      }else{
        $('#SI_for_parents_error_' + id).css('display', 'none');
      }
    }
    
    /**********Limit Option*************/
    limitOption(evt: any, val: any){
      
      var value = evt.target.value;
      var perc = "." + val + "_limit_perc";
      var inr = "." + val + "_limit_value";

      if(value == "per of Sum Insured"){        
        $(perc).css("display","block");
        $(inr).css("display","none");
      }else{
        $(perc).css("display","none");
        $(inr).css("display","block");
      }

    }

    /************** Submit *************/  

    onsubmit_first(value: any){

      var postdata;

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,
        "survey_policy_id": this.survey_policy_id,

        "company_name":this.policyDetails.controls['company_name'].value,
        "industry":this.policyDetails.controls['industry'].value,
        "employee_headcount":this.policyDetails.controls['employee_headcount'].value,
        "MNC":this.policyDetails.controls['MNC'].value, 
        "turnover": this.policyDetails.controls['turnover'].value,        
      }    

      this.policyDetailsService.policyDetailsCreate_first(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.benefit_id = result.data.benefit_id;
              this.client_id = result.data.client_id;
              this.survey_policy_id = result.data.survey_policy_id;                                         

              // this.validmsg = result.message;
              // $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

              // var flag = this;
              // setTimeout(function() {
              //   // flag.route.navigate(['/Client-dashboard']);
              // }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            // this.displayStyleThankyou = "block";
            // this.validmsg = error.error.message;
            // $('#validmsg').css('display', 'block');
            // $('#errormsg').css('display', 'none');
        });

    }

    onsubmit_second(){
      var postdata;

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,          
        "client_product_id": this.client_product_id,
        "survey_policy_id": this.survey_policy_id,
        "existing_policy_check": this.survey_gmc_policy_check,
        "benefit_id": this.benefit_id,

        "emp_atstart":this.verifypolicyDetails.controls['emp_atstart'].value,
        "do_you_have_gmc":this.verifypolicyDetails.controls['do_you_have_gmc'].value,
        "family_contruct":this.verifypolicyDetails.controls['family_contruct'].value,          
        "LGBTQ":this.verifypolicyDetails.controls['LGBTQ'].value,

        "sum_insured_type":this.verifypolicyDetails.controls['sum_insured_type'].value,
        "sum_insured_approach":this.verifypolicyDetails.controls['sum_insured_approach'].value,
        "sum_insured_uniform": $('#sum_insured_uniform').val(),
        "mygradedarray": this.gradedArr,

        "thirty_days_waiting_period":this.verifypolicyDetails.controls['thirty_days_waiting_period'].value,
        "one_and_half_days_waiting_period":this.verifypolicyDetails.controls['one_and_half_days_waiting_period'].value,
        "pre_existing_condition":this.verifypolicyDetails.controls['pre_existing_condition'].value,

        "room_rent_condition_one":this.verifypolicyDetails.controls['room_rent_condition_one'].value,
        "ICU_room_rent":this.verifypolicyDetails.controls['ICU_room_rent'].value,
        "pre_post_hospitalisation":this.verifypolicyDetails.controls['pre_post_hospitalisation'].value,          
        "day_care_procedures":this.verifypolicyDetails.controls['day_care_procedures'].value,
        "min_max_day_care_procedures":this.verifypolicyDetails.controls['min_max_day_care_procedures'].value,
        "day_care_procedures_limit_perc":this.verifypolicyDetails.controls['day_care_procedures_limit_perc'].value,
        "day_care_procedures_limit_value":this.verifypolicyDetails.controls['day_care_procedures_limit_value'].value,
        "domiciliary_hospitalisation":this.verifypolicyDetails.controls['domiciliary_hospitalisation'].value,
        "min_max_domiciliary_hospitalisation":this.verifypolicyDetails.controls['min_max_domiciliary_hospitalisation'].value,
        "domiciliary_hospitalisation_limit_perc":this.verifypolicyDetails.controls['domiciliary_hospitalisation_limit_perc'].value,
        "domiciliary_hospitalisation_limit_value":this.verifypolicyDetails.controls['domiciliary_hospitalisation_limit_value'].value,          
        
        "do_you_want_maternity":this.verifypolicyDetails.controls['do_you_want_maternity'].value,
        
        "room_rent_natal_cover":this.verifypolicyDetails.controls['room_rent_natal_cover'].value,
        "room_rent_natal_cover_isCover":this.verifypolicyDetails.controls['room_rent_natal_cover_isCover'].value,
        "room_rent_natal_cover_limit":this.verifypolicyDetails.controls['room_rent_natal_cover_limit'].value,      
        
        "bay_day_one":this.verifypolicyDetails.controls['bay_day_one'].value,
        "bay_day_one_isCover":this.verifypolicyDetails.controls['bay_day_one_isCover'].value,
        "baby_specific_covered_limit":this.verifypolicyDetails.controls['baby_specific_covered_limit'].value,       
        
        "infertility_cover":this.verifypolicyDetails.controls['infertility_cover'].value,
        "infertility_cover_isCover":this.verifypolicyDetails.controls['infertility_cover_isCover'].value,
        "infertility_covered_limit":this.verifypolicyDetails.controls['infertility_covered_limit'].value,
        
        "surrogacy_cover":this.verifypolicyDetails.controls['surrogacy_cover'].value,
        "surrogacy_cover_isCover":this.verifypolicyDetails.controls['surrogacy_cover_isCover'].value,
        "surrogacy_covered_limit":this.verifypolicyDetails.controls['surrogacy_covered_limit'].value,
        
        "maternity_related_complications_yes":this.verifypolicyDetails.controls['maternity_related_complications_yes'].value,
        "maternity_related_complications_yes_isCover":this.verifypolicyDetails.controls['maternity_related_complications_yes_isCover'].value,
        "maternity_related_complications_no":this.verifypolicyDetails.controls['maternity_related_complications_no'].value,
        "maternity_related_complications_no_isCover":this.verifypolicyDetails.controls['maternity_related_complications_no_isCover'].value,

        "normal_delivery_limit_maternity":this.verifypolicyDetails.controls['normal_delivery_limit_maternity'].value,
        "c_section_limit_maternity":this.verifypolicyDetails.controls['c_section_limit_maternity'].value,

        "copay":this.verifypolicyDetails.controls['copay'].value,
        "deductable":this.verifypolicyDetails.controls['deductable'].value,
        "sum_incured_sub_limit":this.verifypolicyDetails.controls['sum_incured_sub_limit'].value,          
        "claim_diseasewisecapping_condition":this.verifypolicyDetails.controls['claim_diseasewisecapping_condition'].value,
        
        "out_patient_benefits":this.verifypolicyDetails.controls['out_patient_benefits'].value,
        "out_patient_benefits_limit":this.verifypolicyDetails.controls['out_patient_benefits_limit'].value,
        "opd_dental":this.verifypolicyDetails.controls['opd_dental'].value,
        "opd_dental_limit":this.verifypolicyDetails.controls['opd_dental_limit'].value,
        "opd_vision":this.verifypolicyDetails.controls['opd_vision'].value,
        "opd_vision_limit":this.verifypolicyDetails.controls['opd_vision_limit'].value,
        "preventive_health_checkup":this.verifypolicyDetails.controls['preventive_health_checkup'].value,

        "preventive_health_checkup_limit":this.verifypolicyDetails.controls['preventive_health_checkup_limit'].value,
        "organ_donor_cover":this.verifypolicyDetails.controls['organ_donor_cover'].value,
        "min_max_organ_donor_cover":this.verifypolicyDetails.controls['min_max_organ_donor_cover'].value,
        "organ_donor_cover_limit_perc":this.verifypolicyDetails.controls['organ_donor_cover_limit_perc'].value,
        "organ_donor_cover_limit_value":this.verifypolicyDetails.controls['organ_donor_cover_limit_value'].value,
        "oral_chemo_therapy":this.verifypolicyDetails.controls['oral_chemo_therapy'].value,
        "min_max_oral_chemo_therapy":this.verifypolicyDetails.controls['min_max_oral_chemo_therapy'].value,
        "oral_chemo_therapy_limit_perc":this.verifypolicyDetails.controls['oral_chemo_therapy_limit_perc'].value,
        "oral_chemo_therapy_limit_value":this.verifypolicyDetails.controls['oral_chemo_therapy_limit_value'].value,

        "ayush_treatment":this.verifypolicyDetails.controls['ayush_treatment'].value,
        "min_max_ayush_treatment":this.verifypolicyDetails.controls['min_max_ayush_treatment'].value,
        "ayush_treatment_limit_perc":this.verifypolicyDetails.controls['ayush_treatment_limit_perc'].value,
        "ayush_treatment_limit_value":this.verifypolicyDetails.controls['ayush_treatment_limit_value'].value,
        "lasik_treatment":this.verifypolicyDetails.controls['lasik_treatment'].value,
        "min_max_lasik_treatment":this.verifypolicyDetails.controls['min_max_lasik_treatment'].value,
        "lasik_treatment_limit_perc":this.verifypolicyDetails.controls['lasik_treatment_limit_perc'].value,
        "lasik_treatment_limit_value":this.verifypolicyDetails.controls['lasik_treatment_limit_value'].value,
        "corporate_buffer":this.verifypolicyDetails.controls['corporate_buffer'].value,

        "corporate_buffer_isCover":this.verifypolicyDetails.controls['corporate_buffer_isCover'].value,
        "corporate_buffer_limit":this.verifypolicyDetails.controls['corporate_buffer_limit'].value,
        "corporate_buffer_family_limit":this.verifypolicyDetails.controls['corporate_buffer_family_limit'].value,
        
        "road_ambulance":this.verifypolicyDetails.controls['road_ambulance'].value,
        "min_max_road_ambulance":this.verifypolicyDetails.controls['min_max_road_ambulance'].value,
        "road_ambulance_limit_perc":this.verifypolicyDetails.controls['road_ambulance_limit_perc'].value,
        "road_ambulance_limit_value":this.verifypolicyDetails.controls['road_ambulance_limit_value'].value,
        
        "cardiac_ambulance":this.verifypolicyDetails.controls['cardiac_ambulance'].value,
        "min_max_cardiac_ambulance":this.verifypolicyDetails.controls['min_max_cardiac_ambulance'].value,
        "cardiac_ambulance_limit_perc":this.verifypolicyDetails.controls['cardiac_ambulance_limit_perc'].value,
        "cardiac_ambulance_limit_value":this.verifypolicyDetails.controls['cardiac_ambulance_limit_value'].value,

        "air_ambulance":this.verifypolicyDetails.controls['air_ambulance'].value,
        "min_max_air_ambulance":this.verifypolicyDetails.controls['min_max_air_ambulance'].value,
        "air_ambulance_limit_perc":this.verifypolicyDetails.controls['air_ambulance_limit_perc'].value,
        "air_ambulance_limit_value":this.verifypolicyDetails.controls['air_ambulance_limit_value'].value,

        "Premium_Contribution":this.verifypolicyDetails.controls['Premium_Contribution'].value,
        "do_you_offer_topup":this.verifypolicyDetails.controls['do_you_offer_topup'].value

      }   

      this.policyDetailsService.policyDetailsCreate_second(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              this.benefit_id = result.data.benefit_id;              

              // this.validmsg = result.message;
              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');
              var flag = this;
              setTimeout(function() {
                // flag.route.navigate(['/Client-dashboard']);
              }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    onsubmit_third(){      

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,      
        "client_product_id": this.client_product_id,
        "survey_policy_id": this.survey_policy_id,
        "existing_policy_check": this.survey_gmc_policy_check,
        "benefit_id": this.benefit_id,

        // Family
        "do_you_have_gpa": this.policyFeatures.controls['do_you_have_gpa'].value,
        "no_employess_gpa": this.policyFeatures.controls['no_employess_gpa'].value,
        
        // Sum Incured
        "SI_type": this.policyFeatures.controls['SI_type'].value,
        "multiple_of_salary": this.policyFeatures.controls['multiple_of_salary'].value,
        "Define_flat_SI": this.policyFeatures.controls['Define_flat_SI'].value,
        "SI_Junior_management": this.policyFeatures.controls['SI_Junior_management'].value,
        "SI_Middle_management": this.policyFeatures.controls['SI_Middle_management'].value,
        "SI_Senior_management": this.policyFeatures.controls['SI_Senior_management'].value,
        "Graded_extra_fields": this.gradedArrSI_gpa,

        "Accidental_Death":this.policyFeatures.controls['Accidental_Death'].value,
        "Accidental_Death_limit":this.policyFeatures.controls['Accidental_Death_limit'].value,
        "Permanent_Total_Disability":this.policyFeatures.controls['Permanent_Total_Disability'].value,
        "Permanent_Total_Disability_limit":this.policyFeatures.controls['Permanent_Total_Disability_limit'].value,
        "Permanent_Partial_Disability":this.policyFeatures.controls['Permanent_Partial_Disability'].value,
        "Permanent_Partial_Disability_limit":this.img_document,
        "Total_disability_loss_of_wages":this.policyFeatures.controls['Total_disability_loss_of_wages'].value,
        "Total_disability_loss_of_wages_limit":this.policyFeatures.controls['Total_disability_loss_of_wages_limit'].value,
        "Total_disability_loss_of_wages_INR_per_week":this.policyFeatures.controls['Total_disability_loss_of_wages_INR_per_week'].value,
        "Total_disability_loss_of_wages_actual_weekly_salary":this.policyFeatures.controls['Total_disability_loss_of_wages_actual_weekly_salary'].value,

        // Others
        "Medical_benefits_Medex":this.policyFeatures.controls['Medical_benefits_Medex'].value,
        "Medical_benefits_Medex_limit":this.policyFeatures.controls['Medical_benefits_Medex_limit'].value,

        "Double_dismemberment":this.policyFeatures.controls['Double_dismemberment'].value,
        // "Double_dismemberment_limit":this.policyFeatures.controls['Double_dismemberment_limit'].value,

        "Transportation_of_mortal_remains":this.policyFeatures.controls['Transportation_of_mortal_remains'].value,
        "Transportation_of_mortal_remains_limit":this.policyFeatures.controls['Transportation_of_mortal_remains_limit'].value,

        "Ambulance_charges":this.policyFeatures.controls['Ambulance_charges'].value,
        "Ambulance_charges_limit":this.policyFeatures.controls['Ambulance_charges_limit'].value,

        "Modification_benefit":this.policyFeatures.controls['Modification_benefit'].value,
        "Modification_benefit_limit":this.policyFeatures.controls['Modification_benefit_limit'].value,

        "Education_benefit":this.policyFeatures.controls['Education_benefit'].value,
        "Education_benefit_limit":this.policyFeatures.controls['Education_benefit_limit'].value,

        "Funeral_and_Repatriation_expenses":this.policyFeatures.controls['Funeral_and_Repatriation_expenses'].value,
        "Funeral_and_Repatriation_expenses_limit":this.policyFeatures.controls['Funeral_and_Repatriation_expenses_limit'].value,

        "Terrorism_clause":this.policyFeatures.controls['Terrorism_clause'].value,
        "Terrorism_clause_limit":this.policyFeatures.controls['Terrorism_clause_limit'].value,

        "do_you_have_topup": this.policyFeatures.controls['do_you_have_topup'].value,
        "offer_spouse_PA": this.policyFeatures.controls['offer_spouse_PA'].value,

      }

      this.policyDetailsService.policyDetailsCreate_third(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              // if(this.survey_gmc_policy_check == "No"){
              //   this.displayStyleThankyou = "block";
              //   var flag = this;
              //   setTimeout(function() {
              //     flag.route.navigate(['/Client-dashboard']);
              //   }, 3000);
              // }else{

              // }

              this.benefit_id = result.data.benefit_id;

              // this.validmsg = result.message;              
              $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');              

            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    onsubmit_fourth(){      

    var postdata;

      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "client_id":this.client_id,
        "survey_policy_id": this.survey_policy_id,

        "do_you_have_gtl": this.documentUpload.controls['do_you_have_gtl'].value,
        "no_employess_gtl": this.documentUpload.controls['no_employess_gtl'].value,

        // Sum Incured
        "SI_type_gtl": this.documentUpload.controls['SI_type_gtl'].value,
        "multiple_of_salary_gtl": this.documentUpload.controls['multiple_of_salary_gtl'].value,
        "Define_flat_SI_gtl": this.documentUpload.controls['Define_flat_SI_gtl'].value,
        "SI_Junior_management_gtl": this.documentUpload.controls['SI_Junior_management_gtl'].value,
        "SI_Middle_management_gtl": this.documentUpload.controls['SI_Middle_management_gtl'].value,
        "SI_Senior_management_gtl": this.documentUpload.controls['SI_Senior_management_gtl'].value,
        "Graded_extra_fields_gtl": this.gradedArrSI_gtl,

        "actively_at_work": this.documentUpload.controls['actively_at_work'].value,
        "Waived_For_all_Employees": this.documentUpload.controls['Waived_For_all_Employees'].value,
        "Only_For_Existing_Employees": this.documentUpload.controls['Only_For_Existing_Employees'].value,

        // Others
        "Terminal_illness":this.documentUpload.controls['Terminal_illness'].value,
        "Terminal_illness_limit":this.documentUpload.controls['Terminal_illness_limit'].value,

        "Accidental_death_benefit":this.documentUpload.controls['Accidental_death_benefit'].value,
        "Accidental_death_benefit_limit":this.documentUpload.controls['Accidental_death_benefit_limit'].value,
      
        "Accelerated_critical_illness":this.documentUpload.controls['Accelerated_critical_illness'].value,
        "Accelerated_critical_illness_limit":this.documentUpload.controls['Accelerated_critical_illness_limit'].value,

        "Additional_critical_illness":this.documentUpload.controls['Additional_critical_illness'].value,
        "Additional_critical_illness_limit":this.documentUpload.controls['Additional_critical_illness_limit'].value,

        "do_you_have_topupplan":this.documentUpload.controls['do_you_have_topupplan'].value,
        "do_you_have_spouse_cover":this.documentUpload.controls['do_you_have_spouse_cover'].value, 
        
      }

      this.policyDetailsService.policyDetailsCreate_fourth(postdata)
          .subscribe((result: any) => {

            // console.log(result);

            if (result.status == true) {

              // console.log(result);

              // this.proposal_number_show = result.data.proposal_number;
              // this.displayStyleThankyou = "block";

              // $('#sticky-footer').css('opacity', '0');
              // $('.navbar').css('opacity', '0');
              // $('#sidebar-wrapper').css('z-index', '0');

              // $('#validmsg').css('display', 'block');
              $('#errormsg').css('display', 'none');

              // var flag = this;
              // setTimeout(function() {
              //   flag.route.navigate(['/Client-dashboard']);
              // }, 3000);


            } else {
              // console.log(result);
            }
          }, (error) => {
            this.validmsg = error.error.message;
            $('#validmsg').css('display', 'none');
            $('#errormsg').css('display', 'block');
        });

    }

    onsubmit_fivth(){      

      var postdata;
  
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.client_id,
          "survey_policy_id": this.survey_policy_id,
  
          "do_you_have_opd": this.step5form.controls['do_you_have_opd'].value,
          "OPD_cashless": this.step5form.controls['OPD_cashless'].value,       
          "Preventive_Health_Check_up": this.step5form.controls['Preventive_Health_Check_up'].value,
          "Annual_Health_Check_up": this.step5form.controls['Annual_Health_Check_up'].value,               
          "medical_facilities": this.step5form.controls['medical_facilities'].value,               
        }
  
        this.policyDetailsService.policyDetailsCreate_fivth(postdata)
            .subscribe((result: any) => {
  
              // console.log(result);
  
              if (result.status == true) {             
  
                // $('#validmsg').css('display', 'block');
                $('#errormsg').css('display', 'none');
             
  
              } else {
                // console.log(result);
              }
            }, (error) => {
              this.validmsg = error.error.message;
              $('#validmsg').css('display', 'none');
              $('#errormsg').css('display', 'block');
          });
  
    }

    onsubmit_sixth(){      

      var postdata;
  
        postdata = {
          "tokenable_id":localStorage.getItem('tokenable_id'),
          "client_id":this.client_id,
          "survey_policy_id": this.survey_policy_id,
  
          "offer_gratuity": this.step6form.controls['offer_gratuity'].value,
          "offer_gratuity_per_law": this.step6form.controls['offer_gratuity_per_law'].value,

          "do_you_have_ROB": this.step6form.controls['do_you_have_ROB'].value,
          "offer_pension_plans": this.step6form.controls['offer_pension_plans'].value,       
          "offer_car_leases": this.step6form.controls['offer_car_leases'].value,
          "offer_leave_encashment": this.step6form.controls['offer_leave_encashment'].value,
          "offer_canteen_facility": this.step6form.controls['offer_canteen_facility'].value,
          "offer_creche_facilities": this.step6form.controls['offer_creche_facilities'].value,       
          "offer_employee_emergency": this.step6form.controls['offer_employee_emergency'].value,
          "offer_courses": this.step6form.controls['offer_courses'].value,
          "offer_pick_drop": this.step6form.controls['offer_pick_drop'].value,
          "offer_Employee_stock": this.step6form.controls['offer_Employee_stock'].value,       
          "offer_Gadget_allowance": this.step6form.controls['offer_Gadget_allowance'].value,
          "offer_flexi_work": this.step6form.controls['offer_flexi_work'].value,
          "offer_flexi_pay": this.step6form.controls['offer_flexi_pay'].value,
          "offer_sabbatical_benefits": this.step6form.controls['offer_sabbatical_benefits'].value,
          
          "offer_menstural_leave": this.step6form.controls['offer_menstural_leave'].value,

        }
  
        this.policyDetailsService.policyDetailsCreate_sixth(postdata)
            .subscribe((result: any) => {
  
              // console.log(result);
  
              if (result.status == true) {             
  
                this.displayStyleThankyou = "block";

                $('#sticky-footer').css('opacity', '0');
                $('.navbar').css('opacity', '0');
                $('#sidebar-wrapper').css('z-index', '0');
                
                // $('#validmsg').css('display', 'block');
                $('#errormsg').css('display', 'none');
             
  
              } else {
                // console.log(result);
              }
            }, (error) => {
              this.validmsg = error.error.message;
              $('#validmsg').css('display', 'none');
              $('#errormsg').css('display', 'block');
          });
  
    }

    exit() {           
      
      this.displayStyleThankyou = "none";

      $('#sticky-footer').css('opacity', '1');
      $('.navbar').css('opacity', '1');
      $('#sidebar-wrapper').css('z-index', '500');

      localStorage.clear();
      this.route.navigate(['/home']);
      
    }

    gohome() {

      this.displayStyleThankyou = "none";

      $('#sticky-footer').css('opacity', '1');
      $('.navbar').css('opacity', '1');
      $('#sidebar-wrapper').css('z-index', '500');

      // localStorage.clear();
      // this.route.navigate(['/Client-dashboard']);

      let postdataObj = {
          "tokenable_id":localStorage.getItem('tokenable_id'),          
      };
      this.policyDetailsService.getuserdetails(postdataObj)
      .subscribe((userdata: any) => {              
        
        // console.log(userdata.data[0]['type_name']);
        localStorage.setItem('type_name', userdata.data[0]['type_name']);
        localStorage.setItem('client_id', this.client_id);
        localStorage.setItem('plan_name', userdata.data[0]['plan_name']);
        localStorage.setItem('company_name', userdata.data[0]['company_name']);
        localStorage.setItem('email_verify', userdata.data[0]['email_verify']);              
        localStorage.setItem('mobile_no', userdata.data[0]['mobile_no']);
        localStorage.setItem('surveyLoginFlag', 'No');
        
        this.route.navigate(['/Client-dashboard']);        
        
      });
      
    }

    show_cross_combination(val: any){
      if(val == "Parent only"){
        $(".Cross_Combination_status").css('display', 'none');
      }else{
        $(".Cross_Combination_status").css('display', 'contents');
      }
    }

    do_you_want_maternity(e: any, val:any){

      $('#do_you_want_maternity_error').css('display', 'none');

      if(val == "yes"){  
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_want_maternity_no').css('background', '#f1f1f1');            
        $(".do_you_want_maternity_yesdiv").css('display', 'block');
        $(".do_you_want_maternity_nodiv").css('display', 'none');
      }else{
        
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_want_maternity_yes').css('background', '#f1f1f1');

        $(".do_you_want_maternity_yesdiv").css('display', 'none');
        $(".do_you_want_maternity_nodiv").css('display', 'block');
      }
    }

    show_metro_table(val:any){
      if(val == "show"){
        $(".metro_table").css('display', 'block');
      }else{
        $(".metro_table").css('display', 'none');
      }
    }

    check_limit_toggle(val:any, text:any){

      if(text == 'yes'){
        $('.' + val + '_limit_value').css('display','flex');
      }else{
        $('.' + val + '_limit_value').css('display','none');
      }

    }

    changepage(e: any){
      
      var val = e.target.value;

      localStorage.setItem('plan_page_flag', val);

      if(val == 1){
        this.route.navigate(['/policy-details']);
      }
      if(val == 2){
        this.route.navigate(['/topup-policy-details']);
      }

    }    

    Prog(val:any, text:any){
      // let id = "#Prog" + text;
      // let value = "#Value" + text;
      // let progressBar:any = document.querySelector(id);
      // let valueContainer:any = document.querySelector(value);
      // let progressValue = 0;
      // let progressEndValue = val;
      // let speed = 30;

      // if(val == 0){

      //   let progress = setInterval(() => {
      //     // progressValue++;
      //     valueContainer.textContent = `${progressValue}%`;
      //     progressBar.style.background = `conic-gradient(
      //         #58D68C ${progressValue * 3.6}deg,
      //         #DEDEDE ${progressValue * 3.6}deg
      //     )`;
      //     if (progressValue == progressEndValue) {
      //       clearInterval(progress);
      //     }
      //   }, speed);

      // }else{

      //   let progress = setInterval(() => {
      //     progressValue++;
      //     valueContainer.textContent = `${progressValue}%`;
      //     progressBar.style.background = `conic-gradient(
      //         #58D68C ${progressValue * 3.6}deg,
      //         #DEDEDE ${progressValue * 3.6}deg
      //     )`;
      //     if (progressValue == progressEndValue) {
      //       clearInterval(progress);
      //     }
      //   }, speed);

      // }
      
    }

    checkMOS(e: any){

      $('#multiple_of_salary_topup').empty();

      var val = e.target.value;
      this.MOSArr = 8 - val;

      var Arrmos = [0,1,2,3,4,5,6,7];

      if(this.MOSArr == 7){        
        
        let html = `
          <option value="0">0</option>
        `;

        $('#multiple_of_salary_topup').append(html);        
        
      }else{        

        for(var i = 1; i < this.MOSArr; i++){

          let html = `
            <option value="`+Arrmos[i]+`">`+Arrmos[i]+`</option>
          `;
  
          $('#multiple_of_salary_topup').append(html);
  
        }
      }      

      if(val == 7){

        $('.multiple_of_salary_topup').css('display','none');
        $('#topupmsg').css('display','block');

      }else{

        $('.multiple_of_salary_topup').css('display','block');
        $('#topupmsg').css('display','none');

      }

    }

    show_SI_div(val: any){
      if(val == 'Multiple of Salary'){
        $('#div_Multiple_of_Salary').css('display','block');
        $('#div_Flat').css('display','none');
        $('#div_Graded').css('display','none');
      }
      if(val == 'Flat'){
        $('#div_Multiple_of_Salary').css('display','none');
        $('#div_Flat').css('display','block');
        $('#div_Graded').css('display','none');
      }
      if(val == 'Graded'){
        $('#div_Multiple_of_Salary').css('display','none');
        $('#div_Flat').css('display','none');
        $('#div_Graded').css('display','block');
      }
    }

    show_SI_div_gtl(val: any){
      if(val == 'Multiple of Salary'){
        $('#div_Multiple_of_Salary_gtl').css('display','block');
        $('#div_Flat_gtl').css('display','none');
        $('#div_Graded_gtl').css('display','none');
      }
      if(val == 'Flat'){
        $('#div_Multiple_of_Salary_gtl').css('display','none');
        $('#div_Flat_gtl').css('display','block');
        $('#div_Graded_gtl').css('display','none');
      }
      if(val == 'Graded'){
        $('#div_Multiple_of_Salary_gtl').css('display','none');
        $('#div_Flat_gtl').css('display','none');
        $('#div_Graded_gtl').css('display','block');
      }
    }

    checkMOS_gtl(e: any){

      $('#multiple_of_salary_topup_gtl').empty();

      var val = e.target.value;
      this.MOSArr_gtl = 8 - val;

      var Arrmos = [0,1,2,3,4,5,6,7];

      if(this.MOSArr_gtl == 7){        
        
        let html = `
          <option value="0">0</option>
        `;

        $('#multiple_of_salary_topup_gtl').append(html);        
        
      }else{        

        for(var i = 1; i < this.MOSArr_gtl; i++){

          let html = `
            <option value="`+Arrmos[i]+`">`+Arrmos[i]+`</option>
          `;
  
          $('#multiple_of_salary_topup_gtl').append(html);
  
        }
      }      

      if(val == 7){

        $('.multiple_of_salary_topup_gtl').css('display','none');
        $('#topupmsg_gtl').css('display','block');

      }else{

        $('.multiple_of_salary_topup_gtl').css('display','block');
        $('#topupmsg_gtl').css('display','none');

      }

    }

    /***** Add Graded Field *****/
    addGradedField(){

      let html = `
      <div id="this_graded_row_`+this.cnt_graded+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_name_`+this.cnt_graded+`">Graded </label>
            <input type="text" class="form-control" formControlName="graded_name_`+this.cnt_graded+`" id="graded_name_`+this.cnt_graded+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_limit_`+this.cnt_graded+`">Amount </label>
            <input type="text" class="form-control" formControlName="graded_limit_`+this.cnt_graded+`" id="graded_limit_`+this.cnt_graded+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="graded_row_`+this.cnt_graded+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newgradedField').append(html);

      this.policyFeatures.addControl("graded_name_"+this.cnt_graded, new FormControl(''));
      this.policyFeatures.addControl("graded_limit_"+this.cnt_graded, new FormControl(''));

      var bname = "graded_name_"+this.cnt_graded;
      var blimit = "graded_limit_"+this.cnt_graded;

      this.gradedArrSI[this.cnt_graded] = {bname: "", blimit: ""};

      let flag = this;
      $("#graded_row_"+this.cnt_graded).on('click',function(e) {
        flag.removeGradedRow_e(e);
      });

      $("#graded_limit_"+this.cnt_graded).on('keyup',function(e) {
        flag.isnumberGraded_e(e);
      });
      
      $("#graded_limit_"+this.cnt_graded).on('input',function(e) {
        flag.isformatgraded(e);
      });

      this.cnt_graded++;

      console.log(this.gradedArrSI);

    }

    removeGradedRow_e(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_graded_row_' + id;
      $(idstr).remove();

    }

    isnumberGraded_e(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatgraded(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    //
    addGradedField_gtl(){

      let html = `
      <div id="this_graded_row_gtl_`+this.cnt_graded_gtl+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_name_gtl_`+this.cnt_graded_gtl+`">Graded </label>
            <input type="text" class="form-control" formControlName="graded_name_gtl_`+this.cnt_graded_gtl+`" id="graded_name_gtl_`+this.cnt_graded_gtl+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_limit_`+this.cnt_graded_gtl+`">Amount </label>
            <input type="text" class="form-control" formControlName="graded_limit_gtl_`+this.cnt_graded_gtl+`" id="graded_limit_gtl_`+this.cnt_graded_gtl+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="graded_row_gtl_`+this.cnt_graded_gtl+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newgradedField_gtl').append(html);

      this.policyFeatures.addControl("graded_name_gtl_"+this.cnt_graded_gtl, new FormControl(''));
      this.policyFeatures.addControl("graded_limit_gtl_"+this.cnt_graded_gtl, new FormControl(''));

      var bname_gtl = "graded_name_gtl_"+this.cnt_graded_gtl;
      var blimit_gtl = "graded_limit_gtl_"+this.cnt_graded_gtl;

      this.gradedArrSI_gtl[this.cnt_graded_gtl] = {bname_gtl: "", blimit_gtl: ""};

      let flag = this;
      $("#graded_row_gtl_"+this.cnt_graded_gtl).on('click',function(e) {
        flag.removeGradedRow_e_gtl(e);
      });

      $("#graded_limit_gtl_"+this.cnt_graded_gtl).on('keyup',function(e) {
        flag.isnumberGraded_e_gtl(e);
      });
      
      $("#graded_limit_gtl_"+this.cnt_graded_gtl).on('input',function(e) {
        flag.isformatgraded_gtl(e);
      });

      this.cnt_graded_gtl++;

      console.log(this.gradedArrSI_gtl);

    }

    removeGradedRow_e_gtl(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_graded_row_gtl_' + id;
      $(idstr).remove();

    }

    isnumberGraded_e_gtl(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatgraded_gtl(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    //
    addGradedField_gpa(){

      let html = `
      <div id="this_graded_row_gpa_`+this.cnt_graded_gpa+`" class="row">      
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_name_gpa_`+this.cnt_graded_gpa+`">Graded </label>
            <input type="text" class="form-control" formControlName="graded_name_gpa_`+this.cnt_graded_gpa+`" id="graded_name_gpa_`+this.cnt_graded_gpa+`" />
        </div>
        <div class="col-sm-5 form-group mt-3">
            <label class="bs-form-label" for="graded_limit_`+this.cnt_graded_gpa+`">Amount </label>
            <input type="text" class="form-control" formControlName="graded_limit_gpa_`+this.cnt_graded_gpa+`" id="graded_limit_gpa_`+this.cnt_graded_gpa+`" />
        </div>
        <div class="col-sm-2 form-group mt-3 pt-2">                        
            <img src="../../../assets/img/common icons/trash.png" id="graded_row_gpa_`+this.cnt_graded_gpa+`" role="button" class="img-fluid mt-3 pt-2" style="width:20px" title="Remove" />
        </div>
      </div>
      `;

      $('.newgradedField_gpa').append(html);

      this.policyFeatures.addControl("graded_name_gpa_"+this.cnt_graded_gpa, new FormControl(''));
      this.policyFeatures.addControl("graded_limit_gpa_"+this.cnt_graded_gpa, new FormControl(''));

      var bname_gpa = "graded_name_gpa_"+this.cnt_graded_gpa;
      var blimit_gpa = "graded_limit_gpa_"+this.cnt_graded_gpa;

      this.gradedArrSI_gpa[this.cnt_graded_gpa] = {bname_gpa: "", blimit_gpa: ""};

      let flag = this;
      $("#graded_row_gpa_"+this.cnt_graded_gpa).on('click',function(e) {
        flag.removeGradedRow_e_gpa(e);
      });

      $("#graded_limit_gpa_"+this.cnt_graded_gpa).on('keyup',function(e) {
        flag.isnumberGraded_e_gpa(e);
      });
      
      $("#graded_limit_gpa_"+this.cnt_graded_gpa).on('input',function(e) {
        flag.isformatgraded_gpa(e);
      });

      this.cnt_graded_gpa++;

      console.log(this.gradedArrSI_gpa);

    }

    removeGradedRow_e_gpa(e: any){                  
      var str = e.target.id;
      var id = str.charAt(str.length -1 );
      var idstr = '#this_graded_row_gpa_' + id;
      $(idstr).remove();

    }

    isnumberGraded_e_gpa(evt: any){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          var val = '#' + evt.target.id;
          $(val).val('');
      }
      if(evt.target.value == "00"){
        var value = '#' + evt.target.id;
        $(value).val('');
      }
      return true;      
    }

    isformatgraded_gpa(evt: any){
      /******** Number Formatter *******/      
      let id = evt.target.id;                    
      var value: any = evt.target.value.replaceAll(',', '');          
      // console.log(value);

      if(value.length == 4){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + value.charAt(3);
        $('#' + id).val(finalval);
      }
      if(value.length == 5){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + value.charAt(4);
        $('#' + id).val(finalval);
      }
      if(value.length == 6){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + value.charAt(5);
        $('#' + id).val(finalval);
      }
      if(value.length == 7){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + value.charAt(6);
        $('#' + id).val(finalval);
      }
      if(value.length == 8){
        var finalval: any = value.charAt(0) + "," + value.charAt(1) + value.charAt(2) + "," + value.charAt(3) + value.charAt(4) + "," + value.charAt(5) + value.charAt(6) + value.charAt(7);
        $('#' + id).val(finalval);
      }
      if(value.length == 9){
        var finalval: any = value.charAt(0) + value.charAt(1) + "," + value.charAt(2) + value.charAt(3) + "," + value.charAt(4) + value.charAt(5) + "," + value.charAt(6) + value.charAt(7) + value.charAt(8);
        $('#' + id).val(finalval);
      }
      if(value.length < 4){
        var finalval: any = value;
        $('#' + id).val(finalval);
      }
      
      /******** Number Formatter *******/
    }

    /************* END ****************/

    img_document_change(event: any) {
      if (event.target.files.length > 0) {

        this.img_document = event.target.files[0];
        // console.log(event.target.files[0]);

        $("#custom-file-upload_img_document").empty();
        $('#custom-file-upload_img_document').html(event.target.files[0]['name']);
        $('#custom-file-upload_img_document').css('text-align', 'left');

        var iSize = Math.round(event.target.files[0]['size'] / 1024);
        // console.log(iSize);
        iSize = (Math.round(iSize * 100) / 100);

        $('#custom-file-upload_img_document').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

        $('#small_img_document').empty();
        $('#small_img_document').html('');
        $('#small_img_document').append('<span style="color:#0FCA65; letter-spacing: 0.5px;">Uploaded successfully</span>');

      }

    }

    /****************************************************************/

    Double_dismemberment(e:any, val:any){
      $('#Double_dismemberment_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_Double_dismemberment_no').css('background', '#f1f1f1');
        $('.Double_dismemberment_yesdiv').css('display', 'block');
        $('.Double_dismemberment_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_Double_dismemberment_yes').css('background', '#f1f1f1');
        $('.Double_dismemberment_yesdiv').css('display', 'none');
        $('.Double_dismemberment_nodiv').css('display', 'block');
      }
    }

    do_you_have_topup(e:any, val:any){
      $('#do_you_have_topup_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_topup_no').css('background', '#f1f1f1');
        $('.do_you_have_topup_yesdiv').css('display', 'block');
        $('.do_you_have_topup_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_topup_yes').css('background', '#f1f1f1');
        $('.do_you_have_topup_yesdiv').css('display', 'none');
        $('.do_you_have_topup_nodiv').css('display', 'block');
      }
    }

    offer_spouse_PA(e:any, val:any){
      $('#offer_spouse_PA_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_spouse_PA_no').css('background', '#f1f1f1');
        $('.offer_spouse_PA_yesdiv').css('display', 'block');
        $('.offer_spouse_PA_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_spouse_PA_yes').css('background', '#f1f1f1');
        $('.offer_spouse_PA_yesdiv').css('display', 'none');
        $('.offer_spouse_PA_nodiv').css('display', 'block');
      }
    }

    do_you_have_spouse_cover(e:any, val:any){
      $('#do_you_have_spouse_cover_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_spouse_cover_no').css('background', '#f1f1f1');
        $('.do_you_have_spouse_cover_yesdiv').css('display', 'block');
        $('.do_you_have_spouse_cover_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_spouse_cover_yes').css('background', '#f1f1f1');
        $('.do_you_have_spouse_cover_yesdiv').css('display', 'none');
        $('.do_you_have_spouse_cover_nodiv').css('display', 'block');
      }
    }

    do_you_have_topupplan(e:any, val:any){
      $('#do_you_have_topupplan_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_do_you_have_topupplan_no').css('background', '#f1f1f1');
        $('.do_you_have_topupplan_yesdiv').css('display', 'block');
        $('.do_you_have_topupplan_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_do_you_have_topupplan_yes').css('background', '#f1f1f1');
        $('.do_you_have_topupplan_yesdiv').css('display', 'none');
        $('.do_you_have_topupplan_nodiv').css('display', 'block');
      }
    }

    OPD_cashless(e:any, val:any){
      $('#OPD_cashless_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_OPD_cashless_no').css('background', '#f1f1f1');
        $('.OPD_cashless_yesdiv').css('display', 'block');
        $('.OPD_cashless_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_OPD_cashless_yes').css('background', '#f1f1f1');
        $('.OPD_cashless_yesdiv').css('display', 'none');
        $('.OPD_cashless_nodiv').css('display', 'block');
      }
    }

    Preventive_Health_Check_up(e:any, val:any){
      $('#Preventive_Health_Check_up_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_Preventive_Health_Check_up_no').css('background', '#f1f1f1');
        $('.Preventive_Health_Check_up_yesdiv').css('display', 'block');
        $('.Preventive_Health_Check_up_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_Preventive_Health_Check_up_yes').css('background', '#f1f1f1');
        $('.Preventive_Health_Check_up_yesdiv').css('display', 'none');
        $('.Preventive_Health_Check_up_nodiv').css('display', 'block');
      }
    }

    Annual_Health_Check_up(e:any, val:any){
      $('#Annual_Health_Check_up_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_Annual_Health_Check_up_no').css('background', '#f1f1f1');
        $('.Annual_Health_Check_up_yesdiv').css('display', 'block');
        $('.Annual_Health_Check_up_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_Annual_Health_Check_up_yes').css('background', '#f1f1f1');
        $('.Annual_Health_Check_up_yesdiv').css('display', 'none');
        $('.Annual_Health_Check_up_nodiv').css('display', 'block');
      }
    }

    medical_facilities(e:any, val:any){
      $('#medical_facilities_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_medical_facilities_no').css('background', '#f1f1f1');
        $('.medical_facilities_yesdiv').css('display', 'block');
        $('.medical_facilities_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_medical_facilities_yes').css('background', '#f1f1f1');
        $('.medical_facilities_yesdiv').css('display', 'none');
        $('.medical_facilities_nodiv').css('display', 'block');
      }
    }

    offer_gratuity(e:any, val:any){
      $('#offer_gratuity_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_gratuity_no').css('background', '#f1f1f1');
        $('.offer_gratuity_yesdiv').css('display', 'block');
        $('.offer_gratuity_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_gratuity_yes').css('background', '#f1f1f1');
        $('.offer_gratuity_yesdiv').css('display', 'none');
        $('.offer_gratuity_nodiv').css('display', 'block');
      }
    }

    offer_gratuity_per_law(e:any, val:any){
      $('#offer_gratuity_per_law_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_gratuity_per_law_no').css('background', '#f1f1f1');
        $('.offer_gratuity_per_law_yesdiv').css('display', 'block');
        $('.offer_gratuity_per_law_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_gratuity_per_law_yes').css('background', '#f1f1f1');
        $('.offer_gratuity_per_law_yesdiv').css('display', 'none');
        $('.offer_gratuity_per_law_nodiv').css('display', 'block');
      }
    }

    offer_pension_plans(e:any, val:any){
      $('#offer_pension_plans_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_pension_plans_no').css('background', '#f1f1f1');
        $('.offer_pension_plans_yesdiv').css('display', 'block');
        $('.offer_pension_plans_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_pension_plans_yes').css('background', '#f1f1f1');
        $('.offer_pension_plans_yesdiv').css('display', 'none');
        $('.offer_pension_plans_nodiv').css('display', 'block');
      }
    }

    offer_car_leases(e:any, val:any){
      $('#offer_car_leases_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_car_leases_no').css('background', '#f1f1f1');
        $('.offer_car_leases_yesdiv').css('display', 'block');
        $('.offer_car_leases_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_car_leases_yes').css('background', '#f1f1f1');
        $('.offer_car_leases_yesdiv').css('display', 'none');
        $('.offer_car_leases_nodiv').css('display', 'block');
      }
    }

    offer_leave_encashment(e:any, val:any){
      $('#offer_leave_encashment_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_leave_encashment_no').css('background', '#f1f1f1');
        $('.offer_leave_encashment_yesdiv').css('display', 'block');
        $('.offer_leave_encashment_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_leave_encashment_yes').css('background', '#f1f1f1');
        $('.offer_leave_encashment_yesdiv').css('display', 'none');
        $('.offer_leave_encashment_nodiv').css('display', 'block');
      }
    }

    offer_canteen_facility(e:any, val:any){
      $('#offer_canteen_facility_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_canteen_facility_no').css('background', '#f1f1f1');
        $('.offer_canteen_facility_yesdiv').css('display', 'block');
        $('.offer_canteen_facility_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_canteen_facility_yes').css('background', '#f1f1f1');
        $('.offer_canteen_facility_yesdiv').css('display', 'none');
        $('.offer_canteen_facility_nodiv').css('display', 'block');
      }
    }

    offer_employee_emergency(e:any, val:any){
      $('#offer_employee_emergency_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_employee_emergency_no').css('background', '#f1f1f1');
        $('.offer_employee_emergency_yesdiv').css('display', 'block');
        $('.offer_employee_emergency_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_employee_emergency_yes').css('background', '#f1f1f1');
        $('.offer_employee_emergency_yesdiv').css('display', 'none');
        $('.offer_employee_emergency_nodiv').css('display', 'block');
      }
    }

    offer_courses(e:any, val:any){
      $('#offer_courses_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_courses_no').css('background', '#f1f1f1');
        $('.offer_courses_yesdiv').css('display', 'block');
        $('.offer_courses_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_courses_yes').css('background', '#f1f1f1');
        $('.offer_courses_yesdiv').css('display', 'none');
        $('.offer_courses_nodiv').css('display', 'block');
      }
    }

    offer_pick_drop(e:any, val:any){
      $('#offer_pick_drop_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_pick_drop_no').css('background', '#f1f1f1');
        $('.offer_pick_drop_yesdiv').css('display', 'block');
        $('.offer_pick_drop_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_pick_drop_yes').css('background', '#f1f1f1');
        $('.offer_pick_drop_yesdiv').css('display', 'none');
        $('.offer_pick_drop_nodiv').css('display', 'block');
      }
    }

    offer_Employee_stock(e:any, val:any){
      $('#offer_Employee_stock_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_Employee_stock_no').css('background', '#f1f1f1');
        $('.offer_Employee_stock_yesdiv').css('display', 'block');
        $('.offer_Employee_stock_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_Employee_stock_yes').css('background', '#f1f1f1');
        $('.offer_Employee_stock_yesdiv').css('display', 'none');
        $('.offer_Employee_stock_nodiv').css('display', 'block');
      }
    }

    offer_Gadget_allowance(e:any, val:any){
      $('#offer_Gadget_allowance_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_Gadget_allowance_no').css('background', '#f1f1f1');
        $('.offer_Gadget_allowance_yesdiv').css('display', 'block');
        $('.offer_Gadget_allowance_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_Gadget_allowance_yes').css('background', '#f1f1f1');
        $('.offer_Gadget_allowance_yesdiv').css('display', 'none');
        $('.offer_Gadget_allowance_nodiv').css('display', 'block');
      }
    }

    offer_flexi_work(e:any, val:any){
      $('#offer_flexi_work_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_flexi_work_no').css('background', '#f1f1f1');
        $('.offer_flexi_work_yesdiv').css('display', 'block');
        $('.offer_flexi_work_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_flexi_work_yes').css('background', '#f1f1f1');
        $('.offer_flexi_work_yesdiv').css('display', 'none');
        $('.offer_flexi_work_nodiv').css('display', 'block');
      }
    }

    offer_flexi_pay(e:any, val:any){
      $('#offer_flexi_pay_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_flexi_pay_no').css('background', '#f1f1f1');
        $('.offer_flexi_pay_yesdiv').css('display', 'block');
        $('.offer_flexi_pay_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_flexi_pay_yes').css('background', '#f1f1f1');
        $('.offer_flexi_pay_yesdiv').css('display', 'none');
        $('.offer_flexi_pay_nodiv').css('display', 'block');
      }
    }

    offer_sabbatical_benefits(e:any, val:any){
      $('#offer_sabbatical_benefits_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_sabbatical_benefits_no').css('background', '#f1f1f1');
        $('.offer_sabbatical_benefits_yesdiv').css('display', 'block');
        $('.offer_sabbatical_benefits_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_sabbatical_benefits_yes').css('background', '#f1f1f1');
        $('.offer_sabbatical_benefits_yesdiv').css('display', 'none');
        $('.offer_sabbatical_benefits_nodiv').css('display', 'block');
      }
    }

    offer_creche_facilities(e:any, val:any){
      $('#offer_creche_facilities_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_creche_facilities_no').css('background', '#f1f1f1');
        $('.offer_creche_facilities_yesdiv').css('display', 'block');
        $('.offer_creche_facilities_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_creche_facilities_yes').css('background', '#f1f1f1');
        $('.offer_creche_facilities_yesdiv').css('display', 'none');
        $('.offer_creche_facilities_nodiv').css('display', 'block');
      }
    }

    offer_menstural_leave(e:any, val:any){
      $('#offer_menstural_leave_error').css('display', 'none');            

      if(val == 'yes'){
        $('#'+e.target.id+'_'+val).css('background', '#217e2b');
        $('#radio-one_offer_menstural_leave_no').css('background', '#f1f1f1');
        $('.offer_menstural_leave_yesdiv').css('display', 'block');
        $('.offer_menstural_leave_nodiv').css('display', 'none');
      }else{
        $('#'+e.target.id+'_'+val).css('background', '#ff4052');
        $('#radio-two_offer_menstural_leave_yes').css('background', '#f1f1f1');
        $('.offer_menstural_leave_yesdiv').css('display', 'none');
        $('.offer_menstural_leave_nodiv').css('display', 'block');
      }
    }

}
