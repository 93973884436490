<div class="bs-stepper bg-white my-4 mb-5 rounded p-3 pt-1">
  <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title mt-2">Dashboard</h3>
    <hr class="mt-0">
    <!-- <iframe
      src="https://app.powerbi.com/reportEmbed?reportId=31b21044-4fe3-43a9-9289-43946b172f2a&autoAuth=true&ctid=c0024443-ea64-4eb7-a468-649d6af7e45b"
      style="width: 100%; height:500px;">
    </iframe> -->
  
    <iframe title="RPT1 - Page 1" style="width: 100%; height:500px;" src="https://app.powerbi.com/view?r=eyJrIjoiYTA3ZWQ1M2QtZTcyYi00NjIwLWI4MDEtODg5Y2E2ZWEyZGJkIiwidCI6ImMwMDI0NDQzLWVhNjQtNGViNy1hNDY4LTY0OWQ2YWY3ZTQ1YiJ9" frameborder="0" allowFullScreen="true"></iframe>
  
  
  </div>