<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <div class="flagDiv">
    
        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">
                     1   
                    </span> -->
                    <img src="../../../assets/website/icon/basic.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">Basic</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">2</span> -->
                    <img src="../../../assets/website/icon/gmc.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">GMC</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">3</span> -->
                    <img src="../../../assets/website/icon/gpa.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">GPA</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">4</span> -->
                    <img src="../../../assets/website/icon/gtl.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">GTL</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-5">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">5</span> -->
                    <img src="../../../assets/website/icon/health.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">WellBeing</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-6">
                <button class="step-trigger">
                    <!-- <span class="bs-stepper-circle">6</span> -->
                    <img src="../../../assets/website/icon/retrival.png" style="width:25px; height: 25px" />
                    <span class="bs-stepper-label">Retrials</span>
                </button>
            </div>
        </div>
        
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
        <div [ngClass]="{'step5': stepcomplete === 'step5'}"></div>
        <div [ngClass]="{'step6': stepcomplete === 'step6'}"></div>
    </div>

    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Company Info - Section - A </h3>
                    <!-- <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div> -->
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                        

                        <!--------Section 1------->                       
                        <div class="form-group col-sm-4">
                            <label class="bs-form-label" for="company_name">Company Name *</label>
                            <input type="text" class="form-control" formControlName="company_name" id="company_name" placeholder="Company Name" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.company_name.errors}"
                            required />
                            <ng-container
                            *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.company_name.errors">
                            <p class="error">
                                Company name is required
                            </p>
                            </ng-container>
                        </div>

                        <div class="col-sm-4 form-group">
                            <label class="bs-form-label" for="industry">Industry *</label>
                            <select class="form-select" formControlName="industry" id="industry" 
                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.industry.errors}"
                            required>
                                <option value="" selected>Select</option>
                                <optgroup label="AUTO & ANCILLIARY">
                                    <option value="Automobile Industry">Automobile Industry</option>
                                    <option value="Aviation Industry">Aviation Industry </option>
                                    <option value="Tractor Industry">Tractor Industry</option>
                                </optgroup>
                            
                                <optgroup label="FINANCIAL & BANKING">
                                    <option value="Banking Industry">Banking Industry</option>
                                    <option value="Insurance Industry">Insurance Industry</option>
                                    <option value="Mutual Fund Industry">Mutual Fund Industry</option>
                                    <option value="Real Estate Industry">Real Estate Industry</option>
                                </optgroup>
                            
                                <optgroup label="CONSUMER DURABLES">
                                    <option value="Furniture Industry">Furniture Industry</option>
                                    <option value="Jute Industry">Jute Industry</option>
                                    <option value="Leather Industry">Leather Industry</option>
                                    <option value="Paper Industry">Paper Industry</option>
                                    <option value="Plastic Industry">Plastic Industry</option>
                                    <option value="Rubber Industry">Rubber Industry</option>
                                    <option value="Silk Industry">Silk Industry</option>
                                    <option value="Television Industry">Television Industry</option>
                                </optgroup>

                                <optgroup label="TEXTILES">
                                    <option value="Textile Industry">Textile Industry</option>
                                    <option value="Garment Industry">Garment Industry</option>
                                    <option value="Weaving Industry">Weaving Industry</option>
                                </optgroup>

                                <optgroup label="FMCG - FAST MOVING CONSUMER GOODS">
                                    <option value="Biscuit Industry">Biscuit Industry</option>
                                    <option value="Soap Industry">Soap Industry</option>
                                </optgroup>

                                <optgroup label="HEALTH CARE">
                                    <option value="Bio technology Industry">Bio technology Industry</option>
                                    <option value="Health Care Industry">Health Care Industry</option>
                                    <option value="Pharamaceutical Industry">Pharamaceutical Industry</option>
                                </optgroup>

                                <optgroup label="INFORMATION TECHNOLOGY & COMMUNICATIION">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="METAL & MINING">
                                    <option value="Aluminium Industry">Aluminium Industry</option>
                                    <option value="Copper Industry">Copper Industry</option>
                                    <option value="Diamond Industry">Diamond Industry</option>
                                    <option value="Granite Industry">Granite Industry</option>
                                    <option value="Mining Industry">Mining Industry</option>
                                    <option value="Pearl Industry">Pearl Industry</option>
                                    <option value="Zinc Industry">Zinc Industry</option>
                                    <option value="Steel Industry">Steel Industry</option>
                                </optgroup>

                                <optgroup label="OIL & GAS">
                                    <option value="IT Industry">IT Industry</option>
                                </optgroup>

                                <optgroup label="POWER">
                                    <option value="Power Industry">Power Industry</option>
                                </optgroup>

                                <optgroup label="RETAILING">
                                    <option value="Chocolate Industry">Chocolate Industry</option>
                                    <option value="Cosmetic Industry">Cosmetic Industry</option>
                                    <option value="Food Processing Industry">Food Processing Industry</option>
                                    <option value="Jewellery Industry">Jewellery Industry</option>
                                    <option value="Music Industry">Music Industry</option>
                                    <option value="Retail Industry">Retail Industry</option>
                                    <option value="Toy Industry">Toy Industry</option>
                                </optgroup>

                                <optgroup label="SERVICE SECTOR">
                                    <option value="Advertising Industry">Advertising Industry</option>
                                    <option value="Electronic Industry">Electronic Industry</option>
                                    <option value="Hotel Industry">Hotel Industry</option>
                                    <option value="Railway Industry">Railway Industry</option>
                                    <option value="Shipping Industry">Shipping Industry</option>
                                    <option value="Telecom Industry">Telecom Industry</option>
                                    <option value="Tourism Industry">Tourism Industry</option>
                                </optgroup>

                                <optgroup label="AGRICULTURE-BASED">
                                    <option value="Agricultural Industry">Agricultural Industry</option>
                                    <option value="Tobacco Industry">Tobacco Industry</option>
                                    <option value="Dairy Industry">Dairy Industry</option>
                                    <option value="Cotton Industry">Cotton Industry</option>
                                    <option value="Sugar Industry">Sugar Industry</option>
                                    <option value="Tea Industry">Tea Industry</option>
                                    <option value="Poultry Industry">Poultry Industry</option>
                                </optgroup>

                                <optgroup label="OTHER INDUSTRIES">
                                    <option value="Cement Industry">Cement Industry</option>
                                    <option value="Coir Industry">Coir Industry</option>
                                    <option value="Construction Industry">Construction Industry</option>
                                    <option value="Cottage Industry">Cottage Industry</option>
                                    <option value="Fashion Industry">Fashion Industry</option>
                                    <option value="Fertilizer Industry">Fertilizer Industry</option>
                                    <option value="Film Industry">Film Industry</option>
                                    <option value="Paint Industry">Paint Industry</option>
                                    <option value="Printing Industry">Printing Industry</option>
                                    <option value="Solar Industry">Solar Industry</option>
                                    <option value="Turbine Industry">Turbine Industry</option>
                                </optgroup>

                            </select>
                            <ng-container
                                *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.industry.errors">
                                <p class="error">
                                Industry is required
                                </p>
                            </ng-container>
                        </div>

                        <div class="col-sm-4 form-group">
                            <label class="bs-form-label" for="employee_headcount">Company Headcount *</label>
                            <select class="form-select" formControlName="employee_headcount" id="employee_headcount" 
                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.employee_headcount.errors}"
                            required>
                                <option value="" selected>Select</option>
                                <option value="Upto 100 (usually lifes will be multiply by 4 or 5)">Upto 100 (usually lifes will be multiply by 4 or 5)</option>
                                <option value="101 to 250">101 to 250</option>
                                <option value="251 to 500">251 to 500</option>
                                <option value="501 to 1000">501 to 1000</option>
                                <option value="1001 to 2000">1001 to 2000</option>
                                <option value="2001 to 5000">2001 to 5000</option>
                                <option value="5001 to 10000">5001 to 10000</option>
                                <option value="10001 to 25000">10001 to 25000</option>
                                <option value="25001 and more">25001 and more</option>
                            </select>                                
                            <ng-container
                                *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.employee_headcount.errors">
                                <p class="error">
                                Company Headcount is required
                                </p>
                            </ng-container>
                        </div> 

                        <div class="col-sm-4 form-group mt-3">
                            <label class="bs-form-label" for="turnover">Turnover / Revenue</label>
                            <select class="form-select" id="turnover" formControlName="turnover">
                                <option value="" selected>Select</option>
                                <option value="Upto 10 Cr.">Upto 10 Cr.</option>
                                <option value="11 Cr to 100 Cr.">11 Cr to 100 Cr.</option>
                                <option value="101 Cr to 250 Cr.">101 Cr to 250 Cr.</option>
                                <option value="251 Cr to 1,000 Cr.">251 Cr to 1,000 Cr.</option>
                                <option value="1,001 Cr to 5,000 Cr.">1,001 Cr to 5,000 Cr.</option>
                                <option value="5,001 Cr to 10,000 Cr.">5,001 Cr to 10,000 Cr.</option>
                                <option value="Above 10,000 Cr.">Above 10,000 Cr.</option>
                            </select>
                        </div> 

                        <div class="col-sm-4 form-group mt-3">
                            <div class="mb-2 bs-form-label">Company Type * <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>
                            <input type="radio" class="form-radio" value="yes" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> MNC </span>
                            &nbsp; 
                            <input type="radio" class="form-radio" value="no" formControlName="MNC" id="MNC" /> <span class="bs-radio-label"> Non-MNC </span>
                        </div>
                                                
                        <hr class="my-3">
                        <!--------END------->                                                                       

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">GMC - Section - B</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row" id="gmc_form">                                        
                    <div class="form-group">
                        <label class="bs-form-label" for="do_you_have_gmc">Do You Offer Group Health insurance benefits to your employee? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_gmc_div')" (mouseleave)="mouseLeave($event, 'do_you_have_gmc_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="do_you_have_gmc_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do You Offer Group Health insurance benefits to your employee?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_do_you_have_gmc" formControlName="do_you_have_gmc" (click)="do_you_have_gmc($event, 'no')"  name="do_you_have_gmc" value="No" />
                            <label for="radio-one_do_you_have_gmc" id="radio-one_do_you_have_gmc_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_do_you_have_gmc" formControlName="do_you_have_gmc" (click)="do_you_have_gmc($event, 'yes')" name="do_you_have_gmc" value="Yes" />
                            <label for="radio-two_do_you_have_gmc" id="radio-two_do_you_have_gmc_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="do_you_have_gmc_error" style="display:none">
                            This field is required
                        </p>
                    </div>                   
                    
                    <div class="col-sm-12 do_you_have_gmc_yesdiv" style="display: none">
                        <div class="row">
                            <div class="col-sm-4 form-group mt-3"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees *</label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-4 form-group mt-3"> 
                                <label class="bs-form-label" for="family_contruct">Family Construct *</label>
                                <select class="form-select" formControlName="family_contruct" id="family_contruct"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.family_contruct.errors}" 
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Employee Only - E">Employee Only - E</option>
                                    <option value="Employee + Spouse + 2 Children - ESC">Employee + Spouse + 2 Children - ESC</option>
                                    <option value="Employee + Spouse + 2 Children + 2 Parents/Parents in law - ESCP">Employee + Spouse + 2 Children + 2 Parents/Parents in law - ESCP</option>
                                    <option value="Employee + Spouse + 2 Children + Voluntary Parents Cover - ESC_VP">Employee + Spouse + 2 Children + Voluntary Parents Cover - ESC_VP</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.family_contruct.errors">
                                    <p class="error">
                                    This field is required
                                    </p>
                                </ng-container>
                            </div>
                                                       
                            <div class="form-check col-sm-4 mt-3">                                
                                <label class="bs-form-label" for="LGBTQ">LGBTQ & Domestic Partner Cover *</label>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_LGBTQ" formControlName="LGBTQ" name="LGBTQ" value="No" (click)="yes_no_color_func($event, 'no')" />
                                    <label for="radio-one_LGBTQ" id="radio-one_LGBTQ_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_LGBTQ" formControlName="LGBTQ" name="LGBTQ" value="Yes" (click)="yes_no_color_func($event, 'yes')" />
									<label for="radio-two_LGBTQ" id="radio-two_LGBTQ_yes" class="bs-form-label">Yes</label>										
                                </div>
                            </div>                                                        

                            <!--------------- SI --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Sum Insured</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-3 form-group purple">
                                <div class="form-group">
                                    <label class="bs-form-label" for="sum_insured_type">Sum insured type <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_insured_type_div')" (mouseleave)="mouseLeave($event, 'sum_insured_type_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="sum_insured_type_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Sum insured type</p>
                                    </div>
                                </div>
                                <div class="switch-field floaternon">
                                    <input type="radio" id="radio-one_sum_insured_type" formControlName="sum_insured_type" name="sum_insured_type" value="Floater" />
                                    <label for="radio-one_sum_insured_type" class="bs-form-label">Floater</label>
                                    <input type="radio" id="radio-two_sum_insured_type" formControlName="sum_insured_type" name="sum_insured_type" value="Not Floater" />
                                    <label for="radio-two_sum_insured_type" class="bs-form-label">Not Floater</label>	
                                </div>
                            </div>                                

                            <div class="col-sm-6 form-group purple">
                                <div class="form-group">
                                    <label class="bs-form-label" for="sum_insured_approach">Sum insured approach <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_insured_approach_div')" (mouseleave)="mouseLeave($event, 'sum_insured_approach_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="sum_insured_approach_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Sum insured approach</p>
                                    </div>
                                </div>
                                <div class="switch-field unigraded">
                                    <input type="radio" id="radio-one_sum_insured_approach" formControlName="sum_insured_approach" name="sum_insured_approach" value="Uniform" (change)="sumapproach('Uniform')" />
                                    <label for="radio-one_sum_insured_approach" class="bs-form-label">Uniform</label>
                                    <input type="radio" id="radio-two_sum_insured_approach" formControlName="sum_insured_approach" name="sum_insured_approach" value="Graded" (change)="sumapproach('Graded')" />
                                    <label for="radio-two_sum_insured_approach" class="bs-form-label">Graded</label>	
                                </div>
                                <p class="error" id="sum_insured_approach_error" style="display:none">
                                    Sum insured approach is required
                                </p>
                            </div>

                            <div class="col-sm-12 div_sum_insured_approach" style="display: none">
                                <div class="row append_sum_insured_approach">

                                </div>

                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="sumapproach('Graded')" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add Amount</span>
                                    </label>
                                </div>

                            </div>

                            <div class="col-sm-12 div_sum_insured_approach_uniform" style="display: none">
                              <div class="row append_sum_insured_approach_uniform">

                              </div>

                            </div>                            
                            
                            <!--------------- WAITING --------------->

                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Waiting Period</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="thirty_days_waiting_period">30 Days Waiting Period <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'thirty_days_waiting_period_div')" (mouseleave)="mouseLeave($event, 'thirty_days_waiting_period_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="thirty_days_waiting_period_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">30 Days Waiting Period</p>
                                    </div>
                                </div>                                    
                                <div class="switch-field wWaiting">                                        
                                    <input type="radio" id="radio-two_thirty_days_waiting_period" formControlName="thirty_days_waiting_period" name="thirty_days_waiting_period" value="Not Applicable" (change)="checkradio('divthirty_days_waiting_period', 'Covered')" />
                                    <label for="radio-two_thirty_days_waiting_period" id="divthirty_days_waiting_period_Covered" class="bs-form-label">Not Applicable</label>	
                                    <input type="radio" id="radio-one_thirty_days_waiting_period" formControlName="thirty_days_waiting_period" name="thirty_days_waiting_period" value="Applicable" (change)="checkradio('divthirty_days_waiting_period', 'NotCovered')" />
                                    <label for="radio-one_thirty_days_waiting_period" id="divthirty_days_waiting_period_NotCovered" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="one_and_half_days_waiting_period">1 Year or 2 Years Waiting Period <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'one_and_half_days_waiting_period_div')" (mouseleave)="mouseLeave($event, 'one_and_half_days_waiting_period_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="one_and_half_days_waiting_period_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">1 Year or 2 Years Waiting Period</p>
                                    </div>
                                </div>
                                <div class="switch-field wWaiting">                                        
                                    <input type="radio" id="radio-two_one_and_half_days_waiting_period" formControlName="one_and_half_days_waiting_period" name="one_and_half_days_waiting_period" value="Not Applicable" (change)="checkradio('divone_and_half_days_waiting_period', 'Covered')" />
                                    <label for="radio-two_one_and_half_days_waiting_period" id="divone_and_half_days_waiting_period_Covered" class="bs-form-label">Not Applicable</label>	
                                    <input type="radio" id="radio-one_one_and_half_days_waiting_period" formControlName="one_and_half_days_waiting_period" name="one_and_half_days_waiting_period" value="Applicable" (change)="checkradio('divone_and_half_days_waiting_period', 'NotCovered')" />
                                    <label for="radio-one_one_and_half_days_waiting_period" id="divone_and_half_days_waiting_period_NotCovered" class="bs-form-label">Applicable</label>
                                </div>
                            </div>
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="pre_existing_condition">Pre-Existing Condtion <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'pre_existing_condition_div')" (mouseleave)="mouseLeave($event, 'pre_existing_condition_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="pre_existing_condition_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Pre-Existing Condtion</p>
                                    </div>
                                </div>
                                <div class="switch-field wWaiting">                                        
                                    <input type="radio" id="radio-two_pre_existing_condition" formControlName="pre_existing_condition" name="pre_existing_condition" value="Not Applicable" (change)="checkradio('divpre_existing_condition', 'Covered')" />
                                    <label for="radio-two_pre_existing_condition" id="divpre_existing_condition_Covered" class="bs-form-label">Not Applicable</label>	
                                    <input type="radio" id="radio-one_pre_existing_condition" formControlName="pre_existing_condition" name="pre_existing_condition" value="Applicable" (change)="checkradio('divpre_existing_condition', 'NotCovered')" />
                                    <label for="radio-one_pre_existing_condition" id="divpre_existing_condition_NotCovered" class="bs-form-label">Applicable</label>
                                </div>
                            </div>

                            <!---------------  HOSPI --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Hospitalisation Details</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-3 form-group">
                                <label class="bs-form-label" for="room_rent_condition_one">Normal Room Rent *</label>                                                
                                <select class="form-select room_rent_condition_one" formControlName="room_rent_condition_one" id="room_rent_condition_one" style="line-height: 1.7;" (change)="checkopt_table_normal('room_rent_condition_one', 'hosp')"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.room_rent_condition_one.errors}"
                                required>
                                    <option value="">Select</option>
                                    <option value="1% of SI for Normal">1% of SI for Normal</option>
                                    <option value="2% of SI for Normal">2% of SI for Normal</option>
                                    <option value="3% of SI for Normal">3% of SI for Normal</option>
                                    <option value="4% of SI for Normal">4% of SI for Normal</option>
                                    <option value="5% of SI for Normal">5% of SI for Normal</option>
                                    <option value="Single private AC Room">Single private AC Room</option>
                                    <option value="No Capping">No Capping</option>
                                    <option value="Others Please specify">Others Please specify</option>
                                    <option value="NA">NA</option>
                                </select> 
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.room_rent_condition_one.errors">
                                    <p class="error">
                                    Pre and Post hospitalisation is required
                                    </p>
                                </ng-container>                               
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group" id="checkopt_table_ICU_room_rent">
                                    <label class="bs-form-label">ICU Room Rent</label>
                                    <select class="form-select ICU_room_rent" formControlName="ICU_room_rent" id="ICU_room_rent" style="line-height: 1.7;" (change)="checkopt_table('ICU_room_rent', 'ICU')">
                                        <option value="">Select</option>
                                        <option value="2% of SI for ICU">2% of SI for ICU</option>
                                        <option value="3% of SI for ICU">3% of SI for ICU</option>
                                        <option value="4% of SI for ICU">4% of SI for ICU</option>
                                        <option value="5% of SI for ICU">5% of SI for ICU</option>
                                        <option value="No Capping">No Capping</option>
                                        <option value="Others Please specify">Others Please specify</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-sm-4 form-group">
                                <label class="bs-form-label" for="pre_post_hospitalisation">Pre and Post hospitalisation cover *</label>
                                <select class="form-select" formControlName="pre_post_hospitalisation" id="pre_post_hospitalisation" style="line-height: 1.7;" (change)="checkopt('pre_post_hospitalisation')"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.pre_post_hospitalisation.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="30 days pre and 60 days post hospitalization">30 days pre and 60 days post hospitalization</option>
                                    <option value="45 days pre and 90 days post hospitalization">45 days pre and 90 days post hospitalization</option>
                                    <option value="60 days pre and 120 days post hospitalization">60 days pre and 120 days post hospitalization</option>
                                    <option value="Others Please specify">Others Please specify</option>
                                    <option value="NA">NA</option>
                                    <!-- <option *ngFor="let country of countryCodes" [value]="country.abbreviation">{{ country.abbreviation }}</option> -->
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.pre_post_hospitalisation.errors">
                                    <p class="error">
                                    Pre and Post hospitalisation is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-12 row form-group mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="day_care_procedures">Day care procedures <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'day_care_procedures_div')" (mouseleave)="mouseLeave($event, 'day_care_procedures_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="day_care_procedures_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Day care procedures</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_day_care_procedures" formControlName="day_care_procedures" name="day_care_procedures" name="day_care_procedures" value="Not Covered" checked (change)="checkradio('divday_care_procedures_limit', 'NotCovered')" />
                                        <label for="radio-two_day_care_procedures" id="divday_care_procedures_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_day_care_procedures" formControlName="day_care_procedures" name="day_care_procedures" value="Covered" (change)="checkradio_c('divday_care_procedures_limit', 'Covered')" />
                                        <label for="radio-one_day_care_procedures" id="divday_care_procedures_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divday_care_procedures_limit" style="padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="day_care_procedures_limit_perc" id="day_care_procedures_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="day_care_procedures_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>                                        

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_day_care_procedures" value="min_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','yes')" />
                                                <label for="min_day_care_procedures" >Min</label>   

                                                <input class="hiddenRadio" id="na_day_care_procedures" value="na_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','no')" />
                                                <label for="na_day_care_procedures" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_day_care_procedures" value="max_day_care_procedures" formControlName="min_max_day_care_procedures" name="min_max_day_care_procedures" type="radio" (click)="check_limit_toggle('day_care_procedures','yes')" />
                                                <label for="max_day_care_procedures" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 day_care_procedures_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="day_care_procedures_limit_value" id="day_care_procedures_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="day_care_procedures_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>                                        

                                    </div>
                                </div>                                
                            </div>

                            <div class="col-sm-12 row form-group mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="domiciliary_hospitalisation">Domiciliary hospitalisation <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'domiciliary_hospitalisation_div')" (mouseleave)="mouseLeave($event, 'domiciliary_hospitalisation_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="domiciliary_hospitalisation_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Domiciliary hospitalisation</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_domiciliary_hospitalisation" formControlName="domiciliary_hospitalisation" name="domiciliary_hospitalisation" name="domiciliary_hospitalisation" value="Not Covered" checked (change)="checkradio('divdomiciliary_hospitalisation_limit', 'NotCovered')" />
                                        <label for="radio-two_domiciliary_hospitalisation" id="divdomiciliary_hospitalisation_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_domiciliary_hospitalisation" formControlName="domiciliary_hospitalisation" name="domiciliary_hospitalisation" value="Covered" (change)="checkradio_c('divdomiciliary_hospitalisation_limit', 'Covered')" />
                                        <label for="radio-one_domiciliary_hospitalisation" id="divdomiciliary_hospitalisation_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divdomiciliary_hospitalisation_limit" style="padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="domiciliary_hospitalisation_limit_perc" id="domiciliary_hospitalisation_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="domiciliary_hospitalisation_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_domiciliary_hospitalisation" value="min_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','yes')" />
                                                <label for="min_domiciliary_hospitalisation" >Min</label>   

                                                <input class="hiddenRadio" id="na_domiciliary_hospitalisation" value="na_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','no')" />
                                                <label for="na_domiciliary_hospitalisation" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_domiciliary_hospitalisation" value="max_domiciliary_hospitalisation" formControlName="min_max_domiciliary_hospitalisation" name="min_max_domiciliary_hospitalisation" type="radio" (click)="check_limit_toggle('domiciliary_hospitalisation','yes')" />
                                                <label for="max_domiciliary_hospitalisation" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 domiciliary_hospitalisation_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="domiciliary_hospitalisation_limit_value" id="domiciliary_hospitalisation_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="domiciliary_hospitalisation_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>                            

                            <!--------------- MATERNITY --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Maternity Details</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-12 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="do_you_want_maternity">Do you want maternity cover? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_want_maternity_div')" (mouseleave)="mouseLeave($event, 'do_you_want_maternity_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="do_you_want_maternity_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Do you want maternity cover?</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_do_you_want_maternity" formControlName="do_you_want_maternity" (click)="do_you_want_maternity($event, 'no')"  name="do_you_want_maternity" value="No" />
                                    <label for="radio-one_do_you_want_maternity" id="radio-one_do_you_want_maternity_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_do_you_want_maternity" formControlName="do_you_want_maternity" (click)="do_you_want_maternity($event, 'yes')" name="do_you_want_maternity" value="Yes" />
                                    <label for="radio-two_do_you_want_maternity" id="radio-two_do_you_want_maternity_yes" class="bs-form-label">Yes</label>	
                                </div>
                                <p class="error" id="do_you_want_maternity_error" style="display:none">
                                    Maternity cover is required field
                                </p>
                            </div>

                            <div class="col-sm-12 do_you_want_maternity_nodiv">
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group px-0">
                                    <label class="bs-form-label" for="maternity_related_complications_no">Maternity Related Complications <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_related_complications_no_div')" (mouseleave)="mouseLeave($event, 'maternity_related_complications_no_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="maternity_related_complications_no_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Maternity Related Complications</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_maternity_related_complications_no" formControlName="maternity_related_complications_no" name="maternity_related_complications_no" value="Not Covered" (change)="checkradio_class('divmaternity_related_complications_no', 'NotCovered')" />
                                            <label for="radio-two_maternity_related_complications_no" id="divmaternity_related_complications_no_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_maternity_related_complications_no" formControlName="maternity_related_complications_no" name="maternity_related_complications_no" value="Covered" (change)="checkradio_class('divmaternity_related_complications_no', 'Covered')" />
                                            <label for="radio-one_maternity_related_complications_no" id="divmaternity_related_complications_no_Covered" class="bs-form-label">Covered</label>                                           	
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group divmaternity_related_complications_no purple" style="display: none">
                                        <label class="bs-form-label" for="two_maternity_related_complications_no_isCover">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height" style="width:257px">
                                            <input type="radio" id="radio-two_maternity_related_complications_no_isCover" formControlName="maternity_related_complications_no_isCover" name="maternity_related_complications_no_isCover" value="within maternity limit" />
                                            <label for="radio-two_maternity_related_complications_no_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_maternity_related_complications_no_isCover" formControlName="maternity_related_complications_no_isCover" name="maternity_related_complications_no_isCover" value="Over and above" />
                                            <label for="radio-one_maternity_related_complications_no_isCover" class="bs-form-label">SI Limit</label>                                        	
                                        </div>										
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 row do_you_want_maternity_yesdiv" style="display: none">
                                                             
                                <div class="col-sm-12 row form-group mt-3">
                                    <div class="col-sm-4 form-group">
                                    <label class="bs-form-label" for="room_rent_natal_cover">Pre and post Natal cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'room_rent_natal_cover_div')" (mouseleave)="mouseLeave($event, 'room_rent_natal_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="room_rent_natal_cover_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Pre and post Natal cover</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_room_rent_natal_cover" formControlName="room_rent_natal_cover" name="room_rent_natal_cover" value="Not Covered" (change)="checkradio_class('divroom_rent_natal_cover', 'NotCovered')" />
                                            <label for="radio-two_room_rent_natal_cover" id="divroom_rent_natal_cover_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_room_rent_natal_cover" formControlName="room_rent_natal_cover" name="room_rent_natal_cover" value="Covered" (change)="checkradio_class('divroom_rent_natal_cover', 'Covered')" />
                                            <label for="radio-one_room_rent_natal_cover" id="divroom_rent_natal_cover_Covered" class="bs-form-label">Covered</label>                                              	
                                        </div>
                                    </div>
                                    <div class="col-sm-5 form-group divroom_rent_natal_cover purple" style="display: none">
                                        <label class="bs-form-label" for="room_rent_natal_cover_isCover">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height matcovered2">
                                            <input type="radio" id="radio-two_room_rent_natal_cover_isCover" formControlName="room_rent_natal_cover_isCover" name="room_rent_natal_cover_isCover" value="within maternity limit" />
                                            <label for="radio-two_room_rent_natal_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_room_rent_natal_cover_isCover" formControlName="room_rent_natal_cover_isCover" name="room_rent_natal_cover_isCover" value="Over and above" />
                                            <label for="radio-one_room_rent_natal_cover_isCover" class="bs-form-label">Over and Above</label>                                        	
                                        </div>		                                            								
                                    </div>
                                    <div class="col-sm-3 form-group divroom_rent_natal_cover" style="display: none">
                                        <label class="bs-form-label" for="room_rent_natal_cover_limit">Limit *</label>
                                        <input type="text" class="form-control" formControlName="room_rent_natal_cover_limit" id="room_rent_natal_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />                                            
                                        <p class="error" id="room_rent_natal_cover_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row">
                                    <div class="col-sm-3 form-group mt-3">
                                        <label class="bs-form-label" for="normal_delivery_limit_maternity">Normal delivery limit</label>                                                
                                        <input type="text" class="form-control" formControlName="normal_delivery_limit_maternity" id="normal_delivery_limit_maternity" (keypress)="isNumber($event)" (input)="isformat($event)" />                                                                                                                
                                        <p class="error" id="normal_delivery_limit_maternity_error" style="display:none">
                                            Normal delivery limit is required
                                        </p>
                                    </div>
                                                                        
                                    <div class="col-sm-3 form-group mt-3">
                                        <label class="bs-form-label" for="c_section_limit_maternity">C section limit</label>                                                
                                        <input type="text" class="form-control" formControlName="c_section_limit_maternity" id="c_section_limit_maternity" (keypress)="isNumber($event)" (input)="isformat($event)" />                                                                                                                
                                        <p class="error" id="c_section_limit_maternity_error" style="display:none">
                                            C section limit is required
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group mt-3">
                                    <div class="col-sm-4 form-group">
                                    <label class="bs-form-label" for="bay_day_one">Baby Day one <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'bay_day_one_div')" (mouseleave)="mouseLeave($event, 'bay_day_one_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="bay_day_one_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Baby day one</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_bay_day_one" formControlName="bay_day_one" name="bay_day_one" value="Not Covered" (change)="checkradio_class('divbay_day_one', 'NotCovered')" />
                                            <label for="radio-two_bay_day_one" id="divbay_day_one_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_bay_day_one" formControlName="bay_day_one" name="bay_day_one" value="Covered" (change)="checkradio_class('divbay_day_one', 'Covered')" />
                                            <label for="radio-one_bay_day_one" id="divbay_day_one_Covered" class="bs-form-label">Covered</label>                                              	
                                        </div>
                                    </div>
                                    <div class="col-sm-5 form-group divbay_day_one purple" style="display: none">
                                        <label class="bs-form-label" for="bay_day_one_isCover">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height matcovered2">
                                            <input type="radio" id="radio-two_bay_day_one_isCover" formControlName="bay_day_one_isCover" name="bay_day_one_isCover" value="within maternity limit" />
                                            <label for="radio-two_bay_day_one_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_bay_day_one_isCover" formControlName="bay_day_one_isCover" name="bay_day_one_isCover" value="Over and above" />
                                            <label for="radio-one_bay_day_one_isCover" class="bs-form-label">Over and Above</label>                                        	                                                										
                                        </div>                            
                                    </div>
                                    <div class="col-sm-3 form-group divbay_day_one" style="display: none">
                                        <label class="bs-form-label" for="baby_specific_covered_limit">Limit *</label>
                                        <input type="text" class="form-control" formControlName="baby_specific_covered_limit" id="baby_specific_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="baby_specific_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group mt-3">
                                    <div class="col-sm-4 form-group">
                                    <label class="bs-form-label" for="infertility_cover">Infertility Cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'infertility_cover_div')" (mouseleave)="mouseLeave($event, 'infertility_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="infertility_cover_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Infertility cover</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_infertility_cover" formControlName="infertility_cover" name="infertility_cover" value="Not Covered" (change)="checkradio_class('divinfertility_cover', 'NotCovered')" />
                                            <label for="radio-two_infertility_cover" id="divinfertility_cover_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_infertility_cover" formControlName="infertility_cover" name="infertility_cover" value="Covered" (change)="checkradio_class('divinfertility_cover', 'Covered')" />
                                            <label for="radio-one_infertility_cover" id="divinfertility_cover_Covered" class="bs-form-label">Covered</label>                                        	
                                        </div>
                                    </div>
                                    <div class="col-sm-5 form-group divinfertility_cover purple" style="display: none">
                                        <label class="bs-form-label" for="infertility_cover_isCover">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height matcovered2">
                                            <input type="radio" id="radio-two_infertility_cover_isCover" formControlName="infertility_cover_isCover" name="infertility_cover_isCover" value="within maternity limit" />
                                            <label for="radio-two_infertility_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_infertility_cover_isCover" formControlName="infertility_cover_isCover" name="infertility_cover_isCover" value="Over and above" />
                                            <label for="radio-one_infertility_cover_isCover" class="bs-form-label">Over and Above</label>                                        	
                                        </div>										
                                    </div>
                                    <div class="col-sm-3 form-group divinfertility_cover" style="display: none">
                                        <label class="bs-form-label" for="infertility_covered_limit">Limit *</label>
                                        <input type="text" class="form-control" formControlName="infertility_covered_limit" id="infertility_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="infertility_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group mt-3">
                                    <div class="col-sm-4 form-group">
                                    <label class="bs-form-label" for="surrogacy_cover">Surrogacy cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'surrogacy_cover_div')" (mouseleave)="mouseLeave($event, 'surrogacy_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="surrogacy_cover_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Surrogacy cover</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_surrogacy_cover" formControlName="surrogacy_cover" name="surrogacy_cover" value="Not Covered" (change)="checkradio_class('divsurrogacy_cover', 'NotCovered')" />
                                            <label for="radio-two_surrogacy_cover" id="divsurrogacy_cover_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_surrogacy_cover" formControlName="surrogacy_cover" name="surrogacy_cover" value="Covered" (change)="checkradio_class('divsurrogacy_cover', 'Covered')" />
                                            <label for="radio-one_surrogacy_cover" id="divsurrogacy_cover_Covered" class="bs-form-label">Covered</label>                                       	
                                        </div>
                                    </div>
                                    <div class="col-sm-5 form-group divsurrogacy_cover purple" style="display: none">
                                        <label class="bs-form-label" for="surrogacy_cover_isCover">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height matcovered2">
                                            <input type="radio" id="radio-two_surrogacy_cover_isCover" formControlName="surrogacy_cover_isCover" name="surrogacy_cover_isCover" value="within maternity limit" />
                                            <label for="radio-two_surrogacy_cover_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_surrogacy_cover_isCover" formControlName="surrogacy_cover_isCover" name="surrogacy_cover_isCover" value="Over and above" />
                                            <label for="radio-one_surrogacy_cover_isCover" class="bs-form-label">Over and Above</label>                                        	
                                        </div>										
                                    </div>
                                    <div class="col-sm-3 form-group divsurrogacy_cover" style="display: none">
                                        <label class="bs-form-label" for="surrogacy_covered_limit">Limit *</label>
                                        <input type="text" class="form-control" formControlName="surrogacy_covered_limit" id="surrogacy_covered_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="surrogacy_covered_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group mt-3">
                                    <div class="col-sm-4 form-group">
                                    <label class="bs-form-label" for="maternity_related_complications_yes">Maternity Related Complications <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'maternity_related_complications_yes_div')" (mouseleave)="mouseLeave($event, 'maternity_related_complications_yes_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="maternity_related_complications_yes_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Maternity Related Complications</p>
                                        </div>
                                        <div class="switch-field">
                                            <input type="radio" id="radio-two_maternity_related_complications_yes" formControlName="maternity_related_complications_yes" name="maternity_related_complications_yes" value="Not Covered" (change)="checkradio_class('divmaternity_related_complications_yes', 'NotCovered')" />
                                            <label for="radio-two_maternity_related_complications_yes" id="divmaternity_related_complications_yes_NotCovered" class="bs-form-label">Not Covered</label>
                                            <input type="radio" id="radio-one_maternity_related_complications_yes" formControlName="maternity_related_complications_yes" name="maternity_related_complications_yes" value="Covered" (change)="checkradio_class('divmaternity_related_complications_yes', 'Covered')" />
                                            <label for="radio-one_maternity_related_complications_yes" id="divmaternity_related_complications_yes_Covered" class="bs-form-label">Covered</label>                                        	
                                        </div>
                                    </div>
                                    <div class="col-sm-8 form-group divmaternity_related_complications_yes purple" style="display: none">
                                        <label class="bs-form-label" for="maternity_related_complications_yes_limit">Is Covered</label>
                                        <div class="switch-field switch-field_mobile_height" style="width:257px">
                                            <input type="radio" id="radio-two_maternity_related_complications_yes_isCover" formControlName="maternity_related_complications_yes_isCover" name="maternity_related_complications_yes_isCover" value="within maternity limit" />
                                            <label for="radio-two_maternity_related_complications_yes_isCover" class="bs-form-label">Witihn maternity limit</label>
                                            <input type="radio" id="radio-one_maternity_related_complications_yes_isCover" formControlName="maternity_related_complications_yes_isCover" name="maternity_related_complications_yes_isCover" value="Over and above" />
                                            <label for="radio-one_maternity_related_complications_yes_isCover" class="bs-form-label">SI Limit</label>                                        	
                                        </div>										
                                    </div>
                                </div>

                            </div>

                            <!---------------CLAIM --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Claim Control Measures</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="row">                                                                    
                                
                                <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="copay">CoPay <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'copay_div')" (mouseleave)="mouseLeave($event, 'copay_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="copay_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">CoPay</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wclaim">                                    
                                            <input type="radio" id="radio-two_copay" formControlName="copay" name="copay" value="Not Applicable" (change)="checkradio('divcopay', 'Covered')" />
                                            <label for="radio-two_copay" id="divcopay_Covered" class="bs-form-label">Not Applicable</label>   
                                                                                     
                                            <input type="radio" id="radio-one_copay" formControlName="copay" name="copay" value="Applicable" (change)="checkradio('divcopay', 'NotCovered')" />
                                            <label for="radio-one_copay" id="divcopay_NotCovered" class="bs-form-label">Applicable</label>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="deductable">Deductable <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'deductable_div')" (mouseleave)="mouseLeave($event, 'deductable_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="deductable_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Deductable</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wclaim">                                    
                                            <input type="radio" id="radio-two_deductable" formControlName="deductable" name="deductable" value="Not Applicable" (change)="checkradio('divdeductable', 'Covered')" />
                                            <label for="radio-two_deductable" id="divdeductable_Covered" class="bs-form-label">Not Applicable</label>   
                                                                                     
                                            <input type="radio" id="radio-one_deductable" formControlName="deductable" name="deductable" value="Applicable" (change)="checkradio('divdeductable', 'NotCovered')" />
                                            <label for="radio-one_deductable" id="divdeductable_NotCovered" class="bs-form-label">Applicable</label>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="sum_incured_sub_limit">Sum Insured Sub Limit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'sum_incured_sub_limit_div')" (mouseleave)="mouseLeave($event, 'sum_incured_sub_limit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="sum_incured_sub_limit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Sum incured sub limit</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wclaim">                                    
                                            <input type="radio" id="radio-two_sum_incured_sub_limit" formControlName="sum_incured_sub_limit" name="sum_incured_sub_limit" value="Not Applicable" (change)="checkradio('divsum_incured_sub_limit', 'Covered')" />
                                            <label for="radio-two_sum_incured_sub_limit" id="divsum_incured_sub_limit_Covered" class="bs-form-label">Not Applicable</label>   
                                                                                     
                                            <input type="radio" id="radio-one_sum_incured_sub_limit" formControlName="sum_incured_sub_limit" name="sum_incured_sub_limit" value="Applicable" (change)="checkradio('divsum_incured_sub_limit', 'NotCovered')" />
                                            <label for="radio-one_sum_incured_sub_limit" id="divsum_incured_sub_limit_NotCovered" class="bs-form-label">Applicable</label>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-lg-3 col-md-6 col-sm-12 mobmbmargin">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="claim_diseasewisecapping_condition">Disease Wise Capping <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claim_diseasewisecapping_condition_div')" (mouseleave)="mouseLeave($event, 'claim_diseasewisecapping_condition_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="claim_diseasewisecapping_condition_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Disease wise capping</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wclaim">                                    
                                            <input type="radio" id="radio-two_claim_diseasewisecapping_condition" formControlName="claim_diseasewisecapping_condition" name="claim_diseasewisecapping_condition" value="Not Applicable" (change)="checkradio('divclaim_diseasewisecapping_condition', 'Covered')" />
                                            <label for="radio-two_claim_diseasewisecapping_condition" id="divclaim_diseasewisecapping_condition_Covered" class="bs-form-label">Not Applicable</label>   
                                                                                     
                                            <input type="radio" id="radio-one_claim_diseasewisecapping_condition" formControlName="claim_diseasewisecapping_condition" name="claim_diseasewisecapping_condition" value="Applicable" (change)="checkradio('divclaim_diseasewisecapping_condition', 'NotCovered')" />
                                            <label for="radio-one_claim_diseasewisecapping_condition" id="divclaim_diseasewisecapping_condition_NotCovered" class="bs-form-label">Applicable</label>
                                        </div>
                                    </div>
                                </div>                                                                      
        
                            </div>
                            <!--------------- END ---------------->

                            <!----------- OPD --------------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">OPD Details</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="out_patient_benefits">Out patient benefits <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'out_patient_benefits_div')" (mouseleave)="mouseLeave($event, 'out_patient_benefits_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="out_patient_benefits_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Out patient benefits</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_out_patient_benefits" formControlName="out_patient_benefits" name="out_patient_benefits" value="Not Covered" (change)="checkradio_c('divout_patient_benefits_limit', 'NotCovered')" />
                                        <label for="radio-two_out_patient_benefits" id="divout_patient_benefits_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_out_patient_benefits" formControlName="out_patient_benefits" name="out_patient_benefits" value="Covered" (change)="checkradio_c('divout_patient_benefits_limit', 'Covered')" />
                                        <label for="radio-one_out_patient_benefits" id="divout_patient_benefits_limit_Covered" class="bs-form-label">Covered</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divout_patient_benefits_limit" style="display: none">
                                    <label class="bs-form-label" for="out_patient_benefits_limit">If Covered then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="out_patient_benefits_limit" id="out_patient_benefits_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="out_patient_benefits_limit_error" style="display:none; margin-bottom: -10px;;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="opd_dental">Dental <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'opd_dental_div')" (mouseleave)="mouseLeave($event, 'opd_dental_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="opd_dental_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Dental</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_opd_dental" formControlName="opd_dental" name="opd_dental" value="Not Covered" (change)="checkradio_c('divopd_dental_limit', 'NotCovered')" />
                                        <label for="radio-two_opd_dental" id="divopd_dental_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_opd_dental" formControlName="opd_dental" name="opd_dental" value="Covered" (change)="checkradio_c('divopd_dental_limit', 'Covered')" />
                                        <label for="radio-one_opd_dental" id="divopd_dental_limit_Covered" class="bs-form-label">Covered</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divopd_dental_limit" style="display: none">
                                    <label class="bs-form-label" for="opd_dental_limit">If Covered then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="opd_dental_limit" id="opd_dental_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="opd_dental_limit_error" style="display:none; margin-bottom: -10px;;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="opd_vision">Vision <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'opd_vision_div')" (mouseleave)="mouseLeave($event, 'opd_vision_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="opd_vision_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Vision</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_opd_vision" formControlName="opd_vision" name="opd_vision" value="Not Covered" (change)="checkradio_c('divopd_vision_limit', 'NotCovered')" />
                                        <label for="radio-two_opd_vision" class="bs-form-label" id="divopd_vision_limit_NotCovered">Not Covered</label>
                                        <input type="radio" id="radio-one_opd_vision" formControlName="opd_vision" name="opd_vision" value="Covered" (change)="checkradio_c('divopd_vision_limit', 'Covered')" />
                                        <label for="radio-one_opd_vision" class="bs-form-label" id="divopd_vision_limit_Covered">Covered</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divopd_vision_limit" style="display: none">
                                    <label class="bs-form-label" for="opd_vision_limit">If Covered then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="opd_vision_limit" id="opd_vision_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="opd_vision_limit_error" style="display:none; margin-bottom: -10px;;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="preventive_health_checkup">Preventive health checkup <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'preventive_health_checkup_div')" (mouseleave)="mouseLeave($event, 'preventive_health_checkup_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="preventive_health_checkup_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Preventive health checkup</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_preventive_health_checkup" formControlName="preventive_health_checkup" name="preventive_health_checkup" value="Not Covered" (change)="checkradio_c('divpreventive_health_checkup_limit', 'NotCovered')" />
                                        <label for="radio-two_preventive_health_checkup" id="divpreventive_health_checkup_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_preventive_health_checkup" formControlName="preventive_health_checkup" name="preventive_health_checkup" value="Covered" (change)="checkradio_c('divpreventive_health_checkup_limit', 'Covered')" />
                                        <label for="radio-one_preventive_health_checkup" id="divpreventive_health_checkup_limit_Covered" class="bs-form-label">Covered</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divpreventive_health_checkup_limit" style="display: none">
                                    <label class="bs-form-label" for="preventive_health_checkup_limit">If Covered then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="preventive_health_checkup_limit" id="preventive_health_checkup_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="preventive_health_checkup_limit_error" style="display:none; margin-bottom: -10px;;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>
                            <!--------------- END -------------->

                            <!--------------------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Additional Details</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="organ_donor_cover">Organ donor cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'organ_donor_cover_div')" (mouseleave)="mouseLeave($event, 'organ_donor_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="organ_donor_cover_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Organ donor cover</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_organ_donor_cover" formControlName="organ_donor_cover" name="organ_donor_cover" name="organ_donor_cover" value="Not Covered" checked (change)="checkradio_c('divorgan_donor_cover_limit', 'NotCovered')" />
                                        <label for="radio-two_organ_donor_cover" id="divorgan_donor_cover_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_organ_donor_cover" formControlName="organ_donor_cover" name="organ_donor_cover" value="Covered" (change)="checkradio_c('divorgan_donor_cover_limit', 'Covered')" />
                                        <label for="radio-one_organ_donor_cover" id="divorgan_donor_cover_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divorgan_donor_cover_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="organ_donor_cover_limit_perc" id="organ_donor_cover_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="organ_donor_cover_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required
                                            </p>
                                        </div>                                            

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_organ_donor_cover" value="min_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','yes')" />
                                                <label for="min_organ_donor_cover" >Min</label>   

                                                <input class="hiddenRadio" id="na_organ_donor_cover" value="na_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','no')" />
                                                <label for="na_organ_donor_cover" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_organ_donor_cover" value="max_organ_donor_cover" formControlName="min_max_organ_donor_cover" name="min_max_organ_donor_cover" type="radio" (click)="check_limit_toggle('organ_donor_cover','yes')" />
                                                <label for="max_organ_donor_cover" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 organ_donor_cover_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="organ_donor_cover_limit_value" id="organ_donor_cover_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="organ_donor_cover_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>                                            

                                    </div>
                                </div>                                
                            </div>
                            
                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="oral_chemo_therapy">Oral chemo therapy <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'oral_chemo_therapy_div')" (mouseleave)="mouseLeave($event, 'oral_chemo_therapy_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="oral_chemo_therapy_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Oral chemo therapy</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_oral_chemo_therapy" formControlName="oral_chemo_therapy" name="oral_chemo_therapy" name="oral_chemo_therapy" value="Not Covered" checked (change)="checkradio_c('divoral_chemo_therapy_limit', 'NotCovered')" />
                                        <label for="radio-two_oral_chemo_therapy" id="divoral_chemo_therapy_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_oral_chemo_therapy" formControlName="oral_chemo_therapy" name="oral_chemo_therapy" value="Covered" (change)="checkradio_c('divoral_chemo_therapy_limit', 'Covered')" />
                                        <label for="radio-one_oral_chemo_therapy" id="divoral_chemo_therapy_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divoral_chemo_therapy_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="oral_chemo_therapy_limit_perc" id="oral_chemo_therapy_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="oral_chemo_therapy_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_oral_chemo_therapy" value="min_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','yes')" />
                                                <label for="min_oral_chemo_therapy" >Min</label>   

                                                <input class="hiddenRadio" id="na_oral_chemo_therapy" value="na_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','no')" />
                                                <label for="na_oral_chemo_therapy" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_oral_chemo_therapy" value="max_oral_chemo_therapy" formControlName="min_max_oral_chemo_therapy" name="min_max_oral_chemo_therapy" type="radio" (click)="check_limit_toggle('oral_chemo_therapy','yes')" />
                                                <label for="max_oral_chemo_therapy" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 oral_chemo_therapy_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="oral_chemo_therapy_limit_value" id="oral_chemo_therapy_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="oral_chemo_therapy_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            
                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="ayush_treatment">Ayush treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'ayush_treatment_div')" (mouseleave)="mouseLeave($event, 'ayush_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="ayush_treatment_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Ayush treatment</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_ayush_treatment" formControlName="ayush_treatment" name="ayush_treatment" name="ayush_treatment" value="Not Covered" checked (change)="checkradio_c('divayush_treatment_limit', 'NotCovered')" />
                                        <label for="radio-two_ayush_treatment" id="divayush_treatment_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_ayush_treatment" formControlName="ayush_treatment" name="ayush_treatment" value="Covered" (change)="checkradio_c('divayush_treatment_limit', 'Covered')" />
                                        <label for="radio-one_ayush_treatment" id="divayush_treatment_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divayush_treatment_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="ayush_treatment_limit_perc" id="ayush_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="ayush_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_ayush_treatment" value="min_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','yes')" />
                                                <label for="min_ayush_treatment" >Min</label>   

                                                <input class="hiddenRadio" id="na_ayush_treatment" value="na_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','no')" />
                                                <label for="na_ayush_treatment" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_ayush_treatment" value="max_ayush_treatment" formControlName="min_max_ayush_treatment" name="min_max_ayush_treatment" type="radio" (click)="check_limit_toggle('ayush_treatment','yes')" />
                                                <label for="max_ayush_treatment" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 ayush_treatment_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="ayush_treatment_limit_value" id="ayush_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="ayush_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="lasik_treatment">Lasik treatment <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'lasik_treatment_div')" (mouseleave)="mouseLeave($event, 'lasik_treatment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="lasik_treatment_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Lasik treatment</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" name="lasik_treatment" value="Not Covered" checked (change)="checkradio_c('divlasik_treatment_limit', 'NotCovered')" />
                                        <label for="radio-two_lasik_treatment" id="divlasik_treatment_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_lasik_treatment" formControlName="lasik_treatment" name="lasik_treatment" value="Covered" (change)="checkradio_c('divlasik_treatment_limit', 'Covered')" />
                                        <label for="radio-one_lasik_treatment" id="divlasik_treatment_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divlasik_treatment_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-4 form-group"> 
                                            <select class="form-select" formControlName="lasik_treatment_limit_value" id="lasik_treatment_limit_value">
                                                <option value="" selected>Select</option>
                                                <option value="+/- 7.5">+/- 7.5</option>
                                                <option value="+/- 7">+/- 7</option>
                                                <option value="+/- 6.5">+/- 6.5</option>
                                                <option value="+/- 6">+/- 6</option>
                                                <option value="+/- 5.5">+/- 5.5</option>
                                                <option value="+/- 5">+/- 5</option>
                                            </select>                                            
                                        </div>
                                        <!-- <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="lasik_treatment_limit_perc" id="lasik_treatment_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="lasik_treatment_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div> -->

                                        <!-- <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_lasik_treatment" value="min_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                <label for="min_lasik_treatment" >Min</label>   

                                                <input class="hiddenRadio" id="na_lasik_treatment" value="na_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','no')" />
                                                <label for="na_lasik_treatment" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_lasik_treatment" value="max_lasik_treatment" formControlName="min_max_lasik_treatment" name="min_max_lasik_treatment" type="radio" (click)="check_limit_toggle('lasik_treatment','yes')" />
                                                <label for="max_lasik_treatment" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 lasik_treatment_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="lasik_treatment_limit_value" id="lasik_treatment_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="lasik_treatment_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div> -->
                                    </div>
                                </div>                                
                            </div>
                            
                             <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <label class="bs-form-label" for="corporate_buffer">Corporate buffer <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'corporate_buffer_div')" (mouseleave)="mouseLeave($event, 'corporate_buffer_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="corporate_buffer_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Corporate buffer</p>
                                    </div>
                                    <div class="switch-field purple">
                                        <input type="radio" id="radio-two_corporate_buffer" formControlName="corporate_buffer" name="corporate_buffer" value="Not Covered" (change)="checkradio_class('divcorporate_buffer', 'NotCovered')" />
                                        <label for="radio-two_corporate_buffer" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_corporate_buffer" formControlName="corporate_buffer" name="corporate_buffer" value="Covered" (change)="checkradio_class('divcorporate_buffer', 'Covered')" />
                                        <label for="radio-one_corporate_buffer" class="bs-form-label">Covered</label>
                                    </div>
                                </div>
                                <div class="col-sm-5 form-group divcorporate_buffer" id="divcorporate_buffer" style="display: none">
                                    <label class="bs-form-label" for="corporate_buffer_limit">Is Covered</label>
                                    <div class="switch-field switch-field_mobile_height cb purple" style="height:36px;">
                                        <input type="radio" id="radio-two_corporate_buffer_isCover" formControlName="corporate_buffer_isCover" name="corporate_buffer_isCover" value="Applicable for Critical illness" />
                                        <label for="radio-two_corporate_buffer_isCover" class="bs-form-label">Applicable for Critical illness</label>
                                        <input type="radio" id="radio-one_corporate_buffer_isCover" formControlName="corporate_buffer_isCover" name="corporate_buffer_isCover" value="Applicable for all illness" />
                                        <label for="radio-one_corporate_buffer_isCover" class="bs-form-label">Applicable for all illness</label>
                                    </div>
                                </div>
                                <div class="col-sm-2 form-group divcorporate_buffer" id="divcorporate_buffer" style="display: none; margin-bottom: 20px;">
                                    <label class="bs-form-label" for="corporate_buffer_limit">Policy Limit *</label>
                                    <input type="text" class="form-control" formControlName="corporate_buffer_limit" id="corporate_buffer_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="corporate_buffer_limit_error" style="display:none; position: absolute;">
                                        This field is required 
                                    </p>
                                </div>
                                <div class="col-sm-2 form-group divcorporate_buffer" id="divcorporate_buffer" style="display: none">
                                    <label class="bs-form-label" for="corporate_buffer_family_limit">Family Limit *</label>
                                    <input type="text" class="form-control" formControlName="corporate_buffer_family_limit" id="corporate_buffer_family_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="corporate_buffer_family_limit_error" style="display:none; position: absolute;">
                                        This field is required 
                                    </p>
                                </div>
                            </div> 
                            <!---------------------------------------->


                            <!---------------------------------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Ambulance Cover</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="road_ambulance">Road ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'road_ambulance_div')" (mouseleave)="mouseLeave($event, 'road_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="road_ambulance_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Road ambulance</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_road_ambulance" formControlName="road_ambulance" name="road_ambulance" name="road_ambulance" value="Not Covered" checked (change)="checkradio_c('divroad_ambulance_limit', 'NotCovered')" />
                                        <label for="radio-two_road_ambulance" id="divroad_ambulance_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_road_ambulance" formControlName="road_ambulance" name="road_ambulance" value="Covered" (change)="checkradio_c('divroad_ambulance_limit', 'Covered')" />
                                        <label for="radio-one_road_ambulance" id="divroad_ambulance_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divroad_ambulance_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="road_ambulance_limit_perc" id="road_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="road_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_road_ambulance" value="min_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','yes')" />
                                                <label for="min_road_ambulance" >Min</label>   

                                                <input class="hiddenRadio" id="na_road_ambulance" value="na_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','no')" />
                                                <label for="na_road_ambulance" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_road_ambulance" value="max_road_ambulance" formControlName="min_max_road_ambulance" name="min_max_road_ambulance" type="radio" (click)="check_limit_toggle('road_ambulance','yes')" />
                                                <label for="max_road_ambulance" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 road_ambulance_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="road_ambulance_limit_value" id="road_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="road_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="cardiac_ambulance">Cardiac ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'cardiac_ambulance_div')" (mouseleave)="mouseLeave($event, 'cardiac_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="cardiac_ambulance_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Cardiac ambulance</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_cardiac_ambulance" formControlName="cardiac_ambulance" name="cardiac_ambulance" name="cardiac_ambulance" value="Not Covered" checked (change)="checkradio_c('divcardiac_ambulance_limit', 'NotCovered')" />
                                        <label for="radio-two_cardiac_ambulance" id="divcardiac_ambulance_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_cardiac_ambulance" formControlName="cardiac_ambulance" name="cardiac_ambulance" value="Covered" (change)="checkradio_c('divcardiac_ambulance_limit', 'Covered')" />
                                        <label for="radio-one_cardiac_ambulance" id="divcardiac_ambulance_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divcardiac_ambulance_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="cardiac_ambulance_limit_perc" id="cardiac_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="cardiac_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_cardiac_ambulance" value="min_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','yes')" />
                                                <label for="min_cardiac_ambulance" >Min</label>   

                                                <input class="hiddenRadio" id="na_cardiac_ambulance" value="na_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','no')" />
                                                <label for="na_cardiac_ambulance" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_cardiac_ambulance" value="max_cardiac_ambulance" formControlName="min_max_cardiac_ambulance" name="min_max_cardiac_ambulance" type="radio" (click)="check_limit_toggle('cardiac_ambulance','yes')" />
                                                <label for="max_cardiac_ambulance" >Max</label>
                                            </div>                                              
                                        </div>

                                        <div class="col-sm-4 cardiac_ambulance_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="cardiac_ambulance_limit_value" id="cardiac_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="cardiac_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-3 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="air_ambulance">Air ambulance <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'air_ambulance_div')" (mouseleave)="mouseLeave($event, 'air_ambulance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="air_ambulance_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Air ambulance</p>
                                        </div>
                                    </div>
                                    <div class="switch-field">
                                        <input type="radio" id="radio-two_air_ambulance" formControlName="air_ambulance" name="air_ambulance" name="air_ambulance" value="Not Covered" checked (change)="checkradio_c('divair_ambulance_limit', 'NotCovered')" />
                                        <label for="radio-two_air_ambulance" id="divair_ambulance_limit_NotCovered" class="bs-form-label">Not Covered</label>
                                        <input type="radio" id="radio-one_air_ambulance" formControlName="air_ambulance" name="air_ambulance" value="Covered" (change)="checkradio_c('divair_ambulance_limit', 'Covered')" />
                                        <label for="radio-one_air_ambulance" id="divair_ambulance_limit_Covered" class="bs-form-label">Covered</label>                                        
                                    </div>
                                </div>
                                <div class="col-sm-9 form-group" id="divair_ambulance_limit" style="display: none;padding-top: 20px;">
                                    <div class="row">
                                        <div class="col-sm-5" style="display:flex">
                                            <input type="text" (input)="age_limit($event,'agerange')" class="form-control" formControlName="air_ambulance_limit_perc" id="air_ambulance_limit_perc" (keypress)="isNumber($event)" style="width:70%;height: 35px;" /> 
                                            &nbsp; <span class="bs-radio-label mt-1">(% of SI)</span>
                                            <p class="error" id="air_ambulance_limit_perc_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>

                                        <div class="col-sm-3 mob-m" style="text-align: center; display:flex">
                                            <div class="switch-toggle switch-3 switch-candy">                                                                                                                                                                                                           
                                                <input class="hiddenRadio" id="min_air_ambulance" value="min_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','yes')" />
                                                <label for="min_air_ambulance" >Min</label>   

                                                <input class="hiddenRadio" id="na_air_ambulance" value="na_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','no')" />
                                                <label for="na_air_ambulance" class="disabled" >N/A</label>
                                                
                                                <input class="hiddenRadio" id="max_air_ambulance" value="max_air_ambulance" formControlName="min_max_air_ambulance" name="min_max_air_ambulance" type="radio" (click)="check_limit_toggle('air_ambulance','yes')" />
                                                <label for="max_air_ambulance" >Max</label>
                                            </div>                                              
                                        </div>                                           

                                        <div class="col-sm-4 air_ambulance_limit_value" style="display:none">
                                            <input type="text" class="form-control" formControlName="air_ambulance_limit_value" id="air_ambulance_limit_value" (keypress)="isNumber($event)" (input)="isformat($event)" style="width:80%;height: 35px;" />
                                            &nbsp; <span class="bs-radio-label mt-1">(INR)</span>
                                            <p class="error" id="air_ambulance_limit_value_error" style="display:none; position: absolute; margin-top: 35px;">
                                                This field is required 
                                            </p>
                                        </div>                                            

                                    </div>
                                </div>                                
                            </div>
                            <!---------------------------------------->

                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Premium Contribution</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-4 form-group"> 
                                <label class="bs-form-label" for="Premium_Contribution">Premium Contribution *</label>
                                <select class="form-select" formControlName="Premium_Contribution" id="Premium_Contribution"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.Premium_Contribution.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Employee Only - E">Employee Only - E</option>
                                    <option value="Employee + Spouse + 2 Children - ESC">Employee + Spouse + 2 Children - ESC</option>
                                    <option value="Employee + Spouse + 2 Children + 2 Parents/Parents in law - ESCP">Employee + Spouse + 2 Children + 2 Parents/Parents in law - ESCP</option>
                                    <option value="Employee + Spouse + 2 Children + Voluntary Parents Cover - ESC_VP">Employee + Spouse + 2 Children + Voluntary Parents Cover - ESC_VP</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.Premium_Contribution.errors">
                                    <p class="error">
                                        Premium Contribution is required
                                    </p>
                                </ng-container>
                            </div>

                            <!-------------------------------------------->

                            <div class="form-group mt-3">                                
                                <label class="bs-form-label" for="do_you_offer_topup">Do you offer a Top up plan under health insurance cover *</label>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_do_you_offer_topup" formControlName="do_you_offer_topup" (click)="do_you_offer_topup($event, 'no')"  name="do_you_offer_topup" value="No" />
                                    <label for="radio-one_do_you_offer_topup" id="radio-one_do_you_offer_topup_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_do_you_offer_topup" formControlName="do_you_offer_topup" (click)="do_you_offer_topup($event, 'yes')" name="do_you_offer_topup" value="Yes" />
                                    <label for="radio-two_do_you_offer_topup" id="radio-two_do_you_offer_topup_yes" class="bs-form-label">Yes</label>	
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-sm-12 do_you_have_gmc_nodiv" style="display: none">
                        
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; background: none !important;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">               

                <form [formGroup]="policyFeatures">                
                    <h3 class="bs-title">GPA - Section - C</h3>
                    <hr>

                    <div class="row">
                        <div class="form-group">
                            <label class="bs-form-label" for="do_you_have_gpa">Do You Offer Group Accident Insurance benefit to your employee? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_gpa_div')" (mouseleave)="mouseLeave($event, 'do_you_have_gpa_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                            <div id="do_you_have_gpa_div" class="icon_div" style="display: none">
                                <p class="bs-form-label_icon">Do You Offer Group Accident Insurance benefit to your employee?</p>
                            </div>
                            <div class="switch-field wFC">
                                <input type="radio" id="radio-one_do_you_have_gpa" formControlName="do_you_have_gpa" (click)="do_you_have_gpa($event, 'no')"  name="do_you_have_gpa" value="No" />
                                <label for="radio-one_do_you_have_gpa" id="radio-one_do_you_have_gpa_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-two_do_you_have_gpa" formControlName="do_you_have_gpa" (click)="do_you_have_gpa($event, 'yes')" name="do_you_have_gpa" value="Yes" />
                                 <label for="radio-two_do_you_have_gpa" id="radio-two_do_you_have_gpa_yes" class="bs-form-label">Yes</label>	
                            </div>
                            <p class="error" id="do_you_have_gpa_error" style="display:none">
                                This field is required
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-12 do_you_have_gpa_yesdiv" style="display: none" id="gpa_form">
                        <div class="row">
                            <div class="col-sm-4 form-group mt-3"> 
                                <label class="bs-form-label" for="no_employess_gpa">No of employees *</label>
                                <input type="text" class="form-control" formControlName="no_employess_gpa" id="no_employess_gpa" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee" 
                                [ngClass]="{'control-red': policyFeaturesSubmitted && getpolicyFeaturesFormControls.no_employess_gpa.errors}"
                                required />
                                <ng-container
                                    *ngIf="policyFeaturesSubmitted && getpolicyFeaturesFormControls.no_employess_gpa.errors">
                                    <p class="error">
                                    No of employees is required
                                    </p>
                                </ng-container>
                            </div>
                            
                            <!--------------- SI --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Sum Insured</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="row mt-3">                                                                

                                <label class="bs-form-label">What is the basis of Accidental sum assured?</label>

                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Multiple of Salary')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                                </div>                                                                                        
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Flat')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                </div>
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Graded')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                </div>                                

                                <div id="div_Multiple_of_Salary" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="multiple_of_salary">Define the multiple of salary? *</label>
                                        <select class="form-select" formControlName="multiple_of_salary" id="multiple_of_salary" (change)="checkMOS($event)">
                                            <option value="" selected>Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                        </select>                            
                                        <p class="error" id="multiple_of_salary_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Flat" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="Define_flat_SI">Define flat sum insured under accidental plan *</label>
                                        <input type="text" class="form-control" formControlName="Define_flat_SI" id="Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="Define_flat_SI_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Graded" style="display:none">

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Junior_management">Define graded accidental sum insured- Junior management *</label>
                                        <input type="text" class="form-control" formControlName="SI_Junior_management" id="SI_Junior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Junior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Middle_management">Define graded accidental sum insured- Middle management *</label>
                                        <input type="text" class="form-control" formControlName="SI_Middle_management" id="SI_Middle_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Middle_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Senior_management">Define graded accidental sum insured- Senior management *</label>
                                        <input type="text" class="form-control" formControlName="SI_Senior_management" id="SI_Senior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Senior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="row newgradedField_gpa">
    
                                        </div>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <label class="bs-form-label" (click)="addGradedField_gpa()" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add Amount</span>
                                        </label>
                                    </div>                         

                                </div>

                            </div>
                            <!----------------------------->

                            <!--------------- Core Benefits --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Core Benefits</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Accidental_Death">Accidental Death <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_Death_div')" (mouseleave)="mouseLeave($event, 'Accidental_Death_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Accidental_Death_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Accidental Death</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-one_Accidental_Death" formControlName="Accidental_Death" (click)="Accidental_Death($event, 'no')"  name="Accidental_Death" value="No" />
                                        <label for="radio-one_Accidental_Death" id="radio-one_Accidental_Death_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-two_Accidental_Death" formControlName="Accidental_Death" (click)="Accidental_Death($event, 'yes')" name="Accidental_Death" value="Yes" />
                                        <label for="radio-two_Accidental_Death" id="radio-two_Accidental_Death_yes" class="bs-form-label">Yes</label>                                           
                                    </div>
                                </div>                              
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Permanent_Total_Disability">Permanent Total Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Permanent_Total_Disability_div')" (mouseleave)="mouseLeave($event, 'Permanent_Total_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Permanent_Total_Disability_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Permanent Total Disability</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">                                        
                                        <input type="radio" id="radio-two_Permanent_Total_Disability" formControlName="Permanent_Total_Disability" name="Permanent_Total_Disability" value="No" (change)="checkradio_c('divPermanent_Total_Disability_limit', 'NotCovered')" />
                                        <label for="radio-two_Permanent_Total_Disability" id="divPermanent_Total_Disability_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Permanent_Total_Disability" formControlName="Permanent_Total_Disability" name="Permanent_Total_Disability" value="Yes" (change)="checkradio_c('divPermanent_Total_Disability_limit', 'Covered')" />
                                        <label for="radio-one_Permanent_Total_Disability" id="divPermanent_Total_Disability_limit_Covered" class="bs-form-label">Yes</label>                                                         
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divPermanent_Total_Disability_limit" style="display: none">
                                    <label class="bs-form-label" for="Permanent_Total_Disability_limit">If Yes then specify the limit *</label>
                                    <!-- <input type="text" class="form-control" formControlName="Permanent_Total_Disability_limit" id="Permanent_Total_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" /> -->
                                    <select class="form-select" formControlName="Permanent_Total_Disability_limit" id="Permanent_Total_Disability_limit">
                                        <option value="" selected>Select</option>
                                        <option>100%</option>
                                        <option>150%</option>
                                        <option>200%</option>
                                    </select>
                                    <p class="error" id="Permanent_Total_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>                                

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Permanent_Partial_Disability">Permanent Partial Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Permanent_Partial_Disability_div')" (mouseleave)="mouseLeave($event, 'Permanent_Partial_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Permanent_Partial_Disability_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Permanent Partial Disability</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Permanent_Partial_Disability" formControlName="Permanent_Partial_Disability" name="Permanent_Partial_Disability" value="No" (change)="checkradio_c('divPermanent_Partial_Disability_limit', 'NotCovered')" />
                                        <label for="radio-two_Permanent_Partial_Disability" id="divPermanent_Partial_Disability_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Permanent_Partial_Disability" formControlName="Permanent_Partial_Disability" name="Permanent_Partial_Disability" value="Yes" (change)="checkradio_c('divPermanent_Partial_Disability_limit', 'Covered')" />
                                        <label for="radio-one_Permanent_Partial_Disability" id="divPermanent_Partial_Disability_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divPermanent_Partial_Disability_limit" style="display: none">                                  

                                    <div class="form-group">
                                        <label class="bs-form-label" for="img_document">Upload Image</label>
                                        <label for="Permanent_Partial_Disability_limit" id="custom-file-upload_img_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                            <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_img_document" style="color:#4E0099; font-weight: 600; text-align:center">Upload Image</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                        </label>
                                        <input id="Permanent_Partial_Disability_limit" type="file" (change)="img_document_change($event)" accept=".png, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                    
                                    </div>
                                    <small style="font-size: 12px; color:#605D62" id="small_img_document">Upload only .png, .jpg, .jpeg files (Max size 4MB)</small>

                                </div>
                            </div>                                

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-6 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Total_disability_loss_of_wages">Temporary Total Disability (Loss Of Wages) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Total_disability_loss_of_wages_div')" (mouseleave)="mouseLeave($event, 'Total_disability_loss_of_wages_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Total_disability_loss_of_wages_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Temporary Total Disability (Loss Of Wages)</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Total_disability_loss_of_wages" formControlName="Total_disability_loss_of_wages" name="Total_disability_loss_of_wages" value="No" (change)="checkradio_c('divTotal_disability_loss_of_wages_limit', 'NotCovered')" />
                                        <label for="radio-two_Total_disability_loss_of_wages" id="divTotal_disability_loss_of_wages_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Total_disability_loss_of_wages" formControlName="Total_disability_loss_of_wages" name="Total_disability_loss_of_wages" value="Yes" (change)="checkradio_c('divTotal_disability_loss_of_wages_limit', 'Covered')" />
                                        <label for="radio-one_Total_disability_loss_of_wages" id="divTotal_disability_loss_of_wages_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-12 form-group" id="divTotal_disability_loss_of_wages_limit" style="display: none">                                  

                                    <div class="row">
                                        <div class="col-sm-3">
                                            
                                            <div style="display: flex; margin-top: 20px;">
                                                <input type="text" style="width: 30%;height: 30px" class="form-control" formControlName="Total_disability_loss_of_wages_limit" id="Total_disability_loss_of_wages_limit" (keypress)="isNumber($event)" (input)="age_limit($event, 'agerange')" />
                                                <p class="error" id="Total_disability_loss_of_wages_limit_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required 
                                                </p>
                                                <span style="font-size: 12px; margin-top:5px; margin-left: 10px">% of Sum Insured OR INR</span>
                                            </div>
                                            
                                        </div>
                                        <div class="col-sm-6">

                                            <div style="display: flex; margin-left: 12px;margin-top: 20px;">
                                                <input type="text" style="width: 30%;height: 30px" class="form-control" formControlName="Total_disability_loss_of_wages_INR_per_week" id="Total_disability_loss_of_wages_INR_per_week" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                <p class="error" id="Total_disability_loss_of_wages_INR_per_week_error" style="display:none; margin-bottom: -10px;">
                                                    This field is required 
                                                </p>
                                                <span style="font-size: 12px; margin-top:5px; margin-left: 10px">Per Week OR Actual Weekly Salary which ever is lower for</span>
                                            </div>
                                                                                            
                                        </div>
                                        <div class="col-sm-3">

                                            <div style="display: flex; margin-left: 12px;margin-top: 20px;">
                                                <select class="form-select" style="width: 40%;height: 30px" formControlName="Total_disability_loss_of_wages_actual_weekly_salary" id="Total_disability_loss_of_wages_actual_weekly_salary">
                                                    <option value="" selected>Select</option>
                                                    <option value="52">52</option>
                                                    <option value="100">100</option>
                                                    <option value="104">104</option>
                                                </select>                            
                                                <p class="error" id="Total_disability_loss_of_wages_actual_weekly_salary_error" style="display:none">
                                                    This field is required
                                                </p>
                                                <span style="font-size: 12px; margin-top:5px; margin-left: 10px">Weeks</span>
                                            </div>
                                            
                                        </div>                                                                                                                                     
                                    </div>

                                </div>
                            </div>

                            <!-------------------------------->

                            <!--------------- Add on Benefits --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Add on Benefits</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>
                            
                            <div class="col-sm-12 form-group mt-3">
                                <div class="form-group">
                                    <label class="bs-form-label" for="Double_dismemberment">Double Dismemberment (S.I to double in case of  dismemberment) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Double_dismemberment_div')" (mouseleave)="mouseLeave($event, 'Double_dismemberment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="Double_dismemberment_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Double Dismemberment (S.I to double in case of  dismemberment)</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_Double_dismemberment" formControlName="Double_dismemberment" (click)="Double_dismemberment($event, 'no')"  name="Double_dismemberment" value="No" />
                                    <label for="radio-one_Double_dismemberment" id="radio-one_Double_dismemberment_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_Double_dismemberment" formControlName="Double_dismemberment" (click)="Double_dismemberment($event, 'yes')" name="Double_dismemberment" value="Yes" />
                                    <label for="radio-two_Double_dismemberment" id="radio-two_Double_dismemberment_yes" class="bs-form-label">Yes</label>                                           
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Medical_benefits_Medex">Medical Benefits  / Medex <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Medical_benefits_Medex_div')" (mouseleave)="mouseLeave($event, 'Medical_benefits_Medex_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Medical_benefits_Medex_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Medical Benefits  / Medex</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Medical_benefits_Medex" formControlName="Medical_benefits_Medex" name="Medical_benefits_Medex" value="No" (change)="checkradio_c('divMedical_benefits_Medex_limit', 'NotCovered')" />
                                        <label for="radio-two_Medical_benefits_Medex" id="divMedical_benefits_Medex_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Medical_benefits_Medex" formControlName="Medical_benefits_Medex" name="Medical_benefits_Medex" value="Yes" (change)="checkradio_c('divMedical_benefits_Medex_limit', 'Covered')" />
                                        <label for="radio-one_Medical_benefits_Medex" id="divMedical_benefits_Medex_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divMedical_benefits_Medex_limit" style="display: none">
                                    <label class="bs-form-label" for="Medical_benefits_Medex_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Medical_benefits_Medex_limit" id="Medical_benefits_Medex_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Medical_benefits_Medex_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>								                                                               

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Transportation_of_mortal_remains">Transportation Of Mortal Remains <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Transportation_of_mortal_remains_div')" (mouseleave)="mouseLeave($event, 'Transportation_of_mortal_remains_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Transportation_of_mortal_remains_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Transportation Of Mortal Remains</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Transportation_of_mortal_remains" formControlName="Transportation_of_mortal_remains" name="Transportation_of_mortal_remains" value="No" (change)="checkradio_c('divTransportation_of_mortal_remains_limit', 'NotCovered')" />
                                        <label for="radio-two_Transportation_of_mortal_remains" id="divTransportation_of_mortal_remains_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Transportation_of_mortal_remains" formControlName="Transportation_of_mortal_remains" name="Transportation_of_mortal_remains" value="Yes" (change)="checkradio_c('divTransportation_of_mortal_remains_limit', 'Covered')" />
                                        <label for="radio-one_Transportation_of_mortal_remains" id="divTransportation_of_mortal_remains_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divTransportation_of_mortal_remains_limit" style="display: none">
                                    <label class="bs-form-label" for="Transportation_of_mortal_remains_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Transportation_of_mortal_remains_limit" id="Transportation_of_mortal_remains_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Transportation_of_mortal_remains_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Ambulance_charges">Ambulance Charges <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Ambulance_charges_div')" (mouseleave)="mouseLeave($event, 'Ambulance_charges_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Ambulance_charges_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Ambulance Charges</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Ambulance_charges" formControlName="Ambulance_charges" name="Ambulance_charges" value="No" (change)="checkradio_c('divAmbulance_charges_limit', 'NotCovered')" />
                                        <label for="radio-two_Ambulance_charges" id="divAmbulance_charges_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Ambulance_charges" formControlName="Ambulance_charges" name="Ambulance_charges" value="Yes" (change)="checkradio_c('divAmbulance_charges_limit', 'Covered')" />
                                        <label for="radio-one_Ambulance_charges" id="divAmbulance_charges_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divAmbulance_charges_limit" style="display: none">
                                    <label class="bs-form-label" for="Ambulance_charges_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Ambulance_charges_limit" id="Ambulance_charges_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Ambulance_charges_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Modification_benefit">Modification Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Modification_benefit_div')" (mouseleave)="mouseLeave($event, 'Modification_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Modification_benefit_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Modification Benefit</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Modification_benefit" formControlName="Modification_benefit" name="Modification_benefit" value="No" (change)="checkradio_c('divModification_benefit_limit', 'NotCovered')" />
                                        <label for="radio-two_Modification_benefit" id="divModification_benefit_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Modification_benefit" formControlName="Modification_benefit" name="Modification_benefit" value="Yes" (change)="checkradio_c('divModification_benefit_limit', 'Covered')" />
                                        <label for="radio-one_Modification_benefit" id="divModification_benefit_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divModification_benefit_limit" style="display: none">
                                    <label class="bs-form-label" for="Modification_benefit_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Modification_benefit_limit" id="Modification_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Modification_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Education_benefit">Education Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Education_benefit_div')" (mouseleave)="mouseLeave($event, 'Education_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Education_benefit_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Education Benefit</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Education_benefit" formControlName="Education_benefit" name="Education_benefit" value="No" (change)="checkradio_c('divEducation_benefit_limit', 'NotCovered')" />
                                        <label for="radio-two_Education_benefit" id="divEducation_benefit_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Education_benefit" formControlName="Education_benefit" name="Education_benefit" value="Yes" (change)="checkradio_c('divEducation_benefit_limit', 'Covered')" />
                                        <label for="radio-one_Education_benefit" id="divEducation_benefit_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divEducation_benefit_limit" style="display: none">
                                    <label class="bs-form-label" for="Education_benefit_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Education_benefit_limit" id="Education_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Education_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Funeral_and_Repatriation_expenses">Funeral & Repatriation Expenses <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Funeral_and_Repatriation_expenses_div')" (mouseleave)="mouseLeave($event, 'Funeral_and_Repatriation_expenses_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Funeral_and_Repatriation_expenses_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Funeral & Repatriation Expenses</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Funeral_and_Repatriation_expenses" formControlName="Funeral_and_Repatriation_expenses" name="Funeral_and_Repatriation_expenses" value="No" (change)="checkradio_c('divFuneral_and_Repatriation_expenses_limit', 'NotCovered')" />
                                        <label for="radio-two_Funeral_and_Repatriation_expenses" id="divFuneral_and_Repatriation_expenses_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Funeral_and_Repatriation_expenses" formControlName="Funeral_and_Repatriation_expenses" name="Funeral_and_Repatriation_expenses" value="Yes" (change)="checkradio_c('divFuneral_and_Repatriation_expenses_limit', 'Covered')" />
                                        <label for="radio-one_Funeral_and_Repatriation_expenses" id="divFuneral_and_Repatriation_expenses_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divFuneral_and_Repatriation_expenses_limit" style="display: none">
                                    <label class="bs-form-label" for="Funeral_and_Repatriation_expenses_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Funeral_and_Repatriation_expenses_limit" id="Funeral_and_Repatriation_expenses_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Funeral_and_Repatriation_expenses_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>

                            <!------------------------------------>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-4 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="Terrorism_clause">Terrorism Clause <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Terrorism_clause_div')" (mouseleave)="mouseLeave($event, 'Terrorism_clause_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="Terrorism_clause_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Terrorism Clause</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_Terrorism_clause" formControlName="Terrorism_clause" name="Terrorism_clause" value="No" (change)="checkradio_c('divTerrorism_clause_limit', 'NotCovered')" />
                                        <label for="radio-two_Terrorism_clause" id="divTerrorism_clause_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Terrorism_clause" formControlName="Terrorism_clause" name="Terrorism_clause" value="Yes" (change)="checkradio_c('divTerrorism_clause_limit', 'Covered')" />
                                        <label for="radio-one_Terrorism_clause" id="divTerrorism_clause_limit_Covered" class="bs-form-label">Yes</label>                                               
                                    </div>
                                </div>
                                <div class="col-sm-4 form-group" id="divTerrorism_clause_limit" style="display: none">
                                    <label class="bs-form-label" for="Terrorism_clause_limit">If Yes then specify the limit *</label>
                                    <input type="text" class="form-control" formControlName="Terrorism_clause_limit" id="Terrorism_clause_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                    <p class="error" id="Terrorism_clause_limit_error" style="display:none; margin-bottom: -10px;">
                                        This field is required 
                                    </p>
                                </div>
                            </div>
                            
                            <!--------------------------------->

                            <!--------------- Employee Topup and Spouse Cover --------------->
                            <div class="row mt-4">
                                <h6 style="margin-left: 10px;
                                color: #1f9cf8;
                                font-weight: 600;
                                padding: 10px;
                                background: url('../../../assets/website/bg/header-bg.png');
                                background-position: left;">Employee Topup and Spouse Cover</h6>                        
                                <hr class="mx-2 mb-3">
                            </div>

                            <div class="form-group col-sm-12 mt-3">
                                <label class="bs-form-label" for="do_you_have_topup">Do you offer Top up plans under accident cover? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_topup_div')" (mouseleave)="mouseLeave($event, 'do_you_have_topup_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                <div id="do_you_have_topup_div" class="icon_div" style="display: none">
                                    <p class="bs-form-label_icon">Do you offer Top up plans under accident cover?</p>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_do_you_have_topup" formControlName="do_you_have_topup" (click)="do_you_have_topup($event, 'no')"  name="do_you_have_topup" value="No" />
                                    <label for="radio-one_do_you_have_topup" id="radio-one_do_you_have_topup_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_do_you_have_topup" formControlName="do_you_have_topup" (click)="do_you_have_topup($event, 'yes')" name="do_you_have_topup" value="Yes" />
                                    <label for="radio-two_do_you_have_topup" id="radio-two_do_you_have_topup_yes" class="bs-form-label">Yes</label>	
                                </div>
                                <p class="error" id="do_you_have_topup_error" style="display:none">
                                    This field is required
                                </p>
                            </div>

                            <div class="form-group col-sm-12 mt-3">
                                <label class="bs-form-label" for="offer_spouse_PA">Do you offer Spouse PA cover? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_spouse_PA_div')" (mouseleave)="mouseLeave($event, 'offer_spouse_PA_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                <div id="offer_spouse_PA_div" class="icon_div" style="display: none">
                                    <p class="bs-form-label_icon">Do you offer Spouse PA cover? </p>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-one_offer_spouse_PA" formControlName="offer_spouse_PA" (click)="offer_spouse_PA($event, 'no')"  name="offer_spouse_PA" value="No" />
                                    <label for="radio-one_offer_spouse_PA" id="radio-one_offer_spouse_PA_no" class="bs-form-label">No</label>
                                    <input type="radio" id="radio-two_offer_spouse_PA" formControlName="offer_spouse_PA" (click)="offer_spouse_PA($event, 'yes')" name="offer_spouse_PA" value="Yes" />
                                    <label for="radio-two_offer_spouse_PA" id="radio-two_offer_spouse_PA_yes" class="bs-form-label">Yes</label>
                                </div>
                                <p class="error" id="offer_spouse_PA_error" style="display:none">
                                    This field is required
                                </p>
                            </div>

                        </div>
                    </div>

                    <div class="col-sm-12 do_you_have_gpa_nodiv" style="display: none">
                        
                    </div>

                    <hr>
                    <div class="row">
                        <div>
                            <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; background: none !important;">Previous</button>
                        </div>
                    </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">GTL - Section - D</h3>
                <hr>
                <div class="row" id="gtl_form">                    
                    
                    <div class="row">
                        <div class="form-group">
                            <label class="bs-form-label" for="do_you_have_gtl">Do You Offer Group Term Life Insurance Benefits to Your employee? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_gtl_div')" (mouseleave)="mouseLeave($event, 'do_you_have_gtl_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                            <div id="do_you_have_gtl_div" class="icon_div" style="display: none">
                                <p class="bs-form-label_icon">Do You Offer Group Term Life Insurance Benefits to Your employee?</p>
                            </div>
                            <div class="switch-field wFC">
                                <input type="radio" id="radio-one_do_you_have_gtl" formControlName="do_you_have_gtl" (click)="do_you_have_gtl($event, 'no')"  name="do_you_have_gtl" value="No" />
                                <label for="radio-one_do_you_have_gtl" id="radio-one_do_you_have_gtl_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-two_do_you_have_gtl" formControlName="do_you_have_gtl" (click)="do_you_have_gtl($event, 'yes')" name="do_you_have_gtl" value="Yes" />
                                 <label for="radio-two_do_you_have_gtl" id="radio-two_do_you_have_gtl_yes" class="bs-form-label">Yes</label>		
                            </div>
                            <p class="error" id="do_you_have_gtl_error" style="display:none">
                                This field is required
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-12 do_you_have_gtl_yesdiv" style="display: none">
                        <div class="row">
                            <div class="col-sm-4 form-group mt-3"> 
                                <label class="bs-form-label" for="no_employess_gtl">No of employees *</label>
                                <input type="text" class="form-control" formControlName="no_employess_gtl" id="no_employess_gtl" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.no_employess_gtl.errors}"
                                required />
                                <ng-container
                                    *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.no_employess_gtl.errors">
                                    <p class="error">
                                    No of employees is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        
                        <!--------------- SI --------------->
                        <div class="row mt-4">
                            <h6 style="margin-left: 10px;
                            color: #1f9cf8;
                            font-weight: 600;
                            padding: 10px;
                            background: url('../../../assets/website/bg/header-bg.png');
                            background-position: left;">Sum Insured</h6>                        
                            <hr class="mx-2 mb-3">
                        </div>
                        <div class="row mt-3">                                                                
    
                            <label class="bs-form-label" style="text-indent: 10px;">What is the basis of Group Term Life (GTL) sum assured?</label>

                            <div class="form-group col-sm-4 mt-3">
                                <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type_gtl" name="SI_type_gtl" (click)="show_SI_div_gtl('Multiple of Salary')" id="SI_type_gtl" style="background: #fff" />
                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                            </div>                                                                                        
                            <div class="form-group col-sm-4 mt-3">
                                <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type_gtl" name="SI_type_gtl" (click)="show_SI_div_gtl('Flat')" id="SI_type_gtl" style="background: #fff" />
                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                            </div>
                            <div class="form-group col-sm-4 mt-3">
                                <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type_gtl" name="SI_type_gtl" (click)="show_SI_div_gtl('Graded')" id="SI_type_gtl" style="background: #fff" />
                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                            </div>                                

                            <div id="div_Multiple_of_Salary_gtl" style="display:none">
                                
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="multiple_of_salary_gtl">Define the multiple of salary? *</label>
                                    <select class="form-select" formControlName="multiple_of_salary_gtl" id="multiple_of_salary_gtl" (change)="checkMOS_gtl($event)">
                                        <option value="" selected>Select</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>                            
                                    <p class="error" id="multiple_of_salary_gtl_error" style="display:none">
                                        This field is required
                                    </p>
                                </div>

                            </div>

                            <div id="div_Flat_gtl" style="display:none">
                                
                                <div class="col-sm-4 form-group mt-3">
                                    <label class="bs-form-label" for="Define_flat_SI_gtl">Define flat sum insured under accidental plan *</label>
                                    <input type="text" class="form-control" formControlName="Define_flat_SI_gtl" id="Define_flat_SI_gtl" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                    <p class="error" id="Define_flat_SI_gtl_error" style="display:none">
                                        This field is required
                                    </p>
                                </div>

                            </div>

                            <div id="div_Graded_gtl" style="display:none">

                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="SI_Junior_management_gtl">Define graded accidental sum insured- Junior management *</label>
                                    <input type="text" class="form-control" formControlName="SI_Junior_management_gtl" id="SI_Junior_management_gtl" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                    <p class="error" id="SI_Junior_management_gtl_error" style="display:none">
                                        This field is required
                                    </p>
                                </div>

                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="SI_Middle_management_gtl">Define graded accidental sum insured- Middle management *</label>
                                    <input type="text" class="form-control" formControlName="SI_Middle_management_gtl" id="SI_Middle_management_gtl" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                    <p class="error" id="SI_Middle_management_gtl_error" style="display:none">
                                        This field is required
                                    </p>
                                </div>

                                <div class="col-sm-6 form-group mt-3">
                                    <label class="bs-form-label" for="SI_Senior_management_gtl">Define graded accidental sum insured- Senior management *</label>
                                    <input type="text" class="form-control" formControlName="SI_Senior_management_gtl" id="SI_Senior_management_gtl" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                    <p class="error" id="SI_Senior_management_gtl_error" style="display:none">
                                        This field is required
                                    </p>
                                </div>

                                <div class="col-sm-12">
                                    <div class="row newgradedField_gtl">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addGradedField_gtl()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add Amount</span>
                                    </label>
                                </div>

                            </div>                                

                        </div>

                        <div class="col-sm-12 row form-group mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="actively_at_work">Actively at Work Clause - Waived <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'actively_at_work_div')" (mouseleave)="mouseLeave($event, 'actively_at_work_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="actively_at_work_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Actively at Work Clause - Waived</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_actively_at_work" formControlName="actively_at_work" name="actively_at_work" value="No" (change)="checkradio_c('divactively_at_work_limit', 'NotCovered')" />
                                        <label for="radio-two_actively_at_work" id="divactively_at_work_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_actively_at_work" formControlName="actively_at_work" name="actively_at_work" value="Yes" (change)="checkradio_c('divactively_at_work_limit', 'Covered')" />
                                        <label for="radio-one_actively_at_work" id="divactively_at_work_limit_Covered" class="bs-form-label">Yes</label>                                           
                                </div>
                            </div>
                            <div class="col-sm-8 form-group" id="divactively_at_work_limit" style="display: none">
                                <div class="row p-0 m-0 w100" id="show_family_otp_Employees" style="margin-top: 25px !important;">
                                    <div class="form-check col-sm-6">
                                        <input type="checkbox" class="form-check-input empcheck" value="Waived For all Employees" formControlName="Waived_For_all_Employees" name="Waived_For_all_Employees" id="Waived_For_all_Employees" />
                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Waived For all Employees</label>
                                    </div>
                                    <div class="form-check col-sm-6">
                                        <input type="checkbox" class="form-check-input empcheck" value="Only For Existing Employees" formControlName="Only_For_Existing_Employees" name="Only_For_Existing_Employees" id="Only_For_Existing_Employees" />
                                        <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Only For Existing Employees</label>
                                    </div>                                        
                                </div>
                            </div>
                        </div>
                        <!-------------------------------->

                        <!--------------- Add on Benefits --------------->
                        <div class="row mt-4">
                            <h6 style="margin-left: 10px;
                            color: #1f9cf8;
                            font-weight: 600;
                            padding: 10px;
                            background: url('../../../assets/website/bg/header-bg.png');
                            background-position: left;">Add on Benefits</h6>                        
                            <hr class="mx-2 mb-3">
                        </div>

                        <div class="col-sm-12 row form-group mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="Terminal_illness">Terminal illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Terminal_illness_div')" (mouseleave)="mouseLeave($event, 'Terminal_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="Terminal_illness_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Terminal illness</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_Terminal_illness" formControlName="Terminal_illness" name="Terminal_illness" value="No" (change)="checkradio_c('divTerminal_illness_limit', 'NotCovered')" />
                                        <label for="radio-two_Terminal_illness" id="divTerminal_illness_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Terminal_illness" formControlName="Terminal_illness" name="Terminal_illness" value="Yes" (change)="checkradio_c('divTerminal_illness_limit', 'Covered')" />
                                        <label for="radio-one_Terminal_illness" id="divTerminal_illness_limit_Covered" class="bs-form-label">Yes</label>                                               
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divTerminal_illness_limit" style="display: none">
                                <label class="bs-form-label" for="Terminal_illness_limit">If Yes then specify the limit *</label>
                                <input type="text" class="form-control" formControlName="Terminal_illness_limit" id="Terminal_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="Terminal_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="Accidental_death_benefit">Accidental Death Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_death_benefit_div')" (mouseleave)="mouseLeave($event, 'Accidental_death_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="Accidental_death_benefit_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Accidental Death Benefit</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_Accidental_death_benefit" formControlName="Accidental_death_benefit" name="Accidental_death_benefit" value="No" (change)="checkradio_c('divAccidental_death_benefit_limit', 'NotCovered')" />
                                        <label for="radio-two_Accidental_death_benefit" id="divAccidental_death_benefit_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Accidental_death_benefit" formControlName="Accidental_death_benefit" name="Accidental_death_benefit" value="Yes" (change)="checkradio_c('divAccidental_death_benefit_limit', 'Covered')" />
                                        <label for="radio-one_Accidental_death_benefit" id="divAccidental_death_benefit_limit_Covered" class="bs-form-label">Yes</label>                                               
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divAccidental_death_benefit_limit" style="display: none">
                                <label class="bs-form-label" for="Accidental_death_benefit_limit">If Yes then specify the limit *</label>
                                <input type="text" class="form-control" formControlName="Accidental_death_benefit_limit" id="Accidental_death_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="Accidental_death_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>  

                        <div class="col-sm-12 row form-group mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="Accelerated_critical_illness"> Accelerated Critical Illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accelerated_critical_illness_div')" (mouseleave)="mouseLeave($event, 'Accelerated_critical_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="Accelerated_critical_illness_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Accelerated Critical Illness</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_Accelerated_critical_illness" formControlName="Accelerated_critical_illness" name="Accelerated_critical_illness" value="No" (change)="checkradio_c('divAccelerated_critical_illness_limit', 'NotCovered')" />
                                        <label for="radio-two_Accelerated_critical_illness" id="divAccelerated_critical_illness_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Accelerated_critical_illness" formControlName="Accelerated_critical_illness" name="Accelerated_critical_illness" value="Yes" (change)="checkradio_c('divAccelerated_critical_illness_limit', 'Covered')" />
                                        <label for="radio-one_Accelerated_critical_illness" id="divAccelerated_critical_illness_limit_Covered" class="bs-form-label">Yes</label>                                           
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divAccelerated_critical_illness_limit" style="display: none">
                                <label class="bs-form-label" for="Accelerated_critical_illness_limit">If Yes then specify the limit *</label>
                                <input type="text" class="form-control" formControlName="Accelerated_critical_illness_limit" id="Accelerated_critical_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="Accelerated_critical_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <div class="col-sm-12 row form-group mt-3">
                            <div class="col-sm-4 form-group">
                                <div class="form-group">
                                    <label class="bs-form-label" for="Additional_critical_illness">Additional Critical Illness <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Additional_critical_illness_div')" (mouseleave)="mouseLeave($event, 'Additional_critical_illness_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                    <div id="Additional_critical_illness_div" class="icon_div" style="display: none">
                                        <p class="bs-form-label_icon">Additional Critical Illness</p>
                                    </div>
                                </div>
                                <div class="switch-field wFC">
                                    <input type="radio" id="radio-two_Additional_critical_illness" formControlName="Additional_critical_illness" name="Additional_critical_illness" value="No" (change)="checkradio_c('divAdditional_critical_illness_limit', 'NotCovered')" />
                                        <label for="radio-two_Additional_critical_illness" id="divAdditional_critical_illness_limit_NotCovered" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_Additional_critical_illness" formControlName="Additional_critical_illness" name="Additional_critical_illness" value="Yes" (change)="checkradio_c('divAdditional_critical_illness_limit', 'Covered')" />
                                        <label for="radio-one_Additional_critical_illness" id="divAdditional_critical_illness_limit_Covered" class="bs-form-label">Yes</label>                                              
                                </div>
                            </div>
                            <div class="col-sm-4 form-group" id="divAdditional_critical_illness_limit" style="display: none">
                                <label class="bs-form-label" for="Additional_critical_illness_limit">If Yes then specify the limit *</label>
                                <input type="text" class="form-control" formControlName="Additional_critical_illness_limit" id="Additional_critical_illness_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                <p class="error" id="Additional_critical_illness_limit_error" style="display:none; margin-bottom: -10px;">
                                    This field is required 
                                </p>
                            </div>
                        </div>

                        <!---------------------------------->

                        <!--------------- Employee Topup and Spouse Cover --------------->
                        <div class="row mt-4">
                            <h6 style="margin-left: 10px;
                            color: #1f9cf8;
                            font-weight: 600;
                            padding: 10px;
                            background: url('../../../assets/website/bg/header-bg.png');
                            background-position: left;">Employee Topup and Spouse Cover</h6>                        
                            <hr class="mx-2 mb-3">
                        </div>

                        <div class="form-group col-sm-12 mt-3">
                            <label class="bs-form-label" for="do_you_have_topupplan">Employees are given the option to buy a life top up plan? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_topupplan_div')" (mouseleave)="mouseLeave($event, 'do_you_have_topupplan_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                            <div id="do_you_have_topupplan_div" class="icon_div" style="display: none">
                                <p class="bs-form-label_icon">Employees are given the option to buy a life top up plan?</p>
                            </div>
                            <div class="switch-field wFC">
                                <input type="radio" id="radio-one_do_you_have_topupplan" formControlName="do_you_have_topupplan" (click)="do_you_have_topupplan($event, 'no')"  name="do_you_have_topupplan" value="No" />
                                <label for="radio-one_do_you_have_topupplan" id="radio-one_do_you_have_topupplan_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-two_do_you_have_topupplan" formControlName="do_you_have_topupplan" (click)="do_you_have_topupplan($event, 'yes')" name="do_you_have_topupplan" value="Yes" />
                                <label for="radio-two_do_you_have_topupplan" id="radio-two_do_you_have_topupplan_yes" class="bs-form-label">Yes</label>	
                            </div>
                            <p class="error" id="do_you_have_topupplan_error" style="display:none">
                                This field is required
                            </p>
                        </div>

                        <div class="form-group col-sm-12 mt-3">
                            <label class="bs-form-label" for="do_you_have_spouse_cover">Is spouse life cover is offered? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_spouse_cover_div')" (mouseleave)="mouseLeave($event, 'do_you_have_spouse_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                            <div id="do_you_have_spouse_cover_div" class="icon_div" style="display: none">
                                <p class="bs-form-label_icon">Is spouse life cover is offered? </p>
                            </div>
                            <div class="switch-field wFC">
                                <input type="radio" id="radio-one_do_you_have_spouse_cover" formControlName="do_you_have_spouse_cover" (click)="do_you_have_spouse_cover($event, 'no')"  name="do_you_have_spouse_cover" value="No" />
                                <label for="radio-one_do_you_have_spouse_cover" id="radio-one_do_you_have_spouse_cover_no" class="bs-form-label">No</label>
                                <input type="radio" id="radio-two_do_you_have_spouse_cover" formControlName="do_you_have_spouse_cover" (click)="do_you_have_spouse_cover($event, 'yes')" name="do_you_have_spouse_cover" value="Yes" />
                                <label for="radio-two_do_you_have_spouse_cover" id="radio-two_do_you_have_spouse_cover_yes" class="bs-form-label">Yes</label>	
                            </div>
                            <p class="error" id="do_you_have_spouse_cover_error" style="display:none">
                                This field is required
                            </p>
                        </div>

                    </div>

                    <div class="col-sm-12 do_you_have_gtl_nodiv" style="display: none">
                    
                    </div>


                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; background: none !important;">Previous</button>
                    </div>
                </div>
                </form>
            </div>

            <div id="test-l-5" class="content mt-3">

                <form [formGroup]="step5form">
                <h3 class="bs-title">Health and Wellbeing</h3>
                <hr>

                <div class="row">
                    <div class="form-group">
                        <label class="bs-form-label" for="do_you_have_opd">Do you offer health and wellbeing programme to your employee? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_opd_div')" (mouseleave)="mouseLeave($event, 'do_you_have_opd_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="do_you_have_opd_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer health and wellbeing programme to your employee?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_do_you_have_opd" formControlName="do_you_have_opd" (click)="do_you_have_opd($event, 'no')"  name="do_you_have_opd" value="No" />
                            <label for="radio-one_do_you_have_opd" id="radio-one_do_you_have_opd_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_do_you_have_opd" formControlName="do_you_have_opd" (click)="do_you_have_opd($event, 'yes')" name="do_you_have_opd" value="Yes" />
                             <label for="radio-two_do_you_have_opd" id="radio-two_do_you_have_opd_yes" class="bs-form-label">Yes</label>		
                        </div>
                        <p class="error" id="do_you_have_gmc_error" style="display:none">
                            This field is required
                        </p>
                    </div>
                </div>

                <div class="col-sm-12 row do_you_have_opd_yesdiv" style="display: none">
                    
                    <div class="form- col-sm-4 mt-3">
                        <label class="bs-form-label" for="OPD_cashless">OPD Cashless <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'OPD_cashless_div')" (mouseleave)="mouseLeave($event, 'OPD_cashless_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="OPD_cashless_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">OPD Cashless</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_OPD_cashless" formControlName="OPD_cashless" (click)="OPD_cashless($event, 'no')"  name="OPD_cashless" value="No" />
                            <label for="radio-one_OPD_cashless" id="radio-one_OPD_cashless_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_OPD_cashless" formControlName="OPD_cashless" (click)="OPD_cashless($event, 'yes')" name="OPD_cashless" value="Yes" />
                            <label for="radio-two_OPD_cashless" id="radio-two_OPD_cashless_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="OPD_cashless_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-4 mt-3">
                        <label class="bs-form-label" for="Preventive_Health_Check_up">Preventive Health Check up <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Preventive_Health_Check_up_div')" (mouseleave)="mouseLeave($event, 'Preventive_Health_Check_up_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="Preventive_Health_Check_up_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Preventive Health Check up</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_Preventive_Health_Check_up" formControlName="Preventive_Health_Check_up" (click)="Preventive_Health_Check_up($event, 'no')"  name="Preventive_Health_Check_up" value="No" />
                            <label for="radio-one_Preventive_Health_Check_up" id="radio-one_Preventive_Health_Check_up_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_Preventive_Health_Check_up" formControlName="Preventive_Health_Check_up" (click)="Preventive_Health_Check_up($event, 'yes')" name="Preventive_Health_Check_up" value="Yes" />
                            <label for="radio-two_Preventive_Health_Check_up" id="radio-two_Preventive_Health_Check_up_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="Preventive_Health_Check_up_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-4 mt-3">
                        <label class="bs-form-label" for="Annual_Health_Check_up">Annual Health Check up <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Annual_Health_Check_up_div')" (mouseleave)="mouseLeave($event, 'Annual_Health_Check_up_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="Annual_Health_Check_up_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Annual Health Check up</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_Annual_Health_Check_up" formControlName="Annual_Health_Check_up" (click)="Annual_Health_Check_up($event, 'no')"  name="Annual_Health_Check_up" value="No" />
                            <label for="radio-one_Annual_Health_Check_up" id="radio-one_Annual_Health_Check_up_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_Annual_Health_Check_up" formControlName="Annual_Health_Check_up" (click)="Annual_Health_Check_up($event, 'yes')" name="Annual_Health_Check_up" value="Yes" />
                            <label for="radio-two_Annual_Health_Check_up" id="radio-two_Annual_Health_Check_up_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="Annual_Health_Check_up_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-6 mt-3">
                        <label class="bs-form-label" for="medical_facilities">Do you offer medical facilities at office premises? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'medical_facilities_div')" (mouseleave)="mouseLeave($event, 'medical_facilities_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="medical_facilities_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer medical facilities at office premises?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_medical_facilities" formControlName="medical_facilities" (click)="medical_facilities($event, 'no')"  name="medical_facilities" value="No" />
                            <label for="radio-one_medical_facilities" id="radio-one_medical_facilities_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_medical_facilities" formControlName="medical_facilities" (click)="medical_facilities($event, 'yes')" name="medical_facilities" value="Yes" />
                            <label for="radio-two_medical_facilities" id="radio-two_medical_facilities_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="medical_facilities_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                </div>

                <div class="col-sm-12 row do_you_have_opd_nodiv" style="display: none">
                
                </div>
            
                
                <hr class="my-3">
                <div>
                    <button (click)="next(5);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                    <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; background: none !important;">Previous</button>
                </div>
                </form>

            </div>

            <div id="test-l-6" class="content mt-3">

                <form [formGroup]="step6form">
                <h3 class="bs-title">Retrials & Other Benefit - Section - F</h3>
                <hr>

                <div class="row">
                    <div class="form-group">
                        <label class="bs-form-label" for="do_you_have_ROB">Do you offer Retrials and Other Benefits to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'do_you_have_ROB_div')" (mouseleave)="mouseLeave($event, 'do_you_have_ROB_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="do_you_have_ROB_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer Retrials and Other Benefits to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_do_you_have_ROB" formControlName="do_you_have_ROB" (click)="do_you_have_ROB($event, 'no')"  name="do_you_have_ROB" value="No" />
                            <label for="radio-one_do_you_have_ROB" id="radio-one_do_you_have_ROB_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_do_you_have_ROB" formControlName="do_you_have_ROB" (click)="do_you_have_ROB($event, 'yes')" name="do_you_have_ROB" value="Yes" />
                             <label for="radio-two_do_you_have_ROB" id="radio-two_do_you_have_ROB_yes" class="bs-form-label">Yes</label>		
                        </div>
                        <p class="error" id="do_you_have_ROB_error" style="display:none">
                            This field is required
                        </p>
                    </div>
                </div>

                <div class="col-sm-12 row do_you_have_ROB_yesdiv" style="display: none">

                    <!--------------- Gratuity and Retrial Benefit --------------->
                    <div class="row mt-4">
                        <h6 style="margin-left: 10px;
                        color: #1f9cf8;
                        font-weight: 600;
                        padding: 10px;
                        background: url('../../../assets/website/bg/header-bg.png');
                        background-position: left;">Gratuity and Retrial Benefit</h6>                        
                        <hr class="mx-2 mb-3">
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_gratuity">Do you offer future service gratuity? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_gratuity_div')" (mouseleave)="mouseLeave($event, 'offer_gratuity_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_gratuity_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer future service gratuity?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_gratuity" formControlName="offer_gratuity" (click)="offer_gratuity($event, 'no')"  name="offer_gratuity" value="No" />
                            <label for="radio-one_offer_gratuity" id="radio-one_offer_gratuity_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_gratuity" formControlName="offer_gratuity" (click)="offer_gratuity($event, 'yes')" name="offer_gratuity" value="Yes" />
                            <label for="radio-two_offer_gratuity" id="radio-two_offer_gratuity_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_gratuity_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_gratuity_per_law">Is your gratuity benefit as per law? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_gratuity_per_law_div')" (mouseleave)="mouseLeave($event, 'offer_gratuity_per_law_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_gratuity_per_law_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Is your gratuity benefit as per law?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_gratuity_per_law" formControlName="offer_gratuity_per_law" (click)="offer_gratuity_per_law($event, 'no')"  name="offer_gratuity_per_law" value="No" />
                            <label for="radio-one_offer_gratuity_per_law" id="radio-one_offer_gratuity_per_law_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_gratuity_per_law" formControlName="offer_gratuity_per_law" (click)="offer_gratuity_per_law($event, 'yes')" name="offer_gratuity_per_law" value="Yes" />
                            <label for="radio-two_offer_gratuity_per_law" id="radio-two_offer_gratuity_per_law_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_gratuity_per_law_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_pension_plans">Do you offer National Pension System(NPS) to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_pension_plans_div')" (mouseleave)="mouseLeave($event, 'offer_pension_plans_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_pension_plans_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer National Pension System(NPS) to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_pension_plans" formControlName="offer_pension_plans" (click)="offer_pension_plans($event, 'no')"  name="offer_pension_plans" value="No" />
                            <label for="radio-one_offer_pension_plans" id="radio-one_offer_pension_plans_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_pension_plans" formControlName="offer_pension_plans" (click)="offer_pension_plans($event, 'yes')" name="offer_pension_plans" value="Yes" />
                            <label for="radio-two_offer_pension_plans" id="radio-two_offer_pension_plans_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_pension_plans_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <!--------------- Employement Benefit --------------->
                    <div class="row mt-4">
                        <h6 style="margin-left: 10px;
                        color: #1f9cf8;
                        font-weight: 600;
                        padding: 10px;
                        background: url('../../../assets/website/bg/header-bg.png');
                        background-position: left;">Employement Benefit</h6>                        
                        <hr class="mx-2 mb-3">
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_car_leases">Do you offer car lease to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_car_leases_div')" (mouseleave)="mouseLeave($event, 'offer_car_leases_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_car_leases_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer car lease to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_car_leases" formControlName="offer_car_leases" (click)="offer_car_leases($event, 'no')"  name="offer_car_leases" value="No" />
                            <label for="radio-one_offer_car_leases" id="radio-one_offer_car_leases_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_car_leases" formControlName="offer_car_leases" (click)="offer_car_leases($event, 'yes')" name="offer_car_leases" value="Yes" />
                            <label for="radio-two_offer_car_leases" id="radio-two_offer_car_leases_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_car_leases_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_leave_encashment">Do you offer leave encashment to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_leave_encashment_div')" (mouseleave)="mouseLeave($event, 'offer_leave_encashment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_leave_encashment_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer leave encashment to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_leave_encashment" formControlName="offer_leave_encashment" (click)="offer_leave_encashment($event, 'no')"  name="offer_leave_encashment" value="No" />
                            <label for="radio-one_offer_leave_encashment" id="radio-one_offer_leave_encashment_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_leave_encashment" formControlName="offer_leave_encashment" (click)="offer_leave_encashment($event, 'yes')" name="offer_leave_encashment" value="Yes" />
                            <label for="radio-two_offer_leave_encashment" id="radio-two_offer_leave_encashment_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_leave_encashment_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_canteen_facility">Do you offer a canteen facility at the office? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_canteen_facility_div')" (mouseleave)="mouseLeave($event, 'offer_canteen_facility_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_canteen_facility_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer a canteen facility at the office?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_canteen_facility" formControlName="offer_canteen_facility" (click)="offer_canteen_facility($event, 'no')"  name="offer_canteen_facility" value="No" />
                            <label for="radio-one_offer_canteen_facility" id="radio-one_offer_canteen_facility_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_canteen_facility" formControlName="offer_canteen_facility" (click)="offer_canteen_facility($event, 'yes')" name="offer_canteen_facility" value="Yes" />
                            <label for="radio-two_offer_canteen_facility" id="radio-two_offer_canteen_facility_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_canteen_facility_error" style="display:none">
                            This field is required
                        </p>
                    </div>                    

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_employee_emergency">Do you offer an employee emergency loan? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_employee_emergency_div')" (mouseleave)="mouseLeave($event, 'offer_employee_emergency_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_employee_emergency_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer an employee emergency loan?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_employee_emergency" formControlName="offer_employee_emergency" (click)="offer_employee_emergency($event, 'no')"  name="offer_employee_emergency" value="No" />
                            <label for="radio-one_offer_employee_emergency" id="radio-one_offer_employee_emergency_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_employee_emergency" formControlName="offer_employee_emergency" (click)="offer_employee_emergency($event, 'yes')" name="offer_employee_emergency" value="Yes" />
                            <label for="radio-two_offer_employee_emergency" id="radio-two_offer_employee_emergency_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_employee_emergency_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_courses">Do you offer courses to your employees upskilling? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_courses_div')" (mouseleave)="mouseLeave($event, 'offer_courses_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_courses_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer courses to your employees upskilling?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_courses" formControlName="offer_courses" (click)="offer_courses($event, 'no')"  name="offer_courses" value="No" />
                            <label for="radio-one_offer_courses" id="radio-one_offer_courses_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_courses" formControlName="offer_courses" (click)="offer_courses($event, 'yes')" name="offer_courses" value="Yes" />
                            <label for="radio-two_offer_courses" id="radio-two_offer_courses_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_courses_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_pick_drop">Do you offer a pick/drop transportation facility? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_pick_drop_div')" (mouseleave)="mouseLeave($event, 'offer_pick_drop_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_pick_drop_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer a pick/drop transportation facility?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_pick_drop" formControlName="offer_pick_drop" (click)="offer_pick_drop($event, 'no')"  name="offer_pick_drop" value="No" />
                            <label for="radio-one_offer_pick_drop" id="radio-one_offer_pick_drop_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_pick_drop" formControlName="offer_pick_drop" (click)="offer_pick_drop($event, 'yes')" name="offer_pick_drop" value="Yes" />
                            <label for="radio-two_offer_pick_drop" id="radio-two_offer_pick_drop_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_pick_drop_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_Employee_stock">Do you offer Employee stock ownership plan(ESOP) to your valuable employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_Employee_stock_div')" (mouseleave)="mouseLeave($event, 'offer_Employee_stock_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_Employee_stock_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer Employee stock ownership plan(ESOP) to your valuable employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_Employee_stock" formControlName="offer_Employee_stock" (click)="offer_Employee_stock($event, 'no')"  name="offer_Employee_stock" value="No" />
                            <label for="radio-one_offer_Employee_stock" id="radio-one_offer_Employee_stock_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_Employee_stock" formControlName="offer_Employee_stock" (click)="offer_Employee_stock($event, 'yes')" name="offer_Employee_stock" value="Yes" />
                            <label for="radio-two_offer_Employee_stock" id="radio-two_offer_Employee_stock_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_Employee_stock_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_Gadget_allowance">Do you offer Gadget allowance to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_Gadget_allowance_div')" (mouseleave)="mouseLeave($event, 'offer_Gadget_allowance_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_Gadget_allowance_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer Gadget allowance to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_Gadget_allowance" formControlName="offer_Gadget_allowance" (click)="offer_Gadget_allowance($event, 'no')"  name="offer_Gadget_allowance" value="No" />
                            <label for="radio-one_offer_Gadget_allowance" id="radio-one_offer_Gadget_allowance_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_Gadget_allowance" formControlName="offer_Gadget_allowance" (click)="offer_Gadget_allowance($event, 'yes')" name="offer_Gadget_allowance" value="Yes" />
                            <label for="radio-two_offer_Gadget_allowance" id="radio-two_offer_Gadget_allowance_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_Gadget_allowance_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_flexi_work">Do you offer flexi work and work from home to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_flexi_work_div')" (mouseleave)="mouseLeave($event, 'offer_flexi_work_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_flexi_work_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer flexi work and work from home to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_flexi_work" formControlName="offer_flexi_work" (click)="offer_flexi_work($event, 'no')"  name="offer_flexi_work" value="No" />
                            <label for="radio-one_offer_flexi_work" id="radio-one_offer_flexi_work_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_flexi_work" formControlName="offer_flexi_work" (click)="offer_flexi_work($event, 'yes')" name="offer_flexi_work" value="Yes" />
                            <label for="radio-two_offer_flexi_work" id="radio-two_offer_flexi_work_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_flexi_work_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_flexi_pay">Do you offer flexi pay benefits to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_flexi_pay_div')" (mouseleave)="mouseLeave($event, 'offer_flexi_pay_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_flexi_pay_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer flexi pay benefits to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_flexi_pay" formControlName="offer_flexi_pay" (click)="offer_flexi_pay($event, 'no')"  name="offer_flexi_pay" value="No" />
                            <label for="radio-one_offer_flexi_pay" id="radio-one_offer_flexi_pay_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_flexi_pay" formControlName="offer_flexi_pay" (click)="offer_flexi_pay($event, 'yes')" name="offer_flexi_pay" value="Yes" />
                            <label for="radio-two_offer_flexi_pay" id="radio-two_offer_flexi_pay_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_flexi_pay_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_sabbatical_benefits">Do you offer sabbatical benefits to your employees? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_sabbatical_benefits_div')" (mouseleave)="mouseLeave($event, 'offer_sabbatical_benefits_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_sabbatical_benefits_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer sabbatical benefits to your employees?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_sabbatical_benefits" formControlName="offer_sabbatical_benefits" (click)="offer_sabbatical_benefits($event, 'no')"  name="offer_sabbatical_benefits" value="No" />
                            <label for="radio-one_offer_sabbatical_benefits" id="radio-one_offer_sabbatical_benefits_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_sabbatical_benefits" formControlName="offer_sabbatical_benefits" (click)="offer_sabbatical_benefits($event, 'yes')" name="offer_sabbatical_benefits" value="Yes" />
                            <label for="radio-two_offer_sabbatical_benefits" id="radio-two_offer_sabbatical_benefits_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_sabbatical_benefits_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <!--------------- Child care and Women Employee Benefit --------------->
                    <div class="row mt-4">
                        <h6 style="margin-left: 10px;
                        color: #1f9cf8;
                        font-weight: 600;
                        padding: 10px;
                        background: url('../../../assets/website/bg/header-bg.png');
                        background-position: left;">Child care and Women Employee Benefit</h6>                        
                        <hr class="mx-2 mb-3">
                    </div>
                    
                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_creche_facilities">Do you offer creche facilities at the office? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_creche_facilities_div')" (mouseleave)="mouseLeave($event, 'offer_creche_facilities_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_creche_facilities_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer creche facilities at the office?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_creche_facilities" formControlName="offer_creche_facilities" (click)="offer_creche_facilities($event, 'no')"  name="offer_creche_facilities" value="No" />
                            <label for="radio-one_offer_creche_facilities" id="radio-one_offer_creche_facilities_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_creche_facilities" formControlName="offer_creche_facilities" (click)="offer_creche_facilities($event, 'yes')" name="offer_creche_facilities" value="Yes" />
                            <label for="radio-two_offer_creche_facilities" id="radio-two_offer_creche_facilities_yes" class="bs-form-label">Yes</label>	
                        </div>
                        <p class="error" id="offer_creche_facilities_error" style="display:none">
                            This field is required
                        </p>
                    </div>

                    <div class="form- col-sm-12 mt-3">
                        <label class="bs-form-label" for="offer_menstural_leave">Do you offer menstural leave? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'offer_menstural_leave_div')" (mouseleave)="mouseLeave($event, 'offer_menstural_leave_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                        <div id="offer_menstural_leave_div" class="icon_div" style="display: none">
                            <p class="bs-form-label_icon">Do you offer menstural leave?</p>
                        </div>
                        <div class="switch-field wFC">
                            <input type="radio" id="radio-one_offer_menstural_leave" formControlName="offer_menstural_leave" (click)="offer_menstural_leave($event, 'no')"  name="offer_menstural_leave" value="No" />
                            <label for="radio-one_offer_menstural_leave" id="radio-one_offer_menstural_leave_no" class="bs-form-label">No</label>
                            <input type="radio" id="radio-two_offer_menstural_leave" formControlName="offer_menstural_leave" (click)="offer_menstural_leave($event, 'yes')" name="offer_menstural_leave" value="Yes" />
                            <label for="radio-two_offer_menstural_leave" id="radio-two_offer_menstural_leave_yes" class="bs-form-label">Yes</label>
                        </div>
                        <p class="error" id="offer_menstural_leave_error" style="display:none">
                            This field is required
                        </p>
                    </div>
                
                </div>

                <div class="col-sm-12 row do_you_have_ROB_nodiv" style="display: none">
                
                </div>
                
                <hr class="my-3">
                <div>
                    <button (click)="next(6);" class="btn btn-primary" style="float: right;">Finish</button>
                    <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099; background: none !important;">Previous</button>
                </div>
                </form>

            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">                
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Survey created successfully.</h6>                            

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="gohome()" style="margin: auto;">Go to Platform</button>
                    <button type="button" class="btn btn-primary" (click)="exit()" style="margin: auto;">Exit</button>
                </div>
            </div>
        </div>
    </div>

</div>
