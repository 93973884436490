import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyAuthService } from '../survey-email-verification/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-survey-email-verification',
  templateUrl: './survey-email-verification.component.html',
  styleUrls: ['./survey-email-verification.component.css']
})
export class SurveyEmailVerificationComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  public registerForm:FormGroup; 
  validmsg: any;
  registerbtn: any;
  generated_OTP: any;

  time: number = 31;
  display : any;
  interval: any;

  resstatus = localStorage.getItem('resstatus');
  // mobileverification = localStorage.getItem('mobileverification');  

  constructor(
    private fb: FormBuilder, 
    private authService: SurveyAuthService,
    private route: Router
    ) { 
      this.route.events.subscribe((e) => {
        // if (this.resstatus == null) {
        //     this.route.navigate(['/home']);
        // }        
        // if (this.resstatus == null) {
        //   this.route.navigate(['/survey-register']);
        // }
        // if (this.mobileverification == null) {
        //   this.route.navigate(['/verify-mobile']);
        // }    
      });
  }

  ngOnInit(): void {

    this.initialize();    

  }

  initialize(){

    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      $(".div-center").attr('style', 'top: -250px');
    }

    // Form element defined below
    this.registerForm = this.fb.group({
      username: new FormControl('', Validators.required),
      otp: new FormControl('')
    });    

  } 

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }
  
  generate_OTP() {
      
    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    
    $('#validmsg').css('display', 'none');

    let postdataObj = {      
      "email_id": this.registerForm.controls.username.value
    };

    this.authService.generate_OTP(postdataObj)
    .subscribe((result: any) => {
      if (result.status == true) {                        

        this.show = false;
        this.generated_OTP = result.data;
        this.validmsg = result.message;

        localStorage.setItem('Token', result.data.token);
        localStorage.setItem('tokenable_id', result.data.tokenable_id);
        localStorage.setItem('resstatus', result.status);
        localStorage.setItem('surveyLoginFlag', 'Yes');

        $('#validmsg').css('display', 'block');        

        if(result.data.email_verify == "no"){
          localStorage.setItem('surveyClinet_id', result.data.client_id);
          localStorage.setItem('survey_policy_id', result.data.survey_policy_id);
          $('#verifyOTP').css('display', 'block');
        }else{
          $('#verifyOTP').css('display', 'none');
          localStorage.setItem('surveyClinet_id', result.data.client_id);
          localStorage.setItem('survey_policy_id', result.data.survey_policy_id);
          localStorage.setItem('pagehide', 'no');
          this.route.navigate(['/survey-select-product']);
        }

        // Resend OTP
        this.startTimer();

      } else {
        // console.log(result);            
      }
    }, (error) => {
      this.validmsg = error.error.message;
      $('#validmsg').css('display', 'block');          
    });

  }  

  verify_OTP() {
    
    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    $('#validmsg').css('display', 'none');
    
    let postdataObj = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "email_address": this.registerForm.controls.username.value,
      "email_otp": this.registerForm.controls.otp.value
    };

    this.authService.verify_OTP(postdataObj)
    .subscribe((result: any) => {
      console.log(result);
      if (result.status == true) {                        

        this.show = false;
        localStorage.setItem('pagehide', 'no');
        this.route.navigate(['/survey-select-product']);

      } else {
        // console.log(result);  
          this.validmsg = result.message;
          $('#validmsg').css('display', 'block');          
      }
    }, (error) => {
      this.validmsg = error.error.message;
      $('#validmsg').css('display', 'block');          
    });     
    
  }

  goback(){
    localStorage.clear();
    this.route.navigate(['/survey-register']);
  }
  
  /********** Resend OTP Timer *****/
  startTimer() {
    $('#resendbtn').css('display','block');
    this.interval = setInterval(() => {
      if (this.time === 31) {
        this.time--;
      } else {
        this.time--;
      }
      this.display=this.transform( this.time);
      if(this.time == 0){
        this.pauseTimer();
      }
    }, 1000);
  }
  transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + ':' + (value - minutes * 60);
  }
  pauseTimer() {
    clearInterval(this.interval);
    $('#timer').css('display','none');
    $('#resendlink').prop('disabled', false);
    $('#resendlink').css('color', '#4E0099');
    $('#resendlink').css('cursor', 'pointer');    
  }
  resendotp(){
    this.generate_OTP();
    this.validmsg = 'Resending OTP..';
    $('#validmsg').css('display', 'block');
  }
  /********************************/

}
