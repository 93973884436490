<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>

    <!----- Bnner Section ------>
    <div class="row">
        <div class="col-sm-6">
            <img src="../../assets/img/selectproduct/banner.png" class="img-fluid banner" alt="Img" />
        </div>
        <div class="col-sm-6 textm">
            <h3 class="bs-title alignc">Portfolio</h3>
            <p class="bs-p-normal alignc"> 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                Nam non metus id augue tempor volutpat sit amet sit amet nunc.
            </p>
        </div>
    </div>
    <hr class="my-4">
    <!--------- END ------------>

    
    <!------- Main Section --------->
    <form [formGroup]="FormInfo">
        <div class="row">    
            
            <div class="col-sm-12">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gmc.png" class="img-fluid imgicon" alt="GMC" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Mediclaim Benefit <br> (GMC)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-lg-2 col-md-5 col-sm-12">
                            <div class="createplanbtn my-4">
                                <button class="btn btn-primary mt-2 createbtn mobbtn" (click)="filldetails('GMC')">Create Plan</button>
                                <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies('GMC')">Go to policies</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="table-responsive plantable m-2 mt-3">
                        <table class="table table-sm hover mt-2 w1000">
                            <thead style="border: none">
                                <tr style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">Plan ID</td>
                                    <td class="bs-form-label text-center text-black">Plan Name</td>
                                    <td class="bs-form-label text-center text-black">Status</td>
                                    <td class="bs-form-label text-center text-black">Action</td>
                                </tr>
                            </thead>
                            <tbody>  
                                <tr *ngFor="let group of gmcArr; let i = index;" style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">{{group['proposal_number']}}</td>
                                    <td class="bs-form-label text-center text-black">
                                        <span *ngIf="group['proposal_name'] != null">
                                            {{group['proposal_name']}}
                                        </span>
                                        <span *ngIf="group['proposal_name'] == null">
                                            -
                                        </span>
                                    </td>
                                    <td class="text-center text-black pt-2">
                                        <div class="{{group['policy_status']}}">
                                            {{group['policy_status']}}
                                        </div>
                                    </td>
                                    <td class="text-center text-black">
                                        <div class="text-black icons" style="margin-left: -20px;">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/circle.png" atl="" title="" style="width:15px" *ngIf="group['policy_status'] != 'Under-Review' || group['payment_status'] == 'Pending'" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12 mt-3">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gpa.png" class="img-fluid imgicon" alt="GPA" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Personal Accident Benefit <br> Policy (GPA)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-lg-2 col-md-5 col-sm-12">
                            <div class="createplanbtn my-4">
                                <button class="btn btn-primary createbtn mt-2 mobbtn" (click)="filldetails('GPA')">Create Plan</button>
                                <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies('GPA')">Go to policies</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="table-responsive plantable m-2 mt-3">
                        <table class="table table-sm hover mt-2 w1000">
                            <thead style="border: none">
                                <tr style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">Plan ID</td>
                                    <td class="bs-form-label text-center text-black">Plan Name</td>
                                    <td class="bs-form-label text-center text-black">Status</td>
                                    <td class="bs-form-label text-center text-black">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of gpaArr; let i = index;" style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">{{group['proposal_number']}}</td>
                                    <td class="bs-form-label text-center text-black">
                                        <span *ngIf="group['proposal_name'] != null">
                                            {{group['proposal_name']}}
                                        </span>
                                        <span *ngIf="group['proposal_name'] == null">
                                            -
                                        </span>
                                    </td>
                                    <td class="text-center text-black pt-2">
                                        <div class="{{group['policy_status']}}">
                                            {{group['policy_status']}}
                                        </div>
                                    </td>
                                    <td class="text-center text-black">
                                        <div class="text-black icons" style="margin-left: -20px;">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/circle.png" atl="" title="" style="width:15px" *ngIf="group['policy_status'] != 'Under-Review' || group['payment_status'] == 'Pending'" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12 mt-3">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/gtl.png" class="img-fluid imgicon" alt="GTL" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Term Life Benefit <br> (GTL)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-lg-2 col-md-5 col-sm-12">
                            <div class="createplanbtn my-4">
                                <button class="btn btn-primary createbtn mt-2 mobbtn" (click)="filldetails('GTL')">Create Plan</button>
                                <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies('GTL')">Go to policies</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="table-responsive plantable m-2 mt-3">
                        <table class="table table-sm hover mt-2 w1000">
                            <thead style="border: none">
                                <tr style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">Plan ID</td>
                                    <td class="bs-form-label text-center text-black">Plan Name</td>
                                    <td class="bs-form-label text-center text-black">Status</td>
                                    <td class="bs-form-label text-center text-black">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of gtlArr; let i = index;" style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">{{group['proposal_number']}}</td>
                                    <td class="bs-form-label text-center text-black">
                                        <span *ngIf="group['proposal_name'] != null">
                                            {{group['proposal_name']}}
                                        </span>
                                        <span *ngIf="group['proposal_name'] == null">
                                            -
                                        </span>
                                    </td>
                                    <td class="text-center text-black pt-2">
                                        <div class="{{group['policy_status']}}">
                                            {{group['policy_status']}}
                                        </div>
                                    </td>
                                    <td class="text-center text-black">
                                        <div class="text-black icons" style="margin-left: -20px;">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/circle.png" atl="" title="" style="width:15px" *ngIf="group['policy_status'] != 'Under-Review' || group['payment_status'] == 'Pending'" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-12 mt-3">
                <div class="box">                        
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-12 pt-0">
                            <img src="../../assets/img/selectproduct/opdgrp.png" class="img-fluid imgicon" alt="OPD" />
                        </div>
                        <div class="col-lg-8 col-md-5 col-sm-12 alignc">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Outpatient Benefit <br> (OPD)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-lg-2 col-md-5 col-sm-12">
                            <div class="createplanbtn my-4">
                                <button class="btn btn-primary createbtn mt-2 mobbtn" (click)="filldetails('OPD')">Create Plan</button>
                                <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies('OPD')">Go to policies</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="table-responsive plantable m-2 mt-3">
                        <table class="table table-sm hover mt-2 w1000">
                            <thead style="border: none">
                                <tr style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">Plan ID</td>
                                    <td class="bs-form-label text-center text-black">Plan Name</td>
                                    <td class="bs-form-label text-center text-black">Status</td>
                                    <td class="bs-form-label text-center text-black">Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let group of opdArr; let i = index;" style="border-bottom: solid 1px #eee; height:40px">
                                    <td class="bs-form-label text-center text-black">{{group['proposal_number']}}</td>
                                    <td class="bs-form-label text-center text-black">
                                        <span *ngIf="group['proposal_name'] != null">
                                            {{group['proposal_name']}}
                                        </span>
                                        <span *ngIf="group['proposal_name'] == null">
                                            -
                                        </span>
                                    </td>
                                    <td class="text-center text-black pt-2">
                                        <div class="{{group['policy_status']}}">
                                            {{group['policy_status']}}
                                        </div>
                                    </td>
                                    <td class="text-center text-black">
                                        <div class="text-black icons" style="margin-left: -20px;">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/circle.png" atl="" title="" style="width:15px" *ngIf="group['policy_status'] != 'Under-Review' || group['payment_status'] == 'Pending'" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>        
                
        </div>
    </form>
    <!------------ END ------------->

    <!---- Plan Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayGMCPopup}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" [ngClass]="odpflag == 'YES' ? 'yesopd' : ''">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="flag == 'GMC'">Create GMC Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GPA'">Create GPA Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GTL'">Create GTL Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'OPD'">Create OPD Plan</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="PlanInfo">
                       
                        <div *ngIf="flag == 'GMC'">
                            <div class="bs-form-label alignc">
                                Do you have insurance plans?
                            </div>

                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gmc_policy_check" formControlName="gmc_policy_check" [(ngModel)]="gmc_policy_check" name="gmc_policy_check" value="No" (change)="green_red_change('gmc_policy_check', 'no')" />
                                <label for="radio-two_gmc_policy_check" id="gmc_policy_check_no" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gmc_policy_check" formControlName="gmc_policy_check" [(ngModel)]="gmc_policy_check" name="gmc_policy_check" value="Yes" (change)="green_red_change('gmc_policy_check', 'yes')" />
                                <label for="radio-one_gmc_policy_check" id="gmc_policy_check_yes" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div *ngIf="flag == 'GPA'"> 
                            <div class="bs-form-label alignc">
                                Accident insurance benefit offered?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gpa_policy_check" formControlName="gpa_policy_check" [(ngModel)]="gpa_policy_check" name="gpa_policy_check" value="No" (change)="green_red_change('gpa_policy_check', 'no')" />
                                <label for="radio-two_gpa_policy_check" id="gpa_policy_check_no" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gpa_policy_check" formControlName="gpa_policy_check" [(ngModel)]="gpa_policy_check" name="gpa_policy_check" value="Yes" (change)="green_red_change('gpa_policy_check', 'yes')" />
                                <label for="radio-one_gpa_policy_check" id="gpa_policy_check_yes" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'GTL'"> 
                            <div class="bs-form-label alignc">
                                Life insurance is offered to employees?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gtl_policy_check" formControlName="gtl_policy_check" [(ngModel)]="gtl_policy_check" name="gtl_policy_check" value="No" (change)="green_red_change('gtl_policy_check', 'no')" />
                                <label for="radio-two_gtl_policy_check" id="gtl_policy_check_no" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gtl_policy_check" formControlName="gtl_policy_check" [(ngModel)]="gtl_policy_check" name="gtl_policy_check" value="Yes" (change)="green_red_change('gtl_policy_check', 'yes')" />
                                <label for="radio-one_gtl_policy_check" id="gtl_policy_check_yes" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'OPD'" class="opddiv">   

                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="bs-form-label alignc">
                                        Do you have a current OPD program?
                                    </div>                     
                                    <div class="switch-field mobswitch">                                
                                        <input type="radio" id="radio-two_opd_policy_check" formControlName="opd_policy_check" [(ngModel)]="opd_policy_check" name="opd_policy_check" value="No" (change)="green_red_change('opd_policy_check', 'no')" />
                                        <label for="radio-two_opd_policy_check" id="opd_policy_check_no" class="bs-form-label">No</label>	
                                        <input type="radio" id="radio-one_opd_policy_check" formControlName="opd_policy_check" [(ngModel)]="opd_policy_check" name="opd_policy_check" value="Yes" (change)="green_red_change('opd_policy_check', 'yes')" />
                                        <label for="radio-one_opd_policy_check" id="opd_policy_check_yes" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-12 form-group mt-3" id="divOPD_coverage_limit_no" *ngIf="opd_policy_check == 'No'">
                                    <!-------- CPV ------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Are you looking for company paid or Voluntary program?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Graded" formControlName="Company_paid_or_Voluntary" name="Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Graded')" id="Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Flat" formControlName="Company_paid_or_Voluntary" name="Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Flat')" id="Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                        </div>
                                        <p class="error" id="Company_paid_or_Voluntary_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_Flat_CPV" style="display:none">
                                    
                                        <div class="col-sm-12 form-group mt-3">
                                            <label class="bs-form-label" for="CPV_Define_flat_SI">Flat</label>
                                            <input type="text" class="form-control" formControlName="CPV_Define_flat_SI" id="CPV_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                            <p class="error" id="CPV_Define_flat_SI_error" style="display:none">
                                                This field is required
                                            </p>
                                        </div>
    
                                    </div>
    
                                    <div id="div_Graded_CPV" style="display:none">
                                                
                                        <div class="col-sm-12">
                                            <div class="row newgradedField_CPV">
        
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-3">
                                            <label class="bs-form-label" (click)="addGradedField_CPV()" style="cursor: pointer">
                                                <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                <span style="color: #1489F3;">Add Amount</span>
                                            </label>
                                        </div>
    
                                    </div>                                   
                                    <!------------ END ----------->
                                </div>
    
                                <div class="col-sm-12 form-group mt-3 divOPD_coverage_limit" id="divOPD_coverage_limit" *ngIf="opd_policy_check == 'Yes'">                                    
                                    
                                    <!---------- Question --------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Is it Company Paid OR Voluntary Paid?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Company Paid" formControlName="Company_paid_or_Voluntary_yesflow" name="Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('Company_Paid')" id="Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Company Paid</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Voluntary Paid" formControlName="Company_paid_or_Voluntary_yesflow" name="Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('Voluntary_Paid')" id="Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Voluntary Paid</label>
                                        </div>
                                        <p class="error" id="Company_paid_or_Voluntary_yesflow_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_Company_Paid" style="display:none">
                                        
                                        <!-------- Company paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Company paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="Company_paid" name="Company_paid" (click)="show_SI_div('Graded')" id="Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="Company_paid" name="Company_paid" (click)="show_SI_div('Flat')" id="Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="Company_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="Define_flat_SI" id="Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>

                                    <div id="div_Voluntary_Paid" style="display:none">
                                        
                                        <!-------- Voluntary paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Voluntary paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="Voluntary_paid" name="Voluntary_paid" (click)="show_SI_div_Voluntary('Graded')" id="Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="Voluntary_paid" name="Voluntary_paid" (click)="show_SI_div_Voluntary('Flat')" id="Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="Voluntary_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat_Voluntary" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="Voluntary_Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="Voluntary_Define_flat_SI" id="Voluntary_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="Voluntary_Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded_Voluntary" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField_Voluntary">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField_Voluntary()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>                                                                        

                                </div>                                

                            </div>                            

                        </div>                        

                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="flag == 'GMC'" class="btn btn-success" (click)="redirect(1)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GPA'" class="btn btn-success" (click)="redirect(2)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GTL'" class="btn btn-success" (click)="redirect(3)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'OPD'" class="btn btn-success" (click)="redirect(4)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                                       
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="font-size: 14px;"> Close</button>
                </div>
            </div>
        </div>
    </div>

    <!---- Payment Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayPayment}">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Payment ID</h4>                    
                </div>
                <div class="modal-body">
                    <form [formGroup]="PaymentInfo">
                       
                        <div class="col-sm-12 form-group">
                            <label class="bs-form-label" for="payment_id">Enter Payment ID</label>
                            <input type="text" class="form-control" formControlName="payment_id" id="payment_id" placeholder="Enter Payment ID" />
                            <p class="error" id="payment_id_error" style="display:none">
                                This field is required
                            </p>
                        </div>

                        <input type="hidden" id="payment_ID" name="payment_ID" />
                                                    
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="SubmitPayment()" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Submit</button>                                        
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="font-size: 14px;"> Close</button>
                </div>
            </div>
        </div>
    </div>

</div>