<div id="makertData" class="bs-stepper bg-white my-4 mb-5 rounded p-3">

    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>

    <!----- Bnner Section ------>
    <div class="row">
        <div class="col-sm-6">
            <img src="../../assets/img/selectproduct/banner.png" class="img-fluid banner" alt="Img" />
        </div>
        <div class="col-sm-6 textm">
            <h3 class="bs-title alignc">Portfolio</h3>
            <p class="bs-p-normal alignc"> 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                Nam non metus id augue tempor volutpat sit amet sit amet nunc.
            </p>
        </div>
    </div>
    <hr class="my-4">
    <!--------- END ------------>

    
    <!------- Main Section --------->
    <form [formGroup]="FormInfo">
        <div class="row">    
            
            <div class="col-sm-12">
                <div class="box">                        
                    <div class="row">
                        <div class="col-5 pt-0">
                            <img src="../../assets/img/selectproduct/gmc.png" class="img-fluid" alt="GMC" />
                        </div>
                        <div class="col-7">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Mediclaim Benefit <br> (GMC)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-12">
                            <div class="createplanbtn row">
                                <div class="col-6 p-0">
                                    <button class="btn btn-primary mt-2 createbtn mobbtn" (click)="filldetails('GMC')">Create Plan</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies()">Go to policies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="pb-3" style="box-shadow: 0px 0px 6px 1px #adb5bd; padding: 10px; border-radius: 6px;">

                        <label class=" mt-2" style="font-size: 13px;  font-weight: 600; width: 100%" (click)="basicaccord()">
                            <img id="basic_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                            <img id="basic_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="display: none;" />
                            Latest Plans
                            <span class="mx-2 bs-form-label" style="color:#4e0099">View All Plans
                                <img src="../../assets/img/selectproduct/right-arrow-color.png" style="width:15px" />
                            </span>
                        </label>                        

                        <div id="basics_accord">
                            <hr>

                            <div *ngFor="let group of gmcArr; let i = index;">
                                <div class="createplanbtn row">
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan ID</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black textwarp">
                                            {{group['proposal_number']}}
                                        </label>
                                    </div>
                                    <div class="col-3 p-0">
                                        <div class="text-black icons">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan Name</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span *ngIf="group['proposal_name'] != null">
                                                {{group['proposal_name']}}
                                            </span>
                                            <span *ngIf="group['proposal_name'] == null">
                                                -
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Status</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span class="{{group['policy_status']}}">
                                                {{group['policy_status']}}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                </div>
                                <hr>
                            </div>                                                        

                        </div>

                    </div>
                                                        
                </div>
            </div>
            
            <div class="col-sm-12">
                <div class="box">                        
                    <div class="row">
                        <div class="col-5 pt-0">
                            <img src="../../assets/img/selectproduct/gpa.png" class="img-fluid" alt="GPA" />
                        </div>
                        <div class="col-7">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Personal Accident Benefit <br> (GPA)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-12">
                            <div class="createplanbtn row">
                                <div class="col-6 p-0">
                                    <button class="btn btn-primary mt-2 createbtn mobbtn" (click)="filldetails('GPA')">Create Plan</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies()">Go to policies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="pb-3" style="box-shadow: 0px 0px 6px 1px #adb5bd; padding: 10px; border-radius: 6px;">

                        <label class=" mt-2" style="font-size: 13px;  font-weight: 600; width: 100%" (click)="gpaaccord()">
                            <img id="gpa_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                            <img id="gpa_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="display: none;" />
                            Latest Plans
                            <span class="mx-2 bs-form-label" style="color:#4e0099">View All Plans
                                <img src="../../assets/img/selectproduct/right-arrow-color.png" style="width:15px" />
                            </span>
                        </label>                        

                        <div id="gpa_accord">
                            <hr>

                            <div *ngFor="let group of gpaArr; let i = index;">
                                <div class="createplanbtn row">
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan ID</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black textwarp">
                                            {{group['proposal_number']}}
                                        </label>
                                    </div>
                                    <div class="col-3 p-0">
                                        <div class="text-black icons">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan Name</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span *ngIf="group['proposal_name'] != null">
                                                {{group['proposal_name']}}
                                            </span>
                                            <span *ngIf="group['proposal_name'] == null">
                                                -
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Status</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span class="{{group['policy_status']}}">
                                                {{group['policy_status']}}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                </div>
                                <hr>
                            </div>                          

                        </div>

                    </div>
                                                        
                </div>
            </div>
            
            <div class="col-sm-12">
                <div class="box">                        
                    <div class="row">
                        <div class="col-5 pt-0">
                            <img src="../../assets/img/selectproduct/gtl.png" class="img-fluid" alt="GTL" />
                        </div>
                        <div class="col-7">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Group Term Life Benefit <br> (GTL)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-12">
                            <div class="createplanbtn row">
                                <div class="col-6 p-0">
                                    <button class="btn btn-primary mt-2 createbtn mobbtn" (click)="filldetails('GTL')">Create Plan</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies()">Go to policies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="pb-3" style="box-shadow: 0px 0px 6px 1px #adb5bd; padding: 10px; border-radius: 6px;">

                        <label class=" mt-2" style="font-size: 13px;  font-weight: 600; width: 100%" (click)="gtlaccord()">
                            <img id="gtl_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                            <img id="gtl_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="display: none;" />
                            Latest Plans
                            <span class="mx-2 bs-form-label" style="color:#4e0099">View All Plans
                                <img src="../../assets/img/selectproduct/right-arrow-color.png" style="width:15px" />
                            </span>
                        </label>                        

                        <div id="gtl_accord">
                            <hr>

                            <div *ngFor="let group of gtlArr; let i = index;">
                                <div class="createplanbtn row">
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan ID</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black textwarp">
                                            {{group['proposal_number']}}
                                        </label>
                                    </div>
                                    <div class="col-3 p-0">
                                        <div class="text-black icons">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan Name</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span *ngIf="group['proposal_name'] != null">
                                                {{group['proposal_name']}}
                                            </span>
                                            <span *ngIf="group['proposal_name'] == null">
                                                -
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Status</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span class="{{group['policy_status']}}">
                                                {{group['policy_status']}}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                </div>
                                <hr>
                            </div>
                            
                        </div>

                    </div>
                                                        
                </div>
            </div>
            
            <div class="col-sm-12">
                <div class="box">                        
                    <div class="row">
                        <div class="col-5 pt-0">
                            <img src="../../assets/img/selectproduct/opdgrp.png" class="img-fluid" alt="OPD" />
                        </div>
                        <div class="col-7">
                            <div class="my-4">
                                <h5 class="bs-left-form-label subtitle">Outpatient Benefit <br> (OPD)</h5>
                            </div>                                                        
                        </div>
                        <div class="col-12">
                            <div class="createplanbtn row">
                                <div class="col-6 p-0">
                                    <button class="btn btn-primary mt-2 createbtn mobbtn" (click)="filldetails('OPD')">Create Plan</button>
                                </div>
                                <div class="col-6 p-0">
                                    <button class="btn mt-2 mobbtn policybtn" style="padding: 6px 20px;" (click)="gotopolicies()">Go to policies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <div class="pb-3" style="box-shadow: 0px 0px 6px 1px #adb5bd; padding: 10px; border-radius: 6px;">

                        <label class=" mt-2" style="font-size: 13px;  font-weight: 600; width: 100%" (click)="opdaccord()">
                            <img id="opd_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                            <img id="opd_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" style="display: none;" />
                            Latest Plans
                            <span class="mx-2 bs-form-label" style="color:#4e0099">View All Plans
                                <img src="../../assets/img/selectproduct/right-arrow-color.png" style="width:15px" />
                            </span>
                        </label>                        

                        <div id="opd_accord">
                            <hr>

                            <div *ngFor="let group of opdArr; let i = index;">
                                <div class="createplanbtn row">
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan ID</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black textwarp">
                                            {{group['proposal_number']}}
                                        </label>
                                    </div>
                                    <div class="col-3 p-0">
                                        <div class="text-black icons">
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/payment.png" atl="Add Payment ID" title="Add Payment ID" style="width:20px" *ngIf="group['policy_status'] == 'Under-Review' && (group['payment_status'] == 'Requested' || group['payment_status'] == 'Rejected')" (click)="showpayment(group['client_product_id'])" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/convert.png" atl="Convert Policy" title="Convert Policy" style="width:20px" *ngIf="group['policy_status'] == 'Defined'" (click)="ActiveStatus(1, group['client_product_id'], 'convert')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/edit.png" atl="Edit" title="Edit" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" />
                                            <!-- <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/delete.png" atl="Delete" title="Delete" [ngClass]="{'disableicons3': group['policy_status'] === 'Under-Review', 'disableicons4' : group['policy_status'] === 'Finalized' }" /> -->
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/deactive.png" atl="Deactive" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Active" style="width:16px" *ngIf="group['is_active'] == 0" (click)="ActiveStatus(1, group['client_product_id'], 'active_deactive')" />
                                            <img class="mx-2 actionbtn" src="../../assets/img/selectproduct/active.png" atl="Active" [ngClass]="{'disableicons': group['policy_status'] === 'Under-Review', 'disableicons2' : group['policy_status'] === 'Finalized' }" title="Make Deactive" style="width:23px" *ngIf="group['is_active'] == 1" (click)="ActiveStatus(0, group['client_product_id'], 'active_deactive')" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Plan Name</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span *ngIf="group['proposal_name'] != null">
                                                {{group['proposal_name']}}
                                            </span>
                                            <span *ngIf="group['proposal_name'] == null">
                                                -
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                    <div class="col-4">
                                        <label class="bs-form-label text-center">Status</label>
                                    </div>
                                    <div class="col-5">
                                        <label class="bs-form-label text-center text-black">
                                            <span class="{{group['policy_status']}}">
                                                {{group['policy_status']}}
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-3">
                                        
                                    </div>
                                </div>
                                <hr>
                            </div>

                        </div>

                    </div>
                                                        
                </div>
            </div>        
                
        </div>
    </form>
    <!------------ END ------------->

    <!---- Plan Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayGMCPopup}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" [ngClass]="odpflag == 'YES' ? 'yesopd' : ''">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="flag == 'GMC'">Create GMC Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GPA'">Create GPA Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'GTL'">Create GTL Plan</h4>
                    <h4 class="modal-title" *ngIf="flag == 'OPD'">Create OPD Plan</h4>
                </div>
                <div class="modal-body">
                    <form [formGroup]="PlanInfo">
                       
                        <div *ngIf="flag == 'GMC'">
                            <div class="bs-form-label alignc">
                                Do you have insurance plans?
                            </div>

                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gmc_policy_check" formControlName="gmc_policy_check" [(ngModel)]="gmc_policy_check" name="gmc_policy_check" value="No" />
                                <label for="radio-two_gmc_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gmc_policy_check" formControlName="gmc_policy_check" [(ngModel)]="gmc_policy_check" name="gmc_policy_check" value="Yes" />
                                <label for="radio-one_gmc_policy_check" class="bs-form-label">Yes</label>
                            </div>                            
                        </div>

                        <div *ngIf="flag == 'GPA'"> 
                            <div class="bs-form-label alignc">
                                Accident insurance benefit offered?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gpa_policy_check" formControlName="gpa_policy_check" [(ngModel)]="gpa_policy_check" name="gpa_policy_check" value="No" />
                                <label for="radio-two_gpa_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gpa_policy_check" formControlName="gpa_policy_check" [(ngModel)]="gpa_policy_check" name="gpa_policy_check" value="Yes" />
                                <label for="radio-one_gpa_policy_check" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'GTL'"> 
                            <div class="bs-form-label alignc">
                                Life insurance is offered to employees?
                            </div>                       
                            <div class="switch-field mobswitch">                                
                                <input type="radio" id="radio-two_gtl_policy_check" formControlName="gtl_policy_check" [(ngModel)]="gtl_policy_check" name="gtl_policy_check" value="No" />
                                <label for="radio-two_gtl_policy_check" class="bs-form-label">No</label>	
                                <input type="radio" id="radio-one_gtl_policy_check" formControlName="gtl_policy_check" [(ngModel)]="gtl_policy_check" name="gtl_policy_check" value="Yes" />
                                <label for="radio-one_gtl_policy_check" class="bs-form-label">Yes</label>
                            </div>
                        </div>

                        <div *ngIf="flag == 'OPD'" class="opddiv">   

                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="bs-form-label alignc">
                                        Do you have a current OPD program?
                                    </div>                     
                                    <div class="switch-field mobswitch">                                
                                        <input type="radio" id="radio-two_opd_policy_check" formControlName="opd_policy_check" [(ngModel)]="opd_policy_check" name="opd_policy_check" value="No" />
                                        <label for="radio-two_opd_policy_check" class="bs-form-label">No</label>	
                                        <input type="radio" id="radio-one_opd_policy_check" formControlName="opd_policy_check" [(ngModel)]="opd_policy_check" name="opd_policy_check" value="Yes" />
                                        <label for="radio-one_opd_policy_check" class="bs-form-label">Yes</label>
                                    </div>
                                </div>

                                <div class="col-sm-12 form-group mt-3" id="divOPD_coverage_limit_no" *ngIf="opd_policy_check == 'No'">
                                    <!-------- CPV ------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Are you looking for company paid or Voluntary program?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Graded" formControlName="Company_paid_or_Voluntary" name="Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Graded')" id="Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Flat" formControlName="Company_paid_or_Voluntary" name="Company_paid_or_Voluntary" (click)="show_SI_div_CPV('Flat')" id="Company_paid_or_Voluntary" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                        </div>
                                        <p class="error" id="Company_paid_or_Voluntary_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_Flat_CPV" style="display:none">
                                    
                                        <div class="col-sm-12 form-group mt-3">
                                            <label class="bs-form-label" for="CPV_Define_flat_SI">Flat</label>
                                            <input type="text" class="form-control" formControlName="CPV_Define_flat_SI" id="CPV_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                            <p class="error" id="CPV_Define_flat_SI_error" style="display:none">
                                                This field is required
                                            </p>
                                        </div>
    
                                    </div>
    
                                    <div id="div_Graded_CPV" style="display:none">
                                                
                                        <div class="col-sm-12">
                                            <div class="row newgradedField_CPV">
        
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-3">
                                            <label class="bs-form-label" (click)="addGradedField_CPV()" style="cursor: pointer">
                                                <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                <span style="color: #1489F3;">Add Amount</span>
                                            </label>
                                        </div>
    
                                    </div>                                   
                                    <!------------ END ----------->
                                </div>
    
                                <div class="col-sm-12 form-group mt-3 divOPD_coverage_limit" id="divOPD_coverage_limit" *ngIf="opd_policy_check == 'Yes'">                                    
                                    
                                    <!---------- Question --------->
                                    <div class="col-sm-12 row">                                                             
                                        <label class="bs-form-label" style="text-indent: 10px;">Is it Company Paid OR Voluntary Paid?</label>
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Company Paid" formControlName="Company_paid_or_Voluntary_yesflow" name="Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('Company_Paid')" id="Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Company Paid</label>
                                        </div>                                                                                        
                                        <div class="form-check col-sm-6 mt-3">
                                            <input type="radio" class="form-radio-input" value="Voluntary Paid" formControlName="Company_paid_or_Voluntary_yesflow" name="Company_paid_or_Voluntary_yesflow" (click)="show_SI_div('Voluntary_Paid')" id="Company_paid_or_Voluntary_yesflow" style="background: #fff" />
                                            <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Voluntary Paid</label>
                                        </div>
                                        <p class="error" id="Company_paid_or_Voluntary_yesflow_error" style="display:none; margin-bottom: -10px;">
                                            Please select any one option 
                                        </p>
                                    </div>

                                    <div id="div_Company_Paid" style="display:none">
                                        
                                        <!-------- Company paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Company paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="Company_paid" name="Company_paid" (click)="show_SI_div('Graded')" id="Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="Company_paid" name="Company_paid" (click)="show_SI_div('Flat')" id="Company_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="Company_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="Define_flat_SI" id="Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>

                                    <div id="div_Voluntary_Paid" style="display:none">
                                        
                                        <!-------- Voluntary paid ------->
                                        <div class="col-sm-12 row mt-3">                                                             
                                            <label class="bs-form-label" style="text-indent: 10px;">Is it Voluntary paid?</label>
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Graded" formControlName="Voluntary_paid" name="Voluntary_paid" (click)="show_SI_div_Voluntary('Graded')" id="Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-6 mt-3">
                                                <input type="radio" class="form-radio-input" value="Flat" formControlName="Voluntary_paid" name="Voluntary_paid" (click)="show_SI_div_Voluntary('Flat')" id="Voluntary_paid" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <p class="error" id="Voluntary_paid_error" style="display:none; margin-bottom: -10px;">
                                                Please select any one option 
                                            </p>
                                        </div>

                                        <div id="div_Flat_Voluntary" style="display:none">
                                        
                                            <div class="col-sm-12 form-group mt-3">
                                                <label class="bs-form-label" for="Voluntary_Define_flat_SI">Flat</label>
                                                <input type="text" class="form-control" formControlName="Voluntary_Define_flat_SI" id="Voluntary_Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                <p class="error" id="Voluntary_Define_flat_SI_error" style="display:none">
                                                    This field is required
                                                </p>
                                            </div>
        
                                        </div>
        
                                        <div id="div_Graded_Voluntary" style="display:none">
                                                    
                                            <div class="col-sm-12">
                                                <div class="row newgradedField_Voluntary">
            
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <label class="bs-form-label" (click)="addGradedField_Voluntary()" style="cursor: pointer">
                                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                    <span style="color: #1489F3;">Add Amount</span>
                                                </label>
                                            </div>
        
                                        </div>                                   
                                        <!------------ END ----------->

                                    </div>                                                                        

                                </div>                                

                            </div>                            

                        </div>                        

                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="flag == 'GMC'" class="btn btn-success" (click)="redirect(1)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GPA'" class="btn btn-success" (click)="redirect(2)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'GTL'" class="btn btn-success" (click)="redirect(3)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                    
                    <button type="button" *ngIf="flag == 'OPD'" class="btn btn-success" (click)="redirect(4)" role="button" style="background-color: #4e0099;border-color: #4e0099; font-size: 14px;">Continue</button>                                       
                    <button type="button" class="btn btn-danger" (click)="closePopup()" style="font-size: 14px;"> Close</button>
                </div>
            </div>
        </div>
    </div>

</div>