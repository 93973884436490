<div class="back">
  <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

  <div>
    <a routerLink="/home"><img src="../../../assets/img/login/logo.png" class="cmplogo" alt="logo"
        width="4%" style="float: left; margin: 20px; margin-left: 50px;" /></a>
  </div>

  <div class="row div-center">

    <div class="col-sm-12 col-lg-7 col-md-7 hidemd">
      <div>
        <img src="../../../../assets/img/login/otpimg.png" class="loginh4 loginh4img" alt="otpimg"
          width="60%" style="display: block; margin: auto;" />
      </div>
    </div>

    <div class="col-sm-12 col-lg-5 col-md-5 logindiv" style="padding-top: 10px; margin-top: -20px;">

      <h4 class="loginheading loginh4">Mobile Verification</h4>

      <form [formGroup]="registerForm" class="mt-3">
        <div class="mb-2">
          <label for="mobile" class="form-label">Enter mobile number</label>
          <input type="text" class="form-control" id="mobile" formControlName="mobile" minlength="10" maxlength="10"
            placeholder="Enter mobile" (blur)="isNumber($event)">
          <small style="font-size: 12px;">OTP will be sent to above Number</small>
        </div>
        <div>
          <a style="text-decoration: none;">
            <button type="button" id="generate_OTP" (click)="generate_OTP()" class="btn btn-primary w-100 loginbutton">
              Generate OTP
            </button>
          </a>
        </div>

        <!------- Resend OTP ------>
        <div class="form-label mt-2" id="resendbtn" style="text-align: right; display: none"><span id="timer">{{display
            }}</span> <button id="resendlink" disabled (click)="resendotp()"> <u>Resend OTP</u> </button></div>
        <!------------------------->


        <div id="verifyOTP" style="display: none">
          <hr class="mt-4">
          <div class="mb-2">
            <label for="otp" class="form-label">Verify OTP</label>
            <input type="text" class="form-control" id="otp" formControlName="otp" placeholder="Enter OTP"
              (blur)="isNumber($event)" />
          </div>


          <div>
            <a style="text-decoration: none;">
              <button type="submit" id="verify_OTP" (click)="verify_OTP()" class="btn btn-primary w-100 loginbutton">
                Verify OTP
              </button>
            </a>
          </div>
        </div>

      </form>

      <div class="mt-3">
        <button type="button" (click)="goback()" class="btn btn-primary w-100 loginbutton">
          Back to Sign Up
        </button>
      </div>

    </div>

  </div>
  
</div>