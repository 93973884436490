<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    
    <div class="flagDiv">

        <span class="plan_step3">GMC(T)</span>

        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Product Name</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Policy Details</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Policy Features</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">Document Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
    </div>

    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Product Name </h3>
                    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                        

                        <!--------Section 1------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label mt-2">Product Sub Category</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8">

                            <div class="form-group">
                                <!-- <label class="bs-form-label" for="policy_sub_category">Product Sub Category *</label> -->
                                <select class="form-select" formControlName="policy_sub_category" id="policy_sub_category" (change)="changepage($event)">
                                    <option value="" selected>Select</option>
                                </select>                                
                            </div>

                            <!-- <div class="form-group">
                                <label class="bs-form-label" for="policy_sub_category">Product Sub Category *</label>
                                <input type="text" class="form-control" formControlName="policy_sub_category" id="policy_sub_category" style="pointer-events: none; background: #aaaaaa5c;" />
                            </div> -->

                        </div>
                        <hr class="my-3">
                        <!--------END------->                        

                        <!--------Section 2------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label mt-2">Select Base Policy / Plan</h5>
                        </div>
                        <div class="col-sm-3">

                            <div class="form-group">
                                <label class="bs-form-label">Base plan / Plan Id *</label>                                
                                <select class="form-select" formControlName="base_plan" id="base_plan" (change)="getbenefitdata($event)"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.base_plan.errors}"
                                required>
                                    <option value="" selected>Select</option>                                    
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.base_plan.errors">
                                    <p class="error">
                                        Base plan / Plan Id is required
                                    </p>
                                </ng-container>
                                
                            </div>

                        </div>
                        <div class="col-sm-5"></div>
                        <div class="col-sm-4"></div>
                        <!-- <div class="col-sm-8 mt-2">
                            
                            <div class="col-auto">
                                <label class="bs-form-label">Proposal Number *</label>                                
                                <div class="input-group mb-2">                                    
                                    <input type="text" class="form-control" formControlName="proposal_number" name="proposal_number" id="proposal_number"
                                    [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.proposal_number.errors}"
                                    required />               
                                    <div class="input-group-prepend">
                                        <div class="input-group-text bs-form-label" style="height:34px">
                                            ClientID-001_Top Up Policy
                                        </div>
                                    </div>                     
                                </div>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.proposal_number.errors">
                                    <p class="error" style="margin-top:-9px">
                                    Proposal Number is required
                                    </p>
                                </ng-container>
                            </div>

                        </div> -->
                        <hr class="my-3">
                        <!--------END------->

                        <!--------Section 3------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label">Policy Details</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8 row">
                            <!-- <div class="form-group mt-3">
                                <div class="mb-2 bs-form-label">Do you have an existing policy? <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <div class="switch-field wFC">                                
                                    <input type="radio" id="radio-two_existing_policy_check" formControlName="existing_policy_check" name="existing_policy_check" checked value="no" (click)="existing_policy_check()" />
                                    <label for="radio-two_existing_policy_check" class="bs-form-label">No</label>	
                                    <input type="radio" id="radio-one_existing_policy_check" formControlName="existing_policy_check" name="existing_policy_check" value="yes" (click)="existing_policy_check()" />
                                    <label for="radio-one_existing_policy_check" class="bs-form-label">Yes</label>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <div class="mb-2 bs-form-label">Benefit Structure <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="manually" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Enter Manually </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="policy_template" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Upload Excel Template </span>                                                        
                                &nbsp;
                                <input type="radio" class="form-radio" value="existing_doc" formControlName="upload_check" id="upload_check" style="pointer-events:none;" /> <span class="bs-radio-label" style="color:#AAAAAA"> Upload Policy PDF </span>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadPolicyDetails()">Download Sample Template</span>                                                                        
                            </div>
                            

                            <div class="form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="insurance_company">Insurance company *</label>
                                <!-- <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                required >
                                    <option value="" selected>Select</option>
                                </select>                            
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container> -->

                                <ng-select formControlName="insurance_company" id="insurance_company" 
                                            [items]="isuranceCompanyDetailsarr" 
                                            bindLabel="name" 
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                            required>                                    
                                </ng-select>
                                 <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container>
                                
                            </div>                        

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="tpa">Third Party Administrators(TPA) Name *</label>
                                <select class="form-select" formControlName="tpa" id="tpa" style="line-height: 1.7;" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.tpa.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>                                                        
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.tpa.errors">
                                    <p class="error">
                                    TPA is required
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="broker_name">Broker Name *</label>
                                <select class="form-select" formControlName="broker_name" id="broker_name" style="line-height: 1.7;"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors">
                                    <p class="error">
                                    Broker Name is required
                                    </p>
                                </ng-container>
                            </div>                        
                                                
                            <div class="form-group mt-3" id="div_upload_policydocument" style="display: none">
                                <label class="bs-form-label" for="upload_policydocument">Upload policy document</label>
                                <label for="file" id="custom-file-upload_upload_policydocument" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_policydocument" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                </label>
                                <input id="file" type="file" (change)="upload_policydocument_change($event)" enctype="multipart/form-data" accept=".xlsx, .xls, .csv, application/pdf" style="display:none" />
                                <small style="font-size: 12px; color:#605D62" id="small_upload_policydocument">Upload only .xlxs, .xls files (Max size 4MB)</small>

                            </div>
                        </div>
                        <hr class="my-3">
                        <!--------END------->

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">Policy Details</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Policy Details</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 form-group policy_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="policy_date">Policy inception date *</label>
                                <input type="date" class="form-control" formControlName="policy_date" id="policy_date" (change)="loadecpirydate()"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors">
                                    <p class="error">
                                    Policy inception date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group how_soon_buy">
                                <label class="bs-form-label" for="how_soon_buy">How soon you planning to buy *</label>
                                <select class="form-select" formControlName="how_soon_buy" id="how_soon_buy" (change)="how_soon_buy_check($event)"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Immediatly">Immediatly</option>
                                    <option value="Within a month">Within a month</option>
                                    <option value="Specific month">Specific month</option>
                                    <option value="Not yet decided">Not yet decided</option>
                                </select>                            
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors">
                                    <p class="error">
                                    This is required field
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group step2hideFields">
                                <label class="bs-form-label" for="policy_expirydate">Policy expiry date *</label>
                                <input type="date" class="form-control" formControlName="policy_expirydate" id="policy_expirydate"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 data_report_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="data_report_date">Data report date *</label>
                                <input type="date" class="form-control" formControlName="data_report_date" id="data_report_date" min="{{min_date}}" max="{{max_date}}"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors">
                                    <p class="error">
                                    Data report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group choose_month" style="display:none">
                                <label class="bs-form-label" for="choose_month">Choose month *</label>
                                <select class="form-select" formControlName="choose_month" id="choose_month"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors}"
                                required>
                                    <option value="0" selected>Select</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 step2hideFields">
                                <label class="bs-form-label" for="policy_number">Policy number *</label>
                                <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="340100502022000275"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors">
                                    <p class="error">
                                    Policy number is required
                                    </p>
                                </ng-container>
                            </div>                                                        
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-sm-4">
                            <div class="p-5 px-0 pb-0 step2hideFields">
                                <label class="bs-form-label" style="color:#000">Lives at Inception</label>
                            </div>
                            <div class="p-5 px-0 pt-3 step2hideFields">
                                <label class="bs-form-label" style="position: relative; top: 30px;color:#000">Lives at Renewal</label>
                            </div>
                        </div>
                        <div class="row col-sm-8 p-0 m-0">
                            <div class="col-sm-6 form-group emp_atstart" style="margin-top: 16px !important;"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees *</label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group lives_covered mt-3 step2hideFields" style="display:none">
                                <label class="bs-form-label" for="lives_covered">No of total lives *</label>
                                <input type="text" class="form-control" formControlName="lives_covered" id="lives_covered" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors">
                                    <p class="error">
                                    No of lives covered at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="emp_atreport">No of employees *</label>
                                <input type="text" class="form-control" formControlName="emp_atreport" id="emp_atreport" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors">
                                    <p class="error">
                                    No of employees as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="lives_atreport">No of total lives *</label>
                                <input type="text" class="form-control" formControlName="lives_atreport" id="lives_atreport" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors">
                                    <p class="error">
                                    No of lives covered as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">

                <!--------- Banner ---------->
                <div class="row">
                    <div class="col-sm-6">
                        <img src="../../assets/img/policy/policybanner.png" class="img-fluid" alt="Img" style="display:block; margin:auto; width:300px" />
                    </div>
                    <div class="col-sm-6 textm">
                        <h3 class="bs-title alignc">Policy Features</h3>
                        <p class="bs-p-normal alignc"> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                            Nam non metus id augue tempor volutpat sit amet sit amet nunc.
                        </p>
                    </div>
                </div>
                <hr class="my-4">
                <!--------- END ------------>

                <form [formGroup]="policyFeatures">             

                <!---- Family Construct Accordian ---->
                <h5 class="bs-left-form-label accordheading FCheading" (click)="basicaccord()">Family Construct
                    <img id="basic_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="basic_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/Group.png" class="headicon" />
                    <img id="FCtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="FCtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgFC">
                        <div class="value-container" id="ValueFC">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="basics_accord" class="accord_div" style="pointer-events: none;">

                    <div class="row">                     
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12 form-group">                                   
                                    <div class="col-sm-12 form-group">
                                        
                                        <!---- Employees --->
                                        <div style="display: flex">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <!-- <input type="checkbox" class="form-check-input" formControlName="Employees" name="Employees" id="Employees" (click)="show_family_otp('Employees')" /> -->
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Employees</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Employees" class="Employees" formControlName="Employees" name="Employees" value="No" (change)="show_family_otp1('Employees', 'no')" />
                                                    <label for="radio-two_Employees" id="Employees_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_Employees" class="Employees" formControlName="Employees" name="Employees" value="Yes" (change)="show_family_otp1('Employees', 'yes')" />
                                                    <label for="radio-one_Employees" id="Employees_yes" class="bs-form-label">Yes</label>	
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Employees" style="display:none;margin-top: 40px !important;">
                                                <div class="form-check col-sm-3">
                                                    <input type="checkbox" class="form-check-input empcheck" value="On Roll Employees" formControlName="On_Roll_Employees" name="On_Roll_Employees" id="On_Roll_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">On Roll Employees</label>
                                                </div>
                                                <div class="form-check col-sm-4">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Contractual Employees" formControlName="Contractual_Employees" name="Contractual_Employees" id="Contractual_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Contractual Employees</label>
                                                </div>
                                                <div class="form-check col-sm-3">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Retired Employees" formControlName="Retired_Employees" name="Retired_Employees" id="Retired_Employees" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Retired Employees</label>
                                                </div>
                                                <div class="form-check col-sm-2">
                                                    <input type="checkbox" class="form-check-input empcheck" value="Consultants" formControlName="Consultants" name="Consultants" id="Consultants" />
                                                    <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Consultants</label>
                                                </div>
                                            </div>                                            
                                        </div>    
                                        <p class="error" id="empcheck_error" style="display:none">
                                            Choose at least one optiion
                                        </p>
                                        <p class="error" id="empcheck_error_1" style="display:none">
                                            This is required field
                                        </p>   
                                        <hr style="margin-bottom: 0px;">                                                                        

                                        <!---- Spouse --->
                                        <div class="div_Spouse" style="display: flex;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Spouse</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Spouse" formControlName="Spouse" name="Spouse" value="No" (change)="show_family_otp1('Spouse', 'no')" />
                                                    <label for="radio-two_Spouse" id="Spouse_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Spouse" formControlName="Spouse" name="Spouse" value="Yes" (change)="show_family_otp1('Spouse', 'yes')" />
                                                    <label for="radio-one_Spouse" id="Spouse_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Spouse" style="display:none; margin-top: 40px !important;">
                                                <div class="form-check col-sm-3">
													<input type="checkbox" class="form-check-input spousecheck" value="Legally Wedded Spouse" formControlName="Legally_Wedded_Spouse" name="Legally_Wedded_Spouse" id="Legally_Wedded_Spouse" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Legally Wedded Spouse</label>
												</div>
												<div class="form-check col-sm-4">
													<input type="checkbox" class="form-check-input spousecheck" value="Domestic Partner" formControlName="Domestic_Partner" name="Domestic_Partner" id="Domestic_Partner" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Domestic Partner</label>
												</div>
												<div class="form-check col-sm-4">
													<input type="checkbox" class="form-check-input spousecheck" value="LGBTQ Cover" formControlName="LGBTQ_Cover" name="LGBTQ_Cover" id="LGBTQ_Cover" />
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Same Sex Partner(LGBTQ)</label>
												</div>
                                            </div>                                                                                                                                    
                                        </div>
                                        <p class="error" id="spousecheck_error" style="display:none">
                                            Choose at least one optiion
                                        </p>
                                        <p class="error" id="empcheck_error_2" style="display:none">
                                            This is required field
                                        </p>
                                        <hr class="div_Spouse" style="margin-bottom: 0px;display:none">                                         
                                        
                                        <!---- Childrens --->
                                        <div class="div_Childrens" style="display: flex;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle; margin-top:5px">Childrens</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Childrens" formControlName="Childrens" name="Childrens" value="No" (change)="show_family_otp1('Childrens', 'no')" />
                                                    <label for="radio-two_Childrens" id="Childrens_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_Childrens" formControlName="Childrens" name="Childrens" value="Yes" (change)="show_family_otp1('Childrens', 'yes')" />
                                                    <label for="radio-one_Childrens" id="Childrens_yes" class="bs-form-label">Yes</label>	
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Childrens" style="display:none;">
                                                <div class="form-check col-sm-5 p-0">
													<div class="switch-field familychild" style="margin-top:36px">
														<input type="radio" id="radio-one_children_type" formControlName="children_type" name="children_type" value="Upto 2 Child" />
														<label for="radio-one_children_type" class="bs-form-label">Upto 2 child</label>
														<input type="radio" id="radio-two_children_type" formControlName="children_type" name="children_type" value="More Then 2 Child" />
														<label for="radio-two_children_type" class="bs-form-label">More Then 2 Child</label>	
													</div>
												</div>
                                                <div class="col-sm-4 p-0" style="margin-top: 10px;">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="Age_Limit_of_the_Child_text">Age Limit of the Child (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="Age_Limit_of_the_Child_text" name="Age_Limit_of_the_Child_text" id="Age_Limit_of_the_Child_text" (keypress)="isNumber($event)" (input)="age_limit($event,'children')" /> 
                                                        <p class="error" id="Age_Limit_of_the_Child_text_error" style="display:none">
                                                            Age Limit is required
                                                        </p>
                                                    </div>

                                                    <!-- <div class="row">
                                                        <div class="col-sm-6">
                                                            <label class="form-check-label bs-radio-label" style="vertical-align: middle;margin-top: 3px;">Age Limit of the Child</label>
                                                        </div>

                                                        <div class="col-sm-6" style="display:flex; position: relative; left: -80px; top: -5px;">
                                                            <input type="text" class="form-control" formControlName="Age_Limit_of_the_Child_text" name="Age_Limit_of_the_Child_text" id="Age_Limit_of_the_Child_text" (keypress)="isNumber($event)" (input)="age_limit($event,'children')" style="width:40%; margin-top: 3px;" /> 
                                                            &nbsp; <span style="margin-top: 10px;font-size: 13px;font-family: 'Poppins';font-style: normal;">(Years)</span>
                                                        </div>                                                
                                                    </div>        -->

                                                </div>												
                                            </div>
                                        </div>
                                        <p class="error" id="empcheck_error_3" style="display:none">
                                            This is required field
                                        </p>                                          
                                        <hr class="div_Childrens" style="margin-bottom: 0px;display:none">                                      

                                        <!---- Parents --->
                                        <div class="div_Parents" style="display: flex;padding-bottom: 10px;">
                                            <div class="col-sm-2" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label" style="vertical-align: middle;">Parents</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Parents" formControlName="Parents" name="Parents" value="No" (change)="show_family_otp1('Parents', 'no')" />
                                                    <label for="radio-two_Parents" id="Parents_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Parents" formControlName="Parents" name="Parents" value="Yes" (change)="show_family_otp1('Parents', 'yes')" />
                                                    <label for="radio-one_Parents" id="Parents_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="row p-0 m-0 w100" id="show_family_otp_Parents" style="display:none; margin-top: 40px !important;">
                                                <div class="form-check col-sm-2" style="display:flex;padding: 5px 0px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent only" formControlName="Parent_only" name="Parent_only" id="Parent_only" (click)="show_cross_combination('Parent only')" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parent only</label>
												</div>                                                                                        
												<div class="form-check col-sm-3" style="display:flex;padding: 5px 0px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent or Parent Inlaws" formControlName="Parent_only" name="Parent_only" (click)="show_cross_combination('Parent or Parent Inlaws')" id="Parent_only" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parent OR Parent Inlaws</label>
												</div>
												<div class="form-check col-sm-3" style="display:flex;padding: 5px 0px; padding-left: 5px;">
													<input type="radio" class="form-radio-input parentcheck" value="Parent and Parent Inlaws" formControlName="Parent_only" name="Parent_only" (click)="show_cross_combination('Parent and Parent Inlaws')" id="Parent_only" style="background: #fff" />
													<label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Parent & Parent Inlaws</label>
												</div>
												<div class="form-check col-sm-4 Cross_Combination_status" style="display: none">
													<label class="form-check-label bs-radio-label" style="vertical-align: middle;margin-right: 10px;margin-top: 2px;">Cross Combination</label>
													<div class="switch-field wFC" style="height: 35px;">														
														<input type="radio" id="radio-two_Cross_Combination_status" formControlName="Cross_Combination_status" name="Cross_Combination_status" value="No" />
														<label for="radio-two_Cross_Combination_status" class="bs-form-label">No</label>	
                                                        <input type="radio" id="radio-one_Cross_Combination_status" formControlName="Cross_Combination_status" name="Cross_Combination_status" value="Yes" />
														<label for="radio-one_Cross_Combination_status" class="bs-form-label">Yes</label>
													</div>
												</div>
                                            </div>                                            
                                        </div>                                         
                                        <div class="error my-2" id="parentcheck_error" style="display:none">                                            
                                                Choose at least one optiion                                            
                                        </div>
                                        <p class="error" id="empcheck_error_4" style="display:none">
                                            This is required field
                                        </p> 
                                        <hr class="div_Parents" style="margin-bottom: 0px;display:none">                                                                                                                    

                                        <!---- Siblings_or_disabled_siblings --->
                                        <div class="div_Siblings_or_disabled_siblings" style="display: flex;padding-bottom: 10px;">
                                            <div class="col-sm-3" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Siblings/disabled siblings</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_Siblings_or_disabled_siblings" formControlName="Siblings_or_disabled_siblings" name="Siblings_or_disabled_siblings" value="No" (change)="show_family_otp1('Siblings_or_disabled_siblings', 'no')" />
                                                    <label for="radio-two_Siblings_or_disabled_siblings" id="Siblings_or_disabled_siblings_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_Siblings_or_disabled_siblings" formControlName="Siblings_or_disabled_siblings" name="Siblings_or_disabled_siblings" value="Yes" (change)="show_family_otp1('Siblings_or_disabled_siblings', 'yes')" />
                                                    <label for="radio-one_Siblings_or_disabled_siblings" id="Siblings_or_disabled_siblings_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-3" id="show_family_otp_Siblings_or_disabled_siblings" style="display:none;margin-top: 10px;">                                                
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="Age_Limit_of_the_Siblings_text">Minimum Range (In Years) *</label>
                                                    <input type="text" class="form-control" formControlName="Age_Limit_of_the_Siblings_text" name="Age_Limit_of_the_Siblings_text" id="Age_Limit_of_the_Siblings_text" (keypress)="isNumber($event)" (input)="age_limit($event,'sibling')" /> 
                                                    <p class="error" id="Age_Limit_of_the_Siblings_text_error" style="display:none">
                                                        Min Range is required
                                                    </p>
                                                </div>

                                                <!-- <div class="form-check col-sm-12 p-0">
                                                    <div class="row mt-3">
                                                        <div class="form-check col-sm-4">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Age Limit of the Siblings</label>
														</div>
														<div class="form-check col-sm-8" style="position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="Age_Limit_of_the_Siblings_text" name="Age_Limit_of_the_Siblings_text" id="Age_Limit_of_the_Siblings_text" (keypress)="isNumber($event)" (input)="age_limit($event,'sibling')" style="width:20%" /> 
															&nbsp; <span style="margin-top: 6px;font-size: 13px;font-family: 'Poppins';font-style: normal;">(Years)</span>
														</div>                                                
                                                    </div>       
                                                </div> -->

                                            </div>
                                        </div>      
                                        <p class="error" id="empcheck_error_5" style="display:none">
                                            This is required field
                                        </p>                                  
                                        <hr class="div_Siblings_or_disabled_siblings" style="margin-bottom: 0px;display:none">                                      

                                        <!---- Overall Age limit --->
                                        <div class="div_Overall_Age_limit" style="display: flex">
                                            <div class="col-sm-2 pt-3" style="padding: 10px;">
                                                <label class="form-check-label bs-radio-label mt-1" style="vertical-align: middle;">Overall age limit</label> 
                                                <div class="switch-field wFC">                                                    
                                                    <input type="radio" id="radio-two_overall_age_range" formControlName="overall_age_range" value="No" (change)="show_family_otp1('overall_age_range', 'no')" />
                                                    <label for="radio-two_overall_age_range" id="overall_age_range_no" class="bs-form-label">No</label>	
                                                    <input type="radio" id="radio-one_overall_age_range" formControlName="overall_age_range" name="overall_age_range" value="Yes" (change)="show_family_otp1('overall_age_range', 'yes')" />
                                                    <label for="radio-one_overall_age_range" id="overall_age_range_yes" class="bs-form-label">Yes</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12" id="show_family_otp_overall_age_range" style="display:none;margin-top: 10px;">
                                                <div class="col-sm-3 mt-2" style="margin: 0px 15px;">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="age_range_min">Minimum Range (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="age_range_min" name="age_range_min" id="age_range_min" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
                                                        <p class="error" id="age_range_min_error" style="display:none">
                                                            Min Range is required
                                                        </p>
                                                    </div>
                                                </div>                                                
                                                <div class="col-sm-3 mt-2">
                                                    <div class="form-group">
                                                        <label class="bs-form-label" for="age_range_max">Maximum Range (In Years) *</label>
                                                        <input type="text" class="form-control" formControlName="age_range_max" name="age_range_max" id="age_range_max" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
                                                        <p class="error" id="age_range_max_error" style="display:none">
                                                                Max Range is required
                                                        </p>
                                                    </div>
                                                </div>                                                

                                                <!-- <div class="form-check col-sm-12 p-0">
                                                    <div class="row mt-2">
                                                        <div class="form-check col-sm-2">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Min Range</label>
														</div>
														<div class="form-check col-sm-3 px-5" style="display:flex; position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="age_range_min" name="age_range_min" id="age_range_min" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />
															
														</div>
														<div class="form-check col-sm-2">
															<label class="form-check-label bs-radio-label" style="vertical-align: middle;">Max Range</label>
														</div>
														<div class="form-check col-sm-3 px-5" style="display:flex; position: relative; left: -60px; top: -5px;">
															<input type="text" class="form-control" formControlName="age_range_max" name="age_range_max" id="age_range_max" (keypress)="isNumber($event)" (input)="age_limit($event,'agerange')" />															
														</div>														
                                                    </div>       
                                                </div> -->

                                            </div>
                                        </div>
                                        <p class="error" id="empcheck_error_6" style="display:none">
                                            This is required field
                                        </p>

                                    </div>                                    
                                    
                                </div>                                

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->

                <!---- Sum Insured Coverage Accordian ---->
                <h5 class="bs-left-form-label accordheading SIheading" (click)="sumisuredaccord()">Sum Insured Coverage
                    <img id="sumisured_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="sumisured_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/sum-insured-coverage.png" class="headicon" />
                    <img id="SItick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="SItick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgSI">
                        <div class="value-container" id="ValueSI">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="sumisured_accord" class="accord_div">

                    <div class="row">                       
                        <div class="col-sm-12">
                            <div class="row">                                                                
                                
                                <div class="form-check col-sm-4" style="display:flex;">
                                    <input type="radio" class="form-radio-input specialcondition_check" value="Multiplier of base SI" formControlName="basis_of_topup_SI" name="basis_of_topup_SI" (click)="show_table('Multiplier')" id="basis_of_topup_SI"  style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiplier of base SI</label>
                                </div>                                                                                        
                                
                                <div class="form-check col-sm-4" style="display:flex;">
                                    <input type="radio" class="form-radio-input specialcondition_check" value="Graded Topup SI" formControlName="basis_of_topup_SI" name="basis_of_topup_SI" (click)="show_table('Graded')" id="basis_of_topup_SI" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded Topup SI</label>
                                </div>
                                
                                <div class="form-check col-sm-4" style="display:flex; padding-left: 5px;">
                                    <input type="radio" class="form-radio-input specialcondition_check" value="Open topup SI" formControlName="basis_of_topup_SI" name="basis_of_topup_SI" (click)="show_table('Open')" id="basis_of_topup_SI" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Open topup SI</label>
                                </div>
                                <p class="error" id="specialcondition_check_error" style="display:none">
                                    Choose at least one optiion
                                </p>

                                <!-- Display choosed option div -->
                                <div class="col-sm-12 div_Multiplier" style="display: none">
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                            <thead style="border: #4e0099;">
                                                <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                                    <td>Base Policy Sum Insured</td>
                                                    <td>Multiplier of SI</td>
                                                    <td>Topup SI</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let group of mygradedarray; let i = index">
                                                    <td>
                                                        <div class="form-group">
                                                            <label class="bs-form-label mt-2">{{group}}</label>
                                                            <input type="hidden" value="{{group}}" id="base_policy_SI_{{i}}" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <select class="form-select multiplier" id="multiplier_{{i}}" style="line-height: 1.7;" (change)="checkopt_table_SI('multiplier_'+i, $event, i)">
                                                                <option value="" selected>Select</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                            <p class="error" id="multiplier_{{i}}_error" style="display:none; color: #df0000; font-size: 13px;">
                                                                This field is required
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" id="open_toptup_{{i}}" class="form-control topupSI" style="pointer-events: none; background: #aaaaaa5c;" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-sm-12 div_Graded" style="display: none">
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                            <thead style="border: #4e0099;">
                                                <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                                    <td>Base Policy Sum Insured</td>                                                    
                                                    <td>Select Topup SI</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let group of mygradedarray; let i = index">
                                                    <td>
                                                        <div class="form-group">
                                                            <label class="bs-form-label mt-2">{{group}}</label>
                                                            <input type="hidden" value="{{group}}" id="base_policy_SI_{{i}}" />
                                                        </div>
                                                    </td>                                                    
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control open opentopup" id="open_{{i}}" (keypress)="isNumber($event)" />
                                                            <p class="error" id="open_{{i}}_error" style="display:none; color: #df0000; font-size: 13px;">
                                                                This field is required
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-sm-12 div_Open" style="display: none">
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered table-sm row-border hover mt-2 w1000" style="background: #4e009914;box-shadow: 0px 0px 6px 0.1rem;text-align: center;border: #4e009900;">
                                            <thead style="border: #4e0099;">
                                                <tr style="background: #4e0099;color: #ffffff;font-weight: bold; font-size: 14px;">
                                                    <td>Min Topup SI</td>                                                    
                                                    <td>Max Topup SI</td>
                                                    <td>Interval Topup SI</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" id="min_topup_SI" formControlName="min_topup_SI" (keypress)="isNumber($event)" />
                                                            <p class="error" id="min_topup_SI_error" style="display:none">
                                                                Min Topup SI is required
                                                            </p>
                                                        </div>
                                                    </td>                                                    
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" id="max_topup_SI" formControlName="max_topup_SI" (keypress)="isNumber($event)" />
                                                            <p class="error" id="max_topup_SI_error" style="display:none">
                                                                Max Topup SI is required
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" id="interval_topup_SI" formControlName="interval_topup_SI" (keypress)="isNumber($event)" />
                                                            <p class="error" id="interval_topup_SI_error" style="display:none">
                                                                Interval Topup SI is required
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- End -->

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->
                               
                <!---- Waiting period Condition Accordian ---->
                <h5 class="bs-left-form-label accordheading waitingheading" (click)="waitingPeriodCondition()">Special Conditon for Top Up Plan
                    <img id="WPC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="WPC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/waiting.png" class="headicon" />
                    <img id="waitingtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="waitingtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Progwaiting">
                        <div class="value-container" id="Valuewaiting">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="waitingPeriodCondition_accord" class="accord_div">

                    <div class="row">                    
                        <div class="col-sm-12">
                            <div class="row">                                

                                <div class="col-sm-6 form-group row">
                                    <div class="col-sm-6 form-group">
                                        <label class="bs-form-label" style="vertical-align: middle;">Policy Hygien</label> 
                                        <div class="switch-field wFC">                                                    
                                            <input type="radio" id="radio-two_policy_hygien" class="policy_hygien" formControlName="policy_hygien" name="policy_hygien" value="No" (change)="green_red_change('policy_hygien', 'no')" />
                                            <label for="radio-two_policy_hygien" id="policy_hygien_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_policy_hygien" class="policy_hygien" formControlName="policy_hygien" name="policy_hygien" value="Yes" (change)="green_red_change('policy_hygien', 'yes')" />
                                            <label for="radio-one_policy_hygien" id="policy_hygien_yes" class="bs-form-label">Yes</label>	
                                        </div>
                                        <p class="error" id="policy_hygien_error" style="display:none">
                                            Policy Hygien is required
                                        </p>
                                    </div>
                                </div>

                                <div class="yesdivpolicy" style="display:none">
                            
                                    <div class="col-sm-12 row form-group m-0 p-0 mt-3 entry_exit_clause">
                                        <div class="col-sm-4 form-group p-0 m-0">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="entry_exit_clause">Entry Exit clause <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'entry_exit_clause_div')" (mouseleave)="mouseLeave($event, 'entry_exit_clause_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="entry_exit_clause_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Entry Exit clause</p>
                                                </div>
                                            </div>
                                            <div class="switch-field wFC">  
                                                <input type="radio" id="radio-two_entry_exit_clause" formControlName="entry_exit_clause" name="entry_exit_clause" value="No" (change)="checkradio('diventry_exit_clause_limit', 'NotCovered')" />
                                                <label for="radio-two_entry_exit_clause" class="bs-form-label">No</label>
                                                <input type="radio" id="radio-one_entry_exit_clause" formControlName="entry_exit_clause" name="entry_exit_clause" value="Yes" (change)="checkradio('diventry_exit_clause_limit', 'Covered')" />
                                                <label for="radio-one_entry_exit_clause" class="bs-form-label">Yes</label>                                            
                                            </div>
                                            <p class="error" id="entry_exit_clause_error" style="display:none">
                                                Entry Exit clause is required
                                            </p>
                                        </div>
                                        <div class="col-sm-4 form-group entry_exit_clause_limit" id="diventry_exit_clause_limit" style="display: none">
                                            <label class="bs-form-label" for="entry_exit_clause_limit">If Yes then specify the clause *</label>
                                            <input type="text" class="form-control" formControlName="entry_exit_clause_limit" id="entry_exit_clause_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="entry_exit_clause_limit_error" style="display:none; margin-bottom: -10px;;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 row form-group m-0 p-0 mt-3 waver_of_base_SI_sub_limit">
                                        <div class="col-sm-4 form-group p-0 m-0">
                                            <div class="form-group">
                                                <label class="bs-form-label" for="waver_of_base_SI_sub_limit">Waver of Base SI Sub Limit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'waver_of_base_SI_sub_limit_div')" (mouseleave)="mouseLeave($event, 'waver_of_base_SI_sub_limit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                <div id="waver_of_base_SI_sub_limit_div" class="icon_div" style="display: none">
                                                    <p class="bs-form-label_icon">Waver of Base SI Sub Limit</p>
                                                </div>
                                            </div>
                                            <div class="switch-field wFC">
                                                <input type="radio" id="radio-two_waver_of_base_SI_sub_limit" formControlName="waver_of_base_SI_sub_limit" name="waver_of_base_SI_sub_limit" value="No" (change)="checkradio('divwaver_of_base_SI_sub_limit', 'NotCovered')" />
                                                <label for="radio-two_waver_of_base_SI_sub_limit" class="bs-form-label">No</label>
                                                <input type="radio" id="radio-one_waver_of_base_SI_sub_limit" formControlName="waver_of_base_SI_sub_limit" name="waver_of_base_SI_sub_limit" value="Yes" (change)="checkradio('divwaver_of_base_SI_sub_limit', 'Covered')" />
                                                <label for="radio-one_waver_of_base_SI_sub_limit" class="bs-form-label">Yes</label>                                            
                                            </div>
                                            <p class="error" id="waver_of_base_SI_sub_limit_error" style="display:none">
                                                Waver of Base SI Sub Limit is required
                                            </p>
                                        </div>
                                        <div class="col-sm-4 form-group waver_of_base_SI_sub_limit_limit" id="divwaver_of_base_SI_sub_limit" style="display: none">
                                            <label class="bs-form-label" for="waver_of_base_SI_sub_limit_limit">If Yes then specify the clause *</label>
                                            <input type="text" class="form-control" formControlName="waver_of_base_SI_sub_limit_limit" id="waver_of_base_SI_sub_limit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                            <p class="error" id="waver_of_base_SI_sub_limit_limit_error" style="display:none; margin-bottom: -10px;;">
                                                This field is required 
                                            </p>
                                        </div>
                                    </div>                                                                      
                                
                                </div>
                              
                                <div class="col-sm-12 form-group mt-3 morediv add_more_condition" style="display:none">
                                    <label class="bs-form-label" for="add_more_condition">Add More Condition</label>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_add_more_condition" formControlName="add_more_condition" name="add_more_condition" value="No" (click)="addBenefitField('no')" />
                                        <label for="radio-two_add_more_condition" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_add_more_condition" formControlName="add_more_condition" name="add_more_condition" value="Yes" (click)="addBenefitField('yes')" />
                                        <label for="radio-one_add_more_condition" class="bs-form-label">Yes</label>                                            
                                    </div>
                                    <p class="error" id="add_more_condition_error" style="display:none">
                                        Add More Condition is required
                                    </p>
                                </div>

                                <div class="divcondition" style="display:none">
                                    <div class="col-sm-12 mt-3">
                                        <div class="row newbenifitField">

                                        </div>
                                    </div>

                                    <div class="col-sm-12 mt-3">
                                        <label class="bs-form-label" (click)="addBenefitField('yes')" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add more condition</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <div class="row">
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">Document upload</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Document upload</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div *ngIf="stepper_flag == false">
                            As New policy is selected no need for uploading any doc, directly click on Finish
                        </div>
                        <div class="row" *ngIf="stepper_flag == true">                            
                            <!-- <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_your_active_member_data">Upload your active member data(CSV Template)</label>

                                    <label for="file_field2" id="custom-file-upload_upload_your_active_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_your_active_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field2" type="file" (change)="upload_your_active_member_data_change($event)" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_your_active_member_data">Upload only policy template in .csv, .xls & .xlxs format (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadcsv()">Download CSV Template</span>
                            </div> -->

                            <!-- <div class="col-sm-6">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="active_member_data_report_date">Active member data report date *</label>
                                    <input type="date" class="form-control" formControlName="active_member_data_report_date" id="active_member_data_report_date"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors">
                                        <p class="error"
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.active_member_data_report_date.errors.required">
                                        Active member data report date is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_claim_MIS">Upload claim MIS(csv template as per the TPA)</label>

                                    <label for="file_field3" id="custom-file-upload_upload_claim_MIS" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_claim_MIS" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field3" type="file" (change)="upload_claim_MIS_change($event)" accept=".csv, .xls, .xlsx" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_claim_MIS">Upload only policy template in .csv, .xls & .xlxs format (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadclaimMIS()">Download Claim MIS Template</span>
                            </div> -->

                            <!-- <div class="col-sm-6">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="claim_MIS_date">Claim MIS date *</label>
                                    <input type="date" class="form-control" formControlName="claim_MIS_date" id="claim_MIS_date"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors}"
                                    required />
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors">
                                        <p class="error"
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.claim_MIS_date.errors.required">
                                        Company Headcount is required
                                        </p>
                                    </ng-container>
                                </div>
                            </div> -->

                            <!-- <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="upload_claim_pdf_summary">Upload claim pdf summary</label>

                                    <label for="file_field4" id="custom-file-upload_upload_claim_pdf_summary" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_claim_pdf_summary" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field4" type="file" (change)="upload_claim_pdf_summary_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_upload_claim_pdf_summary">Upload only PDF files (Max size 4MB)</small>
                            </div> -->

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_document">Policy Document</label>
                                    <!-- <input type="file" class="form-control" formControlName="policy_document" (change)="policy_document_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field5" id="custom-file-upload_policy_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>&nbsp; <span style="color:#AAAAAA">or drop file here</span>
                                    </label>
                                    <input id="file_field5" type="file" (change)="policy_document_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_policy_document">Upload only PDF files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-12">
                                <div class="row newuploadField">

                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addUploadField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Upload Field</span>
                                </label>
                            </div>


                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right; background: #fff; border: solid 1px #4E0099; color: #4E0099;">Previous</button>
                    </div>
                </div>
                </form>
            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">
                <!-- <div class="modal-header">
                    <h4 class="modal-title">Hurray!!</h4>
                </div> -->
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Plan created successfully.</h6>
                            <h6 class="mt-2 text-center" style="font-weight: 600; font-size: 14px;">Your Plan ID : {{proposal_number_show}}</h6>

                            <div class="form-group mt-3">
                                <label class="bs-form-label text-center" style="margin: auto; display: block;"> Enter Plan Name </label>
                                <input type="text" class="form-control" id="plan_name" name="plan_name" />
                                <p class="error" id="plan_name_error" style="display: none">
                                    This field is required
                                </p>
                            </div>

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closePopup()" style="margin: auto;"> Submit & Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
