<div class="bs-stepper bg-white my-4 mb-5 rounded p-3">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <h3 class="bs-title">Manage Industry
      <button type="button" class="btn btn-success" (click)="openPopupAdd()" role="button" style="float:right">Add New</button>
    </h3>
    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
        <strong>Success! {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
        <strong>Something went wrong. {{ validmsg }} <span class="alertspan">&times;</span></strong>
    </div>
    <hr>

    <div class="table-responsive mt-4">
        <table class="table table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th style="width:5%">SrNo</th>
                <th>Industry Name</th>
                <th>Industry Tag</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let group of InfoArray; let i = index">
                    <td style="width:5%">{{i+1}}</td>
                   <td>{{group['industry_name']}}</td>                
                   <td>{{group['industry_tag']}}</td>                
                   <td style="width: 15%" align="center">
                    <!-- <img src="../../../assets/img/common icons/eye.png" id="viewuser" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="View" /> -->
                    <img src="../../../assets/img/common icons/edit.png" id="edituser" (click)="openPopupEdit(group)" class="img-fluid" role="button" style="width:20px; margin-right: 5px" title="Edit" />
                    <img src="../../../assets/img/common icons/trash.png" id="deleteuser" (click)="openPopupDelete(group)" role="button" class="img-fluid" style="width:20px" title="Delete" />
                   </td>
               </tr>
            </tbody>
          </table>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_add}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Add Country</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="AddInfo">                    
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="industry_name">Industry Name *</label>
                            <input type="text" class="form-control" formControlName="industry_name" id="industry_name" placeholder="Industry Name"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry_name.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_name.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_name.errors.required">
                                    Industry Name is required
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="bs-form-label" for="industry_tag">Industry Tag *</label>
                            <input type="text" class="form-control" formControlName="industry_tag" id="industry_tag" placeholder="Industry Tag"
                            [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors}"
                            required />
                            <ng-container
                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors">
                                <p class="error"
                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.industry_tag.errors.required">
                                    Industry Tag is required
                                </p>
                            </ng-container>
                        </div>
                    </div>                                                                        
                </div>
            </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitAdd()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle_edit}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Edit Industry</h4>
          </div>
          <div class="modal-body">
              
            <form [formGroup]="EditInfo">                    
              <div class="row">
                  <div class="col-sm-6">
                      <div class="form-group">
                          <label class="bs-form-label" for="industry_name_edit">Industry Name *</label>
                          <input type="text" class="form-control" formControlName="industry_name_edit" id="industry_name_edit" placeholder="Industry Name"
                          [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors}"
                          required />
                          <ng-container
                              *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors">
                              <p class="error"
                                  *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_name_edit.errors.required">
                                  Industry Name is required
                              </p>
                          </ng-container>
                      </div>
                  </div>                              
                  <div class="col-sm-6">
                    <div class="form-group">
                        <label class="bs-form-label" for="industry_tag_edit">Industry Tag *</label>
                        <input type="text" class="form-control" formControlName="industry_name_edit" id="industry_tag_edit" placeholder="Industry Tag"
                        [ngClass]="{'control-red': EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors}"
                        required />
                        <ng-container
                            *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors">
                            <p class="error"
                                *ngIf="EditInfoSubmitted && getEditInformationFormControls.industry_tag_edit.errors.required">
                                Industry Tag is required
                            </p>
                        </ng-container>
                    </div>
                </div> 
                  <input type="hidden" class="form-control" formControlName="edit_id" id="edit_id" />
              </div>
          </form>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="onSubmitEdit()" role="button">Save</button>                    
              <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
          </div>
          </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleDelete}">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title">Delete Industry</h4>
              </div>
              <form [formGroup]="DeleteInfo">
                  <div class="modal-body">                                
                      Are you sure you want to delete?
                  </div>
                  <input type="hidden" class="form-control" formControlName="delete_id" id="delete_id" />                                                        
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" (click)="onSubmitDelete()" role="button">Delete</button>                    
                      <button type="button" class="btn btn-danger" (click)="closePopup()"> Close</button>
                  </div>
              </form>            
          </div>
      </div>
    </div>

</div>