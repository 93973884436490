import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  title = 'NeoDigit'; 
  
  ngOnInit(): void {
    /****** Disable Print Screen *******/
    $('body').on("keyup", function (e:any) {
      if (e.keyCode == 123 || e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)
      || e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0) || e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {              
          return false;
      }else if (e.keyCode == 44){
          var inpFld:any = document.createElement("input");
          inpFld.setAttribute("value", ".");
          inpFld.setAttribute("width", "0");
          inpFld.style.height = "0px";
          inpFld.style.width = "0px";
          inpFld.style.border = "0px";
          document.body.appendChild(inpFld);
          inpFld.select();
          document.execCommand("copy");
          inpFld.remove(inpFld);
          alert('Screen Shot is RESTRICTED on this NeoDigit!!');
          return true;
      }else{
        return true;
      }                      
    });
    /****** END *******/
  }

}
