import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  username: any;
  restatus: any;
  user_role: any;
  user_company: any;
  current_page_url: any;
  pagehide_status: any;
  plan_name: any;

  flow1: any;
  flow2: any;
  flow3: any;

  pbar: boolean = false;
  element: any;
  isMobile: any;
  avtivenav:any;
  surveyLoginFlag: any;
  modelpallet = "none";

  public pallet:FormGroup;
  palletSubmitted: boolean = false;

  constructor(private route: Router, private fb: FormBuilder) {    
    
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.username = localStorage.getItem('username');
        this.restatus = localStorage.getItem('resstatus');
        this.user_role = localStorage.getItem('type_name');
        this.user_company = localStorage.getItem('company_name');     
        this.plan_name = localStorage.getItem('plan_name');
        
        this.flow1 = localStorage.getItem('flow1');
        this.flow2 = localStorage.getItem('flow2');
        this.flow3 = localStorage.getItem('flow3');

        // console.log(e.url);
        this.current_page_url = e.url;
        this.pagehide_status = localStorage.getItem('pagehide');        

        if(this.pagehide_status == 'no' && e.url == '/verify-email'){
            localStorage.clear();
            this.route.navigate(['/home']);
        }
        if(this.current_page_url != '/home' || this.current_page_url != '/login' || this.current_page_url != '/register' || this.current_page_url != '/verify-mobile' || this.current_page_url != '/verify-email'){
          $('.bg-white').addClass('containerclass');
        }else{
          $('.bg-white').removeClass('containerclass');
        }

        if(this.flow1 == 'yes' && this.flow2 == 'yes' && this.flow3 == 'yes'){
          
          setTimeout(() => {
            localStorage.setItem('flow1','no');
            localStorage.setItem('flow2','no');
            localStorage.setItem('flow3','no');
            this.element = document.getElementById("pbar");
            this.element.style.display = "none";     

          }, 3000);          
          this.pbar = true;
        }        
        
        this.surveyLoginFlag = localStorage.getItem('surveyLoginFlag');

      }    

      this.isMobile = window.matchMedia("only screen and (max-width: 900px)").matches;                

   });   
  }

  ngOnInit(): void {                           

    window.addEventListener('DOMContentLoaded', event => {

      this.activeNav(localStorage.getItem('activenav'), localStorage.getItem('activenavgroup'));
      
      // Toggle the side navigation
      const toggleiconleft = document.body.querySelector('#toggleiconleft');
      if (toggleiconleft) {
          // Uncomment Below to persist sidebar toggle between refreshes
          // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          //     document.body.classList.toggle('sb-sidenav-toggled');
          // }
          toggleiconleft.addEventListener('click', event => {
              event.preventDefault();
              document.body.classList.toggle('sb-sidenav-toggled');

              var act = $('body').hasClass("sb-sidenav-toggled");
              if(act){                
                $(".sidebarlogo").addClass("logoshowhide");
                $(".sidebaricon").addClass("iconshowhide");
                $(".sidebariconprofile").addClass("iconshowhideprofile");
                $(".dropdown-menu").addClass("profileicon");
                $(".toggleiconright").css("display", "block");
                $(".toggleiconleft").css("display", "none");
                $(".sidebarhr").css("display", "none");
                $(".sidebarhrshort").css("display", "block");  
                // $(".activenav").css("background", "#fff");  
                $(".activenav").addClass("activenavIMP");          
              }else{
                $(".sidebarlogo").removeClass("logoshowhide");
                $(".sidebaricon").removeClass("iconshowhide");
                $(".sidebariconprofile").removeClass("iconshowhideprofile");
                $(".dropdown-menu").removeClass("profileicon");
                $(".toggleiconright").css("display", "none");
                $(".toggleiconleft").css("display", "block");
                $(".sidebarhr").css("display", "block");
                $(".sidebarhrshort").css("display", "none");
                // $(".activenav").css("margin-right", "20px");                
              }
              // localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
          });
      }

      const toggleiconright = document.body.querySelector('#toggleiconright');
      if (toggleiconright) {
          // Uncomment Below to persist sidebar toggle between refreshes
          // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          //     document.body.classList.toggle('sb-sidenav-toggled');
          // }
          toggleiconright.addEventListener('click', event => {
              event.preventDefault();
              document.body.classList.toggle('sb-sidenav-toggled');

              var act = $('body').hasClass("sb-sidenav-toggled");
              if(act){
                $(".sidebarlogo").addClass("logoshowhide");
                $(".sidebaricon").addClass("iconshowhide");
                $(".sidebariconprofile").addClass("iconshowhideprofile");
                $(".dropdown-menu").addClass("profileicon");
                $(".toggleiconright").css("display", "block");
                $(".toggleiconleft").css("display", "none");
                $(".sidebarhr").css("display", "none");
                $(".sidebarhrshort").css("display", "block");
                // $(".activenav").css("margin-right", "0px");              
              }else{
                $(".sidebarlogo").removeClass("logoshowhide");
                $(".sidebaricon").removeClass("iconshowhide");
                $(".sidebariconprofile").removeClass("iconshowhideprofile");
                $(".dropdown-menu").removeClass("profileicon");
                $(".toggleiconright").css("display", "none");
                $(".toggleiconleft").css("display", "block");
                $(".sidebarhr").css("display", "block");
                $(".sidebarhrshort").css("display", "none");
                // $(".activenav").css("background", "#868686");
                $(".activenav").removeClass("activenavIMP");          
              }
              // localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
          });
      }
        
    });

    // Form element defined below
    this.pallet = this.fb.group({
      sidebarinput: new FormControl('')      
    });

  } 

  signout(){

    localStorage.clear();
    this.route.navigate(['/home']);

  }

  activeNav(val:any, groupnav:any){
    
    console.log(val, groupnav);

    $("a").removeClass("activenav");
    
    localStorage.setItem('activenav', val);
    localStorage.setItem('activenavgroup', groupnav);

    $('#'+ val).addClass('activenav');

    if(groupnav != ''){
      $('#' + groupnav).addClass('activenav');
    }

  }

  colorpallet(){
    this.modelpallet = "block";
  }

  closePopup() {    
    this.modelpallet = "none";    
  }

  onSave(){
    
    this.modelpallet = "none";    
    const body = document.body;
    var id = this.pallet.controls['sidebarinput'].value;
    
    // if(id == "pink"){    
    //   body.style.setProperty('--bg-sidebar', '#E91E63');
    // }

    // if(id == "blue"){    
    //   body.style.setProperty('--bg-sidebar', '#1e5ce9');
    // }
    
    // if(id == "orange"){    
    //   body.style.setProperty('--bg-sidebar', '#e96f1e');
    // }

    body.style.setProperty('--bg-sidebar', (id));

  }

}
